import { memo, useMemo, useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import { Card, Typography } from 'antd'

import { IDashboardOverview } from '@interfaces'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'
import { CustomFilterDashboard } from 'src/pages/dashboard/custom-filter'
import { MiniDashboard } from 'src/pages/dashboard/overview/MiniDashboard'
import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

interface IProps {
  overviewData?: IDashboardOverview
  onChangeFilterOverview: ({
    filterBy,
    startDate,
    endDate,
  }: {
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }) => void
  disabled?: boolean
  isLoading?: boolean
}

export const DashboardOverview = memo(
  ({ overviewData, onChangeFilterOverview, disabled, isLoading }: IProps) => {
    const baseColor = themes.theme.dark.colors.miniDashboard
    const overviewList = useMemo(() => {
      if (!overviewData) return []

      return [
        {
          label: t('dashboard:revenue'),
          value: `€${formatCurrencyByType(overviewData?.totalRevenue ?? 0, 'EUR').replace('€', '')}`,
          bgColor: baseColor.lightBlue,
          barColor: baseColor.darkBlue,
        },
        {
          label: t('dashboard:course_sold'),
          value: overviewData?.totalCourseSold ?? 0,
          bgColor: baseColor.lightOrange,
          barColor: baseColor.darkOrange,
        },
        {
          label: t('dashboard:total_student'),
          value: overviewData?.totalStudent ?? 0,
          bgColor: baseColor.lightGreen,
          barColor: baseColor.darkGreen,
        },
        {
          label: t('dashboard:total_enroll_completed'),
          value: overviewData?.totalEnrollCompleted ?? 0,
          bgColor: baseColor.lightYellow,
          barColor: baseColor.darkYellow,
        },
      ]
    }, [baseColor, overviewData])

    return (
      <Card className="!rounded-[8px]">
        <TitleWrapper>
          <Typography.Title level={2} className="!text-[24px]">
            {t('dashboard:overview')}
          </Typography.Title>
          <CustomFilterDashboard
            containerClassName="!min-w-[280px]"
            onChangeFilterOverview={onChangeFilterOverview}
            isDisable={disabled}
          />
        </TitleWrapper>
        <MiniDashboardWrapper>
          {overviewList?.map((item) => (
            <MiniDashboard
              key={item?.label}
              label={item?.label}
              value={item?.value}
              bgColor={item?.bgColor}
              barColor={item?.barColor}
              isLoading={isLoading}
            />
          ))}
        </MiniDashboardWrapper>
      </Card>
    )
  }
)

const MiniDashboardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
