import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuProps, Tooltip } from 'antd'

import { NavMenuIcon, Sidebar } from '@components'
import { EPermissions, MAT_SM_SCREEN_WIDTH, SIDEBAR_DATA } from '@configs'
import {
  RootState,
  selectApp,
  selectLayout,
  setSidebarCollapsed,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { LogApp, useForceUpdate, useMediaQuery } from '@utils'
import usePermissions from 'src/hooks/usePermissions'

export const SidebarModule = memo(() => {
  const { themeMode } = useAppSelector(selectApp)

  const { sidebarCollapsed, navCurrentKey } = useAppSelector(selectLayout)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { accountInfo } = useSelector((state: RootState) => state.auth)
  const userRole = accountInfo?.client ?? ''

  const forceUpdate = useForceUpdate()

  const isSMScreen = useMediaQuery(`(max-width:${MAT_SM_SCREEN_WIDTH})`)
  const { permissions, isAdmin } = usePermissions({ moduleName: '' })

  const handleChangeCollapsed = (value: boolean) => {
    dispatch(setSidebarCollapsed(value))
  }

  const handleSelectMenuItem: MenuProps['onClick'] = (e) => {
    LogApp('selectNav', e)
    const currentKey = e.key

    navigate(e.key)
  }

  const navbarItems: MenuProps['items'] = SIDEBAR_DATA.map((item, _) => {
    LogApp('C0', item)
    // role config
    // const isShow = isAdmin
    //   ? true
    //   : item?.permissionViewKeys?.some(
    //       (permissionKey) =>
    //         !!(permissions as Record<EPermissions, boolean>)?.[permissionKey]
    //     )

    // if (!isShow) return null

    if (item?.role?.includes(userRole)) {
      return {
        key: item.path,
        path: item.path,

        icon: (
          <Tooltip title={item?.label}>
            <NavMenuIcon
              key={item.pathKey}
              pathKey={item.pathKey}
              className="menu__icon"
            />
          </Tooltip>
        ),
        label: item?.label,
        children: item?.subItems?.length
          ? item?.subItems?.map((subItem, _) => {
              // role config
              // const isShow = isAdmin
              //   ? true
              //   : subItem?.permissionViewKeys?.some(
              //       (permissionKey) =>
              //         !!(permissions as Record<EPermissions, boolean>)?.[
              //           permissionKey
              //         ]
              //     )
              // if (!isShow) return null
              if (subItem?.role?.includes(userRole)) {
                return {
                  key: subItem?.path,
                  label: subItem?.label,
                  path: subItem.path,
                  icon: (
                    <Tooltip placement="right" title={subItem?.label}>
                      <NavMenuIcon
                        key={subItem.path}
                        pathKey={subItem?.pathKey}
                        className="sub-menu__icon"
                        size={20}
                      />
                    </Tooltip>
                  ),
                  children: subItem?.subOptions?.length
                    ? subItem?.subOptions?.map((option, _) => {
                        return {
                          key: option?.path,
                          label: option?.label,
                          path: option.path,
                          icon: (
                            <Tooltip title={option?.label}>
                              <NavMenuIcon
                                key={option?.path}
                                pathKey={option?.pathKey}
                                className="sub-option__icon"
                                size={20}
                              />
                            </Tooltip>
                          ),
                        }
                      })
                    : undefined,
                }
              }
              return null
            })
          : undefined,
      }
    }
    return null
  })

  return (
    <Sidebar
      isSMScreen={isSMScreen}
      collapsed={sidebarCollapsed}
      onChangeCollapsed={handleChangeCollapsed}
      themeMode={themeMode}
      navbarItems={navbarItems}
      onSelectMenuItem={handleSelectMenuItem}
    />
  )
})
