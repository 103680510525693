import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { t } from 'i18next'

import { Input } from 'src/common'
import { TDegreeForm } from 'src/pages/auth/sign-up/fill-in-teacher-information/types'
import { StyledExperienceForm } from 'src/pages/auth/sign-up/fill-in-teacher-information/work-experiences/ExperienceForm'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { ALLOW_FILE_DEGREE_TYPES, DEGREE_MAX_FILE_SIZE } from './configs'
import { authActions, useAppDispatch } from '@redux'
import { UPLOAD_DEGREE_LOADING_KEY } from 'src/pages/auth/sign-up/configs'
import { EUploadFileType } from '@configs'

export const DegreeForm = ({ index }: TDegreeForm) => {
  const dispatch = useAppDispatch()
  const onChangeDesktopRef = useRef<any>(null)

  const { control, setValue, watch } = useFormContext()

  const watchDegreeFile = watch(
    `specificInformation.education.${index}.degree.mediaFile`
  )

  return (
    <StyledExperienceForm>
      <Controller
        name={`specificInformation.education.${index}.degree.file.name`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={'Certificate'}
              alignment="col"
              placeholder={'Input certificate name'}
              name={`specificInformation.education.${index}.degree.file.name`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              // required
            />
          )
        }}
      />

      <Controller
        name={`specificInformation.education.${index}.degree.file.fileId`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          onChangeDesktopRef.current = onChange
          return (
            <div className="col-span-8 mt-5">
              <CustomDragger
                label={t('common:file')}
                alignment="col"
                name={`specificInformation.education.${index}.degree.file.fileId`}
                id={`specificInformation.education.${index}.degree.file.fileId`}
                onLoadEnd={(data) => {
                  setValue(
                    `specificInformation.education.${index}.degree.file.fileId`,
                    Number(data?.dataValues?.id || 0)
                  )
                  setValue(
                    `specificInformation.education.${index}.degree.mediaFile`,
                    {
                      ...data?.dataValues,
                      url: data?.baseUrl || '',
                    }
                  )
                  onChange(data?.dataValues?.id || 0)
                }}
                fileName={watchDegreeFile?.name ?? ''}
                errors={error?.message}
                multiple={false}
                initResource={watchDegreeFile?.url ?? ''}
                allowFileTypes={ALLOW_FILE_DEGREE_TYPES}
                limitFileSize={DEGREE_MAX_FILE_SIZE}
                labelClassName="w-full"
                changeLoading={(isLoading) => {
                  dispatch(
                    authActions.setLoadings({
                      key: UPLOAD_DEGREE_LOADING_KEY,
                      isLoading,
                    })
                  )
                }}
                uploadType={EUploadFileType.OTHERS}
                needAuthorized={false}
              />
            </div>
          )
        }}
      />
    </StyledExperienceForm>
  )
}
