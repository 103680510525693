import { CheckOutlined, HomeOutlined } from '@ant-design/icons'
import { AppModal } from '@components'
import { Typography } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { Button } from 'src/common'

export type TModalType = 'success' | 'fail'

export type ModalPaymentStatusRef = {
  present: () => void
  close: () => void
}

interface IModalPaymentStatusProps {
  cardInformation?: any
  onClose?: () => void
  onSubmit?: () => void
}

export const ModalPaymentStatus = React.forwardRef(
  (
    { onClose, onSubmit  }: IModalPaymentStatusProps,
    ref: React.Ref<ModalPaymentStatusRef>
  ) => {
    const [visible, setVisible] = React.useState<boolean>(false)

    React.useImperativeHandle(ref, () => ({
      present: () => {
        setVisible(true)
      },
      close: handleClose,
    }))

    const handleClose = () => {
      setVisible(false)
    }

    const handleSubmit = () => {
      onSubmit?.()
      setVisible(false)
    }

    return (
      <AppModal open={visible} haveCloseIcon={false}>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="flex items-center justify-center h-20  w-20 rounded-full bg-green-500">
            <CheckOutlined className=" text-4xl !text-white" />
          </div>
          <Typography.Paragraph className=" text-xl font-semibold !text-main mt-10">
            {t('subscription:upgradeSubscriptionSuccessfully')}
          </Typography.Paragraph>

          <Typography.Paragraph className="text-base">
            {t('subscription:payment_successfully_description')}
          </Typography.Paragraph>
          <div className="flex items-center w-full justify-center mt-10">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="!item-center !flex"
              onClick={handleSubmit}
            >
              <HomeOutlined />
              {t('subscription:backToHome')}
            </Button>
          </div>
        </div>
      </AppModal>
    )
  }
)
