import { Card, Typography } from 'antd'
import styled from 'styled-components'
import { SignatureForm } from './SignatureForm'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { SignatureView } from './SignatureView'
import { EditOutlined } from '@ant-design/icons'

export const SignatureSection = ({
  isViewMode,
  isRegister = false,
}: {
  isViewMode: boolean
  isRegister?: boolean
}) => {
  const { watch } = useFormContext()
  const signatureData = watch('specificInformation.teacherSignature')
  const [editable, setEditable] = useState(false)
  const noData = !Object.keys(signatureData?.signaturePhoto || {})?.length

  useEffect(() => {
    if (isViewMode) {
      setEditable(false)
      return
    }

    if (isRegister) {
      setEditable(true)
      return
    }

    if (!signatureData?.signaturePhoto) return

    setEditable(!!noData)
  }, [isRegister, isViewMode, noData, signatureData?.signaturePhoto])

  return (
    <SignatureSectionContainerStyled className="flex items-center justify-center w-full">
      <div className="flex justify-between w-full">
        <Typography.Title level={4} className="text-left !mb-0">
          Teacher Signature
        </Typography.Title>

        {(() => {
          if (editable || isViewMode) return null

          return (
            <EditOutlined
              className="self-end mr-3 text-xl text-gray-500"
              onClick={() => {
                setEditable(true)
              }}
            />
          )
        })()}
      </div>

      <SignatureContentStyled className="!border-none flex items-center justify-center w-full">
        {(() => {
          if (editable) return <SignatureForm setEditable={setEditable} />

          return <SignatureView data={signatureData} />
        })()}
      </SignatureContentStyled>
    </SignatureSectionContainerStyled>
  )
}

const SignatureSectionContainerStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`

const SignatureContentStyled = styled(Card)`
  .ant-card-body {
    padding: 0;
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
