import { message } from 'antd'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseProps,
  ICommonDataResponse,
  ILoginRequest,
} from '@interfaces'
import { authAPI } from 'src/api/auth'
import { QK_VERIFY_EMAIL } from 'src/configs/queryClient/queryKeyConstants'

type IMutationOptions = Omit<
  UseMutationOptions<BaseResponseProps<any>, any, ILoginRequest, unknown>,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

interface IMutationParams {
  configs?: IMutationOptions
  handleSuccess?: (res: ICommonDataResponse) => void
}

export const verifyEmailFn = (values: ILoginRequest) =>
  authAPI.forgotPassword({ email: values.email })

export const useMutationVerifyEmail = (params?: IMutationParams) => {
  const { configs, handleSuccess } = { ...params }

  return useMutation({
    mutationKey: [QK_VERIFY_EMAIL],
    mutationFn: verifyEmailFn,
    onSuccess: (res) => {
      message.success({
        content: res?.message,
      })
      handleSuccess?.(res?.data)
    },
    onError: (error) => {
      message.error({
        content: error?.message,
      })
    },

    ...configs,
  })
}
