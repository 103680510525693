import { useLocation } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { isNil } from 'lodash'
import styled from 'styled-components'

import { Button } from 'src/common'
import { ExperienceSection } from './ExperienceSection'
import { TWorkExperience } from '@interfaces'
import { TWorkExperiences } from '../types'
import { PATH_UPDATE_APPLICATION } from '@configs'
import { TEACHER_INFO_SPECIFIC_INFO_VALIDATION } from 'src/pages/auth/sign-up/configs'

export const WorkExperiences = ({
  isViewMode,
  canEdit = false,
}: TWorkExperiences) => {
  const { pathname } = useLocation()
  const isUpdatingApplication = [PATH_UPDATE_APPLICATION].includes(pathname)

  const { control, getValues, setValue, watch } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'specificInformation.workExperience',
    rules: {
      minLength: TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MIN_LENGTH,
      maxLength: TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH,
    },
  })
  const removableField = fields?.length > 1 && !isViewMode
  const [isAddableField, setAddableField] = React.useState<boolean>(false)
  const watchWorkExperienceValue = watch(`specificInformation.workExperience`)

  const isEditting = watchWorkExperienceValue?.some((it: any) => !!it?.editable)

  const verifyAddableField = () =>
    watchWorkExperienceValue?.length <
    TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH

  const handleRemoveField = (id?: number) => {
    remove(id)

    const isAddableValue =
      watchWorkExperienceValue?.length >=
      TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH
        ? true
        : verifyAddableField()
    setAddableField(isAddableValue)
  }

  const handleSaveField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    // NOTE:  useFieldArray cannot auto update fields value, you should do it by your hand
    update(id, getValues(`specificInformation.workExperience[${id}]`))
    setAddableField(verifyAddableField())
  }

  const handleAddField = () => {
    const defaultFieldValue: TWorkExperience = {
      companyName: '',
      jobTitle: '',
      description: '',
      startDate: '',
      endDate: '',
      editable: true,
      isPresent: false,
    }
    append(defaultFieldValue)
    setAddableField(false)
  }

  const handleEditField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    setValue(`specificInformation.workExperience[${id}].editable`, true)
    update(id, getValues(`specificInformation.workExperience[${id}]`))
    setAddableField(false)
  }

  useEffect(() => {
    if (isUpdatingApplication || canEdit) {
      setAddableField(true)
    }
  }, [canEdit, isUpdatingApplication])

  return (
    <WorkExperiencesStyled className="flex items-center justify-center w-full">
      <Typography.Title level={4} className="text-left">
        Professional Information
      </Typography.Title>
      {fields?.map((field: any, index) => {
        return (
          <ExperienceSection
            key={field.id}
            data={field}
            index={index}
            onRemove={removableField ? handleRemoveField : undefined}
            onSave={handleSaveField}
            onEdit={isViewMode ? undefined : handleEditField}
          />
        )
      })}
      {isAddableField && !isEditting ? (
        <div className="flex items-center max-[800px] justify-center my-5">
          <Button
            htmlType="submit"
            type="primary"
            size="small"
            className="min-[600px] max-[600px]:!w-full"
            onClick={handleAddField}
          >
            {t('common:addExperience')}
          </Button>
        </div>
      ) : null}
    </WorkExperiencesStyled>
  )
}

const WorkExperiencesStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
