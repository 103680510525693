import { DownloadOutlined } from '@ant-design/icons'
import { PLACEHOLDER_IMAGE_AVATAR } from '@configs'
import { useAppSelector } from '@redux'
import { themes } from '@theme'
import { Button, Typography } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Loading } from 'src/common'
import useCheckConnection from 'src/hooks/useCheckConnection'
import { EMessageType, IMedia } from 'src/interfaces/course'
import { downloadFileFromUrl } from 'src/utils/file'

export default function MessageItem({
  message,
  isShowSentTime,
  handleClickImage,
  receiverId,
  messageRef,
}: {
  message: any
  isShowSentTime: boolean
  receiverId: number
  handleClickImage: () => void
  messageRef?: React.RefObject<HTMLDivElement> | null
}) {
  const { t } = useTranslation(['courseManagement'])
  const { isOffline } = useCheckConnection()
  const sendingMessageIds = useAppSelector(
    (state) => state.course.sendingMessageIds
  )
  const {
    content,
    type,
    media,
    user,
    userId,
    createdAt,
    tempId: sendingId,
  } = message || {}
  const isReceiverMessage = userId === receiverId
  const isSendingMessage =
    sendingMessageIds?.some((id) => id === sendingId) && !isOffline
  const isSendMessageError =
    sendingMessageIds?.some((id) => id === sendingId) && isOffline

  return (
    <>
      {isReceiverMessage ? (
        <>
          <div className="clear-both"></div>
          <div
            className="float-left mb-4 flex max-w-[100%] items-start relative"
            ref={messageRef}
          >
            <div className="image-fit relative mr-2 hidden h-10 w-10 flex-none sm:block">
              <img
                className="rounded-full w-full h-full object-cover"
                src={user?.profilePhoto?.url || PLACEHOLDER_IMAGE_AVATAR}
                alt="Alt text"
              />
            </div>
            <div className="flex flex-col items-start max-w-[90%]">
              <div className="rounded-r-md rounded-t-md bg-slate-100 px-4 py-3 text-slate-500 dark:bg-darkmode-400 w-fit">
                <MessageContent
                  messageType={type}
                  message={content}
                  media={media}
                  isMyMessage={false}
                  isSendingMessage={isSendingMessage}
                  handleClickImage={handleClickImage}
                />
              </div>

              {isShowSentTime ? (
                <div className="mt-1 text-xs text-slate-500">
                  <Typography>{moment(createdAt).format('hh:mm A')}</Typography>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="clear-both"></div>
          <div
            className="float-right mb-4 flex max-w-[100%] items-start justify-end relative"
            ref={messageRef}
          >
            <div className="flex flex-col items-end max-w-[90%]">
              <div className="rounded-l-md rounded-t-md bg-primary opacity-90 px-4 py-3 w-fit">
                <MessageContent
                  messageType={type}
                  message={content}
                  media={media}
                  isMyMessage
                  isSendingMessage={isSendingMessage}
                  handleClickImage={handleClickImage}
                />
              </div>

              <div className="mt-1 text-xs text-slate-500">
                {isShowSentTime ? (
                  <Typography>
                    {moment(createdAt).format('hh:mm A')}-
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                      }}
                    >
                      {user?.name}
                    </span>
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                    }}
                  >
                    {user?.name}
                  </Typography>
                )}
              </div>

              {isSendingMessage ? (
                <Typography
                  style={{
                    color: '#8a8a8a',
                    fontSize: 12,
                  }}
                >
                  {t('courseManagement:sending')}
                </Typography>
              ) : null}

              {isSendMessageError ? (
                <Typography
                  style={{
                    color: `${themes.theme.light.colors.error}`,
                    fontSize: 12,
                  }}
                >
                  {t('courseManagement:failed')}
                </Typography>
              ) : null}
            </div>

            <div className="image-fit relative ml-2 hidden h-10 w-10 flex-none sm:block">
              <img
                className="rounded-full w-full h-full object-cover"
                src={user?.profilePhoto?.url || PLACEHOLDER_IMAGE_AVATAR}
                alt="Alt text"
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

function MessageContent({
  messageType,
  message,
  media,
  isMyMessage,
  isSendingMessage,
  handleClickImage,
}: {
  messageType: EMessageType
  message: string
  media: IMedia
  isMyMessage: boolean
  isSendingMessage: boolean
  handleClickImage?: () => void
}) {
  const [isDownloadingFile, setIsDownloadingFile] = useState(false)

  const handleDownloadFile = async (url: string) => {
    try {
      setIsDownloadingFile(true)
      await downloadFileFromUrl(url, false)
      setIsDownloadingFile(false)
    } catch (error: any) {
      setIsDownloadingFile(false)
      toast?.error(error?.message || error?.data?.message || error)
    }
  }

  if (messageType === EMessageType.TEXT)
    return (
      <Typography
        style={{
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word',
          textAlign: 'left',
          wordBreak: 'break-word',
          fontWeight: 'medium',
          marginBottom: 0,
          color: isMyMessage ? '#ffffff' : '#64748b',
        }}
      >
        {message}
      </Typography>
    )

  if (messageType === EMessageType.IMAGE)
    return (
      <div className="rounded-sm max-w-[184px] h-fit">
        <img
          className="object-cover"
          src={media?.url || PLACEHOLDER_IMAGE_AVATAR}
          alt="Alt text"
          onClick={handleClickImage}
        />
      </div>
    )

  if (messageType === EMessageType.FILE)
    return (
      <div
        className={`file-wrapper flex gap-2 items-center cursor-pointer hover:opacity-80 ${
          !!isDownloadingFile ? '!cursor-not-allowed' : ''
        }`}
        onClick={() => {
          if (!!isDownloadingFile) return
          handleDownloadFile(media?.url)
        }}
      >
        {isMyMessage ? (
          <Button
            style={{
              border: 'none',
              minWidth: 24,
              minHeight: 24,
              padding: '0 8px',
              background: 'transparent',
            }}
            disabled={isSendingMessage || !!isDownloadingFile}
          >
            {!!isDownloadingFile ? (
              <Loading />
            ) : (
              <DownloadOutlined
                className={`hover:!text-slate-300`}
                style={{
                  fontSize: 20,
                  color: isMyMessage ? '#ffffff' : '#8a8a8a',
                }}
              />
            )}
          </Button>
        ) : null}

        <button className={`${isMyMessage ? 'text-white' : ''}`}>
          {media?.name}
        </button>

        {!isMyMessage ? (
          <Button
            style={{
              border: 'none',
              minWidth: 24,
              minHeight: 24,
              padding: '0 8px',
              background: 'transparent',
            }}
            disabled={isSendingMessage || !!isDownloadingFile}
          >
            {!!isDownloadingFile ? (
              <Loading />
            ) : (
              <DownloadOutlined
                className={`hover:!text-slate-400`}
                style={{
                  fontSize: 20,
                  color: isMyMessage ? '#ffffff' : '#8a8a8a',
                }}
              />
            )}
          </Button>
        ) : null}
      </div>
    )

  return null
}
