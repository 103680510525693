import { ChartData, ChartOptions } from 'chart.js'
import { t } from 'i18next'

import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

const rootColor = themes.theme.dark.colors.miniDashboard

export const doughnutChartChannelsData: ChartData<'doughnut'> = {
  labels: [t('dashboard:sold_course'), t('dashboard:package_enroll')],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [rootColor.darkPurple, rootColor.lightPurple],
      borderWidth: 0,
    },
  ],
}

export const doughnutChartChannelsConfig: ChartOptions<'doughnut'> = {
  responsive: true,
  cutout: '65%',
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: themes.theme.light.colors.body,
      borderColor: themes.theme.light.colors.primary,
      borderWidth: 1,
      callbacks: {
        title: function () {
          return ''
        },
        label: function (context) {
          const label = context.label
          return `${label} - €${formatCurrencyByType(
            context.dataset.data[context.dataIndex], "EUR"
          ).replace('€', '')}`
        },
        labelTextColor: function (context) {
          const bgColor =
            context.dataIndex === 0 ? rootColor.darkPurple : rootColor.darkBlue
          return bgColor
        },
      },
    },
  },
}
