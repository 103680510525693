import { message } from 'antd'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseError,
  BaseResponseProps,
  IRequest,
} from '@interfaces'
import { QK_PUBLISH_TEACHER_COURSE } from 'src/configs/queryClient/queryKeyConstants'
import { courseManagementAPI } from 'src/api/course'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<{
      message: string
      request: IRequest
    }>,
    any,
    number | string,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

interface IMutationParams {
  configs?: IMutationOptions
  handleSuccess?: (res: { message: string; request: IRequest }) => void
  handleError?: (error: BaseResponseError) => void
}

export const publishTeacherCourse = (id: number | string) =>
  courseManagementAPI.publishTeacherCourse(id)

export const useMutationPublishCourse = (params?: IMutationParams) => {
  const { configs, handleSuccess, handleError } = { ...params }

  return useMutation({
    mutationKey: [QK_PUBLISH_TEACHER_COURSE],
    mutationFn: publishTeacherCourse,
    onSuccess: (res) => {
      handleSuccess?.(res?.data)
    },
    onError: (error) => {
      handleError?.(error)
    },

    ...configs,
  })
}
