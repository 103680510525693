import { useMemo, useRef } from 'react'
import { Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

import { PackageTagStyled } from './styled'
import { ISubscription } from 'src/interfaces/package-management'
import { Button } from 'src/common'
import { themes } from '@theme'
import useTruncateElement from 'src/hooks/useTruncateElement'
import styled from 'styled-components'

interface IProps {
  packageData?: ISubscription
  isActive?: boolean
  onUpgrade: () => void
}

export const PackageTag = ({ packageData, isActive, onUpgrade }: IProps) => {
  const packageNameRef = useRef(null)
  const { isTruncated } = useTruncateElement({ ref: packageNameRef })

  const renderBenefits = (
    memberCourse?: number,
    premiumCourse?: number,
    memberCommission?: boolean,
    premiumShareRatio?: number
  ) => {
    return (
      <div
        // use package-tag className to custom max height of element
        className={`scroll-content flex flex-col gap-2 mt-4 overflow-y-auto ${
          isActive ? 'package-tag max-h-[200px]' : 'max-h-[120px]'
        }`}
      >
        {[
          {
            content: t('subscription:up_to_member_course_publish', {
              num: memberCourse,
            }),
            isShow: !!memberCourse,
          },
          {
            content: t('subscription:up_to_premium_course_publish', {
              num: premiumCourse,
            }),
            isShow: !!premiumCourse,
          },
          {
            content: t('subscription:member_course_commission'),
            tooltipIcon: (
              <Tooltip title={t('subscription:tooltip_subscription')}>
                <ExclamationCircleOutlined className="text-[18px] text-neutral-500" />
              </Tooltip>
            ),
            isShow: !!memberCommission,
          },
          {
            content: t('subscription:premium_course_share_ratio', {
              num: premiumShareRatio,
            }),

            isShow: !!premiumShareRatio,
          },
        ].map((item, idx) => {
          const { content, tooltipIcon, isShow } = item || {}

          if (!isShow) return null

          return (
            <Typography.Text className="text-[14px] flex items-center gap-2">
              <CheckCircleOutlined className="text-[24px] !text-green-500" />
              {content}
              {tooltipIcon ? tooltipIcon : null}
            </Typography.Text>
          )
        })}
      </div>
    )
  }

  const actionButtonTitle = useMemo(() => {
    if (packageData?.isSubscribed && packageData?.packageSubscribed?.isActive) {
      if (packageData?.packageSubscribed?.isAutoRenew)
        return t('profile:cancelRenewal')

      return t('profile:renewSubscription')
    }

    if (packageData?.isSubscribed && !packageData?.packageSubscribed?.isActive)
      return ''

    return t('common:upgrade')
  }, [
    packageData?.isSubscribed,
    packageData?.packageSubscribed?.isActive,
    packageData?.packageSubscribed?.isAutoRenew,
  ])

  return (
    <PackageTagStyled $isCenter={isActive} className="package__tag">
      <div className="mb-10">
        <div className="flex items-start justify-between gap-1">
          <Tooltip
            title={isTruncated ? packageData?.name : ''}
            placement="left"
          >
            <Typography.Title
              level={4}
              className={`!text-[28px] !mb-0 line-clamp-2 cursor-default ${
                isActive ? '!text-blue-500' : 'text-black'
              }`}
              ref={packageNameRef}
            >
              {packageData?.name}
            </Typography.Title>
          </Tooltip>

          {packageData?.isSubscribed &&
          packageData?.packageSubscribed?.isActive ? (
            <div className="bg-[#2ACCCFFF] h-fit px-2 py-1 rounded mt-1">
              <Typography.Text
                style={{
                  textWrap: 'nowrap',
                  fontWeight: 'semibold',
                  fontSize: 12,
                  color: themes.theme.light.colors.body,
                }}
              >
                Current
              </Typography.Text>
            </div>
          ) : null}
        </div>

        <Typography.Title level={4} className="!text-[32px] !mt-4">
          ${packageData?.priceUsd}
          <span className="!text-[14px] text-neutral-400 align-text-top">
            /{t('common:month')}
          </span>
        </Typography.Title>

        <BenefitWrapper>
          {renderBenefits(
            packageData?.accessModule?.course?.memberLimit,
            packageData?.accessModule?.course?.premiumLimit,
            !!packageData?.accessModule?.revenue?.memberCourse?.length,
            packageData?.accessModule?.revenue?.premiumRate
          )}
        </BenefitWrapper>
      </div>

      <Button
        type="primary"
        onClick={onUpgrade}
        className={`!w-full py-4 rounded-[4px] ${
          isActive
            ? 'bg-blue-500'
            : '!bg-white border-[1px] !border-blue-500 !text-blue-500'
        }`}
      >
        {actionButtonTitle}
      </Button>
    </PackageTagStyled>
  )
}

const BenefitWrapper = styled.div`
  .scroll-content {
    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border: 3px solid transparent;
      background-clip: content-box;
      background-color: ${themes.theme.light.colors.icon_gray_500};
    }
  }
`
