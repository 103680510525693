// sign up
export const ENDPOINT_GET_USER_APPLICATION = '/teacher-requests/teacher'

// subscription
export const ENDPOINT_GET_PACKAGE_SUBSCRIPTIONS = '/teacher-package/teacher'
export const ENDPOINT_SUBSCRIBE_PACKAGE = '/packages/subscribe/$id'
export const ENDPOINT_VERIFY_SUBSCRIBE_PACKAGE =
  '/packages/verify-subscribed-package'
export const ENDPOINT_CONFIRM_PAYMENT_INTENT = '/stripe/confirm-payment-intent'
export const ENDPOINT_CHECKOUT_PACKAGE = '/packages/checkout'

// teacher course
export const ENDPOINT_TEACHER_CREATE_COURSE = '/courses/teacher'
export const ENDPOINT_UPDATE_TEACHER_COURSE_BY_ID = '/courses/teacher/$id'
export const ENDPOINT_GET_LIST_COURSE_TEACHER = '/courses/teacher-courses'
export const ENDPOINT_GET_TEACHER_COURSE_DETAILS =
  '/courses/teacher-courses/$id'
export const ENDPOINT_GET_TEACHER_COURSE_MINI_DASHBOARD =
  '/courses/teacher-courses/mini-dashboard'
export const ENDPOINT_PUBLISH_TEACHER_COURSE = '/course-requests/publish/$id'
export const ENDPOINT_INACTIVE_TEACHER_COURSE = '/course-requests/status/$id'

// teacher dashboard
export const ENDPOINT_DASHBOARD_OVERVIEW = '/revenues/overview'
export const ENDPOINT_DASHBOARD_CHANNELS = '/revenues/statistic/$year'
export const ENDPOINT_DASHBOARD_COURSE_TABLE = '/revenues/courses/$courseId'
export const ENDPOINT_DASHBOARD_LIST_COURSE_ORDERS =
  '/courses/teacher-courses-dashboard'
export const ENDPOINT_DASHBOARD_LIST_COURSE_FILTER =
  '/courses/teacher-published-courses'
export const ENDPOINT_DASHBOARD_ORDER_INCOME_DETAILS = '/revenues/$id'

// revenue
export const ENDPOINT_GET_LIST_TEACHER_INVOICES = '/teacher-invoices'
export const ENDPOINT_GET_INVOICES_DETAILS = '/teacher-invoices/$id'
export const ENDPOINT_GET_HISTORY_LOG = '/teacher-invoices/history/$id'
export const ENDPOINT_CREATE_ACCOUNT_ONBOARD =
  '/users/connected-account/onboard'
export const ENDPOINT_GENERATE_REVENUE = '/teacher-invoices/generate-revenue'
export const ENDPOINT_GET_WITHDRAW_INFORMATION =
  '/teacher-invoices/withdraw-information'
export const ENDPOINT_WITHDRAW = '/teacher-invoices/withdraw'
export const ENDPOINT_VERIFY_WITHDRAW = '/teacher-invoices/verify-withdraw'
export const ENDPOINT_REDO_TRANSACTION = '/teacher-invoices/re-do/$id'
export const ENDPOINT_REGENERATE_INVOICE_BY_MONTH =
  '/teacher-invoices/teacher/generate-revenue'

// notification
export const ENDPOINT_GET_LIST_NOTIFICATIONS = '/notifications'
export const ENDPOINT_GET_NOTIFICATION_UNREAD_NUMBER =
  '/notifications/unread-number'
export const ENDPOINT_MARK_NOTIFICATION_AS_READ_BY_ID =
  '/notifications/$notificationId'
export const ENDPOINT_MARK_ALL_NOTIFICATION_AS_REAd = '/notifications'
