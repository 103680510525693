import { Typography } from 'antd'
import React from 'react'
import { t } from 'i18next'

import { ShareSelectInput } from '@components'
import { ISubscription } from '@interfaces'

type TPackagesSelectionProps = {
  onChangeValue?: (value: ISubscription) => void
  data?: Array<ISubscription>
  selectedPackage?: ISubscription
}

export const PackagesSelection: React.FC<TPackagesSelectionProps> = ({
  onChangeValue,
  data,
  selectedPackage,
}) => {
  const packages = React.useMemo(() => {
    return data?.map((item) => {
      return {
        value: item?.id,
        label: item.name,
      }
    })
  }, [data])

  const handleChangeSelection = (value?: string) => {
    const selectedPackage = data?.find((item) => item?.id === Number(value))
    if (!!selectedPackage) {
      onChangeValue?.(selectedPackage)
    }
  }

  if (!data) {
    return null
  }

  return (
    <div className=" border border-main p-5 rounded-lg min-w-[400px] max-w-[400px] self-start bg-white">
      <Typography.Text className="text-2xl font-bold capitalize text-black">
        {t('common:professional')}
      </Typography.Text>
      <ShareSelectInput
        data={packages}
        value={selectedPackage?.id}
        containerClassName="flex w-full flex-col mt-5"
        onChange={handleChangeSelection}
        showArrow
        required
      />
      <div className="border-t border-b border-gray-300 mt-5 pb-2">
        <div className="justify-between flex items-center mt-3">
          <Typography.Text className="text-lg font-bold text-black">
            {t('common:priceMonth')}
          </Typography.Text>
          <Typography.Text className="text-lg font-bold text-black">
            {`$${selectedPackage?.priceUsd}`}
          </Typography.Text>
        </div>
      </div>

      <div className="justify-between flex items-center mt-5">
        <Typography.Text className="text-lg font-bold capitalize text-black">
          {t('common:subtotal')}
        </Typography.Text>
        <Typography.Text className="text-lg font-bold capitalize text-black">
          {`$${selectedPackage?.priceUsd}`}
        </Typography.Text>
      </div>
    </div>
  )
}
