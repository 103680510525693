import {
  IChartData,
  IDashboardOverview,
  IDashboardParams,
  IFetchNewUserStatSuccessData,
  IFetchRevenueStatSuccessData,
  IGetDashboardChannels,
  IGetDashboardListCourseOrder,
  IGetDashboardListCourseTableParams,
  IGetDashboardOverviewRequest,
  IGetListCourseOrderTable,
  IGetOrderDetails,
  IGetStatisticChartRequest,
  IGetTeacherCoursesDashboardParams,
} from '@interfaces'
import { ApiClient } from './axiosClient'
import {
  ENDPOINT_DASHBOARD_CHANNELS,
  ENDPOINT_DASHBOARD_COURSE_TABLE,
  ENDPOINT_DASHBOARD_LIST_COURSE_FILTER,
  ENDPOINT_DASHBOARD_LIST_COURSE_ORDERS,
  ENDPOINT_DASHBOARD_ORDER_INCOME_DETAILS,
  ENDPOINT_DASHBOARD_OVERVIEW,
} from 'src/api/react-query'

const STATISTIC_URL = '/statistics'
const GET_USER = '/users'
const GET_INCOME = '/income'

export const dashboardAPI = {
  getRevenueStat: async (params: IDashboardParams) => {
    return await ApiClient.get<IFetchRevenueStatSuccessData>(
      '/transaction/stats',
      {
        params,
      }
    )
  },

  getNewUserStat: async (params: IDashboardParams) => {
    return await ApiClient.get<IFetchNewUserStatSuccessData>('/users/stats', {
      params,
    })
  },

  getStatisticChart: async (
    params?: IGetStatisticChartRequest,
    type: 'users' | 'income' = 'users'
  ) => {
    return await ApiClient.get<IChartData[]>(
      `${STATISTIC_URL}${type === 'users' ? GET_USER : GET_INCOME}`,
      {
        params: params,
      }
    )
  },

  // teacher dashboard
  getDashboardOverview: (params?: IGetDashboardOverviewRequest) => {
    return ApiClient.get<IDashboardOverview>(
      `${ENDPOINT_DASHBOARD_OVERVIEW}${
        !!params?.filterBy ? `?filterBy=${params?.filterBy}` : ''
      }`,
      {
        params: {
          startDate: params?.startDate,
          endDate: params?.endDate,
        },
      }
    )
  },

  getDashboardChannels: (year?: number | string) => {
    return ApiClient.get<IGetDashboardChannels>(
      ENDPOINT_DASHBOARD_CHANNELS.replace('$year', `${year}`)
    )
  },

  getDashboardCourseTable: (payload: {
    params?: IGetDashboardListCourseTableParams
    courseId: number | string
  }) => {
    return ApiClient.get<IGetListCourseOrderTable>(
      ENDPOINT_DASHBOARD_COURSE_TABLE.replace(
        '$courseId',
        `${payload.courseId}`
      ),
      { params: payload.params }
    )
  },

  getDashboardListCourseOrders: (
    params?: IGetTeacherCoursesDashboardParams
  ) => {
    return ApiClient.get<IGetDashboardListCourseOrder>(
      ENDPOINT_DASHBOARD_LIST_COURSE_ORDERS,
      { params }
    )
  },

  getDashboardListCourseFilter: () => {
    return ApiClient.get<any>(ENDPOINT_DASHBOARD_LIST_COURSE_FILTER)
  },

  getDashboardOrderIncomeDetails: (orderIncomeId: string | number) => {
    return ApiClient.get<IGetOrderDetails>(
      ENDPOINT_DASHBOARD_ORDER_INCOME_DETAILS.replace('$id', `${orderIncomeId}`)
    )
  },
}
