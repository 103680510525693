import { capitalize, upperCase } from 'lodash'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { Typography } from 'antd'
import styled from 'styled-components'

import { TitleWrapperStyled } from 'src/pages/dashboard/list-course/course-acordion/config'
import { formatDate } from '@utils'
import { FORMAT_DATE_TIME } from '@configs'
import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export enum EBalanceType {
  ALL = 'ALL',
  REVENUE_WITHDRAW = 'REVENUE_WITHDRAWAL',
  REVENUE_RECEIVED = 'REVENUE_RECEIVED',
}

export const BALANCE_TOOLBAR_RHF_FIELDS = {
  DATE_RANGE: 'date_range',
  TYPE: 'type',
}

export const BALANCE_TYPE_OPTIONS = [
  {
    label: 'All',
    value: EBalanceType.ALL,
  },
  {
    label: capitalize(EBalanceType.REVENUE_WITHDRAW.replace('_', ' ')),
    value: EBalanceType.REVENUE_WITHDRAW,
  },
  {
    label: capitalize(EBalanceType.REVENUE_RECEIVED.replace('_', ' ')),
    value: EBalanceType.REVENUE_RECEIVED,
  },
]

export const DEFAULT_TOOLBAR_VALUES = {
  [BALANCE_TOOLBAR_RHF_FIELDS.DATE_RANGE]: [],
  [BALANCE_TOOLBAR_RHF_FIELDS.TYPE]: EBalanceType.ALL,
}

export enum EBalanceStatus {
  PENDING = 'PENDING',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  PAYOUT_FAIL = 'PAYOUT_FAIL',
}

export const BALANCE_TABLE_COLUMNS: ColumnsType<any> = [
  {
    width: '15%',
    title: upperCase(t('dashboard:no')),
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '30%',
    title: () => {
      return (
        <TitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('revenue:order_id')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'orderId',
    key: 'orderId',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 font-medium !text-primaryPurple !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '35%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:type')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'type',
    key: 'type',
    render: (e: EBalanceType) => (
      <div className="w-full flex justify-center">
        {e === EBalanceType.REVENUE_RECEIVED ? (
          <TypeStyled
            $bgColor={themes.theme.light.colors.balanceOverviewBg}
            $textColor={themes.theme.light.colors.note}
          >
            {capitalize(e?.replace('_', ' '))}
          </TypeStyled>
        ) : (
          <TypeStyled
            $bgColor={themes.theme.light.colors.lightRed}
            $textColor={themes.theme.light.colors.delete}
          >
            {capitalize(e?.replace('_', ' '))}
          </TypeStyled>
        )}
      </div>
    ),
  },
  {
    width: '30%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:date_time')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'dateTime',
    key: 'dateTime',
    render: (e: string | null) => {
      return (
        <>
          {!!e ? (
            <Typography.Text
              className="!m-0 !text-[14px] !text-wrap"
              ellipsis={true}
            >
              {formatDate(e, FORMAT_DATE_TIME)}
            </Typography.Text>
          ) : null}
        </>
      )
    },
    ellipsis: true,
  },
  {
    width: '30%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:status')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'status',
    key: 'status',
    render: (e: EBalanceStatus) => {
      const textColors = {
        [EBalanceStatus.SUCCESS]: `${themes.theme.light.colors.note}`,
        [EBalanceStatus.PENDING]: `${themes.theme.light.colors.darkWarning}`,
        [EBalanceStatus.FAIL]: `${themes.theme.light.colors.delete}`,
        [EBalanceStatus.PAYOUT_FAIL]: `${themes.theme.light.colors.delete}`,
      }
      return (
        <Typography.Text
          className="!m-0 !text-[14px]"
          ellipsis={true}
          style={{
            color: textColors[e],
          }}
        >
          {capitalize(e.replace('_', ' '))}
        </Typography.Text>
      )
    },
    ellipsis: true,
  },
  {
    width: '20%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:amount')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'amount',
    key: 'amount',
    render: (
      _: any,
      record: { amount: string | number; type: EBalanceType }
    ) => {
      return (
        <AmountWrapper
          $textColor={
            record?.type === EBalanceType.REVENUE_RECEIVED
              ? themes.theme.light.colors.success
              : themes.theme.light.colors.delete
          }
        >
          €
          {formatCurrencyByType(Number(record?.amount ?? 0), 'EUR').replace(
            '€',
            ''
          )}
        </AmountWrapper>
      )
    },
    align: 'center',
    ellipsis: true,
  },
  {
    width: '10%',
    title: null,
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    render: () => null,
  },
]

const TypeStyled = styled.span<{
  $bgColor?: string
  $textColor?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 14px;
  background-color: ${(p) => p.$bgColor || ''};
  color: ${(p) => p.$textColor || ''};
`
const AmountWrapper = styled.span<{ $textColor?: string }>`
  font-size: 14px;
  color: ${(p) => p.$textColor || ''};
`
