import { t } from 'i18next'

export enum EOverviewDateFilter {
  LAST_MONTH = 'lastMonth',
  LAST_SIX_MONTHS = 'lastSixMonths',
  LAST_YEAR = 'lastYear',
  CUSTOM = 'custom',
}

export const FILTER_OVERVIEW_DATA = [
  {
    label: t('dashboard:last_month'),
    value: EOverviewDateFilter.LAST_MONTH,
  },
  {
    label: t('dashboard:last_6_months'),
    value: EOverviewDateFilter.LAST_SIX_MONTHS,
  },
  {
    label: t('dashboard:last_year'),
    value: EOverviewDateFilter.LAST_YEAR,
  },
]
