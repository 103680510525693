import { Typography } from 'antd'
import { t } from 'i18next'

import { AppModal } from '@components'
import { Button } from 'src/common'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'

interface IProps {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onRedoTransaction: () => void
}

export const RedoTransactionModal = ({
  open,
  isLoading,
  onClose,
  onRedoTransaction,
}: IProps) => {
  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <Typography.Title level={4} className="!mb-0 !text-[24px] !text-left">
          {t('revenue:re_do_failed_transaction')}
        </Typography.Title>
      }
      contentClassName="!min-w-[700px] !max-w-[700px] !px-8"
      childrenClassName="!mt-2"
      modalClassName="!z-[110]"
    >
      <Typography.Text className="!text-[14px]">
        {t('revenue:modal_confirm_re_do_transaction_content')}
      </Typography.Text>
      <ButtonWrapper className="!mt-6 !justify-end">
        <Button
          className="w-full flex justify-center !bg-neutral-500 !text-white !border-neutral-500"
          type="primary"
          onClick={onClose}
        >
          {t('common:back')}
        </Button>
        <Button
          className="w-full flex justify-center"
          type="primary"
          onClick={onRedoTransaction}
          loading={isLoading}
        >
          {t('revenue:re_do_transaction')}
        </Button>
      </ButtonWrapper>
    </AppModal>
  )
}
