import { t } from 'i18next'

import {
  EActiveInActiveOptions,
  ENavBarRole,
  EPermissions,
  EPublishDraftOptions,
} from './enum'
import {
  PATH_ACADEMY_PAGE,
  PATH_ACADEMY_MANAGEMENT,
  PATH_SUB_DAILY_ROUTINE,
  PATH_SUB_SETUP_DAILY_ROUTINE,
  PATH_TEACHER_MANAGEMENT,
  PATH_TEST_MANAGEMENT,
  PATH_WITHDRAWAL,
  PATH_REVENUE,
} from 'src/routes/navigation'

import { SideBarData } from '@interfaces'
import {
  EActivityLogActionType,
  EActivityLogType,
  EDailyRoutineType,
  EEmailContentType,
  EYesNoOptions,
  EUserPostStatus,
  EUserStatus,
  enumCashbackType,
  enumDashboardFilterTime,
  enumMemberType,
  enumNavKey,
  enumPageSize,
  enumRuleType,
} from 'src/configs/enum'
import {
  ABOUT_US,
  EDIT_COMPANY_INFOR,
  MAIL_CONTENT,
  PATH_ARTICLE_MANAGEMENT,
  PATH_BLOG_NEWS,
  PATH_COURSE_MANAGEMENT,
  PATH_CATEGORY,
  PATH_CATEGORY_MANAGEMENT,
  PATH_CHAT_CHANNEL,
  PATH_CONTACT,
  PATH_DASHBOARD,
  PATH_DECLARATION_MANAGEMENT,
  PATH_EMAIL_MANAGEMENT,
  PATH_GROUP_MANAGEMENT,
  PATH_HABIT_MANAGEMENT,
  PATH_HOME,
  PATH_MANAGE_ROLE,
  PATH_NEW_LETTER,
  PATH_PACKAGE_MANAGEMENT,
  PATH_POST_MANAGEMENT,
  PATH_STAFF_AND_ROLE_MANAGEMENT,
  PATH_SUB_CATEGORY,
  PATH_SUB_GALLERY_PAGE,
  PATH_SUB_HABIT_CHALLENGE,
  PATH_SUB_HABIT_GOAL,
  PATH_SUB_HABIT_QUESTION,
  PATH_SUB_MEMBERSHIP_PAGE,
  PATH_SUB_MENU_ABOUT_US_PAGE,
  PATH_SUB_MENU_BLOG_PAGE,
  PATH_SUB_MENU_HOME_PAGE,
  PATH_SUB_MENU_TERM_POLICIES_PAGE,
  PATH_USER_MANAGEMENT,
  PRIVACY_PAGE,
  ROAD_MAP,
  SLIDE_SHOW,
  TERM,
} from 'src/routes/navigation'

export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'frn'
const ALL_THEMES = 'themes'
const CURRENT_THEME = 'theme'
export const LLC_ACCESS_TOKEN = 'llc-access-token-teacher'
export const LIVE_LIFE_CLEAN = 'Live Life Clean'

const SIDEBAR_WIDTH = '320px'
const SIDEBAR_COLLAPSED_WIDTH = '92px'
const SIDEBAR_ICON_SIZE = '24px'
const HEADER_HEIGHT = '65px'
const HEADER_PADDING_TOP = '20px'
const COURSE_DETAIL_HEADING_HEIGHT = '180px'

export const MEDIA_PATH_ACTION = {
  UPLOAD_IMAGE:
    process.env.REACT_APP_PUBLIC_API_URL + '/media/resize-resolution',
  UPLOADVIDEO: process.env.REACT_APP_PUBLIC_API_URL + '/media/video-media',
}

export const CURRENT_YEAR = new Date().getFullYear()

export const EMPTY_DATE = '-/-/-'
export const FORMAT_DATE_TABLE = 'DD/MM/YYYY'
export const FORMAT_DATE_FILTER = 'DD-MM-YYYY'
export const FORMAT_MONTH_YEAR = 'MM/YYYY'
export const FORMAT_DATE_DASHBOARD_COURSE_TABLE = 'MMM D, YYYY'
export const FORMAT_DATE_TIME = 'DD/MM/YYYY - h:mma'
export const FORMAT_TIME_LONG = 'hh:mm A'
export const FORMAT_TIME_WITH_GMT = 'hh:mm:ss A [GMT]Z'

export const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d).+$/

export const FULLNAME_REGEX = /^([\w]{3,})+\s+([\w\s]{3,})+$/i

export const OTP_TIMER = 120

export const WITHDRAWAL_LIMIT = 10000
export const WITHDRAWAL_STEP = 0.01

export const MIN_DOB = 18
export const MOMENT_DAYS = 'days'
export const MOMENT_MONTHS = 'months'
export const MOMENT_YEARS = 'years'

export const MIN_PRICE = 0.5

export const DEFAULT_GET_INVOICES_PARAMS = {
  PAGE: 1,
  LIMIT: 5,
}

const SIDEBAR_DATA: SideBarData[] = [
  {
    label: 'Dashboard',
    path: PATH_HOME,
    pathKey: enumNavKey.DASHBOARD,
    role: [ENavBarRole.TEACHER_OTHER],
  },

  // Academy
  {
    label: t('common:academy_management'),
    path: PATH_ACADEMY_MANAGEMENT,
    pathKey: enumNavKey.ACADEMY_MANAGEMENT,
    role: [ENavBarRole.TEACHER_OTHER],
    permissionViewKeys: [
      EPermissions.COURSE_MANAGEMENT,
      EPermissions.TEST_MANAGEMENT,
      EPermissions.TEACHER_MANAGEMENT,
    ],

    subItems: [
      // Course
      {
        label: t('common:course_management'),
        path: PATH_COURSE_MANAGEMENT,
        pathKey: enumNavKey.COURSE_MANAGEMENT,
        role: [ENavBarRole.TEACHER_OTHER],
      },
      // Test
      {
        label: 'Test management',
        path: PATH_TEST_MANAGEMENT,
        pathKey: enumNavKey.TEST_MANAGEMENT,
        role: [ENavBarRole.TEACHER_OTHER],
      },
      // Teacher
      // {
      //   label: t('common:teacher_management'),
      //   path: PATH_TEACHER_MANAGEMENT,
      //   pathKey: enumNavKey.TEACHER_MANAGEMENT,
      //   role: [ENavBarRole.ADMIN],
      // },
    ],
  },

  {
    label: t('revenue:revenue'),
    path: PATH_REVENUE,
    pathKey: enumNavKey.REVENUE_MANAGEMENT,
    role: [ENavBarRole.TEACHER_OTHER],
    permissionViewKeys: [EPermissions.WITHDRAWAL],

    subItems: [
      // withdrawal
      {
        label: t('revenue:withdrawal'),
        path: PATH_WITHDRAWAL,
        pathKey: enumNavKey.WITHDRAWAL,
        role: [ENavBarRole.TEACHER_OTHER],
      },
    ],
  },

  // User
  // {
  //   label: 'User',
  //   path: PATH_USER_MANAGEMENT,
  //   pathKey: enumNavKey.USER_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [
  //     EPermissions.USER_MANAGEMENT,
  //     EPermissions.PACKAGE_MANAGEMENT,
  //     EPermissions.NEWLETTER_MANAGEMENT,
  //     EPermissions.EMAIL_MANAGEMENT,
  //     EPermissions.GROUP_MANAGEMENT,
  //   ],
  //   subItems: [
  //     {
  //       label: 'User management',
  //       path: PATH_USER_MANAGEMENT,
  //       pathKey: enumNavKey.USER_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.USER_MANAGEMENT],
  //     },
  //     {
  //       label: 'Package management',
  //       path: PATH_PACKAGE_MANAGEMENT,
  //       pathKey: enumNavKey.PACKAGE_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.PACKAGE_MANAGEMENT],
  //     },
  //     {
  //       label: 'New letter management',
  //       path: PATH_NEW_LETTER,
  //       pathKey: enumNavKey.EMAIL_SUBSCRIBED_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.NEWLETTER_MANAGEMENT],
  //     },
  //     {
  //       label: 'Email management',
  //       path: PATH_EMAIL_MANAGEMENT,
  //       pathKey: enumNavKey.PATH_EMAIL_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.EMAIL_MANAGEMENT],
  //     },
  //     {
  //       label: 'Group management',
  //       path: PATH_GROUP_MANAGEMENT,
  //       pathKey: enumNavKey.PATH_GROUP_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.GROUP_MANAGEMENT],
  //     },
  //   ],
  // },
  // // Category
  // {
  //   label: 'Category management',
  //   path: PATH_CATEGORY_MANAGEMENT,
  //   pathKey: enumNavKey.CATEGORY_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [
  //     EPermissions.CATEGORY_MANAGEMENT,
  //     EPermissions.SUB_CATEGORY_MANAGEMENT,
  //   ],

  //   subItems: [
  //     {
  //       label: 'Category',
  //       path: PATH_CATEGORY,
  //       pathKey: enumNavKey.CATEGORY,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.CATEGORY_MANAGEMENT],
  //     },
  //     {
  //       label: 'Sub category',
  //       path: PATH_SUB_CATEGORY,
  //       pathKey: enumNavKey.SUBCATEGORY,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.SUB_CATEGORY_MANAGEMENT],
  //     },
  //   ],
  // },
  // // Content
  // {
  //   label: 'Content management',
  //   path: PATH_ARTICLE_MANAGEMENT,
  //   pathKey: enumNavKey.ARTICLE_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [
  //     EPermissions.HOMEPAGE,
  //     EPermissions.ABOUT_US,
  //     EPermissions.MEMBERSHIP,
  //     EPermissions.ACADEMY,
  //     EPermissions.BLOG_PAGE,
  //     EPermissions.CONTACT,
  //     EPermissions.TERM_POLICY,
  //     EPermissions.GALLERY,
  //   ],

  //   subItems: [
  //     {
  //       label: 'Home page',
  //       path: PATH_SUB_MENU_HOME_PAGE,
  //       pathKey: enumNavKey.SUB_MENU_HOME_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.HOMEPAGE],
  //     },
  //     {
  //       label: 'About us page',
  //       path: PATH_SUB_MENU_ABOUT_US_PAGE,
  //       pathKey: enumNavKey.SUB_MENU_ABOUT_US_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.ABOUT_US],
  //     },
  //     {
  //       label: 'Membership page',
  //       path: PATH_SUB_MEMBERSHIP_PAGE,
  //       pathKey: enumNavKey.SUB_PACKAGE_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.MEMBERSHIP],
  //     },
  //     {
  //       label: 'Academy page',
  //       path: PATH_ACADEMY_PAGE,
  //       pathKey: enumNavKey.SUB_ACADEMY_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.ACADEMY],
  //     },
  //     {
  //       label: 'Blog page',
  //       path: PATH_SUB_MENU_BLOG_PAGE,
  //       pathKey: enumNavKey.SUB_MENU_BLOG_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.BLOG_PAGE],
  //     },
  //     {
  //       label: 'Contact',
  //       path: PATH_CONTACT,
  //       pathKey: enumNavKey.CONTACT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.CONTACT],
  //     },
  //     {
  //       label: 'Terms & policies page',
  //       path: PATH_SUB_MENU_TERM_POLICIES_PAGE,
  //       pathKey: enumNavKey.SUB_MENU_TERM_POLICIES_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.TERM_POLICY],
  //     },

  //     {
  //       label: 'Gallery',
  //       path: PATH_SUB_GALLERY_PAGE,
  //       pathKey: enumNavKey.SUB_GALLERY_PAGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.GALLERY],
  //     },
  //   ],
  // },
  // // Blog
  // {
  //   label: 'Blog management',
  //   path: PATH_BLOG_NEWS,
  //   pathKey: enumNavKey.BLOG_NEW_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [EPermissions.BLOG_MANAGEMENT],
  // },

  // // Test management (for teacher screen)
  // {
  //   label: 'Test management',
  //   path: PATH_TEST_MANAGEMENT,
  //   pathKey: enumNavKey.TEST_MANAGEMENT,
  //   role: [
  //     ENavBarRole.TEACHER,
  //     ENavBarRole.TEACHER_LLC,
  //     ENavBarRole.TEACHER_OTHER,
  //   ],
  //   permissionViewKeys: [EPermissions.TEST_MANAGEMENT],
  // },

  // // Community
  // {
  //   label: 'Community management',
  //   path: PATH_POST_MANAGEMENT,
  //   pathKey: enumNavKey.POST_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // // Habit
  // {
  //   label: 'Habit building management',
  //   path: PATH_HABIT_MANAGEMENT,
  //   pathKey: enumNavKey.HABIT_MANAGEMENT,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [
  //     EPermissions.GOAL_MANAGEMENT,
  //     EPermissions.QUESTION_MANAGEMENT,
  //     EPermissions.CHALLENGE_MANAGEMENT,
  //     EPermissions.DAILY_ROUTINE_MANAGEMENT,
  //     EPermissions.STARTING_SCREEN,
  //   ],

  //   subItems: [
  //     {
  //       label: 'Goals',
  //       path: PATH_SUB_HABIT_GOAL,
  //       pathKey: enumNavKey.SUB_HABIT_TAG,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.GOAL_MANAGEMENT],
  //     },
  //     {
  //       label: 'Questions',
  //       path: PATH_SUB_HABIT_QUESTION,
  //       pathKey: enumNavKey.SUB_HABIT_QUESTION,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.QUESTION_MANAGEMENT],
  //     },
  //     {
  //       label: 'Challenges',
  //       path: PATH_SUB_HABIT_CHALLENGE,
  //       pathKey: enumNavKey.SUB_HABIT_CHALLENGE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  //     },
  //     {
  //       label: 'Daily routine',
  //       path: PATH_SUB_DAILY_ROUTINE,
  //       pathKey: enumNavKey.SUB_DAILY_ROUTINE,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.DAILY_ROUTINE_MANAGEMENT],
  //     },
  //     {
  //       label: 'Set up starting screen',
  //       path: PATH_SUB_SETUP_DAILY_ROUTINE,
  //       pathKey: enumNavKey.SET_UP_STARTING_SCREEN,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.STARTING_SCREEN],
  //     },
  //   ],
  // },
  // // Internal
  // {
  //   label: 'Internal',
  //   path: PATH_CHAT_CHANNEL,
  //   pathKey: enumNavKey.CHAT_CHANNEL,
  //   role: [ENavBarRole.ADMIN],
  //   permissionViewKeys: [
  //     EPermissions.STAFF_MANAGEMENT,
  //     EPermissions.ROLE_MANAGEMENT,
  //     EPermissions.CHAT_CHANNEL,
  //   ],

  //   subItems: [
  //     {
  //       label: 'Chat channel',
  //       path: PATH_CHAT_CHANNEL,
  //       pathKey: enumNavKey.CHAT_CHANNEL,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [EPermissions.CHAT_CHANNEL],
  //     },
  //     {
  //       label: 'Staff and role management',
  //       path: PATH_STAFF_AND_ROLE_MANAGEMENT,
  //       pathKey: enumNavKey.STAFF_AND_ROLE_MANAGEMENT,
  //       role: [ENavBarRole.ADMIN],
  //       permissionViewKeys: [
  //         EPermissions.STAFF_MANAGEMENT,
  //         EPermissions.ROLE_MANAGEMENT,
  //       ],
  //     },
  //   ],
  // },
]

const AUTH_THEME_COLOR = '#184f64'

const MAIN_THEME_DATA = {
  mainColor: '#184f64',
}

const MAT_SM_SCREEN_WIDTH = '1279px'
const MAT_SM_SCREEN_WIDTH_MIN = '1280px'

const RESET = 'RESET'

const CASHBACK_RULE_TYPE = [
  {
    value: enumRuleType.standard,
    label: 'Standard Cashback Rule',
  },
  {
    value: enumRuleType.superior,
    label: 'Superior Cashback Rule',
  },
]

const CASHBACK_TYPE = [
  {
    value: enumCashbackType.cost,
    label: '$',
  },
  {
    value: enumCashbackType.percent,
    label: '%',
  },
]

export const TOP_FILTER = [
  {
    value: 10,
    label: 'Top 10',
  },
  {
    value: 20,
    label: 'Top 20',
  },
  {
    value: 50,
    label: 'Top 50',
  },
]

const PAGE_SIZE_OPTIONS = [
  {
    value: enumPageSize.LIMIT_10,
    label: '10',
  },
  {
    value: enumPageSize.LIMIT_20,
    label: '20',
  },
  {
    value: enumPageSize.LIMIT_50,
    label: '50',
  },
]

const FILTER_MEMBER_TYPE = [
  {
    value: enumMemberType.ACTIVE,
    label: 'Active members',
  },
  {
    value: enumMemberType.NEW_MEMBER,
    label: 'New members',
  },
  {
    value: enumMemberType.ALL,
    label: 'All',
  },
]

const DASHBOARD_FILTER_TIME = [
  {
    value: enumDashboardFilterTime.ALL_DAYS,
    label: 'All days',
  },
  {
    value: enumDashboardFilterTime.TODAY,
    label: 'Today',
  },
  {
    value: enumDashboardFilterTime.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: enumDashboardFilterTime.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: enumDashboardFilterTime.LAST_14_DAYS,
    label: 'Last 14 days',
  },
  {
    value: enumDashboardFilterTime.LAST_21_DAYS,
    label: 'Last 21 days',
  },
  {
    value: enumDashboardFilterTime.LAST_28_DAYS,
    label: 'Last 28 days',
  },
  {
    value: enumDashboardFilterTime.LAST_60_DAYS,
    label: 'Last 60 days',
  },
  {
    value: enumDashboardFilterTime.CUSTOM,
    label: 'Custom date',
  },
]

const OPENING_TYPE = {
  ALL: 1,
  CUSTOM: 2,
}
const DAYS = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THU: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
}
const DEFAULT_OPENING_HOUR = {
  [DAYS.MON]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.TUE]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.WED]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.THU]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.FRI]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: true,
  },
  [DAYS.SAT]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
  [DAYS.SUN]: {
    openHour: '00:00:00',
    closeHour: '23:59:59',
    timezone: 8,
    isOpen: false,
  },
}
export const DEFAULT_WELCOMED_BENEFITS_VALUE = {
  CASHBACK: 'cashback',
  POINTS: 'point',
  COUPONS: 'coupon',
  FREE_ITEMS: 'item',
}
const DEFAULT_WELCOMED_BENEFITS = [
  {
    value: DEFAULT_WELCOMED_BENEFITS_VALUE.CASHBACK,
    label: 'Cashback',
    disabled: false,
  },
  {
    value: DEFAULT_WELCOMED_BENEFITS_VALUE.POINTS,
    label: 'Points',
    disabled: false,
  },
  {
    value: DEFAULT_WELCOMED_BENEFITS_VALUE.COUPONS,
    label: 'Coupons',
    disabled: false,
  },
  {
    value: DEFAULT_WELCOMED_BENEFITS_VALUE.FREE_ITEMS,
    label: 'Free items',
    disabled: false,
  },
]
export const ruleDateFormat = 'DD-MM-YYYY'
export const ruleTimeFormat = 'HH:mm:ss'
export const dateOfBirthFormat = 'DD/MM/YYYY'
export const dateTimeFormat = 'HH:mm:ss DD-MM-YYYY'
export const dateFormat = 'DD-MM-YYYY'
export const dateStringFormat = 'DD MM YYYY'
export const dateTimeLongFormat = 'HH:mm - MMMM Do YYYY'
export const dateLongFormat = 'MMMM Do YYYY'
export const monthYearFormat = 'MMM YYYY'

export const RESEND_OTP_COUNTDOWN_TIME = 60 //seconds
export const MEMBER_LINK = 'https://minty-member-stage.adamo.tech/'
export const INITIAL_PAGE = 1
export const DEFAULT_LIMIT_ITEMS = 10
export const DEFAULT_TOTAL_ITEMS = 0
export const DEFAULT_ANNOUNCEMENT_TYPE = 'redirect'
export const DEFAULT_ANNOUNCEMENT_STATUS = true
export const MAXIMUM_IMAGE_SIZE = 1000000 //bytes - 1 MB
export const MAXIMUM_LIMIT = 1000000 //bytes - 1 MB

// react-query configs
export const DEFAULT_REACT_QUERY_STALE_TIME = 1000

export const MAP_BOX_DEV_KEY =
  'pk.eyJ1IjoiYW5keWhpdSIsImEiOiJjbGNyOWl2ZDAwYmdjM3FucGMwbGhkbW90In0.ezl2EXwziuTkjZmYeh4Bcw'
export {
  ALL_THEMES,
  AUTH_THEME_COLOR,
  CASHBACK_RULE_TYPE,
  CASHBACK_TYPE,
  CURRENT_THEME,
  DASHBOARD_FILTER_TIME,
  DAYS,
  DEFAULT_OPENING_HOUR,
  DEFAULT_WELCOMED_BENEFITS,
  FILTER_MEMBER_TYPE,
  HEADER_HEIGHT,
  HEADER_PADDING_TOP,
  COURSE_DETAIL_HEADING_HEIGHT,
  MAIN_THEME_DATA,
  MAT_SM_SCREEN_WIDTH,
  MAT_SM_SCREEN_WIDTH_MIN,
  OPENING_TYPE,
  PAGE_SIZE_OPTIONS,
  RESET,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_DATA,
  SIDEBAR_ICON_SIZE,
  SIDEBAR_WIDTH,
}

export const INITIAL_PAGINATION_SiZE = 10

export enum StatusUser {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum StaticPageType {
  ABOUT_US = 'about-us',
  TERM_POLICY = 'term-policy',
}

export enum ClientStatusCode {
  NOT_ACCEPTABLE = 406,
  OTP_TIMEOUT = 600,
  OTP_INVALID = 601,
  WRONG_OTP_CODE = 602,
  OVERTIME_SCAN_OTP = 603,
  AVAILABILITY_CREATE_SUCCESS = 604,
  AVAILABILITY_CREATE_ERROR = 605,
  AVAILABILITY_CREATE_EXISTED = 606,
  AVAILABILITY_UPDATE_SUCCESS = 607,
  AVAILABILITY_UPDATE_ERROR = 608,
  AVAILABILITY_DELETE_SUCCESS = 609,
  AVAILABILITY_DELETE_ERROR = 610,
  AVAILABILITY_GET_SUCCESS = 611,
  AVAILABILITY_GET_ERROR = 612,
  AVAILABILITY_GET_NOT_FOUND = 613,
  USER_NOT_FOUND = 614,
  EMAIL_ALREADY_EXISTS = 615,
  MOBILE_ALREADY_EXISTS = 616,
  SALESFORCE_CONTACT_ERR = 617,
  SALESFORCE_CONTACT_DUPLICATE = 618,
  USER_UPDATE_SUCCESS = 619,
  USER_NOT_ACTIVE = 620,
  INVALID_LOGIN_LINK = 621,
  ITEM_NOT_FOUND = 622,
  SEND_EMAIL_FAIL = 623,
}

export const CLIENT_IN_HEADER_AXIOS = 'admin'
export const CLIENT_HEADER_ADMIN = 'admin'
export const CLIENT_HEADER_TEACHER = 'teacher_llc'
export const CLIENT_HEADER_TEACHER_OTHER = 'teacher_other'

export const USER_GENDER_OPTIONS = [
  {
    label: 'AAA',
    value: 'male',
  },
  {
    label: 'BBB',
    value: 'female',
  },
]

export const USER_STATUS_OPTIONS = [
  {
    label: 'XXXX',
    value: 'active',
  },
  {
    label: 'ZZZZZ',
    value: 'inactive',
  },
]

export enum EAuthType {
  LOGIN = 'login',
  CHANGE_PASSWORD = 'change-password',
  TEACHER_SIGNUP = 'teacher-signup',
  TEACHER_VERIFY_SIGNUP = 'verify-signup',
  TEACHER_REQUEST = 'teacher-requests',
}

export enum EForgotPasswordPageType {
  VERIFY_EMAIL = 'verify-email',
  RESEND_CODE = 'resend-otp-code',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  VERIFY_FORGOT_PASSWORD = 'verify-forgot-password',
}

export enum EPlanType {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum EPlanViewType {
  VIEW = 'view',
  ADD = 'add',
  EDIT = 'edit',
}

export enum EUserGender {
  MALE = 1,
  FEMALE = 2,
}

export enum EKeyBoardCode {
  ENTER = 'Enter',
  SPACE = 'Space',
}

export enum EDeviceType {
  MOBILE_APP = 1,
  WATCH_APP = 2,
  BOTH = 3,
}

export const SICKNESS_OPTIONS = [
  'Heart Failure',
  'Myocardial Infarction',
  'Angina',
  'Aortic Dissection',
  'Valvular Disease',
  'Pulmonary Hypertension',
  'Arrhythmia',
  'Cardiomyopathy',
  'Other',
]

export const SIDEBAR_CONTENTS = [
  'Dashboard',
  'Chat channel',
  'User management',
  'Manage role',
  'Content management',
  'Declaration management',
]

export const SIDEBAR_PATHS = [
  PATH_DASHBOARD,
  PATH_CHAT_CHANNEL,
  PATH_USER_MANAGEMENT,
  PATH_MANAGE_ROLE,
  PATH_ARTICLE_MANAGEMENT,
  PATH_DECLARATION_MANAGEMENT,
]

export const ITEMS_IN_DECLARATION_MANAGEMENT = [
  ROAD_MAP,
  SLIDE_SHOW,
  EDIT_COMPANY_INFOR,
  MAIL_CONTENT,
  ABOUT_US,
  TERM,
  PRIVACY_PAGE,
]

export enum EManageRoleTabs {
  STAFF_MANAGEMENT = 'Staff management',
  ROLE_MANAGEMENT = 'Role management',
}
export const PASSWORD_LENGTH_MIN = 8
export const PASSWORD_LENGTH_MAX = 32
export const OPT_LENGTH = 4

export enum ERoleModule {
  USERS = 'users',
  ROLES = 'roles',
}

export const LIST_STATUS_USER = {
  [EUserStatus.ACTIVE]: 'Active',
  [EUserStatus.INACTIVE]: 'Inactive',
  [EUserStatus.BLOCK]: 'Blocked/Restricted',
  [EUserStatus.DELETED]: 'Deleted',
  [EUserStatus.TEMP_DELETED]: 'Delete - can recover',
  [EUserStatus.TOTAL_DELETED]: 'Delete - can not recover',
}

export const DEFAULT_USER_AVATAR =
  'https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg'

export const REGEX_EMAIL =
  /^[a-zA-Z0-9._%+-]+@(?!.*\.\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const REGEX_PHONENUMBER = /^\s*\d*\s*$|^$/
export const REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d).+$/
export const REGEX_FULLNAME = /^[a-zA-Z\s]+$/

export const REGEX_OTP =
  /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

export const REGEX_TWO_DIGIT_AFTER_COMMA = /^\d*\.?\d{0,2}$/

export const ROLE_DEFAULT_ID = 2

export const LIST_USER_LOG_TYPE = {
  [EActivityLogActionType.LOGIN_SUCCESS]: 'Profile log',
  [EActivityLogActionType.ACCOUNT_CREATION]: 'Profile log',
  [EActivityLogActionType.PROFILE_UPDATE]: 'Profile log',
  [EActivityLogActionType.POST_INTERACTION]: 'User activity log',
  [EActivityLogActionType.COMMENT_INTERACTION]: 'User activity log',
  [EActivityLogActionType.MESSAGE_INTERACTION]: 'User activity log',
  [EActivityLogActionType.CONTENT_CREATION]: 'Content log',
  [EActivityLogActionType.CONTENT_EDITING]: 'Content log',
  [EActivityLogActionType.CONTENT_DELETION]: 'Content log',
  [EActivityLogActionType.BOOKING_ACTION]: 'Purchase log',
  [EActivityLogActionType.LOGIN_FAIL]: 'Profile log',
  [EActivityLogActionType.ACCESS_CONTROL_LOG]: 'Security log',
  [EActivityLogActionType.PAYMENT_DETAIL]: 'Purchase log',
  [EActivityLogActionType.BOOKING_DETAIL]: 'Purchase log',
  [EActivityLogActionType.INVENTORY_CHANGES]: 'Purchase log',
  [EActivityLogActionType.CONVERSATION_TRACKING]: 'Conversation log',
  [EActivityLogActionType.MARKETING_CAMPAIGN]: 'Purchase log',
}

export const LIST_USER_LOG_ACTION = {
  [EActivityLogType.ACTIVITY]: 'User activity log',
  [EActivityLogType.SECURITY]: 'Security log',
  [EActivityLogType.TRANSACTION]: 'Transaction log',
  [EActivityLogType.ANALYTICS]: 'Analytics log',
}

export const POST_DISPLAY_NAME = {
  [EUserPostStatus.PUBLIC]: 'Published',
  [EUserPostStatus.FRIEND]: 'Draft',
}

export enum ECurrency {
  USD = 'USD',
  EUR = 'EUR',
}

export const CHAT_FILE_LIMIT_SIZE_MB = 20
export const ABOUT_US_FILE_LIMIT_SIZE_MB = 50
export const LIMIT_ITEM_PER_PAGE = 10
export const MAX_LIMIT_ITEM_PER_PAGE = 999999999

export const BLOG_STATUS = {
  ALL: 'all status',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
}

export const COURSE_STATUS = {
  ALL: 'All status',
  ACTIVE: '1',
  INACTIVE: '0',
}

export const COURSE_TYPE = {
  ALL: 'All type',
  FREE: 'free',
  PREMIUM: 'premium',
}

export const PLACEHOLDER_IMAGE_AVATAR =
  'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'

export const SEO_CONFIG = 'SEO config'

// 5mb
export const CHUNK_SIZE = 1024 * 1024 * 25

// .mp4, .webm, .m4v, .mov, .qt
export const VIDEO_FILTER = /[^\s]+(.*?).(webm|mp4|x-m4v|quicktime)$/
export const POST_MEDIA_FILTER =
  /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|mp4|mpeg|text|plain|webm|x-m4v|quicktime)$/

export const EMAIL_CONTENT_NAME = {
  All: 'All',
  [EEmailContentType.NEW_USER]: 'New user',
  [EEmailContentType.ADVERTISEMENT]: 'Advertisement',
  [EEmailContentType.SUBSCRIPTION]: 'Subscription',
  [EEmailContentType.COMMUNITY]: 'Community',
  [EEmailContentType.CHALLENGE]: 'Challenge',
  [EEmailContentType.DAILY_ROUTINE]: 'Daily routine',
  [EEmailContentType.OTHER]: 'Other',
}

export const DAILY_ROUTINE_TYPE = {
  All: 'All',
  [EDailyRoutineType.NUTRITION]: 'Nutrition',
  [EDailyRoutineType.EXERCISE]: 'Exercise',
  [EDailyRoutineType.MOBILITY]: 'Mobility',
  [EDailyRoutineType.SLEEP]: 'Sleep',
  [EDailyRoutineType.REFLECTION]: 'Reflection',
  [EDailyRoutineType.WELL_BEING]: 'Well-being',
}

export const DISPLAY_YES_NO_OPTIONS = {
  [EYesNoOptions.ALL]: 'All',
  [EYesNoOptions.YES]: 'Yes',
  [EYesNoOptions.NO]: 'No',
}

export const DISPLAY_ACTIVE_INACTIVE_OPTIONS = {
  [EActiveInActiveOptions.ALL]: 'All',
  [EActiveInActiveOptions.ACTIVE]: 'Active',
  [EActiveInActiveOptions.INACTIVE]: 'Inactive',
}

export const DISPLAY_PUBLISH_DRAFT_OPTIONS = {
  [EPublishDraftOptions.ALL]: 'All',
  [EPublishDraftOptions.PUBLISHED]: 'Published',
  [EPublishDraftOptions.DRAFT]: 'Draft',
}

export const WHITE_COLOR = '#ffffff'

export enum ECommonContentLayout {
  LAYOUT_ONE = 'layout_one',
  LAYOUT_TWO = 'layout_two',
  LAYOUT_THREE = 'layout_three',
  LAYOUT_FOUR = 'layout_four',
}

export const LAYOUT_OPTIONS = [
  {
    label: 'Layout 1',
    value: ECommonContentLayout.LAYOUT_ONE,
  },
  {
    label: 'Layout 2',
    value: ECommonContentLayout.LAYOUT_TWO,
  },
  {
    label: 'Layout 3',
    value: ECommonContentLayout.LAYOUT_THREE,
  },
  {
    label: 'Layout 4',
    value: ECommonContentLayout.LAYOUT_FOUR,
  },
]

export const DEFAULT_PAGE = 1
export const DEFAULT_ROWS_PER_PAGE = 10
export const REMOVE_SPECIAL_CHARACTER_REGEX = /^[a-zA-Z\s]+$/
export const VALID_URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/

export const VALID_EDITOR_REGEX = /<\/?[a-z][\s\S]*>/i
export const LEXICAL_EDITOR_P_TAG = `<p class=\"PlaygroundEditorTheme__paragraph\">$value</p>`

export const KEYBOARD_EVENT_CODE = {
  ENTER: 13,
}
