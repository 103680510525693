import { z } from 'zod'
import { t } from 'i18next'

import {
  PASSWORD_LENGTH_MAX,
  PASSWORD_LENGTH_MIN,
  PASSWORD_REGEX,
} from '@configs'

export interface IChangePassword {
  password: string
  newPassword: string
  confirmNewPassword: string
}

export const changePasswordSchema = z
  .object({
    password: z
      .string()
      .nonempty({
        message: t('error:field_required'),
      })
      .min(PASSWORD_LENGTH_MIN, t('error:password_min_error'))
      .max(PASSWORD_LENGTH_MAX, t('error:password_max_error'))
      .refine(
        (value) => PASSWORD_REGEX.test(value ?? ''),
        t('error:password_format_error')
      ),
    newPassword: z
      .string()
      .nonempty({
        message: t('error:field_required'),
      })
      .min(PASSWORD_LENGTH_MIN, t('error:password_min_error'))
      .max(PASSWORD_LENGTH_MAX, t('error:password_max_error'))
      .refine(
        (value) => PASSWORD_REGEX.test(value ?? ''),
        t('error:password_format_error')
      ),
    confirmNewPassword: z
      .string()
      .nonempty({
        message: t('error:field_required'),
      })
      .min(PASSWORD_LENGTH_MIN, t('error:password_min_error'))
      .max(PASSWORD_LENGTH_MAX, t('error:password_max_error'))
      .refine(
        (value) => PASSWORD_REGEX.test(value ?? ''),
        t('error:password_format_error')
      ),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: t('error:confirm_new_password_must_match_new_password'),
    path: ['confirmNewPassword'],
  })

export const CHANGE_PASSWORD_DEFAULT_VALUES = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
}
