import { useGetSubscriptionQuery } from '@api'
import { AppModal, XCloseIcon } from '@components'
import { ISubscription } from '@interfaces'
import { handleAPIError } from '@utils'
import { message, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useUpdatePackageRenewStatusMutation } from 'src/api/react-query/hooks/subscription/useUpdatePackageRenewStatus'
import { Button } from 'src/common'

interface IProps {
  open: boolean
  onClose: () => void
  currentPackage: ISubscription
}

export default function CancelRenewalModal({
  open,
  onClose,
  currentPackage,
}: IProps) {
  const { t } = useTranslation(['profile', 'common'])
  const { mutate: updatePackageRenewStatus, isPending: isPackageRenewLoading } =
    useUpdatePackageRenewStatusMutation()
  const { refetchGetPackageSubscription } = useGetSubscriptionQuery()

  const handleUpdatePackageRenewStatus = async (packageId: string | number) => {
    if (!packageId) return

    try {
      await updatePackageRenewStatus(
        { packageId },
        {
          onError: (err) => {
            handleAPIError(err)
          },
          onSuccess: (res) => {
            message.success(res?.message)
            refetchGetPackageSubscription()
            onClose()
          },
        }
      )
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  const daysLeft = moment(currentPackage?.packageSubscribed?.dueDate).diff(
    moment(),
    'days'
  )

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon={false}
      contentClassName="!px-8 !py-6"
    >
      <div className="h-auto">
        <div className="flex items-center justify-between gap-4">
          <div>
            <Typography.Title
              style={{ marginBottom: 0, fontSize: 24, fontWeight: 'bold' }}
            >
              {t('profile:cancelRenewal')}
            </Typography.Title>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[700px] mt-4 mb-8 mx-auto">
        <Typography
          style={{ color: '#424955FF', textAlign: 'start', fontSize: 16 }}
        >
          {t('profile:cancelRenewalMessage', {
            expiredDate: moment(
              currentPackage?.packageSubscribed?.dueDate
            ).format('MMM DD, YYYY'),
          })}
        </Typography>

        <div className="bg-[#F3F4F6FF] w-full rounded mt-6 p-4 flex flex-col gap-4">
          <div className="flex">
            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                flex: 1,
              }}
            >
              {t('profile:currentPlan')}:
            </Typography>

            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                fontWeight: 'bold',
                flex: 1,
              }}
            >
              {currentPackage?.name || ''}
            </Typography>
          </div>

          <div className="flex">
            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                flex: 1,
              }}
            >
              {t('profile:expirationDate')}:
            </Typography>

            <Typography
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                flex: 1,
              }}
            >
              {moment(currentPackage?.packageSubscribed?.dueDate).format(
                'MMM DD, YYYY'
              )}{' '}
              <span style={{ fontSize: 18, color: '#ff0000' }}>
                {`(${daysLeft} ${
                  daysLeft > 1 ? t('profile:daysLeft') : t('profile:dayLeft')
                })`}
              </span>
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center my-2 mt-4 gap-2">
        <Button
          type="primary"
          style={{
            width: '100%',
            maxWidth: 200,
            borderRadius: '100rem',
            background: '#535CE8FF',
          }}
          onClick={() => handleUpdatePackageRenewStatus(currentPackage?.id)}
          loading={isPackageRenewLoading}
        >
          {t('common:confirm')}
        </Button>

        <Button danger type="text" onClick={onClose}>
          <Typography.Text
            style={{ color: '#FF765AFF', textDecoration: 'underline' }}
          >
            {t('profile:keepPlan')}
          </Typography.Text>
        </Button>
      </div>
    </AppModal>
  )
}
