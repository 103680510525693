import { Card, Typography } from 'antd'
import styled from 'styled-components'

import ProfileSection from 'src/pages/profile/ProfileSection'

export const ProfilePage = () => {
  return (
    <ProfilePageStyled className="flex items-center justify-center w-full">
      <Typography
        style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 24 }}
      >
        Account Settings
      </Typography>

      <ProfileSection />
    </ProfilePageStyled>
  )
}

const ProfilePageStyled = styled(Card)`
  background-color: transparent;
  border: none;

  .ant-card-body {
    width: 100%;
    padding: 0;
  }
`
