import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps, TConfirmPaymentIntentRequest } from '@interfaces'
import { handleAPIError } from '@utils'
import { QK_MARK_ALL_NOTIFICATION_AS_READ } from 'src/configs/queryClient/queryKeyConstants'
import { notificationAPI } from 'src/api/notification'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<{ message: string }>,
    any,
    TConfirmPaymentIntentRequest,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<{ message: string }>) => void
}

export const useMarkAllNotificationAsReadMutation = (
  configs?: IMutationParams
) => {
  return useMutation({
    mutationKey: [QK_MARK_ALL_NOTIFICATION_AS_READ],
    mutationFn: () => notificationAPI.markAllNotificationsAsRead(),
    onError: (error: any) => {
      handleAPIError(error)
    },
    ...configs,
  })
}
