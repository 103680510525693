import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

export const FilterWrapper = styled(TitleWrapper)`
  width: auto !important;
  gap: 16px !important;
`

export const ListCourseOrdersStyled = styled.div`
  width: 100%;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  animation-name: FadeIn;
  animation-duration: 0.5s;
  transition-timing-function: linear;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`
