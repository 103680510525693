import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'i18next'
import { FieldValues, UseFormReset, UseFormSetValue } from 'react-hook-form'

import { getMiniDashboard, RootState, useAppDispatch } from '@redux'
import {
  EMiniDashBoardColor,
  SEARCH_FORM_FIELDS,
} from 'src/pages/test-management/config'
import DashboardTag from 'src/pages/test-management/mini-dashboard/DashboardTag'
import { BookOutlined, CalendarOutlined } from '@ant-design/icons'
import { EGradingStatus } from 'src/pages/test-management/type'

interface IProps {
  reset: UseFormReset<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  reload?: boolean
}

const MiniDashboard = ({ setValue, reset }: IProps) => {
  const dispatch = useAppDispatch()

  const { miniDashboard } = useSelector(
    (state: RootState) => state.testManagement
  )

  const renderData = useMemo(() => {
    if (!miniDashboard) return

    return [
      {
        title: t('teacher:total_grade_test'),
        value: miniDashboard?.totalGradedTest || 0,
        bgColor: EMiniDashBoardColor.LIGHT_PURPLE,
        logo: <CalendarOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset()
          setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [EGradingStatus.GRADED])
        },
      },
      {
        title: t('teacher:total_in_progress_test'),
        value: miniDashboard?.totalInprogessTest || 0,
        bgColor: EMiniDashBoardColor.LIGHT_BLUE,
        logo: <BookOutlined className="text-[28px] text-black" />,
        onClick: () => {
          reset()
          setValue(SEARCH_FORM_FIELDS.GRADING_STATUS, [
            EGradingStatus.IN_PROGRESS,
          ])
        },
      },
    ]
  }, [miniDashboard, reset, setValue])

  useEffect(() => {
    dispatch(getMiniDashboard())
  }, [dispatch])

  return (
    <MiniDashboardContainer>
      {renderData?.map((item, index) => (
        <DashboardTag
          key={`${item.title}.${index}`}
          analysticNumber={item.value}
          title={item.title}
          bgColor={item.bgColor}
          logo={item.logo}
          onClick={item.onClick}
        />
      ))}
    </MiniDashboardContainer>
  )
}

export default MiniDashboard

const MiniDashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  gap: 32px;
`
