import { LoadingOutlined } from '@ant-design/icons'
import { EUploadFileType } from '@configs'
import { UploadProps, message, Upload, UploadFile, Typography } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  IMultipartPresignUrlResponse,
  IUploadMediaResponse,
} from 'src/interfaces/media'
import { SimpleImage } from '../image/SimpleImage'
import { Text } from '../typography'
import { galleryAPI } from 'src/api/gallery'
import { EMediaCategory } from 'src/interfaces/gallery'
import { useMediaQuery } from '@utils'
import { lessonAPI } from '@api'
import { AxiosRequestConfig } from 'axios'
import { RcFile } from 'antd/es/upload'
import { Uploader } from 'src/utils/multipartUpload'
import useMultipartUploader from 'src/hooks/useUploadMultipart'
import { useTranslation } from 'react-i18next'
import { getFileExtension, isImageFile } from 'src/utils/file'

export const CustomDragger = (
  props: UploadProps & {
    containerClassName?: string
    label?: string
    placeholder?: string
    required?: boolean
    onLoadEnd?: (data: IUploadMediaResponse) => void
    onSetDuration?: (duration: number) => void
    errors?: string
    initResource?: string
    fileName?: string
    reset?: boolean
    changeLoading?: (loading: boolean) => void
    allowFileTypes?: string[]
    limitFileSize?: number
    uploadType?: EUploadFileType
    uploadCategory?: EMediaCategory
    labelClassName?: string
    alignment?: 'row' | 'col'
    note?: string
    aborted?: boolean
    needAuthorized?: boolean
  }
) => {
  const {
    containerClassName,
    required,
    label,
    placeholder = 'Click to upload media file',
    onLoadEnd,
    initResource,
    errors,
    reset,
    changeLoading,
    allowFileTypes = ['image/png', 'image/jpeg', 'image/jpg'],
    limitFileSize = 20,
    uploadType = EUploadFileType.IMAGE,
    uploadCategory,
    // Id must be the same as name
    name = 'avatar',
    id = 'avatar',
    labelClassName,
    alignment = 'row',
    disabled,
    note,
    aborted = false,
    onSetDuration,
    needAuthorized = true,
    fileName = '',
    ...restProps
  } = props
  const [loading, setLoading] = useState(false)
  const [resource, setResource] = useState<string | undefined>(initResource)
  const isSMScreen = useMediaQuery(`(max-width:640px)`)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [multipartFile, setMultipartFile] = useState<File>()
  const [uploader, setUploader] = useState<Uploader>()
  const { initialize, finalResult } = useMultipartUploader()
  const [multiparts, setMultiparts] = useState<IMultipartPresignUrlResponse[]>(
    []
  )
  const [duration, setDuration] = useState(null)
  const [isDone, setIsDone] = useState(false)
  const [currentFileName, setCurrentFileName] = useState<string>('')

  const videoRef = React.createRef<any>()
  const audioRef = React.createRef<any>()
  const inputVideoRef = React.createRef<any>()
  const { t } = useTranslation(['common', 'error'])

  const handleLoadedMetadata = () => {
    setDuration(videoRef?.current?.duration || 0)
    onSetDuration && onSetDuration(videoRef?.current?.duration || 0)
  }
  const handleLoadedMetadataAudio = () => {
    setDuration(audioRef?.current?.duration || 0)
    onSetDuration && onSetDuration(audioRef?.current?.duration || 0)
  }
  let inputContainerClass = 'grid grid-cols-7 gap-3'
  let labelClass = 'text-right mb-0 '
  let localAlignment = isSMScreen ? 'col' : alignment

  if (localAlignment === 'col') {
    inputContainerClass = 'flex items-start flex-col '
    labelClass = 'text-left mb-2 '
  }

  const handleChangeMedia = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    changeLoading?.(true)
    if (!e.target.files || e.target.files.length === 0) {
      setLoading(false)
      changeLoading?.(false)
      return
    }

    const file = e.target.files[0]
    setCurrentFileName(file?.name ?? '')

    const isMatchMediaType = allowFileTypes.includes(file.type)

    const allowedInputType = allowFileTypes
      ?.map((item, index) => item.split('/')[1])
      ?.join('/')
      ?.toUpperCase()

    if (!isMatchMediaType) {
      message.error(`You can only upload ${allowedInputType}file!`)
      setLoading(false)
      changeLoading?.(false)
      return
    }

    const isMatchMediaSize =
      limitFileSize > 0
        ? Number(file.size) <= limitFileSize * 1024 * 1024
        : true
        
    if (!isMatchMediaSize) {
      message.error(`Media file must smaller than ${limitFileSize}MB!`)
      setLoading(false)
      changeLoading?.(false)
      return
    }

    try {
      if (uploadType === EUploadFileType.IMAGE && needAuthorized) {
        const response = await galleryAPI.uploadImage(file, uploadCategory)
        if (response.success) {
          if (onLoadEnd) {
            setResource(response?.data.original)
            onLoadEnd(response?.data)
          }
          setLoading(false)
          changeLoading?.(false)
        }
      } else if (
        (uploadType === EUploadFileType.IMAGE ||
          uploadType === EUploadFileType.OTHERS) &&
        !needAuthorized
      ) {
        const response = await galleryAPI.uploadSignUpMedia(
          file,
          uploadCategory
        )
        if (response?.data?.data) {
          if (onLoadEnd) {
            setResource(response?.data?.data?.original)
            onLoadEnd(response?.data?.data)
          }
          setLoading(false)
          changeLoading?.(false)
        }
      } else {
        setMultipartFile(file)

        const videoUploaderOptions = {
          fileName: file.name,
          fileType: file.type,
          file: file,
        }

        const uploader = new Uploader(videoUploaderOptions)
        setUploader(uploader)
      }
    } catch (error: any) {
      setLoading(false)
      message.error(
        error?.code === 500
          ? t('common:upload_file_failed')
          : error?.message ?? t('common:upload_file_failed')
      )
    }
  }

  const hanldeStartUpload = async () => {
    let percentage: number

    if (!uploader || !multipartFile) return
    await uploader.start()
    uploader.onProgressFn((data: any) => {
      if (data.percentage !== percentage) {
        percentage = data.percentage
      }
    })

    uploader.onErrorFn((error: any) => {
      setMultipartFile(undefined)
      changeLoading?.(false)
      setLoading(false)
    })

    uploader.setUploadCompletionCallback(async () => {
      try {
        changeLoading?.(true)
        const result = await uploader.sendCompleteRequest()
        if (result.Location) {
          const formData = new FormData()
          // formData.append('file', multipartFile)
          formData.append('bucket', result.Bucket)
          formData.append('key', result.Key)
          formData.append('contentType', multipartFile.type)
          formData.append('originalname', multipartFile.name)
          if (uploadCategory) {
            formData.append('category', uploadCategory)
          }

          createMedia(formData)
        }
      } catch (error) {
        changeLoading?.(false)
        setLoading(false)
        message.error(error ?? t('error:upload_failed'))
      } finally {
        changeLoading?.(true)
        setLoading(false)
      }
    })
  }

  const createMedia = async (data: any) => {
    try {
      const result = await galleryAPI.createMedia(data)
      if (result) {
        if (onLoadEnd) {
          onLoadEnd(result?.data)
        }
        setResource(result.data.url)
      }
    } catch (error) {
      message.error(error ?? t('error:upload_failed'))
    } finally {
      setIsDone(true)
    }
  }

  useEffect(() => {
    hanldeStartUpload()
  }, [multipartFile, uploader])

  useEffect(() => {
    if (duration !== null && duration > 0 && isDone) {
      message.success(t('common:upload_success'))
      setLoading(false)
      changeLoading?.(false)
      setIsDone(false)
    }
  }, [isDone, duration, t, changeLoading])

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, onProgress } = options
    setLoading(true)

    const file = options.file as RcFile

    const config: AxiosRequestConfig<any> = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    try {
      const res = await lessonAPI.uploadMedia(file, config)
      if (onSuccess) {
        onSuccess(res.data)
      }
      if (onLoadEnd) {
        onLoadEnd(res?.data?.dataValues as any)
      }
      setResource(res.data?.url || '')
      message.success(res.data?.message)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }
  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList]
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url
      }
      return file
    })

    setFileList(newFileList)
  }

  // get preview content base on old logic
  const getPreviewContent = () => {
    if (uploadType !== EUploadFileType.AUDIO) {
      if (loading) return <LoadingOutlined size={32} color={'blue'} spin />

      if (!!resource) {
        if (uploadType === EUploadFileType.IMAGE)
          return <SimpleImage source={resource} />

        if (uploadType === EUploadFileType.OTHERS)
          return (
            <div>
              {isImageFile(getFileExtension(resource)) ? (
                <SimpleImage source={resource} />
              ) : (
                <Typography.Text className="text-base">
                  {currentFileName}
                </Typography.Text>
              )}
            </div>
          )

        return (
          <div className="w-full h-full flex items-center justify-center">
            <video
              src={resource}
              loop
              ref={videoRef}
              onLoadedMetadata={handleLoadedMetadata}
              controls={!!resource}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </div>
        )
      }
      return <Text>{t('common:click_to_upload_file')}</Text>
    }

    return (
      <Upload
        accept=".mp3"
        onChange={handleChange}
        id={id}
        name={name}
        showUploadList={false}
        customRequest={customRequest}
      >
        {loading ? (
          <LoadingOutlined size={32} color={'blue'} spin />
        ) : resource ? (
          <audio
            controls
            src={resource}
            ref={audioRef}
            onLoadedMetadata={handleLoadedMetadataAudio}
          />
        ) : (
          <Text>{t('common:click_to_upload_file')}</Text>
        )}
      </Upload>
    )
  }

  useEffect(() => {
    setResource(initResource)
  }, [reset, initResource, uploadType])

  useEffect(() => {
    if (!resource) {
      inputVideoRef.current.value = null
    }
  }, [resource])

  useEffect(() => {
    setCurrentFileName(fileName)
  }, [fileName, reset, uploadType])

  useEffect(() => {
    if (aborted && uploader) {
      uploader.aborted = false
    }
  }, [aborted, uploader])

  return (
    <div key={id} className="w-full">
      <div
        className={`Input w-full ${inputContainerClass} ${
          containerClassName || ''
        }`}
      >
        {label && (
          <label
            htmlFor={label}
            className={`Input__label ${labelClass} inline-flex mr-[1.25rem] capitalize  text-dark col-span-2 `}
          >
            {label}
            {required && (
              <span className="required text-[#B91C1C] font-bold"> *</span>
            )}
          </label>
        )}

        <div
          className={`Input__field-container ${labelClassName} relative ${
            label ? 'col-span-5' : 'col-span-7'
          }`}
        >
          <label
            className={`icon-change-avatar cursor-pointer border-[1.5px] border-dashed h-[200px] w-full p-5 col-span-5 inline-flex items-center justify-center rounded-lg ${
              disabled ? '' : 'hover:border-primary'
            } `}
            htmlFor={name}
            style={{
              border: errors ? '1px solid #B91C1C' : '',
              borderRadius: errors ? '0.375rem' : '',
            }}
          >
            {getPreviewContent()}
          </label>

          {uploadType !== EUploadFileType.AUDIO && (
            <input
              ref={inputVideoRef}
              type="file"
              id={id}
              name={name}
              className="hidden w-3/4"
              accept={allowFileTypes.join(',')}
              onChange={handleChangeMedia}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {errors && (
        <div className="grid grid-cols-7 w-full ">
          {label && localAlignment === 'row' && (
            <div className={labelClass + ' col-span-2 min-w-[1px]'}></div>
          )}
          <div
            style={{
              color: 'rgb(var(--color-danger)',
            }}
            className={`Input__text-error mt-2 text-sm col-span-7 ${
              alignment === 'col' ? 'sm:col-span-7 text-left' : 'sm:col-span-5'
            }`}
          >
            {errors}
          </div>
        </div>
      )}
    </div>
  )
}
