import { useEffect, useState } from 'react'
import '@translations/i18n'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { store } from 'src/redux'
import Router from './routes'
import { themes, useTheme } from '@theme'
import { queryClient } from '@configs'
import { AppToast } from './components'
import RoleProvider from 'src/contexts/RoleContext'
// ..
AOS.init()

function App() {
  const { theme, themeLoaded } = useTheme()
  const [selectedTheme, setSelectedTheme] = useState(theme)

  useEffect(() => {
    setSelectedTheme(themes.theme.light)
  }, [themeLoaded])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RoleProvider>
          <ThemeProvider theme={selectedTheme || themes.theme.light}>
            <BrowserRouter>
              <Router />
              <AppToast />
            </BrowserRouter>
          </ThemeProvider>
        </RoleProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  )
}

export default App
