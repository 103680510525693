import { z } from 'zod'
import { t } from 'i18next'

export const withdrawVerificationOtp = z.object({
  otp: z
    .string()
    .nonempty({
      message: t('error:field_required'),
    })
    .min(4, {
      message: t('error:otp_length_error'),
    })
    .max(4, {
      message: t('error:otp_length_error'),
    }),
})
