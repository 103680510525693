import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_INVOICE_DETAILS } from 'src/configs/queryClient/queryKeyConstants'
import { BaseResponseError, BaseResponseProps, IInvoiceItem } from '@interfaces'
import { revenueAPI } from 'src/api/revenue'

interface IGetInvoiceDetails {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<IInvoiceItem>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
  invoiceId: string | number
}

export const useGetInvoiceDetailsQuery = ({
  configs,
  invoiceId,
}: IGetInvoiceDetails) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_INVOICE_DETAILS, invoiceId],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_INVOICE_DETAILS, invoiceId],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getTeacherInvoiceDetails() {
    return revenueAPI.getInvoiceDetail(invoiceId)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IInvoiceItem>,
    BaseResponseError
  >({
    queryKey: [QK_GET_INVOICE_DETAILS, invoiceId],
    queryFn: getTeacherInvoiceDetails,

    ...configs,
  })

  return {
    data: data?.data ?? ({} as IInvoiceItem),
    invalidateData,
    refetchData,
    ...restData,
  }
}
