import { useMemo, useState } from 'react'
import { t } from 'i18next'
import { Typography } from 'antd'
import styled from 'styled-components'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import { IDashboardCourseOrder } from '@interfaces'
import { themes } from '@theme'
import { Button } from 'src/common'
import { CourseOrderTable } from './CourseOrderTable'
import { ITempCourseInfor, useDashboardContext } from '../../provider'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

interface IProps {
  courseOrder?: IDashboardCourseOrder
}

const CourseAccordionHeader = ({
  courseOrder,
  showDetails,
  tempCourse,
  setShowDetails,
}: IProps & {
  showDetails?: boolean
  tempCourse?: ITempCourseInfor
  setShowDetails?: () => void
}) => {
  return (
    <CourseAccordionOverview>
      <span className="flex items-center gap-4 max-w-[80%]">
        <Button
          className={`!flex items-center justify-center p-2 !min-w-0 !w-fit !h-fit !rounded-[4px] !border-none !shadow-none ${
            showDetails ? '!bg-primaryPurple' : '!bg-secondaryPurple'
          }`}
          onClick={setShowDetails}
        >
          {showDetails ? (
            <MinusOutlined className="!text-[12px] !text-secondaryPurple" />
          ) : (
            <PlusOutlined className="!text-[12px] !text-primaryPurple" />
          )}
        </Button>
        <Typography.Title
          level={4}
          className="!m-0 !text-[14px] !whitespace-pre"
        >
          {courseOrder?.title ?? ''}
        </Typography.Title>
        <Typography.Paragraph className="!m-0 !text-[14px] !text-black !text-nowrap">
          <Typography.Text className="!m-0 font-medium !text-[14px] !text-primaryPurple">
            {(!!showDetails
              ? tempCourse?.totalOrder
              : courseOrder?.totalOrders) ?? 0}{' '}
          </Typography.Text>
          {t('dashboard:orders')}
        </Typography.Paragraph>
      </span>
      <span>
        <Typography.Paragraph className="!m-0 !text-[14px] !text-black">
          {t('dashboard:total')}{' '}
          <Typography.Text className="!m-0 font-medium !text-[14px] !text-primaryPurple">
            €
            {formatCurrencyByType(
              (!!showDetails
                ? tempCourse?.totalAmount
                : courseOrder?.totalAmount) ?? 0,
              'EUR'
            ).replace('€', '')}
          </Typography.Text>
        </Typography.Paragraph>
      </span>
    </CourseAccordionOverview>
  )
}

export const CourseAccordion = ({ courseOrder }: IProps) => {
  const { temporaryCoursesInfor, removeTemporaryCourseByKey } =
    useDashboardContext()
  const [showDetails, setShowDetails] = useState(false)

  const tempCourse = useMemo(() => {
    if (!courseOrder?.id || !Object.keys(temporaryCoursesInfor)?.length) return

    return temporaryCoursesInfor?.[courseOrder?.id]
  }, [courseOrder?.id, temporaryCoursesInfor])

  const handleToggleShowDetails = () => {
    if (!courseOrder?.id) return

    if (showDetails) {
      removeTemporaryCourseByKey(String(courseOrder?.id))
    }

    setShowDetails(!showDetails)
  }

  return (
    <CourseAccordionStyled>
      <CourseAccordionHeader
        courseOrder={courseOrder}
        tempCourse={tempCourse}
        showDetails={showDetails}
        setShowDetails={handleToggleShowDetails}
      />
      {showDetails ? <CourseOrderTable courseId={courseOrder?.id} /> : null}
    </CourseAccordionStyled>
  )
}

const CourseAccordionStyled = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${themes.theme.light.colors.lightGray};
  padding: 24px 16px;
`

const CourseAccordionOverview = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
