import React from 'react'
import { t } from 'i18next'

import { Button } from 'src/common'
import { AppModal } from '@components'
import { CheckCircleOutlined } from '@ant-design/icons'

interface IModalProps {
  open: boolean
  onSave: () => void
  onClose: () => void
}

const PublishCourseModal = ({ open, onClose, onSave }: IModalProps) => {
  return (
    <AppModal open={open} onClose={onClose} contentClassName="max-w-[700px]">
      <div className="flex items-center justify-between">
        <div className="flex gap-4 items-center">
          <CheckCircleOutlined className="!text-green-500 !text-[32px]" />
          <h1 className="m-0 text-[20px]">
            {t('courseManagement:confirm_publish_title')}
          </h1>
        </div>
      </div>
      <div className="mt-6">
        <span className="text-[16px] text-danger">
          {t('courseManagement:confirm_publish_content')}
        </span>
      </div>
      <div className="mt-6">
        <div className="flex items-center mt-5 justify-end">
          <Button
            type="ghost"
            size="middle"
            className="submit__btn login-btn mr-10"
            onClick={onClose}
          >
            {t('common:no')}
          </Button>
          <Button
            type="primary"
            size="middle"
            className="submit__btn login-btn"
            onClick={onSave}
          >
            {t('common:yes')}
          </Button>
        </div>
      </div>
    </AppModal>
  )
}

export default PublishCourseModal
