import { useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { t } from 'i18next'

import { Button } from 'src/common'
import { StyledExperienceForm } from 'src/pages/auth/sign-up/fill-in-teacher-information/work-experiences/ExperienceForm'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { authActions, useAppDispatch } from '@redux'
import { UPLOAD_DEGREE_LOADING_KEY } from 'src/pages/auth/sign-up/configs'
import { EUploadFileType } from '@configs'
import { DEGREE_MAX_FILE_SIZE } from 'src/pages/auth/sign-up/fill-in-teacher-information/degree/configs'
import { ALLOW_SIGNATURE_FILE_TYPES } from './config'

export const SignatureForm = ({ setEditable }: { setEditable: any }) => {
  const dispatch = useAppDispatch()
  const onChangeDesktopRef = useRef<any>(null)
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const { control, trigger, setValue, watch } = useFormContext()

  const watchSignatureFile = watch(`specificInformation.teacherSignature`)
  const imagePreviewUrl =
    watchSignatureFile?.signaturePhoto?.url || watchSignatureFile?.url || ''

  const handleSaveForm = async () => {
    const isValid = await trigger(`specificInformation.teacherSignature`)
    if (!isValid) return

    setEditable(false)
  }

  return (
    <>
      <SignatureAdvice
        listAdvices={[
          'File Format: The signature must be in PNG format.',
          'Image Dimensions: The image should be in a 4x4 aspect ratio.',
          'Signature Position: Please ensure that your signature is centered within the frame and properly cropped.',
        ]}
      />

      <StyledExperienceForm>
        <Controller
          name={`specificInformation.teacherSignature.fileId`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeDesktopRef.current = onChange
            return (
              <div className="col-span-8 mt-5">
                <CustomDragger
                  label={t('common:file')}
                  alignment="col"
                  name={`specificInformation.teacherSignature.fileId`}
                  id={`specificInformation.teacherSignature.fileId`}
                  onLoadEnd={(data) => {
                    setValue(
                      `specificInformation.teacherSignature.fileId`,
                      data?.dataValues?.id ?? 0
                    )
                    setValue(`specificInformation.teacherSignature`, {
                      ...data?.dataValues,
                      url: data?.baseUrl ?? '',
                    })
                    onChange(data?.dataValues?.id ?? 0)
                  }}
                  fileName={watchSignatureFile?.name ?? ''}
                  errors={error?.message}
                  multiple={false}
                  initResource={imagePreviewUrl}
                  allowFileTypes={ALLOW_SIGNATURE_FILE_TYPES}
                  limitFileSize={DEGREE_MAX_FILE_SIZE}
                  labelClassName="w-full"
                  changeLoading={(isLoading) => {
                    dispatch(
                      authActions.setLoadings({
                        key: UPLOAD_DEGREE_LOADING_KEY,
                        isLoading,
                      })
                    )
                    setIsUploadingImage(isLoading)
                  }}
                  uploadType={EUploadFileType.OTHERS}
                  needAuthorized={false}
                  required
                />
              </div>
            )
          }}
        />

        <div className="flex items-center max-[800px] justify-center my-5">
          <Button
            htmlType="submit"
            type="primary"
            size="small"
            className="min-[600px] max-[600px]:!w-full"
            onClick={handleSaveForm}
            loading={isUploadingImage}
          >
            Save Signature
          </Button>
        </div>
      </StyledExperienceForm>
    </>
  )
}

function SignatureAdvice({ listAdvices }: { listAdvices: string[] }) {
  return (
    <div className="bg-[#F1C932FF] rounded px-6 py-3 text-white text-base font-bold leading-6 mt-2">
      Notes: To ensure your signature is processed correctly, please follow
      these guidelines:
      <ul className="!list-inside mb-0">
        {listAdvices?.map((it, idx) => (
          <li key={idx}>{it}</li>
        ))}
      </ul>
    </div>
  )
}
