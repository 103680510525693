import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_WITHDRAW_INFORMATION } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetCreateWithdrawGenericInfor,
} from '@interfaces'
import { revenueAPI } from 'src/api/revenue'

interface IGetWithdrawInfor {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetCreateWithdrawGenericInfor>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
}

export const useGetWithdrawInformationQuery = ({
  configs,
}: IGetWithdrawInfor) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_WITHDRAW_INFORMATION],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_WITHDRAW_INFORMATION],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getWithdrawInformation() {
    return revenueAPI.getWithdrawInfor()
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetCreateWithdrawGenericInfor>,
    BaseResponseError
  >({
    queryKey: [QK_WITHDRAW_INFORMATION],
    queryFn: getWithdrawInformation,

    ...configs,
  })

  return {
    data: data?.data ?? ({} as IGetCreateWithdrawGenericInfor),
    invalidateData,
    refetchData,
    ...restData,
  }
}
