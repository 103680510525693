import { memo, useEffect, useState } from 'react'
import { Card, Skeleton, Typography } from 'antd'
import { t } from 'i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { StyledAuthenContainer } from 'src/components/auth/loginStyle'
import { IMAGES } from '@assets'
import {
  ETeacherRequestStatus,
  PATH_DASHBOARD,
  PATH_LOGIN,
  PATH_UPDATE_APPLICATION,
  PATH_VIEW_TEACHER_APPLICATION,
} from '@configs'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { Button } from 'src/common'
import { useGetProfileQuery } from '@api'
import { TResponseData } from '@interfaces'
import { authActions, RootState, useAppDispatch } from '@redux'

const WaittingScreenContent = memo(
  ({
    status,
    resonseData,
    onBack,
    onGoToApplication,
  }: {
    status: ETeacherRequestStatus
    resonseData?: TResponseData
    onBack: () => void
    onGoToApplication: () => void
  }) => (
    <>
      <Typography.Title
        level={3}
        className="capitalize text-center text-[28px]"
      >
        {[
          ETeacherRequestStatus.PENDING,
          ETeacherRequestStatus.UPDATED,
        ].includes(status)
          ? t('signUp:waiting_for_confirmation')
          : t('signUp:need_to_update_application')}
      </Typography.Title>
      <ContentWrapperStyled>
        {[
          ETeacherRequestStatus.PENDING,
          ETeacherRequestStatus.UPDATED,
        ].includes(status) ? (
          <Typography.Text>{t('signUp:pending_message')}</Typography.Text>
        ) : (
          <Typography.Paragraph className="text-center font-semiBold text-base italic">
            {t('signUp:please_update_your_application')}
          </Typography.Paragraph>
        )}
        {[ETeacherRequestStatus.REQUEST_UPDATE].includes(status) ? (
          <div>
            <Typography.Text className="mt-4 font-semibold !text-neutral-500 text-[18px]">
              {t('signUp:update_request_description')}
            </Typography.Text>
            <Typography.Paragraph className="mt-2 px-2 py-4 h-max max-h-[200px] overflow-y-auto bg-neutral-200 rounded-md break-words">
              {resonseData?.reason ?? ''}
            </Typography.Paragraph>
          </div>
        ) : null}
      </ContentWrapperStyled>
      <ButtonWrapper className="mt-12">
        <Button
          htmlType="button"
          type="default"
          size="small"
          id="blog-form"
          onClick={onBack}
        >
          {t('signUp:back_to_home')}
        </Button>
        <Button
          htmlType="button"
          type="primary"
          size="small"
          id="blog-form"
          onClick={onGoToApplication}
        >
          {[
            ETeacherRequestStatus.PENDING,
            ETeacherRequestStatus.UPDATED,
          ].includes(status)
            ? t('signUp:view_my_application')
            : t('signUp:update_my_application')}
        </Button>
      </ButtonWrapper>
    </>
  )
)

export const WaittingScreen = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state: RootState) => state.auth)

  const [status, setStatus] = useState(ETeacherRequestStatus.PENDING)

  const {
    data: userProfileData,
    error: userProfileError,
    isFetching: userProfileFetching,
  } = useGetProfileQuery()

  useEffect(() => {
    if (userProfileData?.data?.requests[0]?.status) {
      setStatus(userProfileData?.data?.requests[0]?.status)
    }
  }, [userProfileData])

  const onGoBack = () => {
    if (!accessToken) return
    dispatch(authActions.logout())
    navigate(PATH_LOGIN)
  }

  const onGoToApplication = () => {
    if (!accessToken) return
    if ([ETeacherRequestStatus.REQUEST_UPDATE].includes(status)) {
      navigate(PATH_UPDATE_APPLICATION)
      return
    }
    navigate(PATH_VIEW_TEACHER_APPLICATION)
  }

  if ([ETeacherRequestStatus.ACCEPTED].includes(status)) {
    return <Navigate to={PATH_DASHBOARD} />
  }

  if (
    [ETeacherRequestStatus.REJECTED].includes(status) ||
    Number(userProfileError?.code) === 401
  ) {
    onGoBack()
    return
  }

  return (
    <StyledAuthenContainer className="!bg-authen_bg_secondary">
      <Card className="min-w-[50dvw] max-w-[50dvw] !rounded-2xl mb-8">
        <img
          src={IMAGES.llcTeacherBlackLogo}
          alt=""
          className="logo max-w-[160px] mx-auto mb-4"
        />
        {userProfileFetching ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <WaittingScreenContent
            status={status}
            resonseData={userProfileData?.data?.requests[0]?.responseData}
            onBack={onGoBack}
            onGoToApplication={onGoToApplication}
          />
        )}
      </Card>
    </StyledAuthenContainer>
  )
}

const ContentWrapperStyled = styled.div`
  width: 100%;
`
