import React from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'
import { Control, Controller } from 'react-hook-form'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'

interface ICardInputProps {
  onClose?: () => void
  onSubmit?: (cardInformation: any) => Promise<void>
  control?: Control<any>
}

export const CardInput = (props: ICardInputProps) => {
  const { control } = props

  const [errorMessage, setErrorMessage] = React.useState<{
    cardNumberError?: string
    cardExpiryError?: string
    cardCvcError?: string
  }>({
    cardNumberError: '',
    cardExpiryError: '',
    cardCvcError: '',
  })

  React.useEffect(() => {
    return () => {
      setErrorMessage({
        cardNumberError: '',
        cardExpiryError: '',
        cardCvcError: '',
      })
    }
  }, [])

  return (
    <div>
      <CardContainer className="gap-6">
        <InputWrapper>
          <div className="pb-3 border-b border-solid border-brand-neutral-100">
            <Controller
              name="cardNumber"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <CardNumberElement
                    onChange={(e) => {
                      setErrorMessage((prev) => ({
                        ...prev,
                        cardNumberError: e?.error?.message ?? '',
                      }))
                      onChange(!!e?.complete)
                    }}
                    options={{
                      style: {
                        base: {
                          padding: '0 0 12px 0',
                          fontSize: '18px',
                          fontWeight: '500',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                )
              }}
            />
          </div>
          {errorMessage.cardNumberError && (
            <Typography.Text type="danger">
              {errorMessage.cardNumberError}
            </Typography.Text>
          )}
        </InputWrapper>

        <InputWrapper>
          <div className="flex flex-col gap-2 pb-3 border-b border-solid border-brand-neutral-100">
            <Controller
              name="exp"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <CardExpiryElement
                    onChange={(e) => {
                      setErrorMessage((prev) => ({
                        ...prev,
                        cardExpiryError: e?.error?.message ?? '',
                      }))
                      onChange(!!e?.complete)
                    }}
                    options={{
                      style: {
                        base: {
                          padding: '0 0 12px 0',
                          fontSize: '18px',
                          fontWeight: '500',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                )
              }}
            />
          </div>
          {errorMessage.cardExpiryError && (
            <Typography.Text type="danger">
              {errorMessage.cardExpiryError}
            </Typography.Text>
          )}
        </InputWrapper>

        <InputWrapper>
          <div className="flex flex-col gap-2 pb-3 border-b border-solid border-brand-neutral-100">
            <Controller
              name="cvc"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <CardCvcElement
                    onChange={(e) => {
                      setErrorMessage((prev) => ({
                        ...prev,
                        cardCvcError: e?.error?.message ?? '',
                      }))
                      onChange(!!e?.complete)
                    }}
                    options={{
                      style: {
                        base: {
                          padding: '0 0 12px 0',
                          fontSize: '18px',
                          fontWeight: '500',
                          fontSmoothing: 'antialiased',
                        },
                      },
                    }}
                  />
                )
              }}
            />
          </div>
          {errorMessage.cardCvcError && (
            <Typography.Text type="danger">
              {errorMessage.cardCvcError}
            </Typography.Text>
          )}
        </InputWrapper>
      </CardContainer>
    </div>
  )
}

const CardContainer = styled.div`
  display: flex;

  & > span:nth-child(1) {
    flex: 1;

    & .InputElement {
      font-size: 16px;
    }
  }

  & > span:nth-child(2) {
    width: 76px;

    & .InputElement {
      font-size: 16px;
    }
  }

  & > span:nth-child(3) {
    width: 76px;

    & .InputElement {
      font-size: 16px;
    }
  }
`
const InputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
