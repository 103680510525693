import { memo, useMemo } from 'react'
import { t } from 'i18next'
import { message, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Control, Controller, useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'

import { AppModal, WarningCard } from '@components'
import { themes } from '@theme'
import { Button, Input } from 'src/common'
import { withdrawVerificationOtp } from './config'
import { ClientStatusCode, OTP_TIMER } from '@configs'
import { formatDate, handleAPIError } from '@utils'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import useCountdown from 'src/hooks/useCountdown'
import { useWithdrawContext } from 'src/pages/withdrawal/provider'
import { useVerifyWithdrawMutation } from '@api'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

interface IProps {
  onClose: () => void
  resendOtp: () => void
  open: boolean
  isSendingOtp?: boolean
  onSuccess?: () => void
  onFailed?: () => void
}

interface IWithdrawInfo {
  lastFourNumber: string
  bankName: string
  amount: number
  fees: number
  actualAmount: number
}

const WithdrawVerifyInfo = ({
  lastFourNumber,
  bankName,
  amount,
  fees,
  actualAmount,
}: IWithdrawInfo) => {
  const listContents = [
    {
      title: t('revenue:bank_account'),
      content: `***********${lastFourNumber}`,
      color: themes.theme.light.colors.lightBrown,
    },
    {
      title: t('revenue:bank'),
      content: bankName ?? '',
      color: themes.theme.light.colors.lightBlueSecondary,
    },
    {
      title: t('revenue:amount'),
      content: `${formatCurrencyByType(amount ?? 0, 'EUR')}`,
      color: themes.theme.light.colors.darkWarning,
    },
    {
      title: (
        <span className="flex items-center gap-2">
          {t('revenue:fees')}{' '}
          <Tooltip
            title={t('revenue:stripe_fees_formula', {
              fixed_fee: process.env.REACT_APP_PUBLIC_STRIPE_FIXED_FEE,
              percentage_fee:
                process.env.REACT_APP_PUBLIC_STRIPE_PERCENTAGE_FEE,
            })}
          >
            <InfoCircleOutlined className="!text-black !text-[14px]" />
          </Tooltip>
        </span>
      ),
      content: `${formatCurrencyByType(fees ?? 0, 'EUR')}`,
      color: themes.theme.light.colors.darkWarning,
    },
    {
      title: t('revenue:actual_amount_received'),
      content: `${formatCurrencyByType(actualAmount ?? 0, 'EUR')}`,
      color: themes.theme.light.colors.darkWarning,
    },
  ]

  return (
    <WithdrawVerifyInfoStyled>
      {listContents?.map((item, index) => (
        <WithdrawContentWrapper key={index}>
          <Typography.Text className="!text-[14px] !text-neutral-400">
            {item?.title}
          </Typography.Text>
          <ContentWrapper $textColor={item?.color}>
            {item?.content}
          </ContentWrapper>
        </WithdrawContentWrapper>
      ))}
    </WithdrawVerifyInfoStyled>
  )
}

const WithdrawVerifyForm = ({
  name,
  control,
  isSendingOtp,
  isVerifying,
  onSubmit,
  onResendOtp,
}: {
  name: string
  control: Control<any>
  isSendingOtp?: boolean
  isVerifying?: boolean
  onSubmit: () => void
  onResendOtp: () => void
}) => {
  const { countdown, resetCountdown } = useCountdown(OTP_TIMER)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResendCode = async () => {
    if (countdown > 0) return
    resetCountdown()
    onResendOtp()
  }

  const countdownRendering = useMemo(() => {
    if (isSendingOtp) {
      return (
        <Typography.Text className="!text-[12px] !text-blue-300" disabled>
          {t('common:sending_otp')}
        </Typography.Text>
      )
    }

    return (
      <Typography.Text
        className={`!text-[12px] cursor-pointer ${
          countdown > 0 ? '!text-blue-300' : '!text-blue-500'
        }`}
        onClick={handleResendCode}
        disabled={countdown > 0}
      >
        {countdown > 0
          ? formatDate(moment.utc(countdown * 1000), 'mm:ss')
          : t('common:send')}
      </Typography.Text>
    )
  }, [countdown, handleResendCode, isSendingOtp])

  return (
    <OTPFormWrapper>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={t('revenue:email_verification')}
              name={name}
              onChange={onChange}
              value={value ?? ''}
              errors={error?.message}
              alignment="col"
              suffix={countdownRendering}
              type="number"
              min={0}
              required
            />
          )
        }}
      />
      <ButtonWrapper className="!mt-3">
        <Button
          htmlType="submit"
          type="primary"
          className="!bg-black !text-[12px] !rounded-full !text-white !border-none"
          onClick={onSubmit}
          loading={isVerifying}
        >
          {t('revenue:confirm')}
        </Button>
      </ButtonWrapper>
    </OTPFormWrapper>
  )
}

export const WithdrawVerifyModal = memo((props: IProps) => {
  const { onClose, open, isSendingOtp, resendOtp, onSuccess, onFailed } = {
    ...props,
  }

  const { genericWithdrawalInfo, setWithdrawInvoice } = useWithdrawContext()

  const { handleSubmit, control } = useForm<{
    otp: string
  }>({
    defaultValues: { otp: '' },
    resolver: zodResolver(withdrawVerificationOtp),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { mutate: verifyWithdraw, isPending: verifyingWithdraw } =
    useVerifyWithdrawMutation({
      handleSuccess(value) {
        message.success(value?.message ?? '')
        setWithdrawInvoice?.((prev) => ({
          ...prev,
          ...value?.invoice,
        }))
        onSuccess?.()
      },
      handleError(err) {
        handleAPIError(err)
        if (err?.code === ClientStatusCode.NOT_ACCEPTABLE) {
          onFailed?.()
        }
      },
    })

  const handleVerify = handleSubmit(async (value) => {
    await verifyWithdraw({
      hash: genericWithdrawalInfo?.hash ?? '',
      otpCode: value.otp,
    })
  })

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <Typography.Title level={4} className="!text-[16px]">
          {t('revenue:withdrawal_verification')}
        </Typography.Title>
      }
      modalClassName="!z-[110]"
      contentClassName="!w-[430px] !px-[24px]"
      childrenClassName="!mt-0"
    >
      <WarningCard
        className="!text-[12px]"
        content={t('revenue:ensure_information')}
        icon={<InfoCircleOutlined className="!text-[14px]" />}
      />
      <WithdrawVerifyInfo
        lastFourNumber={genericWithdrawalInfo?.lastFourBankNumber ?? ''}
        bankName={genericWithdrawalInfo?.bankName ?? ''}
        amount={genericWithdrawalInfo?.amount ?? 0}
        fees={genericWithdrawalInfo?.fees ?? 0}
        actualAmount={genericWithdrawalInfo?.actualAmount ?? 0}
      />
      <WithdrawVerifyForm
        control={control}
        name="otp"
        onSubmit={handleVerify}
        isSendingOtp={isSendingOtp}
        onResendOtp={resendOtp}
        isVerifying={verifyingWithdraw}
      />
    </AppModal>
  )
})

const WithdrawVerifyInfoStyled = styled.div`
  width: 100%;
  margin-top: 10px;
`

const WithdrawContentWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const ContentWrapper = styled.span<{ $textColor?: string }>`
  font-size: 14px !important;
  ${({ $textColor }) =>
    !!$textColor &&
    css`
      color: ${$textColor} !important;
    `}
`
const OTPFormWrapper = styled.form`
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
