import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_PACKAGE_SUBSCRIPTION } from '@configs'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetPackageSubscriptionSuccessData,
} from '@interfaces'
import { packageManagementAPI } from 'src/api/package-management'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetPackageSubscriptionSuccessData>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
}

export const useGetSubscriptionQuery = (queryProps?: IQueryProps) => {
  const { configs } = queryProps || {}

  const queryClient = useQueryClient()

  function invalidateGetPackageSubscription() {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_PACKAGE_SUBSCRIPTION],
      type: 'all',
    })
  }

  function refetchGetPackageSubscription() {
    queryClient.refetchQueries({
      queryKey: [QK_GET_PACKAGE_SUBSCRIPTION],
      type: 'all',
    })
  }

  async function getPackageSubscription() {
    return packageManagementAPI.getListPackageSubscription()
  }

  const { data: packageSubscriptionData, ...restData } = useQuery<
    BaseResponseProps<IGetPackageSubscriptionSuccessData>,
    BaseResponseError
  >({
    queryKey: [QK_GET_PACKAGE_SUBSCRIPTION],
    queryFn: getPackageSubscription,
    ...configs,
  })

  return {
    data: packageSubscriptionData,
    invalidateGetPackageSubscription,
    refetchGetPackageSubscription,
    ...restData,
  }
}
