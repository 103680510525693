import { Input, DatePicker } from 'antd'
import {
  Control,
  Controller,
  FieldValues,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form'
import {
  GRADING_STATUS_OPTIONS,
  SEARCH_FORM_FIELDS,
} from 'src/pages/test-management/config'
import { ShareSelectInput } from '@components'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/common'

const { RangePicker } = DatePicker

interface ITestTableToolbarProps {
  control: Control<FieldValues, any>
  reset: UseFormReset<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

export default function TestTableToolbar({
  control,
  reset,
  setValue,
}: ITestTableToolbarProps) {
  const { t } = useTranslation(['common', 'testManagement'])

  return (
    <div className="flex flex-wrap item-centers justify-between gap-4">
      <Controller
        name={SEARCH_FORM_FIELDS.SEARCH}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Input
              prefix={<SearchOutlined className="pr-2 pl-0" />}
              placeholder={t('testManagement:searchTextPlaceholder')}
              name={SEARCH_FORM_FIELDS.SEARCH}
              onChange={onChange}
              value={value}
              style={{
                maxWidth: 380,
                width: '100%',
                borderRadius: 6,
                borderColor: '#e2e8f0',
              }}
            />
          )
        }}
      />

      <div className="flex item-centers justify-between gap-4">
        <Controller
          name={SEARCH_FORM_FIELDS.DATE_RANGE}
          control={control}
          render={({ field: { value } }) => {
            return (
              <RangePicker
                value={value}
                format={'DD-MM-YYYY'}
                allowEmpty={[true, true]}
                onChange={(values) => {
                  setValue(SEARCH_FORM_FIELDS.DATE_RANGE, values)
                }}
                style={{
                  minWidth: 230,
                  borderRadius: 6,
                  borderColor: '#e2e8f0',
                }}
              />
            )
          }}
        />

        <Controller
          name={SEARCH_FORM_FIELDS.GRADING_STATUS}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                data={
                  GRADING_STATUS_OPTIONS as { label: string; value: string }[]
                }
                onChange={(value: string) => {
                  onChange(value)
                }}
                errors={error?.message}
                value={value}
                style={{ minWidth: 200, marginRight: 16 }}
                placeholder={t('testManagement:gradingStatus')}
                mode="multiple"
                showArrow
              />
            )
          }}
        />

        <Button
          type="primary"
          onClick={() => {
            reset()
          }}
          danger
          style={{
            backgroundColor: '#ff0000',
            border: 'none',
          }}
        >
          {t('common:clear')}
        </Button>
      </div>
    </div>
  )
}
