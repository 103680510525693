// auth
export const QK_LOGIN = 'QK_LOGIN'
export const QK_SIGN_UP = 'QK_SIGN_UP'
export const QK_SIGN_UP_DETAILS = 'QK_SIGN_UP_DETAILS'
export const QK_UPDATE_DETAILS = 'QK_UPDATE_DETAILS'
export const QK_VERIFY_EMAIL = 'QK_VERIFY_EMAIL'
export const QK_VERIFY_OTP = 'QK_VERIFY_OTP'
export const QK_RESEND_OTP = 'QK_RESEND_OTP'
export const QK_CHANGE_PASSWORD = 'QK_CHANGE_PASSWORD'

// profile
export const QK_GET_USER_APPLICATION = 'QK_GET_USER_APPLICATION'
export const QK_GET_USER_PROFILE = 'QK_GET_USER_PROFILE'

// package
export const QK_GET_PACKAGE_SUBSCRIPTION = 'QK_GET_PACKAGE_SUBSCRIPTION'
export const QK_GET_TEACHER_COURSE_MINI_DASHBOARD =
  'QK_GET_TEACHER_COURSE_MINI_DASHBOARD'
export const QK_SUBSCRIBE_PACKAGE = 'QK_SUBSCRIBE_PACKAGE'
export const QK_CONFIRM_PAYMENT_INTENT = 'QK_CONFIRM_PAYMENT_INTENT'
export const QK_CHECKOUT_PACKAGE = 'QK_CHECKOUT_PACKAGE'
export const QK_PAYMENT_CARDS = 'QK_PAYMENT_CARDS'
export const QK_PACKAGE_RENEW_STATUS = 'QK_PACKAGE_RENEW_STATUS'
export const QK_SAVE_PAYMENT_CARD_INFO = 'QK_SAVE_PAYMENT_CARD_INFO'

// course
export const QK_PUBLISH_TEACHER_COURSE = 'QK_PUBLISH_TEACHER_COURSE'
export const QK_INACTIVE_TEACHER_COURSE = 'QK_INACTIVE_TEACHER_COURSE'

// teacher dashboard
export const QK_GET_DASHBOARD_OVERVIEW = 'QK_GET_DASHBOARD_OVERVIEW'
export const QK_GET_DASHBOARD_CHANNELS = 'QK_GET_DASHBOARD_CHANNELS'
export const QK_GET_DASHBOARD_COURSE_TABLE = 'QK_GET_DASHBOARD_COURSE_TABLE'
export const QK_GET_DASHBOARD_LIST_COURSE_ORDER =
  'QK_GET_DASHBOARD_LIST_COURSE_ORDER'
export const QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER =
  'QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER'
export const QK_GET_DASHBOARD_ORDER_INCOME_DETAILS =
  'QK_GET_DASHBOARD_ORDER_INCOME_DETAILS'

// revenue
export const QK_GET_LIST_INVOICES = 'QK_GET_LIST_INVOICES'
export const QK_GET_INVOICE_DETAILS = 'QK_GET_INVOICE_DETAILS'
export const QK_CREATE_ACCOUNT_ONBOARD_LINK = 'QK_CREATE_ACCOUNT_ONBOARD_LINK'
export const QK_GENERATE_REVENUE = 'QK_GENERATE_REVENUE'
export const QK_WITHDRAW_INFORMATION = 'QK_WITHDRAW_INFORMATION'
export const QK_WITHDRAW = 'QK_WITHDRAW'
export const QK_VERIFY_WITHDRAW = 'QK_VERIFY_WITHDRAW'
export const QK_GET_HISTORY_LOG = 'QK_GET_HISTORY_LOG'
export const QK_REDO_TRANSACTION = 'QK_REDO_TRANSACTION'
export const QK_REGENERATE_INVOICE_BY_MONTH = 'QK_REGENERATE_INVOICE_BY_MONTH'

// notification
export const QK_GET_LIST_NOTIFICATIONS = 'QK_GET_LIST_NOTIFICATIONS'
export const QK_GET_NOTIFICATION_UNREAD_NUMBER =
  'QK_GET_NOTIFICATION_UNREAD_NUMBER'
export const QK_MARK_NOTIFICATION_AS_READ_BY_ID =
  'MARK_NOTIFICATION_AS_READ_BY_ID'
export const QK_MARK_ALL_NOTIFICATION_AS_READ = 'MARK_ALL_NOTIFICATION_AS_READ'
