import { useEffect } from 'react'
import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_DASHBOARD_CHANNELS } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetDashboardChannels,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardChannel {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetDashboardChannels>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
  year?: number | string
}

export const useGetDashboardChannelQuery = ({
  configs,
  year,
}: IGetDashboardChannel) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_CHANNELS],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_CHANNELS],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardChannel() {
    return dashboardAPI.getDashboardChannels(year)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetDashboardChannels>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_CHANNELS],
    queryFn: getDashboardChannel,

    ...configs,
  })

  useEffect(() => {
    refetchData()
  }, [year])

  return {
    data: data?.data ?? ({} as IGetDashboardChannels),
    invalidateData,
    refetchData,
    ...restData,
  }
}
