import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  enumTableTitleTeacher,
  ETeacherRole,
  ETeacherRoleTitle,
  FORMAT_DATE_TABLE,
  PASSWORD_LENGTH_MAX,
  PASSWORD_LENGTH_MIN,
  REGEX_EMAIL,
  REGEX_FULLNAME,
  REGEX_PASSWORD,
  REGEX_PHONENUMBER,
} from '@configs'
import { formatDate, getKeyEnumFromValue } from '@utils'
import { z } from 'zod'

export enum ETeacherStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL = 'all',
}

export enum ETeacherStatusLabel {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ALL = 'All status',
}

export const TEACHER_STATUS_FILTER_OPTIONS = [
  {
    label: ETeacherStatusLabel.ALL,
    value: ETeacherStatus.ALL,
  },
  {
    label: ETeacherStatusLabel.ACTIVE,
    value: ETeacherStatus.ACTIVE,
  },
  {
    label: ETeacherStatusLabel.INACTIVE,
    value: ETeacherStatus.INACTIVE,
  },
]

export const TEACHER_TYPE_OPTIONS = [
  {
    label: ETeacherRoleTitle.LLC,
    value: ETeacherRole.LLC,
  },
  {
    label: ETeacherRoleTitle.AUTHORIZED,
    value: ETeacherRole.AUTHORIZED,
  },
  {
    label: ETeacherRoleTitle.OTHER,
    value: ETeacherRole.OTHER,
  },
]

export enum ETeacherColumnAction {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const TEACHER_COLUMN = [
  {
    width: '5%',
    title: enumTableTitleTeacher.NO,
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '20%',
    key: 'id',
    dataIndex: 'name',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-start w-full">
          <p className="m-0">{enumTableTitleTeacher.TEACHER_NAME}</p>
        </div>
      )
    },
    render: (e: string) => {
      return (
        <div className="flex items-center max-w-[460px]">
          <Typography.Text
            style={{
              width: 400,
              whiteSpace: 'nowrap',
            }}
            ellipsis={true}
          >
            {e}
          </Typography.Text>
        </div>
      )
    },
  },
  {
    width: '10%',
    key: 'client',
    dataIndex: 'client',
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTitleTeacher.TEACHER_TYPE}</p>
        </div>
      )
    },
    ellipsis: true,
    render: (e: string) => {
      const roleKey = getKeyEnumFromValue(
        ETeacherRole,
        e
      ) as keyof typeof ETeacherRoleTitle
      return (
        <div
          className="flex items-center justify-center capitalize"
          style={{ backgroundColor: '#F3F4F6', borderRadius: '10px' }}
        >
          {ETeacherRoleTitle[roleKey]}
        </div>
      )
    },
  },
  {
    width: '10%',
    dataIndex: 'numberOfCourses',
    key: 'numberOfCourses',
    align: 'center',
    title: () => {
      return (
        <span className="flex flex-wrap items-center justify-center max-w-[100px] ">
          {enumTableTitleTeacher.NUMBER_OF_COURSE}
        </span>
      )
    },
  },
  {
    width: '10%',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTitleTeacher.CREATE_DATE}</p>
        </div>
      )
    },
    render: (e: string) => {
      return (
        <div className="flex items-center justify-center">
          {formatDate(e, FORMAT_DATE_TABLE)}
        </div>
      )
    },
  },
  {
    width: '10%',
    dataIndex: 'isActive',
    key: 'isActive',
    ellipsis: true,
    title: () => {
      return (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTitleTeacher.STATUS}</p>
        </div>
      )
    },
    render: (e: boolean) => (
      <StatusText $isActive={e}>{e ? 'Active' : 'Inactive'}</StatusText>
    ),
  },
  {
    width: '5%',
    key: ETeacherColumnAction.EDIT,
    dataIndex: 'action',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '5%',
    key: ETeacherColumnAction.DELETE,
    dataIndex: 'action',
    ellipsis: true,
    align: 'center',
  },
]

const StatusText = styled.span<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${(props) => (props.$isActive ? '#19ba8c' : '#DE3B40')};
`

export const baseTeacherShema = z.object({
  type: z.string().trim(),
  name: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(2, {
      message: t('teacher:min_name_2') as string,
    })
    .max(50, {
      message: t('teacher:max_name_50') as string,
    })
    .regex(REGEX_FULLNAME, {
      message: t('error:full_name_invalid') as string,
    }),
  email: z
    .string()
    .trim()
    .email({
      message: t('error:email_format_error') as string,
    })
    .regex(REGEX_EMAIL, {
      message: t('error:email_format_error') as string,
    })
    .max(50, {
      message: t('error:email_length_error') as string,
    })
    .nullable(),
  description: z
    .string()
    .trim()
    .max(500, {
      message: t('teacher:max_bio_500') as string,
    })
    .nullable()
    .optional(),
  isActive: z.boolean({
    required_error: t('teacher:field_required') as string,
  }),
  phone: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(8, { message: t('error:phone_length_error') })
    .max(13, { message: t('error:phone_length_error') })
    .regex(REGEX_PHONENUMBER, {
      message: t('error:phone_format_error') as string,
    }),
  createdAt: z.string().trim().nullable(),
  lastActiveTime: z.string().trim().nullable(),
  profilePhotoId: z.number().nullable().optional(),
})

export const TeacherOverviewSchema = baseTeacherShema.refine(
  (data) => {
    if (
      data.type === ETeacherRole.LLC &&
      (!data.email || data.email.length === 0)
    ) {
      return false
    }
    return true
  },
  {
    message: t('error:field_required') as string,
    path: ['email'],
  }
)

export const CreateTeacherAuthorizedSchema = baseTeacherShema.omit({
  type: true,
  isActive: true,
  createdAt: true,
  lastActiveTime: true,
  email: true,
})

export const CreateTeacherLLCSchema = baseTeacherShema
  .omit({
    type: true,
    isActive: true,
    createdAt: true,
    lastActiveTime: true,
    description: true,
  })
  .extend({
    password: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .min(PASSWORD_LENGTH_MIN, {
        message: t('error:password_min_error') as string,
      })
      .max(PASSWORD_LENGTH_MAX, {
        message: t('error:password_max_error') as string,
      })
      .regex(REGEX_PASSWORD, {
        message: t('error:password_format_error') as string,
      }),
  })
