import { useEffect, useRef, useState } from "react"

const useCountdown = (initialTime: number) => {
  const [countdown, setCountdown] = useState(initialTime)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (countdown > 0) {
      timerRef.current = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
    } else {
      clearInterval(timerRef.current as NodeJS.Timeout)
    }

    return () => {
      clearInterval(timerRef.current as NodeJS.Timeout)
    }
  }, [countdown])

  const resetCountdown = () => {
    clearInterval(timerRef.current as NodeJS.Timeout)
    setCountdown(initialTime)
  }

  return { countdown, resetCountdown }
}

export default useCountdown
