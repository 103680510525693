import { t } from 'i18next'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseProps,
  ICommonDataResponse,
  IResendCode,
  IVerifyForgotPassword,
} from '@interfaces'
import { authAPI } from 'src/api/auth'
import { authActions, useAppDispatch } from '@redux'
import { handleAPIError } from '@utils'
import {
  QK_RESEND_OTP,
  QK_VERIFY_OTP,
} from 'src/configs/queryClient/queryKeyConstants'

type IMutationVerifyOTPOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<ICommonDataResponse>,
    any,
    IVerifyForgotPassword,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationResendOTPOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<ICommonDataResponse>,
    any,
    IResendCode,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationVerifyOTPParams = {
  configs?: IMutationVerifyOTPOptions
  handleSuccess?: (res: BaseResponseProps<ICommonDataResponse>) => void
}

type IMutationResendOTPParams = {
  configs?: IMutationResendOTPOptions
  handleSuccess?: () => void
}

export const verifySignUpFn = (values: IVerifyForgotPassword) =>
  authAPI.verifyTeacherSignUp(values)

export const resendOtpFn = (value: IResendCode) => authAPI.resendCode(value)

export const useMutationVerifyOtpQuery = ({
  configs,
  handleSuccess,
}: IMutationVerifyOTPParams) => {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: [QK_VERIFY_OTP],
    mutationFn: verifySignUpFn,
    onSuccess: (res: BaseResponseProps<ICommonDataResponse>) => {
      message.success(t('login:verify_otp_successfully'))
      if (res?.data) {
        dispatch(
          authActions.setHashState({
            data: '',
            email: '',
          })
        )
        handleSuccess?.(res)
      }
    },
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}

export const useMutationResendOtpQuery = ({
  configs,
  handleSuccess,
}: IMutationResendOTPParams) => {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: [QK_RESEND_OTP],
    mutationFn: resendOtpFn,
    onSuccess: (res) => {
      message.success(t('signUp:resend_otp_code_success'))
      if (res?.data) {
        dispatch(
          authActions.setHashState({
            data: res?.data?.data,
            email: '',
          })
        )

        handleSuccess?.()
      }
    },
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}
