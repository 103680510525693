import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_LIST_INVOICES } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetInvoiceListResponse,
  IGetInvoiceListParams,
} from '@interfaces'
import { revenueAPI } from 'src/api/revenue'
import { DEFAULT_GET_INVOICES_PARAMS } from '@configs'


interface IGetInvoiceList {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetInvoiceListResponse>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
  queryParams?: IGetInvoiceListParams
}

export const useGetTeacherInvoiceListQuery = ({
  configs,
  queryParams = {
    page: DEFAULT_GET_INVOICES_PARAMS.PAGE,
    limit: DEFAULT_GET_INVOICES_PARAMS.LIMIT,
  },
}: IGetInvoiceList) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_LIST_INVOICES],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_LIST_INVOICES],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getListTeacherInvoices() {
    return revenueAPI.getListInvoice(queryParams)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetInvoiceListResponse>,
    BaseResponseError
  >({
    queryKey: [QK_GET_LIST_INVOICES],
    queryFn: getListTeacherInvoices,

    ...configs,
  })

  useDeepCompareEffect(() => {
    refetchData()
  }, [queryParams])

  return {
    data: data?.data ?? ({} as IGetInvoiceListResponse),
    invalidateData,
    refetchData,
    ...restData,
  }
}
