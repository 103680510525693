import { useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import { Typography } from 'antd'
import { useForm } from 'react-hook-form'

import {
  TABLE_TOOLBAR_DEFAULT_VALUES,
  TABLE_TOOLBAR_RHF_FIELDS,
  COURSE_ORDER_TABLE_COLUMNS,
} from './config'
import { Button, SharedTable } from 'src/common'
import { IGetDashboardListCourseTableParams } from '@interfaces'
import { CourseOrderTableToolbar } from './CourseOrderTableToolbar'
import { EIncomeType } from '@configs'
import { useGetDashboardCourseOrderTableQuery } from '@api'
import { useDebounce } from 'src/hooks/useDebounce'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'
import { useDashboardContext } from 'src/pages/dashboard/provider'
import { OrderIncomeModal } from 'src/pages/dashboard/list-course/order-income-modal'
import { themes } from '@theme'

export const CourseOrderTable = ({
  courseId,
}: {
  courseId?: number | string
}) => {
  const { filter, setTemporaryCoursesInfor } = useDashboardContext()
  const [params, setParams] = useState<IGetDashboardListCourseTableParams>({
    page: 1,
    limit: 10,
  })
  const [selectedItemId, setSelectedItemId] = useState<string | number | null>(
    null
  )

  const { control, reset, setValue, watch } = useForm({
    defaultValues: TABLE_TOOLBAR_DEFAULT_VALUES,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const watchType = watch(TABLE_TOOLBAR_RHF_FIELDS.TYPE)
  const watchSearch = watch(TABLE_TOOLBAR_RHF_FIELDS.SEARCH)

  const searchDebounceValue = useDebounce(String(watchSearch).trim(), 500)

  const { data: courseOrderTable, isFetching: courseOrderTableLoading } =
    useGetDashboardCourseOrderTableQuery({
      configs: {
        enabled: !!courseId,
      },
      queryParams: {
        params: {
          ...params,
          filterBy: filter?.filterBy,
          ...(!!filter?.startDate &&
            [EOverviewDateFilter.CUSTOM].includes(
              filter?.filterBy as EOverviewDateFilter
            ) && {
              startDate: filter.startDate,
            }),
          ...(!!filter?.endDate &&
            [EOverviewDateFilter.CUSTOM].includes(
              filter?.filterBy as EOverviewDateFilter
            ) && {
              endDate: filter.endDate,
            }),
          ...(!!searchDebounceValue && { search: searchDebounceValue }),
          ...(!!watchType &&
            watchType !== EIncomeType.ALL && {
              incomeType: watchType as EIncomeType,
            }),
        },
        courseId: courseId ?? '',
      },
    })

  const tableDataFormatted = useMemo(() => {
    if (!courseOrderTable) return []

    return courseOrderTable?.items?.map((item: any) => ({
      ...item,
      studentName: item?.userInformation?.name,
      orderDate: item?.createdAt,
    }))
  }, [courseOrderTable])

  const columns = useMemo(() => {
    return COURSE_ORDER_TABLE_COLUMNS.map((column) => ({
      ...column,
      ...(column.key === 'action' && {
        render: (_: any, record: { id: string | number }) => (
          <Button
            type="primary"
            style={{
              width: '100%',
              borderRadius: '32px',
              maxWidth: 100,
              background: `${themes.theme.dark.colors.primaryPurple}`,
              color: 'white',
            }}
            onClick={() => {
              setSelectedItemId(record?.id ?? null)
            }}
          >
            {t('dashboard:detail')}
          </Button>
        ),
      }),
    }))
  }, [])

  useEffect(() => {
    if (!courseOrderTable || !courseId) return

    setTemporaryCoursesInfor((prev) => ({
      ...prev,
      [courseId]: {
        totalOrder: courseOrderTable?.total,
        totalAmount: courseOrderTable?.totalAmount,
      },
    }))
  }, [courseId, courseOrderTable, setTemporaryCoursesInfor])

  return (
    <>
      <CourseOrderTableStyled>
        <CourseOrderTableToolbar
          control={control}
          reset={reset}
          setValue={setValue}
        />
        <SharedTable
          columns={columns}
          dataSource={tableDataFormatted}
          paginationProps={{
            total: courseOrderTable?.total ?? 0,
            pageSize: courseOrderTable?.limit ?? 0,
            current: courseOrderTable?.page ?? 1,
            onChange(page) {
              setParams((prev) => ({
                ...prev,
                page: page,
              }))
            },
          }}
          loading={{
            spinning: !courseOrderTable || courseOrderTableLoading,
            size: 'large',
          }}
          {...(!!tableDataFormatted?.length && {
            scroll: { x: 1280 },
          })}
          sticky
        />
      </CourseOrderTableStyled>
      {!!selectedItemId ? (
        <OrderIncomeModal
          open
          onClose={() => setSelectedItemId(null)}
          orderIncomeId={selectedItemId ?? ''}
        />
      ) : null}
    </>
  )
}

const CourseOrderTableStyled = styled.div`
  margin-top: 14px;
  animation-name: FadeIn;
  animation-duration: 0.5s;
  transition-timing-function: linear;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .ant-table-wrapper {
    margin: 0 !important;
    margin-top: 16px !important;
  }

  .ant-table-content {
    margin-right: 0 !important;
  }

  // sticky header
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
`
