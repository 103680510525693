import { AppModal } from '@components'
import { OrderTable } from './OrderTable'
import { useGetDashboardOrderIncomeDetails } from '@api'

interface IProps {
  open: boolean
  onClose: () => void
  orderIncomeId?: string | number
}

export const OrderIncomeModal = ({ open, onClose, orderIncomeId }: IProps) => {
  const { data: orderIncomeDetails, isFetching: isFetchingDetails } =
    useGetDashboardOrderIncomeDetails({
      configs: {
        enabled: !!orderIncomeId,
      },
      orderIncomeId: orderIncomeId ?? '',
    })

  return (
    <AppModal open={open} onClose={onClose} contentClassName="!max-w-[85dvw]">
      <OrderTable
        orderDetails={[orderIncomeDetails]}
        isLoading={isFetchingDetails}
      />
    </AppModal>
  )
}
