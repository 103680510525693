import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from 'src/common'
import stripeConnectIcon from '../assets/Stripe_connect_icon.png'

export const EmptyStripe = ({
  onStripeConnect,
  isLoading,
}: {
  onStripeConnect: () => void
  isLoading?: boolean
}) => {
  return (
    <EmptyStripeStyled>
      <span className="!text-center">
        <Typography.Title level={4} className="!m-0 !text-[24px]">
          {t('revenue:withdraw_empty_question')}
        </Typography.Title>
        <Typography.Text className="!m-0 !font-light !text-[18px]">
          {t('revenue:withdraw_empty_subtitle')}
        </Typography.Text>
      </span>
      <img src={stripeConnectIcon} alt="empty" className="w-[240px]" />
      <Button
        type="default"
        className="!bg-white !border-primaryPurple !text-primaryPurple !w-[400px]"
        onClick={onStripeConnect}
        loading={isLoading}
      >
        {t('revenue:connect_with_stripe')}
      </Button>
    </EmptyStripeStyled>
  )
}

const EmptyStripeStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 16px;
`
