import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { QK_GET_DASHBOARD_COURSE_TABLE } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetDashboardListCourseTableParams,
  IGetListCourseOrderTable,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardCourseTable {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetListCourseOrderTable>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
  queryParams: {
    params?: IGetDashboardListCourseTableParams
    courseId: number | string
  }
}

export const useGetDashboardCourseOrderTableQuery = ({
  configs,
  queryParams,
}: IGetDashboardCourseTable) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_COURSE_TABLE, queryParams.courseId],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_COURSE_TABLE, queryParams.courseId],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardCourseTable() {
    return dashboardAPI.getDashboardCourseTable(queryParams)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetListCourseOrderTable>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_COURSE_TABLE, queryParams.courseId],
    queryFn: getDashboardCourseTable,

    ...configs,
  })

  useDeepCompareEffect(() => {
    refetchData()
  }, [queryParams])

  return {
    data: data?.data ?? ({} as IGetListCourseOrderTable),
    invalidateData,
    refetchData,
    ...restData,
  }
}
