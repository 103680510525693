import { yupResolver } from '@hookform/resolvers/yup'
import { Card, Typography, message } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { LoginForm } from '@components'
import { PATH_FORGOT_PASSWORD, PATH_SIGN_UP } from '@configs'
import { ILoginFields } from '@interfaces'
import { useMutationLoginQuery } from '@api'
import { loginSchema } from 'src/pages/auth/login/config'
import { IMAGES } from '@assets'
import { StyledAuthenContainer } from 'src/components/auth/loginStyle'

export const LoginPage = () => {
  const navigate = useNavigate()

  const [isRemember, setIsRemember] = useState<boolean>(false)

  const { control, handleSubmit, reset, setValue } = useForm<ILoginFields>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const handleRedirectToForgot = () => {
    navigate(PATH_FORGOT_PASSWORD)
  }

  const handleRedirectToSignUp = () => {
    navigate(PATH_SIGN_UP)
  }
  const { mutate: loginMutate, isPending: isLoginLoading } =
    useMutationLoginQuery()

  const handleLogin = handleSubmit(async (data) => {
    try {
      loginMutate(data)
    } catch (err) {
      setValue('password', '')
    }
  })

  return (
    <StyledAuthenContainer>
      <div className="min-w-[520px] max-w-[600px]">
        <img
          src={IMAGES.llcTeacherBlackLogo}
          alt=""
          className="logo max-w-[160px] mx-auto mb-8"
        />

        <LoginForm
          control={control}
          redirectToForgot={handleRedirectToForgot}
          redirectToSignUp={handleRedirectToSignUp}
          handleLogin={handleLogin}
          isLoading={isLoginLoading}
          isRemember={isRemember}
          onRemember={(e: CheckboxChangeEvent) =>
            setIsRemember(e.target.checked)
          }
        />
      </div>
    </StyledAuthenContainer>
  )
}
