import { Typography } from 'antd'
import { memo } from 'react'
import styled from 'styled-components'

interface IProps {
  number: number
  bgColor?: string
  textColor?: string
  className?: string
}

export const StyledBulletNumbering = memo(
  ({ number, bgColor, textColor, className }: IProps) => {
    return (
      <StyledBulletNumberingContainer $bgColor={bgColor}>
        <Typography.Title level={4} className={`!mb-0 ${className}`}>
          {number}
        </Typography.Title>
      </StyledBulletNumberingContainer>
    )
  }
)

const StyledBulletNumberingContainer = styled.div<{
  $bgColor?: string
  $textColor?: string
}>`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 999px;
  background-color: ${({ $bgColor }) => $bgColor ?? 'black'};

  .ant-typography {
    color: ${({ $textColor }) => $textColor ?? 'white'};
    font-size: 14px;
    margin-bottom: 0px !important;
    text-align: center;
    width: 100%;
  }
`
