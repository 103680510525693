import Cookies from 'js-cookie'
import { Navigate, Outlet } from 'react-router-dom'

import { LLC_ACCESS_TOKEN, PATH_LOGIN } from '@configs'

export const RestrictedRoute = () => {
  const accessToken = Cookies.get(LLC_ACCESS_TOKEN)

  return !accessToken ? <Navigate to={PATH_LOGIN} /> : <Outlet />
}
