import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_NOTIFICATION_UNREAD_NUMBER } from '@configs'
import { BaseResponseError, BaseResponseProps } from '@interfaces'
import { notificationAPI } from 'src/api/notification'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<{ unreadNumber: number }>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
}

export const useGetNotificationUnreadNumber = (queryProps?: IQueryProps) => {
  const { configs } = queryProps || {}

  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_NOTIFICATION_UNREAD_NUMBER],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_NOTIFICATION_UNREAD_NUMBER],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getNotificationUnreadNumber() {
    return notificationAPI.getNotificationUnreadAmount()
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<{ unreadNumber: number }>,
    BaseResponseError
  >({
    queryKey: [QK_GET_NOTIFICATION_UNREAD_NUMBER],
    queryFn: getNotificationUnreadNumber,

    ...configs,
  })

  return {
    data: data?.data?.unreadNumber ?? 0,
    invalidateData,
    refetchData,
    ...restData,
  }
}
