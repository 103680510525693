import { t } from 'i18next'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseProps,
  ICommonDataResponse,
  ISignUpFields,
} from '@interfaces'
import { authAPI } from 'src/api/auth'
import { LLC_ACCESS_TOKEN, PATH_DASHBOARD } from '@configs'
import { authActions, useAppDispatch } from '@redux'
import { handleAPIError } from '@utils'
import { QK_SIGN_UP } from 'src/configs/queryClient/queryKeyConstants'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<ICommonDataResponse>,
    any,
    ISignUpFields,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (res: BaseResponseProps<ICommonDataResponse>) => void
}

export const signUpFn = (values: ISignUpFields) => authAPI.signUp(values)

export const useMutationSignUpQuery = ({
  configs,
  handleSuccess,
}: IMutationParams) => {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: [QK_SIGN_UP],
    mutationFn: signUpFn,
    onSuccess: (res: BaseResponseProps<ICommonDataResponse>) => {
      message.success(t('signUp:send_request_sign_up_successfully'))
      if (res?.data) {
        dispatch(
          authActions.setHashState({
            data: res?.data?.data,
            email: '',
          })
        )
        handleSuccess?.(res)
      }
    },
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}
