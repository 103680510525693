import React, { useRef, useState } from 'react'
import { Card } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { HookFormTextArea, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType } from 'src/interfaces/gallery'
import { ShareSelectInput } from '@components'
import {
  ALLOW_PERSONAL_INFOR_FILE_TYPES,
  GENDER_OPTION,
  MAX_PERSONAL_INFOR_FILE_SIZE,
} from './config'
import { authActions, useAppDispatch } from '@redux'
import { UPLOAD_AVATAR_LOADING_KEY } from 'src/pages/auth/sign-up/configs'
import { TPersonalInformationForm } from '../types'
import { themes } from '@theme'

const PersonalInformationForm = ({ isViewMode }: TPersonalInformationForm) => {
  const dispatch = useAppDispatch()
  const { control, trigger, setValue, getValues } = useFormContext()
  const onChangeDesktopRef = useRef<any>(null)

  const [currentImage, setCurrentImage] = useState('')

  React.useEffect(() => {
    setCurrentImage(getValues('personalInformation.avatar')?.url)
  }, [getValues('personalInformation.avatar')?.url])

  return (
    <PersonalInformationFormStyled>
      <Controller
        name={`personalInformation.firstname`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={t('common:first_name') as string}
              alignment="col"
              placeholder={t('common:first_name')}
              name={`personalInformation.firstname`}
              containerClassName="mt-5"
              inputStyledClassName="col-span-4"
              errors={error?.message}
              value={value}
              onChange={onChange}
              required
              isViewMode={isViewMode}
            />
          )
        }}
      />

      <Controller
        name={`personalInformation.lastname`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={t('common:last_name') as string}
              alignment="col"
              placeholder={t('common:last_name')}
              name={`personalInformation.lastname`}
              containerClassName="mt-5"
              inputStyledClassName="col-span-4"
              errors={error?.message}
              value={value}
              onChange={onChange}
              required
              isViewMode={isViewMode}
            />
          )
        }}
      />

      <Controller
        name={`personalInformation.gender`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <ShareSelectInput
              data={GENDER_OPTION}
              label={t('common:gender') as string}
              placeholder={t('common:enterGender')}
              value={!!value.length ? value : undefined}
              onChange={onChange}
              errors={error?.message}
              containerClassName="mt-5 col-span-4"
              showArrow={!isViewMode}
              required
              disabled={isViewMode}
            />
          )
        }}
      />

      <Controller
        name={`personalInformation.dateOfBirth`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              name={`personalInformation.dateOfBirth`}
              label={t('common:dob') as string}
              placeholder={t('common:enterDob')}
              alignment="col"
              type="date"
              containerClassName="mt-5"
              inputStyledClassName="col-span-4"
              errors={error?.message}
              value={value}
              onChangeDate={onChange}
              isViewMode={isViewMode}
              required
            />
          )
        }}
      />

      <Controller
        name={`personalInformation.phone`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={t('common:phone_number') as string}
              alignment="col"
              placeholder={t('common:phone_number')}
              name={`personalInformation.phone`}
              containerClassName="mt-5"
              inputStyledClassName="col-span-4"
              errors={error?.message}
              value={value}
              onChange={(e) => onChange(e.target.value?.toString()?.trim())}
              required
              isViewMode={isViewMode}
            />
          )
        }}
      />

      <div className="col-span-8 mt-5">
        <HookFormTextArea
          control={control}
          controlName="personalInformation.description"
          label={t('common:aboutMe')}
          labelClassName="mb-2"
          rows={5}
          isViewMode={isViewMode}
        />
      </div>

      <Controller
        name={'personalInformation.avatarId'}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          onChangeDesktopRef.current = onChange
          return (
            <div className="col-span-8 mt-5">
              <CustomDragger
                label={t('common:upload_avatar')}
                alignment="col"
                name="personalInformation.avatarId"
                id="personalInformation.avatarId"
                onLoadEnd={(data) => {
                  setValue(
                    'personalInformation.avatarId',
                    data?.dataValues?.id ?? 0
                  )
                  onChange(data?.dataValues?.id ?? 0)
                  setCurrentImage(data?.url ?? '')
                  // setIsChange(true)
                }}
                errors={error?.message}
                multiple={false}
                initResource={currentImage}
                allowFileTypes={ALLOW_PERSONAL_INFOR_FILE_TYPES}
                limitFileSize={MAX_PERSONAL_INFOR_FILE_SIZE}
                labelClassName="w-full"
                changeLoading={(isLoading) => {
                  dispatch(
                    authActions.setLoadings({
                      key: UPLOAD_AVATAR_LOADING_KEY,
                      isLoading,
                    })
                  )
                }}
                uploadType={EMediaType.IMAGE as any}
                needAuthorized={false}
                required
                disabled={isViewMode}
              />
            </div>
          )
        }}
      />
    </PersonalInformationFormStyled>
  )
}

export default PersonalInformationForm

const PersonalInformationFormStyled = styled(Card)`
  border: none !important;

  .ant-card-body {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 16px;

    ::before {
      content: none;
    }

    ::after {
      content: none;
    }
  }
  .ant-input-view {
    cursor: default;
  }
  input:read-only {
    color: ${themes.theme.light.colors.text} !important;
    cursor: default;
  }
  .ant-select-selector {
    background: ${themes.theme.light.colors.body} !important;
  }
  textarea.ant-input-disabled {
    color: ${themes.theme.light.colors.text} !important;
    cursor: default !important;
  }
  .textarea:focus,
  .textarea:active,
  .textarea:focus-within,
  .textarea:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: ${themes.theme.light.colors.border_gray_100};
  }
`
