import { t } from 'i18next'
import { message } from 'antd'
import Cookies from 'js-cookie'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseProps,
  TFillInTeacherInformation,
  TSignUpDetailsResponse,
} from '@interfaces'
import { authAPI } from 'src/api/auth'
import { authActions, useAppDispatch } from '@redux'
import { handleAPIError } from '@utils'
import {
  QK_SIGN_UP_DETAILS,
  QK_UPDATE_DETAILS,
} from 'src/configs/queryClient/queryKeyConstants'
import { LLC_ACCESS_TOKEN } from '@configs'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<TSignUpDetailsResponse>,
    any,
    TFillInTeacherInformation,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (res: TSignUpDetailsResponse) => void
}

export const signUpDetailsFn = (values: TFillInTeacherInformation) =>
  authAPI.signUpDetails(values)

export const updateDetailsFn = (values: TFillInTeacherInformation) =>
  authAPI.updateSignUpDetails(values)

export const useMutationSignUpDetailsQuery = ({
  configs,
  handleSuccess,
}: IMutationParams) => {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: [QK_SIGN_UP_DETAILS],
    mutationFn: signUpDetailsFn,
    onSuccess: (res: BaseResponseProps<TSignUpDetailsResponse>) => {
      message.success(t('signUp:send_request_sign_up_successfully'))
      if (res?.data) {
        Cookies.set(LLC_ACCESS_TOKEN, res?.data?.token?.accessToken ?? '')
        dispatch(
          authActions.setAccessToken({
            access_token: res?.data?.token?.accessToken ?? '',
          })
        )
        handleSuccess?.(res?.data)
      }
    },
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}

export const useMutationUpdateDetailsQuery = ({ configs }: IMutationParams) => {
  return useMutation({
    mutationKey: [QK_UPDATE_DETAILS],
    mutationFn: updateDetailsFn,
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}
