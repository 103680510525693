import { useMemo, useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import 'chartjs-adapter-moment'
import { sortBy } from 'lodash'
import {
  Chart as ChartJS,
  CategoryScale,
  ChartData,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Typography } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { IChannelStatisticByMonth } from '@interfaces'
import { themes } from '@theme'
import { channelsStatisticChartOptions } from 'src/pages/dashboard/channels/statistics-section/config'
import { ShareSelectInput, StyledEmpty } from '@components'
import { RootState } from '@redux'
import { formatDate } from '@utils'
import { CURRENT_YEAR, FORMAT_MONTH_YEAR } from '@configs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface IProps {
  statisticByMonths?: IChannelStatisticByMonth[]
  onChangeFilter?: (year: number | string) => void
}

export const ChannelsStatisticSection = ({
  statisticByMonths,
  onChangeFilter,
}: IProps) => {
  const [filter, setFilter] = useState<number | string>(CURRENT_YEAR)
  const accountInfor = useSelector((state: RootState) => state.auth.accountInfo)

  const isDataEmpty = statisticByMonths?.every(
    (stat) => !stat?.totalSold && !stat?.totalMembership
  )

  const filterByYearData = useMemo(() => {
    if (!accountInfor?.approvedAt) return []

    const startYear = moment(accountInfor?.approvedAt).year()
    const currentYear = moment().year()

    const options = []

    for (let year = startYear; year <= currentYear; year++) {
      options.push({
        label: String(year),
        value: Number(year),
      })
    }

    return options
  }, [accountInfor?.approvedAt])

  const rootColor = themes.theme.dark.colors.miniDashboard

  const chartData = useMemo(() => {
    // sort list statistic asc by month
    const sortedStatisticByMonths = sortBy(
      statisticByMonths,
      (item) => new Date(item?.month)
    )

    // label list
    const labelList =
      sortedStatisticByMonths?.map((item) =>
        formatDate(item?.month, FORMAT_MONTH_YEAR)
      ) ?? []
    // sold course data
    const soldCourseData =
      sortedStatisticByMonths?.map((item) => item?.totalSold) ?? []
    // member course data
    const memberCourseData =
      sortedStatisticByMonths?.map((item) => item?.totalMembership) ?? []

    const data: ChartData<'bar'> = {
      labels: labelList,
      datasets: [
        {
          label: t('dashboard:sold_course'),
          data: soldCourseData,
          backgroundColor: rootColor.darkPurple,
          maxBarThickness: 30,
          minBarLength: 2,
        },
        {
          label: t('dashboard:package_enroll'),
          data: memberCourseData,
          backgroundColor: rootColor.lightPurple,
          maxBarThickness: 30,
          minBarLength: 2,
        },
      ],
    }

    return data
  }, [rootColor.darkPurple, rootColor.lightPurple, statisticByMonths])

  return (
    <ChannelsStatisticSectionStyled>
      <TitleWrapper>
        <Typography.Title level={2} className="!text-[18px]">
          {t('dashboard:statistics')}
        </Typography.Title>
        <ShareSelectInput
          data={filterByYearData}
          placeholder={t('dashboard:year')}
          onChange={(value) => {
            setFilter(value)
            onChangeFilter?.(value)
          }}
          value={filter}
          className="!min-w-[80px] !max-w-[100px]"
          containerClassName="!min-w-[80px] !max-w-[100px]"
          disabled={filterByYearData.length <= 1}
          showArrow
        />
      </TitleWrapper>
      {!isDataEmpty ? (
        <Bar
          data={chartData}
          options={channelsStatisticChartOptions}
          className="mt-8"
        />
      ) : (
        <StyledEmpty className="!mt-14" />
      )}
    </ChannelsStatisticSectionStyled>
  )
}

const ChannelsStatisticSectionStyled = styled.div`
  width: 70%;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid ${themes.theme.light.colors.lightGray};
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
