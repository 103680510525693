import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { Skeleton, Spin, Tooltip, Typography, message } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  MessageOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import { ShareSelectInput } from '@components'
import {
  PATH_COURSE_MANAGEMENT,
  PATH_COURSE_MANAGEMENT_NEW,
  enumTableTitleCourse,
  COURSE_STATUS,
  ETeacherRequestStatus,
  ERequest,
  enumStatus,
} from '@configs'
import { RootState, useAppDispatch, selectCourseLoading } from '@redux'
import { formatDate } from '@utils'
import { Button, Input, Loading, SharedTable } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { useDebounce } from 'src/hooks/useDebounce'
import { ICourseParams } from 'src/interfaces/course'
import {
  deleteCourseByIdAction,
  duplicateCourseByIdAction,
  getCourseDetailAction,
  getListTeacherCourseAction,
  highLightByIdAction,
} from 'src/redux/actions/course'
import {
  EActionSort,
  ECourseActiveStatus,
  ECoursePublishStatus,
  ECourseStatusFilterOptions,
  ECourseTypeFilterOptions,
  optionsFilterReviewState,
  optionsFilterStatus,
  optionsFilterType,
} from './config'
import { themes } from '@theme'
import { useGetTeacherCourseMiniDashboardQuery } from '@api'
import { IRequest } from '@interfaces'

type Props = {}

const CoursePage = (props: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openConfirmHighLight, setOpenConfirmHighLight] = useState(false)
  const [searchValue, setSearchValue] = useState<string | null>(() =>
    !!searchParams.get('search') ? searchParams.get('search') : null
  )
  const [isSearching, setIsSearching] = useState(false)
  const debouncedValue = useDebounce<string | null>(searchValue, 800)
  const [sort, setSort] = useState<{ [key: string]: any }>({})
  const [status, setStatus] = useState(optionsFilterStatus[0].value)
  const [courseType, setCourseType] = useState(optionsFilterType[0].value)
  const [reviewState, setReviewState] = useState(
    optionsFilterReviewState[0].value
  )
  const [selectedCourseId, setSelectedCourseId] = useState<number | string>()
  const [isModalLoading, setIsModalLoading] = useState(false)

  const { courses, totalCourses, pageCourses, limitCourses } = useSelector(
    (state: RootState) => state.course
  )

  const getSortOrder = (sort: string) => {
    if (sort === EActionSort.ASC) {
      return EActionSort.DESC
    }
    return EActionSort.ASC
  }

  const chosenCourse = courses?.find((c) => c.id === selectedCourseId)

  const { data: miniDashboardData, isPending: isGetMiniDashboardLoading } =
    useGetTeacherCourseMiniDashboardQuery()

  const getCourses = async (params?: ICourseParams) => {
    dispatch(
      getListTeacherCourseAction({
        page: +pageCourses,
        sort,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
        ...(params || {}),
      })
    )
  }

  const getCourseFilterParams = useMemo(() => {
    const params: ICourseParams = {
      page: 1,
      ...(!!debouncedValue &&
        !!debouncedValue.length && { search: debouncedValue.trim() }),
      ...(status !== optionsFilterStatus[0].value && { status }),
      ...(reviewState !== optionsFilterReviewState[0].value && { reviewState }),
      ...(courseType !== optionsFilterType[0].value && { type: courseType }),
      ...(!!sort.unreadMessage && {
        sort: { unreadMessage: getSortOrder(sort.unreadMessage) },
      }),
    }
    return params
  }, [courseType, debouncedValue, reviewState, sort.unreadMessage, status])

  const getCoursesBySearchValue = async () => {
    try {
      setIsSearching(true)
      const options: {
        page: number
        status?: string
        type?: string
        search: string | undefined
      } = {
        page: 1,
        search:
          debouncedValue !== null && debouncedValue.trim()
            ? debouncedValue.trim()
            : undefined,
      }
      if (
        status === optionsFilterStatus[1].value ||
        status === optionsFilterStatus[2].value
      ) {
        options.status = status
      }

      if (
        courseType === optionsFilterType[1].value ||
        courseType === optionsFilterType[2].value
      ) {
        options.type = courseType
      }

      await dispatch(getListTeacherCourseAction(options)).unwrap()
    } catch (error: any) {
      if (error.message) {
        message.error({
          content: error.message,
        })
      }
    } finally {
      setIsSearching(false)
      // clear search params
      const emptySearchParams = new URLSearchParams(searchParams)
      emptySearchParams.set('search', '')
      setSearchParams(emptySearchParams)
    }
  }

  const getListTeacherCourseActionLoading =
    useSelector((state: RootState) =>
      selectCourseLoading(state, 'getListTeacherCourseAction')
    ) || isGetMiniDashboardLoading

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }
  const onCloseHModal = () => {
    setOpenConfirmHighLight(false)
  }
  const handleConfirmDelete = async () => {
    try {
      if (selectedCourseId) {
        setIsModalLoading(true)

        const response = await dispatch(
          deleteCourseByIdAction(selectedCourseId as string)
        ).unwrap()

        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
          getCourses({
            page: 1,
            type:
              courseType === optionsFilterType[1].value ||
              courseType === optionsFilterType[2].value
                ? courseType
                : undefined,
            status: status !== COURSE_STATUS.ALL ? status : undefined,
          })
        }
      } else {
        setIsModalLoading(false)
        setOpenConfirmDeleteModal(false)
      }
    } catch (error: any) {
      console.log(error?.message)
      message.error(error?.message)
    } finally {
      setIsModalLoading(false)
      setOpenConfirmDeleteModal(false)
    }
  }

  const onDuplicateCourse = async (courseId: number | string) => {
    try {
      setIsModalLoading(true)

      const response = await dispatch(
        duplicateCourseByIdAction(courseId)
      ).unwrap()

      if (response?.success) {
        message.success(response.message)
        setIsModalLoading(false)
        getCourses({
          page: 1,
          type:
            courseType === optionsFilterType[1].value ||
            courseType === optionsFilterType[2].value
              ? courseType
              : undefined,
          status: status !== COURSE_STATUS.ALL ? status : undefined,
        })
      }
    } catch (error: any) {
      message.error(error?.message)
    }
  }
  const onHighLightCourse = async (id?: number) => {
    if (!selectedCourseId && !id) {
      return
    }
    if (selectedCourseId) {
      setSelectedCourseId(id)
      try {
        const response = await dispatch(
          highLightByIdAction(selectedCourseId)
        ).unwrap()
        if (response?.success) {
          message.success(response.message)
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setSelectedCourseId(undefined)
        setOpenConfirmHighLight(false)
      }
      return
    }

    if (id) {
      setSelectedCourseId(id)
      try {
        const response = await dispatch(highLightByIdAction(id)).unwrap()
        if (response?.success) {
          message.success(response.message)
        }
      } catch (error: any) {
        message.error(error?.message)
      } finally {
        setSelectedCourseId(undefined)
        setOpenConfirmHighLight(false)
      }
      return
    }
  }

  const columns = [
    {
      width: '10%',
      title: enumTableTitleCourse.NO,
      dataIndex: 'index',
      key: 'index',
      ellipsis: true,
      align: 'center',
    },
    {
      width: '30%',
      title: () => {
        return (
          <div className="flex items-center justify-start w-full">
            <p className="m-0">{enumTableTitleCourse.COURSE_NAME}</p>
          </div>
        )
      },
      dataIndex: 'title',
      key: 'id',
      render: (e: string) => {
        return (
          <div className="flex items-center max-w-[460px]">
            <Typography.Text
              style={{
                width: 400,
                whiteSpace: 'pre',
              }}
              ellipsis={true}
            >
              {e}
            </Typography.Text>
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.TYPE}</p>
          </div>
        )
      },
      dataIndex: 'type',
      key: 'type',
      render: (e: string) => {
        return (
          <div className="flex items-center justify-center capitalize rounded-[10px] bg-neutral-200 px-3 min-w-[130px]">
            {e.replace('_', ' ')}
          </div>
        )
      },
      ellipsis: true,
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.REVIEW_STATE}</p>
          </div>
        )
      },
      dataIndex: 'reviewState',
      key: 'reviewState',
      render: (_: any, record: { reviewState: ETeacherRequestStatus }) => (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <ReviewStateTextStyled $state={record?.reviewState}>
            {record?.reviewState ?? t('common:N/A')}
          </ReviewStateTextStyled>
        </div>
      ),
      ellipsis: true,
    },
    {
      width: '10%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.STATUS}</p>
          </div>
        )
      },
      dataIndex: 'isActived',
      key: 'isActived',
      render: (_: any, record: { deletedAt: any; isActived: any }) => (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <p className={`m-0 bold ${!!record?.deletedAt && 'text-danger'}`}>
            {record?.deletedAt ? 'Deleted at' : record?.isActived}
          </p>
          {!!record?.deletedAt && (
            <p className="m-0 text-danger">
              {formatDate(record?.deletedAt, 'DD-MM-YYYY')}
            </p>
          )}
        </div>
      ),
      ellipsis: true,
    },
    {
      width: '20%',
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">{enumTableTitleCourse.PUBLISHED_DRAFT}</p>
          </div>
        )
      },
      dataIndex: 'isDrafted',
      key: 'isDrafted',
      render: (e: boolean) => {
        return <div className="flex items-center justify-center">{e}</div>
      },
      ellipsis: true,
    },
    {
      width: '20%',
      title: () => (
        <div className="flex items-center justify-center w-full">
          <p className="m-0">{enumTableTitleCourse.ACTION}</p>
          <div
            className="flex items-center ml-2 cursor-pointer"
            onClick={() => {
              setSort({ unreadMessage: getSortOrder(sort?.unreadMessage) })
            }}
          >
            {sort.unreadMessage === EActionSort.ASC ? (
              <CaretDownOutlined />
            ) : (
              <CaretUpOutlined />
            )}
          </div>
        </div>
      ),
      key: 'action',
      dataIndex: 'action',
      render: (
        _: any,
        record: {
          id: string
          deletedAt: string | null
          isDrafted: ECoursePublishStatus
          unreadMessage: number
          isActived: enumStatus
          requests: IRequest[]
        }
      ) => {
        const reviewState = record?.requests?.find(
          (it) => it?.type === ERequest.PUBLISH
        )?.status

        const disableDelete =
          !!record.deletedAt ||
          record.isDrafted === ECoursePublishStatus.PUBLISHED ||
          record.isActived === enumStatus.ACTIVE

        const canChat =
          !!record?.deletedAt ||
          (!!reviewState &&
            ![
              ETeacherRequestStatus.PENDING,
              ETeacherRequestStatus.REJECTED,
            ].includes(reviewState))
        return (
          <div className="flex space-x-4 justify-center items-center min-w-[120px]">
            <Tooltip title={t('common:chat')}>
              <div className="relative">
                <MessageOutlined
                  className={`text-lg font-light mr-2.5 ${
                    !!record?.unreadMessage ? '!text-[#ff0000]' : ''
                  } ${!canChat && '!text-gray-300 !cursor-not-allowed'}`}
                  onClick={async () => {
                    if (!canChat) return
                    navigate(
                      `${PATH_COURSE_MANAGEMENT}/${record?.id}?tab=HOME_PAGE_CHAT`
                    )
                  }}
                />

                {!!record?.unreadMessage ? (
                  <div
                    className={`flex h-1 w-1 p-2 items-center justify-center rounded-full bg-red-500 text-[8px] text-white absolute top-0 right-0 -translate-y-1/2`}
                  >
                    {record?.unreadMessage > 9 ? '9+' : record?.unreadMessage}
                  </div>
                ) : null}
              </div>
            </Tooltip>

            <Tooltip title={t('common:view_detail')}>
              <EyeOutlined
                className={`text-lg font-light mr-2.5 ${
                  !!record.deletedAt
                    ? '!text-gray-300 !cursor-not-allowed'
                    : 'cursor-pointer text-[#184f64]'
                }`}
                onClick={() => {
                  if (!!record.deletedAt) return
                  navigate(`${PATH_COURSE_MANAGEMENT}/${record.id}`)
                }}
              />
            </Tooltip>
            <Tooltip title={t('common:duplicate')}>
              <CopyOutlined
                className={`text-lg font-light mr-2.5 ${
                  !!record.deletedAt
                    ? '!text-gray-300 !cursor-not-allowed'
                    : 'cursor-pointer text-[#184f64]'
                }`}
                onClick={() => {
                  if (!!record.deletedAt) return
                  onDuplicateCourse(record.id)
                }}
              />
            </Tooltip>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 ${
                disableDelete
                  ? '!text-gray-300 !cursor-not-allowed'
                  : 'cursor-pointer hover:opacity-80 !text-red-600'
              }`}
              onClick={() => {
                if (disableDelete) return
                setOpenConfirmDeleteModal(true)
                setSelectedCourseId(record.id)
              }}
            />
          </div>
        )
      },
      ellipsis: true,
      align: 'center',
    },
  ]

  const handleChangeStatus = (value: string) => {
    setStatus(value as ECourseStatusFilterOptions)
  }

  const handleChangeType = (value: string) => {
    setCourseType(value as ECourseTypeFilterOptions)
  }

  const handleChangeReviewState = (value: string) => {
    setReviewState(value as ETeacherRequestStatus)
  }

  useEffect(() => {
    getCourses({ page: 1 })
  }, [])

  useEffect(() => {
    getCourses({ ...getCourseFilterParams })
  }, [getCourseFilterParams])

  useEffect(() => {
    debouncedValue !== null && getCoursesBySearchValue()
  }, [debouncedValue])

  return (
    <CourseManagementStyled>
      <>
        <PackageInfoWrapperStyled>
          <div className="gradient-border">
            <div className="content-wrapper">
              <Typography.Title level={2}>
                {t('courseManagement:current_package', {
                  string: miniDashboardData?.data?.teacherPackageName,
                })}
              </Typography.Title>
              {!!miniDashboardData?.data?.memberCourseLimit ? (
                <Typography.Text className="mr-4">
                  {t('courseManagement:member_course_publish', {
                    num1: miniDashboardData?.data?.memberCoursePublish,
                    num2: miniDashboardData?.data?.memberCourseLimit,
                  })}
                </Typography.Text>
              ) : null}
              {!!miniDashboardData?.data?.premiumCourseLimit ? (
                <Typography.Text>
                  {t('courseManagement:platium_course_publish', {
                    num1: miniDashboardData?.data?.premiumCoursePublish,
                    num2: miniDashboardData?.data?.premiumCourseLimit,
                  })}
                </Typography.Text>
              ) : null}
            </div>
          </div>
        </PackageInfoWrapperStyled>
        <div className="flex justify-between items-start py-[1.25rem] w-full">
          <div className="max-w-[700px] flex-1 flex gap-3">
            <Input
              inputStyledClassName="min-w-[320px]"
              prefix={<SearchOutlined className="pl-2" />}
              placeholder={t('courseManagement:enter_course_name')}
              value={searchValue === null ? '' : searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              suffix={isSearching ? <Loading /> : undefined}
            />
            <section className="w-full flex items-start gap-4">
              <div className="w-full wrapper-status-filter flex items-center justify-center gap-1">
                <ShareSelectInput
                  className="min-w-[150px] max-w-[150px]"
                  data={optionsFilterType}
                  value={courseType}
                  onChange={handleChangeType}
                  showArrow
                />
              </div>
              <div className="w-full wrapper-status-filter flex items-center justify-center gap-1">
                <ShareSelectInput
                  className="min-w-[150px] max-w-[150px]"
                  data={optionsFilterStatus}
                  value={status}
                  onChange={handleChangeStatus}
                  showArrow
                />
              </div>
              <div className="w-full wrapper-status-filter flex items-center justify-center gap-1">
                <ShareSelectInput
                  className="min-w-[150px] max-w-[150px]"
                  data={optionsFilterReviewState}
                  value={reviewState}
                  onChange={handleChangeReviewState}
                  showArrow
                />
              </div>
            </section>
          </div>
          <div>
            <Button
              className="floating__button"
              type="primary"
              onClick={() => {
                // setOpen(true)
                // navigate(PATH_ADD_COURSE)
                navigate(PATH_COURSE_MANAGEMENT_NEW)
              }}
            >
              {t('common:create_new')}
            </Button>
          </div>
        </div>
        <div className="pb-[1.25rem]">
          <SharedTable
            columns={columns as any}
            dataSource={courses?.map((item, index) => {
              return {
                ...item,
                index: index + 1,
                key: item?.id,
                id: item?.id,
                deletedAt: item?.deletedAt,
                title: item?.title,
                user: item?.user?.name,
                type: item?.type,
                isActived: item?.isActived
                  ? ECourseActiveStatus.ACTIVE
                  : ECourseActiveStatus.INACTIVE,
                isDrafted: item?.isDrafted
                  ? ECoursePublishStatus.DRAFT
                  : ECoursePublishStatus.PUBLISHED,
                reviewState: item?.requests[0]?.status,
                category: item?.categories
                  ? item?.categories.map((category) => category.name).join(', ')
                  : [],
              }
            })}
            paginationProps={{
              total: !!totalCourses ? +totalCourses : undefined,
              pageSize: +limitCourses || 10,
              current: +pageCourses,
              onChange(page) {
                const params: ICourseParams = {
                  ...getCourseFilterParams,
                  page: page,
                }
                getCourses(params)
              },
            }}
            loading={{
              spinning: getListTeacherCourseActionLoading || isSearching,
              size: 'large',
            }}
            sticky
          />
        </div>
      </>

      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title={`${
            chosenCourse?.isDrafted
              ? 'Delete course'
              : 'Do you want to delete this course?'
          }`}
          content={`${
            chosenCourse?.isDrafted
              ? 'Are you sure to delete this course?'
              : 'This course was published , this deletion will affect to others participating in this!'
          }`}
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={handleConfirmDelete}
          onClose={onCloseConfirmModal}
          highlight={!chosenCourse?.isDrafted}
        />
      )}
      {openConfirmHighLight && (
        <ConfirmDeleteModal
          title={`You want to highlight this course`}
          content={`You 've highlighted 5 courses , if confirm is clicked , this oldest highlight course will be removed`}
          open={openConfirmHighLight}
          isLoading={!!isModalLoading}
          onDelete={onHighLightCourse}
          onClose={onCloseHModal}
        />
      )}
    </CourseManagementStyled>
  )
}

export default CoursePage

const CourseManagementStyled = styled('div')(() => {
  return {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',

    '& .ant-table-wrapper': {
      margin: 0,
      '& .ant-table-content': {
        marginRight: 0,
      },
    },

    '.ant-spin-nested-loading>div>.ant-spin': { maxHeight: '100%' },
  }
})

const PackageInfoWrapperStyled = styled.div`
  width: max-content;
  max-width: 100%;

  .gradient-border {
    background-image: linear-gradient(
      45deg,
      ${themes.theme.light.colors.primary},
      ${themes.theme.light.colors.lightBlue}
    );
    border-radius: 16px;
    padding: 8px;

    .content-wrapper {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0 15px ${themes.theme.light.colors.primary};
      padding: 16px 24px;
    }
  }
`

const ReviewStateTextStyled = styled(Typography.Paragraph)<{
  $state?: ETeacherRequestStatus
}>`
  font-weight: 600;
  text-transform: capitalize !important;
  margin: 0 !important;
  ${(props) => {
    const { $state } = props
    if (!$state) return css``
    const cssRenderObject: Record<
      ETeacherRequestStatus,
      FlattenSimpleInterpolation
    > = {
      [ETeacherRequestStatus.ACCEPTED]: css`
        color: ${themes.theme.light.colors.highlight};
      `,
      [ETeacherRequestStatus.PENDING]: css`
        color: ${themes.theme.light.colors.warning};
      `,
      [ETeacherRequestStatus.REJECTED]: css`
        color: ${themes.theme.light.colors.delete};
      `,
      [ETeacherRequestStatus.UPDATED]: css`
        color: '#000';
      `,
      [ETeacherRequestStatus.REQUEST_UPDATE]: css`
        color: '#000';
      `,
      [ETeacherRequestStatus.NO_DATA]: css`
        color: '#000';
      `,
      [ETeacherRequestStatus.ALL]: css`
        color: '#000';
      `,
    }
    return cssRenderObject[$state]
  }}
`
