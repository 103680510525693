import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { themes } from '@theme'
import { Typography } from 'antd'

export const WarningCard = memo(
  ({
    content,
    className,
    icon,
  }: {
    content?: string
    className?: string
    icon?: ReactNode
  }) => {
    return (
      <WarningCardStyled>
        {icon ?? (
          <ExclamationCircleOutlined className="!text-[24px] !text-orange-400" />
        )}
        <Typography.Text className={`${className}`}>{content}</Typography.Text>
      </WarningCardStyled>
    )
  }
)

const WarningCardStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  border-radius: 8px;
  padding: 18px;
  background-color: ${themes.theme.light.colors.lightGray};
`
