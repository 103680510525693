import styled from 'styled-components'

import { HEADER_HEIGHT, HEADER_PADDING_TOP } from '@configs'

export const SubscriptionStyled = styled.div`
  width: 100dvw;
  min-height: calc(100dvh - (${HEADER_HEIGHT} + ${HEADER_PADDING_TOP} + 2rem));
  overflow-y: auto;
  z-index: 50;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;

  .ant-select-selection-item {
    max-width: 90%;
  }
`
