import { t } from 'i18next'
import { Typography } from 'antd'
import { capitalize, upperCase } from 'lodash'
import styled from 'styled-components'
import { ColumnsType } from 'antd/lib/table'

import { EIncomeType, FORMAT_DATE_DASHBOARD_COURSE_TABLE } from '@configs'
import { themes } from '@theme'
import { formatDate } from '@utils'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export const TABLE_TOOLBAR_RHF_FIELDS = {
  SEARCH: 'search',
  TYPE: 'incomeType',
}

export const INCOME_TYPE_OPTIONS = [
  {
    label: 'All',
    value: EIncomeType.ALL,
  },
  {
    label: capitalize(EIncomeType.SOLD.replace('_', ' ')),
    value: EIncomeType.SOLD,
  },
  {
    label: capitalize(EIncomeType.MEMBERSHIP_ENROLL.replace('_', ' ')),
    value: EIncomeType.MEMBERSHIP_ENROLL,
  },
]

export const TABLE_TOOLBAR_DEFAULT_VALUES = {
  [TABLE_TOOLBAR_RHF_FIELDS.SEARCH]: '',
  [TABLE_TOOLBAR_RHF_FIELDS.TYPE]: INCOME_TYPE_OPTIONS[0].value,
}

export const COURSE_ORDER_TABLE_COLUMNS: ColumnsType<any> = [
  {
    width: '15%',
    title: upperCase(t('dashboard:no')),
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
    fixed: 'left',
  },
  {
    width: '50%',
    title: () => {
      return (
        <TitleWrapperStyled className="!justify-start">
          <p className="m-0">{t('dashboard:income_order_number')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'revenueId',
    key: 'revenueId',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 font-medium !text-primaryPurple !text-[14px]">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '40%',
    title: () => {
      return (
        <TitleWrapperStyled className="!justify-start">
          <p className="m-0">{t('dashboard:student_name')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'studentName',
    key: 'studentName',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 font-medium !text-primaryPurple !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '30%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:order_date')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'orderDate',
    key: 'id',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]" ellipsis={true}>
          {formatDate(e, FORMAT_DATE_DASHBOARD_COURSE_TABLE)}
        </Typography.Text>
      )
    },
    align: 'center',
    ellipsis: true,
  },
  {
    width: '25%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:type')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'incomeType',
    key: 'id',
    render: (e: string) => {
      if (e === EIncomeType.SOLD) {
        return (
          <TypeWrapper>
            <IncomeTypeStyled
              $bgColor={themes.theme.dark.colors.miniDashboard.lightBlue}
              $textColor={themes.theme.dark.colors.miniDashboard.darkBlue}
            >
              {capitalize(e.replace('_', ' '))}
            </IncomeTypeStyled>
          </TypeWrapper>
        )
      }
      return (
        <TypeWrapper>
          <IncomeTypeStyled
            $bgColor={themes.theme.dark.colors.miniDashboard.lightGreen}
            $textColor={themes.theme.dark.colors.miniDashboard.darkGreen}
          >
            {capitalize(e.replace('_', ' '))}
          </IncomeTypeStyled>
        </TypeWrapper>
      )
    },
    ellipsis: true,
  },
  {
    width: '20%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:amount')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'amount',
    key: 'id',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]" ellipsis={true}>
          €{formatCurrencyByType(Number(e ?? 0), 'EUR').replace('€', '')}
        </Typography.Text>
      )
    },
    align: 'center',
    ellipsis: true,
  },
  {
    width: '20%',
    title: null,
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    render: () => null,
  },
]

export const TitleWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const IncomeTypeStyled = styled.span<{
  $bgColor?: string
  $textColor?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  padding: 6px 8px;
  border-radius: 14px;
  background-color: ${(p) => p.$bgColor || ''};
  color: ${(p) => p.$textColor || ''};
`
export const TypeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
