import { memo, useMemo } from 'react'
import { Typography } from 'antd'
import { t } from 'i18next'

import { AppModal } from '@components'
import { Button, SharedStyledTableWrapper, SharedTable } from 'src/common'
import { HISTORY_LOG_COLUMNS } from './configs'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { useGetHistoryLogQuery } from '@api'

interface IProps {
  open: boolean
  onClose: () => void
  invoiceId: number | string
}

export const HistoryLogModal = memo(({ open, onClose, invoiceId }: IProps) => {
  const { data: historyLogData, isFetching: fetchingHistoryLogData } =
    useGetHistoryLogQuery({
      invoiceId,
    })

  const formattedTableData = useMemo(() => {
    if (!historyLogData) return []

    return historyLogData?.map((it, idx) => ({
      ...it,
      index: idx + 1,
      dateTime: it?.createdAt,
    }))
  }, [historyLogData])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <Typography.Title level={4} className="!text-[36px] !text-center">
          {t('revenue:invoice_history')}
        </Typography.Title>
      }
      bottomButton={
        <ButtonWrapper className="!mt-0">
          <Button
            className="w-full flex justify-center !bg-neutral-500 !text-white !border-neutral-500"
            type="primary"
            onClick={onClose}
          >
            {t('common:back')}
          </Button>
        </ButtonWrapper>
      }
      contentClassName="!min-w-[920px] !max-w-[920px] !max-h-[75dvh]"
    >
      <SharedStyledTableWrapper>
        <SharedTable
          columns={HISTORY_LOG_COLUMNS}
          dataSource={formattedTableData}
          className="!m-0"
          loading={{
            spinning: fetchingHistoryLogData || !historyLogData,
            size: 'large',
          }}
          hasIndexColumn={false}
          sticky
        />
      </SharedStyledTableWrapper>
    </AppModal>
  )
})
