import { message } from 'antd'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { authAPI } from 'src/api/auth'
import { QK_CHANGE_PASSWORD } from 'src/configs/queryClient/queryKeyConstants'
import { BaseResponseProps, IChangePasswordFields } from '@interfaces'
import { handleAPIError } from '@utils'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<any>,
    any,
    IChangePasswordFields,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

interface IMutationParams {
  configs?: IMutationOptions
  handleSuccess?: (res: {}) => void
}

export const changePasswordFn = (values: IChangePasswordFields) =>
  authAPI.changePassword(values)

export const useMutationChangePasswordQuery = (params?: IMutationParams) => {
  const { configs, handleSuccess } = { ...params }

  return useMutation({
    mutationKey: [QK_CHANGE_PASSWORD],
    mutationFn: changePasswordFn,
    onSuccess: (res) => {
      if (res?.data) {
        handleSuccess?.(res?.data)
      }
    },
    onError: (error) => {
      handleAPIError(error)
    },

    ...configs,
  })
}
