import React from 'react'

import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { Button } from 'src/common'

interface IConfirmDeleteModalProps {
  open: boolean
  isLoading: boolean
  title: string
  content: string
  onClose: () => void
  onDelete: () => void
  highlight?: boolean
}

const ConfirmDeleteModal = (props: IConfirmDeleteModalProps) => {
  const { open, isLoading, title, content, onClose, onDelete, highlight } =
    props

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between w-[500px]">
        <div>
          <h1 className="m-0 text-[20px]">{title}</h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer"></div>
      </div>
      <div className="max-w-[495px] mt-6">
        <div className="w-full">
          <p className={`${highlight ? 'text-danger' : ' '} w-full text-md`}>
            {content}
          </p>
        </div>
        <div className="flex items-center mt-5 justify-end">
          <Button
            type="ghost"
            size="large"
            className="submit__btn login-btn mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="submit__btn login-btn"
            loading={isLoading}
            onClick={onDelete}
          >
            Confirm
          </Button>
        </div>
      </div>
    </AppModal>
  )
}

export default ConfirmDeleteModal
