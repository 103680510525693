import { memo, useMemo } from 'react'
import { t } from 'i18next'
import { Typography } from 'antd'
import styled from 'styled-components'

import { AppModal } from '@components'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { Button } from 'src/common'

interface IProps {
  open: boolean
  onClose: () => void
  onConfirmBack: () => void
}

export const ModalConfirmBack = memo(
  ({ open, onClose, onConfirmBack }: IProps) => {
    const modalContent = useMemo(
      () => (
        <ModalContentStyled>
          <Typography.Text>
            {t('signUp:content_modal_warning_back')}
          </Typography.Text>
          <ButtonWrapper className="!justify-end mt-8">
            <Button
              htmlType="button"
              type="default"
              size="small"
              id="blog-form"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
            <Button
              htmlType="button"
              type="primary"
              size="small"
              id="blog-form"
              onClick={onConfirmBack}
            >
              {t('common:confirm')}
            </Button>
          </ButtonWrapper>
        </ModalContentStyled>
      ),
      [onClose, onConfirmBack]
    )

    return (
      <AppModal
        open={open}
        onClose={onClose}
        title={t('common:warning')}
        contentClassName="max-w-[42dvw]"
        haveCloseIcon
      >
        {modalContent}
      </AppModal>
    )
  }
)

const ModalContentStyled = styled.div`
  width: 100%;
`
