import { t } from 'i18next'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps, ILoginRequest } from '@interfaces'
import { authAPI } from 'src/api/auth'
import { LLC_ACCESS_TOKEN, PATH_DASHBOARD } from '@configs'
import { authActions, useAppDispatch } from '@redux'
import { QK_LOGIN } from 'src/configs/queryClient/queryKeyConstants'

type IMutationOptions = Omit<
  UseMutationOptions<BaseResponseProps<any>, any, ILoginRequest, unknown>,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

export const loginFn = (values: ILoginRequest) => authAPI.login(values)

export const useMutationLoginQuery = (configs?: IMutationOptions) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: [QK_LOGIN],
    mutationFn: loginFn,
    onSuccess: (res) => {
      message.success(t('login:login_success'))
      if (res?.data) {
        Cookies.set(LLC_ACCESS_TOKEN, res.data.token.accessToken)
        dispatch(authActions.setCurrentAccount(res?.data))
        navigate(PATH_DASHBOARD)
      }
    },
    onError: (error) => {
      message.error({
        content: error?.message,
      })
    },

    ...configs,
  })
}
