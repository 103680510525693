import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSelector } from 'react-redux'
import { Checkbox, message, Skeleton, Tooltip } from 'antd'
import { t } from 'i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { ICreateEditTeacherPackage, TTeacherPackage } from '@interfaces'
import { Button, Input, SwitchButton, Text } from 'src/common'
import {
  InputClassName,
  teacherPackageSchema,
} from 'src/pages/package-management/teacher-package-management/config'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { RootState, useAppDispatch } from '@redux'
import { thunkActionLoading } from '@configs'

const defaultValues = {
  name: '',
  nameInDutch: '',
  priceUsd: 0,
  priceEur: 0,
  textColor: '#ffffff',
  mediaId: null,
  backgroundColor: '',
  isActive: false,
  accessModule: {
    course: {
      isPublicPremiumLimit: false,
      premiumLimit: null,
      isPublicMemberLimit: false,
      memberLimit: null,
    },
    revenue: {
      defaultRate: null,
      memberCourse: [],
      premiumRate: null,
    },
  },
}

interface IProps {
  onClose: () => void
  onSubmit: (data: ICreateEditTeacherPackage) => void
  selectedPackageId?: number
}

const CreateEditPackageForm = ({
  onClose,
  onSubmit,
  selectedPackageId,
}: IProps) => {
  const onChangeDesktopRef = useRef<any>(null)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const dispatch = useAppDispatch()

  const { packages, loadings: packageLoading } = useSelector(
    (state: RootState) => state.package
  )

  const { selectedTeacherPackage, loadings: teacherPackageLoading } =
    useSelector((state: RootState) => state.teacherPackages)

  const isFetchingListPublicPackage =
    packageLoading.getListPublicPackageForUserActionLoading

  const isCreateEditTeacherPackageLoading =
    teacherPackageLoading[
      thunkActionLoading.CREATE_EDIT_TEACHER_PACKAGE_LOADING
    ]

  const isGetTeacherPackageDetailsLoading =
    teacherPackageLoading[
      thunkActionLoading.GET_TEACHER_PACKAGE_DETAILS_LOADING
    ]

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICreateEditTeacherPackage>({
    defaultValues: defaultValues,
    resolver: zodResolver(teacherPackageSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const watchIsPublicMemberCourse = watch(
    'accessModule.course.isPublicMemberLimit'
  )
  const watchIsPublicPremiumCourse = watch(
    'accessModule.course.isPublicPremiumLimit'
  )

  const watchMemberCourse = watch('accessModule.revenue.memberCourse')
  const watchDefaultRate = watch('accessModule.revenue.defaultRate')

  const handleSubmitForm = handleSubmit((value) => {
    const isNotMemberCourseRateFilled = watchMemberCourse.find(
      (item) => !item.rate
    )

    if (isNotMemberCourseRateFilled && !watchDefaultRate) {
      message.error(t('teacher:default_rate_and_member_course'))
      return
    }

    onSubmit(value)
  })

  const { append, fields } = useFieldArray({
    control,
    name: 'accessModule.revenue.memberCourse',
  })

  const memberCourseList = useMemo(() => {
    if (!packages?.length) return []
    const filteredPublicPackages = packages.filter((item) => item.isActive)
    return filteredPublicPackages.map((item) => ({
      packageId: item?.id ?? null,
      name: item?.name ?? '',
      rate:
        selectedTeacherPackage?.accessModule?.revenue?.memberCourse?.find(
          (i) => i.packageId === item.id
        )?.rate ?? null,
    }))
  }, [packages, selectedTeacherPackage])

  const onSelectVideoMedia = (data: IMediaItem) => {
    setValue('mediaId', data.id)
    setSelectedImage(data.url ?? data.baseUrl ?? '')
  }

  useEffect(() => {
    if (!memberCourseList.length || selectedPackageId) return
    setValue(
      'accessModule.revenue.memberCourse',
      memberCourseList.map((item) => ({
        packageId: item?.packageId,
        rate: item?.rate,
        name: item?.name,
      }))
    )
  }, [append, memberCourseList, selectedPackageId, setValue])

  useEffect(() => {
    if (!selectedTeacherPackage) return

    const teacherPackage = JSON.parse(
      JSON.stringify(selectedTeacherPackage)
    ) as TTeacherPackage
    if (teacherPackage?.accessModule?.revenue?.memberCourse) {
      teacherPackage.accessModule.revenue.memberCourse = memberCourseList
    }

    reset({
      ...teacherPackage,
      textColor: selectedTeacherPackage?.textColor ?? '',
      backgroundColor: selectedTeacherPackage?.backgroundColor ?? '',
    })

    setValue(
      'accessModule.course.isPublicMemberLimit',
      (selectedTeacherPackage?.accessModule?.course?.memberLimit ?? 0) > 0
    )
    setValue(
      'accessModule.course.isPublicPremiumLimit',
      (selectedTeacherPackage?.accessModule?.course?.premiumLimit ?? 0) > 0
    )
    setSelectedImage(selectedTeacherPackage?.media?.url ?? '')
  }, [selectedTeacherPackage, memberCourseList, reset, setValue])

  return (
    <>
      <SelectMediaModal
        isOpen={showModalSelectMedia}
        onClose={() => setShowModalSelectMedia(false)}
        mediaType={EMediaType.IMAGE}
        category={EMediaCategory.PACKAGE}
        onSelectMedia={onSelectVideoMedia}
      />
      {isFetchingListPublicPackage || isGetTeacherPackageDetailsLoading ? (
        <div className="w-full min-h-[85dvh] min-w-[80dvw]">
          <Skeleton paragraph={{ rows: 6 }} />
        </div>
      ) : (
        <CEPackageFormWrapper onSubmit={handleSubmitForm}>
          <div className="flex flex-start gap-8">
            <FormInputWrapper className="!min-w-[50%] !max-w-[50%]">
              <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Name"
                      value={value}
                      onChange={onChange}
                      label="Name"
                      alignment="col"
                      errors={error?.message}
                      className={InputClassName}
                      required
                    />
                  )
                }}
              />
              <Controller
                name="priceUsd"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Price (USD)"
                      placeholder="price in USD"
                      name="priceUsd"
                      type="number"
                      alignment="col"
                      onChange={(e) => {
                        e.target.value
                          ? onChange(parseFloat(e.target.value))
                          : onChange(0)
                      }}
                      value={value}
                      errors={error?.message}
                      step={0.01}
                      required
                    />
                  )
                }}
              />
              <>
                <Controller
                  name={'mediaId'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <>
                        <CustomDragger
                          label="Image"
                          containerClassName="w-full"
                          labelClassName="!w-full"
                          onLoadEnd={(data) => {
                            onChange(data?.id ?? 0)
                            setValue('mediaId', data?.id ?? 0)
                            // setIsChange(true)
                          }}
                          name="mediaId"
                          id="mediaId"
                          errors={error?.message}
                          multiple={false}
                          initResource={selectedImage}
                          //   reset={reset}
                          changeLoading={setIsModalLoading}
                          allowFileTypes={[
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                          ]}
                          limitFileSize={5}
                          uploadType={EMediaType.IMAGE as any}
                          uploadCategory={EMediaCategory.PACKAGE}
                          alignment="col"
                          required
                        />
                      </>
                    )
                  }}
                />
                <SelectMedia
                  onClickSelectMedia={() => {
                    setShowModalSelectMedia(true)
                  }}
                  isNotGrid={true}
                />
              </>
              <AccessModuleWrapper>
                <Text className="!text-[20px] text-neutral-600">
                  * Access module:
                </Text>
                <div className="flex flex-col gap-4">
                  {/* number of public course */}
                  <Text className="!text-[20px] mb-4 font-bold">Course</Text>
                  <span className="flex gap-4">
                    <Controller
                      name="accessModule.course.isPublicPremiumLimit"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Checkbox
                            name="accessModule.course.isPublicPremiumLimit"
                            onChange={(e) => {
                              onChange(e.target.checked)
                            }}
                            checked={value}
                            className="!w-fit !h-fit !font-normal"
                          />
                        )
                      }}
                    />
                    <Controller
                      name="accessModule.course.premiumLimit"
                      control={control}
                      disabled={!watchIsPublicPremiumCourse}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <span className="w-max flex gap-1">
                            <Text>Public: </Text>
                            <Input
                              name="accessModule.course.premiumLimit"
                              placeholder=""
                              containerClassName="input-container-border-bottom"
                              inputFieldContainerClassName="input-field-container-border-bottom"
                              errorClassName="!block"
                              className={`input-border-bottom`}
                              inputStyledClassName="!w-max"
                              value={value ?? undefined}
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              type="number"
                              label="Premium Course"
                              errors={error?.message}
                              disabled={!watchIsPublicPremiumCourse}
                            />
                          </span>
                        )
                      }}
                    />
                  </span>
                  <span className="flex gap-4">
                    <Controller
                      name="accessModule.course.isPublicMemberLimit"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Checkbox
                            name="accessModule.course.isPublicMemberLimit"
                            onChange={(e) => {
                              onChange(e.target.checked)
                            }}
                            checked={value}
                            className="!w-fit !h-fit !font-normal"
                          />
                        )
                      }}
                    />
                    <Controller
                      name="accessModule.course.memberLimit"
                      control={control}
                      disabled={!watchIsPublicMemberCourse}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <span className="w-max flex gap-1">
                            <Text>Public: </Text>
                            <Input
                              name="accessModule.course.memberLimit"
                              placeholder=""
                              containerClassName="input-container-border-bottom"
                              inputFieldContainerClassName="input-field-container-border-bottom"
                              className={`input-border-bottom`}
                              errorClassName="!block"
                              inputStyledClassName="!w-max"
                              value={value ?? undefined}
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              type="number"
                              label="Member Course"
                              errors={error?.message}
                              disabled={!watchIsPublicMemberCourse}
                            />
                          </span>
                        )
                      }}
                    />
                  </span>
                  <Text className="!text-[20px] mt-4 font-bold">Revenue</Text>

                  {/* Member course */}
                  <div className="flex flex-col gap-4 ">
                    <Text className="!text-[16px] mt-2 font-semibold !text-orange-500 underline">
                      Member course
                    </Text>
                    {/* default rate */}
                    <div className="flex items-center gap-5">
                      <Controller
                        name="accessModule.revenue.defaultRate"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <span className="w-max flex gap-1">
                              <Text>Default rate: </Text>
                              <Input
                                containerClassName="input-container-border-bottom"
                                inputFieldContainerClassName="input-field-container-border-bottom"
                                className={`input-border-bottom`}
                                errorClassName="!block"
                                inputStyledClassName="!w-max"
                                value={value ?? undefined}
                                onChange={(e) => {
                                  e.target.value
                                    ? onChange(parseFloat(e.target.value))
                                    : onChange(0)
                                }}
                                type="number"
                                label="%"
                                errors={error?.message}
                              />
                            </span>
                          )
                        }}
                      />
                      <Tooltip
                        title={t('teacher:default_rate_tooltips')}
                        className="-ml-[30%]"
                      >
                        <ExclamationCircleOutlined className="w-6 h-6" />
                      </Tooltip>
                    </div>

                    {/* from api */}
                    {fields?.map((item, index) => (
                      <Controller
                        key={item?.packageId}
                        name={`accessModule.revenue.memberCourse.${index}.rate`}
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <span className="w-max flex gap-1">
                              <Text>{`Member course revenue commison ${
                                memberCourseList?.find(
                                  (course) =>
                                    item?.packageId === course?.packageId
                                )?.name ?? ''
                              }:`}</Text>
                              <Input
                                containerClassName="input-container-border-bottom"
                                inputFieldContainerClassName="input-field-container-border-bottom"
                                className={`input-border-bottom`}
                                errorClassName="!block"
                                inputStyledClassName="!w-max"
                                value={!!value ? value : undefined}
                                onChange={(e) => {
                                  e.target.value
                                    ? onChange(parseFloat(e.target.value))
                                    : onChange(0)
                                }}
                                type="number"
                                label="%"
                                errors={error?.message}
                              />
                            </span>
                          )
                        }}
                      />
                    ))}
                  </div>
                  {/* platium course */}
                  <div className="flex flex-col gap-4">
                    <Text className="!text-[16px] mt-2 font-semibold !text-yellow-500 underline">
                      Platium course
                    </Text>
                    <Controller
                      name="accessModule.revenue.premiumRate"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <span className="w-max flex gap-1">
                            <Text>Premium course share ratio: </Text>
                            <Input
                              containerClassName="input-container-border-bottom"
                              inputFieldContainerClassName="input-field-container-border-bottom"
                              className={`input-border-bottom`}
                              errorClassName="!block"
                              inputStyledClassName="!w-max"
                              value={!!value ? value : undefined}
                              onChange={(e) => {
                                e.target.value
                                  ? onChange(parseFloat(e.target.value))
                                  : onChange(0)
                              }}
                              type="number"
                              label="%"
                              errors={error?.message}
                            />
                          </span>
                        )
                      }}
                    />
                  </div>
                </div>
              </AccessModuleWrapper>
              <Controller
                name="isActive"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <span className="w-max flex gap-2">
                      <Text className="!text-[16px] font-semibold">Active</Text>
                      <SwitchButton
                        size="default"
                        onChange={onChange}
                        checked={value}
                      />
                    </span>
                  )
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper className="!w-full">
              <Controller
                name="nameInDutch"
                defaultValue=""
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Name in Netherlands"
                      value={value}
                      onChange={onChange}
                      label="Name in Netherlands"
                      alignment="col"
                      errors={error?.message}
                      className={InputClassName}
                      required
                    />
                  )
                }}
              />
              <Controller
                name="priceEur"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Price (EUR)"
                      placeholder="price in EUR"
                      name="priceUsd"
                      type="number"
                      alignment="col"
                      onChange={(e) => {
                        e.target.value
                          ? onChange(parseFloat(e.target.value))
                          : onChange(0)
                      }}
                      value={value}
                      errors={error?.message}
                      step={0.01}
                      required
                    />
                  )
                }}
              />
              <Controller
                name="textColor"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Text color"
                      name="textColor"
                      type="color"
                      onChange={(e) => {
                        onChange(e?.target?.value)
                      }}
                      value={value}
                      errors={error?.message}
                      isDisableShadow
                      alignment="col"
                    />
                  )
                }}
              />
            </FormInputWrapper>
          </div>

          <ButtonWrapper>
            <Button
              type="ghost"
              size="middle"
              className="submit__btn login-btn"
              onClick={onClose}
            >
              {'Cancel'}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="middle"
              className="submit__btn login-btn"
              loading={isModalLoading || isCreateEditTeacherPackageLoading}
              disabled={isModalLoading || isCreateEditTeacherPackageLoading}
            >
              {'Confirm'}
            </Button>
          </ButtonWrapper>
        </CEPackageFormWrapper>
      )}
    </>
  )
}

export default CreateEditPackageForm

const CEPackageFormWrapper = styled.form`
  width: 100%;
  min-width: 80dvw;
  max-height: 85dvh;
  padding: 16px 0px;
`

const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  .ant-typography {
    line-height: inherit;
  }

  .input-container-border-bottom {
    width: max-content !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    line-height: 0px;
  }

  .input-field-container-border-bottom {
    width: max-content !important;
    border-radius: 0 !important;
    background: none !important;
    border: none !important;
    border-bottom: 1px solid black !important;
    padding: 0 !important;
    line-height: 0px;
  }

  .input-border-bottom {
    max-width: 60px;
    background: none !important;
    border-radius: 0 !important;
    padding: 0px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    line-height: 0px;
  }

  .ant-input-status-error {
    border-bottom: 2px solid #ff4d4f !important;
  }
`
const AccessModuleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: flex-end;
  margin-top: 24px;
  padding-bottom: 24px;
`
