import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { t } from 'i18next'

import { BaseResponseProps, IRegenerateInvoiceResponse } from '@interfaces'
import {
  QK_GET_LIST_INVOICES,
  QK_GET_USER_PROFILE,
  QK_REGENERATE_INVOICE_BY_MONTH,
} from 'src/configs/queryClient/queryKeyConstants'
import { revenueAPI } from 'src/api/revenue'
import { message } from 'antd'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<IRegenerateInvoiceResponse>,
    any,
    any,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (value: IRegenerateInvoiceResponse) => void
  handleError?: (err: any) => void
}

export const useRegenerateInvoiceMutation = (configs?: IMutationParams) => {
  const { handleError, handleSuccess } = { ...configs }

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QK_REGENERATE_INVOICE_BY_MONTH],
    mutationFn: (time: string) => revenueAPI.regenerateInvoiceByMonth(time),
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
      handleError?.(error)
    },
    onSuccess(res) {
      message.success(t('revenue:generate_invoice_successfully'))
      handleSuccess?.(res?.data)
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QK_GET_USER_PROFILE],
        type: 'all',
      })
    },

    ...configs,
  })
}
