import { useEffect } from 'react'
import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_DASHBOARD_OVERVIEW } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IDashboardOverview,
  IGetDashboardOverviewRequest,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardOverview {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<IDashboardOverview>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
  queryParams?: IGetDashboardOverviewRequest
}

export const useGetDashboardOverviewQuery = ({
  configs,
  queryParams,
}: IGetDashboardOverview) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_OVERVIEW],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_OVERVIEW],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardOverview() {
    return dashboardAPI.getDashboardOverview(queryParams)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IDashboardOverview>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_OVERVIEW],
    queryFn: getDashboardOverview,

    ...configs,
  })

  useEffect(() => {
    refetchData()
  }, [queryParams?.filterBy, queryParams?.startDate, queryParams?.endDate])

  return {
    data: data?.data ?? ({} as IDashboardOverview),
    invalidateData,
    refetchData,
    ...restData,
  }
}
