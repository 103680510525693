import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_DASHBOARD_ORDER_INCOME_DETAILS } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetOrderDetails,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardOrderIncomeDetails {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<IGetOrderDetails>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
  orderIncomeId: string | number
}

export const useGetDashboardOrderIncomeDetails = ({
  configs,
  orderIncomeId,
}: IGetDashboardOrderIncomeDetails) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_ORDER_INCOME_DETAILS, orderIncomeId],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_ORDER_INCOME_DETAILS, orderIncomeId],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardOrderIncomeDetails() {
    return dashboardAPI.getDashboardOrderIncomeDetails(orderIncomeId)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetOrderDetails>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_ORDER_INCOME_DETAILS, orderIncomeId],
    queryFn: getDashboardOrderIncomeDetails,

    ...configs,
  })

  return {
    data: data?.data ?? ({} as IGetOrderDetails),
    invalidateData,
    refetchData,
    ...restData,
  }
}
