import { z } from 'zod'
import { t } from 'i18next'

export const withdrawInputSchema = z.object({
  amount: z
    .union([z.string().transform((val) => Number(parseFloat(val))), z.number()])
    .refine((val) => Number(val) >= 10, {
      message: t('error:min_withdraw'),
    }),
})
