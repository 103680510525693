import { useMemo } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Typography } from 'antd'

import {
  doughnutChartChannelsConfig,
  doughnutChartChannelsData,
} from 'src/pages/dashboard/channels/overview-section/config'
import { themes } from '@theme'
import { IChannelOverview } from '@interfaces'
import { StyledEmpty } from '@components'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

ChartJS.register(ArcElement, Tooltip, Legend)

interface IProps {
  channelsOverview?: IChannelOverview
}

const Description = ({ channelsOverview }: IProps) => {
  const rootColor = themes.theme.dark.colors.miniDashboard

  const renderDescription = (
    title: string,
    bgColor: string,
    revenue: number,
    percentage: number
  ) => {
    return (
      <DescriptionItemStyled>
        <DescriptionSubItemStyled>
          <DotStyled $bgColor={bgColor} />
          <Typography.Text className="!text-[14px] !font-medium">
            {title}
          </Typography.Text>
        </DescriptionSubItemStyled>
        <DescriptionSubItemStyled>
          <Typography.Title level={3} className="!text-[14px] !m-0">
          €{formatCurrencyByType(revenue, 'EUR').replace('€', '')}
          </Typography.Title>
          <PercentageWrapper>{percentage}%</PercentageWrapper>
        </DescriptionSubItemStyled>
      </DescriptionItemStyled>
    )
  }

  return (
    <DescriptionWrapper>
      {renderDescription(
        t('dashboard:sold_course'),
        rootColor.darkPurple,
        channelsOverview?.sold?.revenue ?? 0,
        channelsOverview?.sold?.percentage ?? 0
      )}
      {renderDescription(
        t('dashboard:package_enroll'),
        rootColor.lightPurple,
        channelsOverview?.membership?.revenue ?? 0,
        channelsOverview?.membership?.percentage ?? 0
      )}
    </DescriptionWrapper>
  )
}

export const ChannelsOverviewSection = ({ channelsOverview }: IProps) => {
  const isEmptyData =
    !channelsOverview?.sold?.revenue && !channelsOverview?.membership?.revenue

  const chartData = useMemo(() => {
    const data = {
      ...doughnutChartChannelsData,
      datasets: [
        {
          ...doughnutChartChannelsData?.datasets[0],
          data: [
            channelsOverview?.sold?.revenue ?? 0,
            channelsOverview?.membership?.revenue ?? 0,
          ],
        },
      ],
    }
    return data
  }, [channelsOverview?.membership?.revenue, channelsOverview?.sold?.revenue])

  return (
    <ChannelsOverviewSectionStyled>
      <Typography.Title level={2} className="!text-[24px]">
        {t('dashboard:channels')}
      </Typography.Title>
      <div className="flex flex-col mt-8 gap-8 items-center w-full">
        {!isEmptyData ? (
          <Doughnut data={chartData} options={doughnutChartChannelsConfig} />
        ) : (
          <StyledEmpty />
        )}
        <Description channelsOverview={channelsOverview} />
      </div>
    </ChannelsOverviewSectionStyled>
  )
}

const ChannelsOverviewSectionStyled = styled.div`
  width: 25%;
  padding: 12px;
`

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`
const DotStyled = styled.div<{ $bgColor?: string }>`
  width: 14px;
  height: 14px;
  background-color: ${(p) => p.$bgColor || `${themes.theme.light.colors.body}`};
  border-radius: 999px;
`

const DescriptionItemStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const DescriptionSubItemStyled = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`

const PercentageWrapper = styled.span`
  width: fit-content;
  height: fit-content;
  min-width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
  background-color: ${themes.theme.dark.colors.miniDashboard
    .lightYellowSecondary};
  color: ${themes.theme.dark.colors.miniDashboard.darkYellowSecondary};
`
