import { ShareSelectInput } from '@components'
import { ISubscription, TPaymentInfo } from '@interfaces'
import { Divider, Typography } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/common'
import styled from 'styled-components'
import UpgradePlanModal from './UpgradePlanModal'
import ChangeCardModal from './ChangeCard'
import { useGetTeacherPaymentCardsQuery } from 'src/api/react-query/hooks/subscription/useGetTeacherPaymentCards'

interface IProps {
  initialPackage: ISubscription | null
  packagesForTeacher: ISubscription[]
  onCancelUpgrade: () => void
}

interface IOption {
  value: string | number
  label: string
}

export default function UpgradePlanForm({
  initialPackage,
  packagesForTeacher,
  onCancelUpgrade,
}: IProps) {
  const { t } = useTranslation(['profile', 'common'])
  const [selectedPackage, setSelectedPackage] = useState<ISubscription | null>(
    initialPackage
  )
  const [selectedCard, setSelectedCard] = useState<TPaymentInfo | null>(null)
  const [isOpenUpgradePlanModal, setIsOpenUpgradePlanModal] = useState(false)
  const [isOpenChangeCardModal, setIsOpenChangeCardModal] = useState(false)

  const {
    data: paymentCards,
    isLoading: isLoadingPaymentCards,
    isFetching: isFetchingPaymentCards,
  } = useGetTeacherPaymentCardsQuery()

  const packageOptions = useMemo(() => {
    if (!Array.isArray(packagesForTeacher) || !packagesForTeacher?.length)
      return []

    return packagesForTeacher
      .map((it: ISubscription) => {
        if (it?.isSubscribed) return null

        return {
          value: it?.id,
          label: it?.name,
        }
      })
      .filter(Boolean)
  }, [packagesForTeacher])

  const currentPackage = useMemo(() => {
    return packagesForTeacher?.find(
      (it) => !!it?.isSubscribed && !!it?.packageSubscribed?.isActive
    )
  }, [packagesForTeacher])

  const defaultCard = useMemo(
    () => paymentCards?.data?.find((it) => !!it?.isDefault),
    [paymentCards?.data]
  )

  const cardInfo = !!Object?.keys(selectedCard || {})?.length
    ? selectedCard
    : defaultCard

  const { brand = '', last4 = '' } = cardInfo?.paymentMethod?.card || {}

  return (
    <>
      <UpgradePlanFormContainer>
        <div className="w-[70%]">
          <Typography.Title
            style={{ marginBottom: 20, fontSize: 20, fontWeight: 'bold' }}
          >
            {t('profile:upgradePlan')}
          </Typography.Title>

          <ShareSelectInput
            data={packageOptions as IOption[]}
            value={selectedPackage?.id}
            onChange={(value) => {
              const chosenPackage = packagesForTeacher?.find(
                (it) => String(it?.id) === String(value)
              )
              setSelectedPackage(chosenPackage as ISubscription)
            }}
            showArrow
            size={'small' as SizeType}
          />

          <Divider className="!mt-12 !mb-2" />

          <div className="flex justify-between items-center">
            <Typography.Title
              style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}
            >
              {t('profile:subtotal')}
            </Typography.Title>

            <Typography.Title
              style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}
            >
              {!!selectedPackage?.priceUsd
                ? `$${selectedPackage?.priceUsd} / ${t('profile:month')}`
                : ''}
            </Typography.Title>
          </div>

          <Divider className="!my-2" />

          <div className="flex justify-between items-center">
            <Typography.Title
              style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}
            >
              {t('profile:cardDetail')}:
            </Typography.Title>

            <Typography.Title
              style={{ margin: 0, fontSize: 16, fontWeight: 'bold' }}
            >
              {`${brand?.toUpperCase()}  ${
                last4 ? `**** **** **** ${last4}` : ''
              }`}
            </Typography.Title>

            <Typography.Title
              style={{
                margin: 0,
                fontSize: 16,
                fontWeight: 'bold',
                color: '#FF765AFF',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpenChangeCardModal(true)}
            >
              {t('profile:changeCard')}
            </Typography.Title>
          </div>

          <div className="flex flex-col justify-center items-center my-2 mt-10 gap-4">
            <Button
              type="primary"
              style={{
                width: '100%',
                maxWidth: 250,
                borderRadius: '100rem',
                background: '#535CE8FF',
              }}
              onClick={() => setIsOpenUpgradePlanModal(true)}
              disabled={!cardInfo?.paymentMethod?.id}
            >
              {t('common:upgrade')}
            </Button>

            <Button
              type="text"
              style={{
                boxShadow: 'none',
                width: '100%',
                maxWidth: 250,
                borderRadius: '100rem',
                background: 'transparent',
              }}
              onClick={onCancelUpgrade}
            >
              <Typography.Text>{t('common:cancel')}</Typography.Text>
            </Button>
          </div>
        </div>
      </UpgradePlanFormContainer>

      {isOpenUpgradePlanModal ? (
        <UpgradePlanModal
          open
          onClose={() => {
            setIsOpenUpgradePlanModal(false)
          }}
          onBackToListPackage={onCancelUpgrade}
          newPlan={selectedPackage as ISubscription}
          currentPlan={currentPackage as ISubscription}
          cardInformation={cardInfo as TPaymentInfo}
        />
      ) : null}

      {isOpenChangeCardModal ? (
        <ChangeCardModal
          open
          onClose={() => setIsOpenChangeCardModal(false)}
          listPaymentCards={paymentCards?.data as TPaymentInfo[]}
          onSelectCard={(cardInfo) => setSelectedCard(cardInfo)}
          isLoadingData={isLoadingPaymentCards || isFetchingPaymentCards}
          cardInfo={cardInfo as TPaymentInfo}
        />
      ) : null}
    </>
  )
}

const UpgradePlanFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
