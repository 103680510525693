import { ReactNode, useMemo, useState } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'
import { capitalize } from 'lodash'

import { HistoryLogModal } from './history-log-modal/HistoryLogModal'
import { themes } from '@theme'
import { IInvoiceItem } from '@interfaces'
import {
  EBalanceStatus,
  EBalanceType,
} from 'src/pages/withdrawal/balance-table/configs'
import { formatDate } from '@utils'
import {
  FORMAT_DATE_DASHBOARD_COURSE_TABLE,
  FORMAT_TIME_WITH_GMT,
} from '@configs'

export const OverviewSection = ({
  invoiceItem,
}: {
  invoiceItem: IInvoiceItem
}) => {
  const type = invoiceItem?.invoiceType
  const [openHistoryLogModal, setOpenHistoryLogModal] = useState(false)

  const titleContentList: Record<string, ReactNode | null> = useMemo(
    () => ({
      [t('revenue:order_id')]: invoiceItem?.orderId,
      [t('revenue:status')]: (
        <StatusTitleStyled
          $textColor={
            [EBalanceStatus.FAIL, EBalanceStatus.PAYOUT_FAIL].includes(
              invoiceItem?.status
            )
              ? `${themes.theme.light.colors.delete}`
              : [EBalanceStatus.PENDING].includes(invoiceItem?.status)
              ? `${themes.theme.light.colors.warning}`
              : `${themes.theme.light.colors.success}`
          }
        >
          {capitalize(invoiceItem?.status?.replace('_', ' '))}
        </StatusTitleStyled>
      ),
      [t('revenue:type')]: [EBalanceType.REVENUE_WITHDRAW].includes(type) ? (
        <TypeStyled
          $bgColor={themes.theme.light.colors.bgTagRed}
          $textColor={themes.theme.light.colors.delete}
        >
          {capitalize(type?.replace('_', ' '))}
        </TypeStyled>
      ) : (
        <TypeStyled
          $bgColor={themes.theme.light.colors.balanceOverviewBg}
          $textColor={themes.theme.light.colors.note}
        >
          {capitalize(type?.replace('_', ' '))}
        </TypeStyled>
      ),
      [t('revenue:date_of_issue')]: formatDate(
        invoiceItem?.createdAt,
        FORMAT_DATE_DASHBOARD_COURSE_TABLE
      ),
      [t('revenue:time')]: !!invoiceItem?.updatedAt
        ? formatDate(invoiceItem?.updatedAt, FORMAT_TIME_WITH_GMT)
        : '',
      [t('revenue:method')]: [EBalanceType.REVENUE_WITHDRAW].includes(type)
        ? t('revenue:method_content', {
            bankName:
              invoiceItem?.invoiceDetail?.withdrawalDetail?.bankName ?? '',
            lastNumber:
              invoiceItem?.invoiceDetail?.withdrawalDetail
                ?.lastFourBankNumber ?? '',
          })
        : null,
    }),
    [
      invoiceItem?.orderId,
      invoiceItem?.status,
      invoiceItem?.createdAt,
      invoiceItem?.updatedAt,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.bankName,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.lastFourBankNumber,
      type,
    ]
  )

  const titleRendering = useMemo(() => {
    return (
      <TextWrapper className="col-span-1">
        {Object.entries(titleContentList).map(([key, value]) =>
          value ? (
            <Typography.Title key={key} level={4} className="!m-0 !text-[21px]">
              {key}
            </Typography.Title>
          ) : null
        )}
      </TextWrapper>
    )
  }, [titleContentList])

  const handleViewHistoryLog = () => {
    setOpenHistoryLogModal(true)
  }

  const contentRendering = useMemo(() => {
    return (
      <TextWrapper className="col-span-3">
        {Object.entries(titleContentList).map(([key, value]) =>
          value ? (
            <Typography.Title key={key} level={4} className="!m-0 !text-[21px]">
              {value}
            </Typography.Title>
          ) : null
        )}
      </TextWrapper>
    )
  }, [titleContentList])

  return (
    <>
      {openHistoryLogModal ? (
        <HistoryLogModal
          open
          onClose={() => setOpenHistoryLogModal(false)}
          invoiceId={invoiceItem?.id}
        />
      ) : null}
      <OverviewSectionStyled>
        <Typography.Title level={2} className="!text-[36px]">
          {t('revenue:revenue')}
        </Typography.Title>
        <OverviewContentWrapper>
          {titleRendering}
          {contentRendering}
        </OverviewContentWrapper>
        <Typography.Title level={2} className="!mb-0 !mt-3 !text-[21px]">
          EUR {invoiceItem?.amount ?? 0}{' '}
          {[EBalanceType.REVENUE_WITHDRAW].includes(type)
            ? t('revenue:withdrawal')
            : t('revenue:deposit')}{' '}
          on{' '}
          {formatDate(
            invoiceItem?.createdAt,
            FORMAT_DATE_DASHBOARD_COURSE_TABLE
          )}
        </Typography.Title>
        {[EBalanceType.REVENUE_WITHDRAW].includes(invoiceItem?.invoiceType) &&
        ![EBalanceStatus.PENDING].includes(invoiceItem?.status) ? (
          <Typography.Paragraph
            className="!mb-0 !text-[14px] !text-blue-500 cursor-pointer underline !text-right"
            onClick={handleViewHistoryLog}
          >
            {t('revenue:view_history_log')}
          </Typography.Paragraph>
        ) : null}
      </OverviewSectionStyled>
    </>
  )
}

const OverviewSectionStyled = styled.div`
  width: 100%;
  padding: 18px 22px;
  border-radius: 4px;
  border: 1px solid ${themes.theme.light.colors.bgPage};
  background: ${themes.theme.light.colors.lightGray};
`
const OverviewContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 24px;
`
const TextWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const TypeStyled = styled.span<{
  $bgColor?: string
  $textColor?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  font-weight: 400;
  font-size: 12px;
  padding: 6px 8px;
  border-radius: 14px;
  background-color: ${(p) => p.$bgColor || ''};
  color: ${(p) => p.$textColor || ''};
`
const StatusTitleStyled = styled.h3<{ $textColor?: string }>`
  margin-bottom: 0 !important;
  font-size: 18px !important;
  color: ${(p) => p.$textColor || ''} !important;
`
