import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { QK_GET_DASHBOARD_LIST_COURSE_ORDER } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetTeacherCoursesDashboardParams,
  IGetDashboardListCourseOrder,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardCourseTable {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetDashboardListCourseOrder>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
  queryParams: IGetTeacherCoursesDashboardParams
}

export const useGetDashboardListOrderQuery = ({
  configs,
  queryParams,
}: IGetDashboardCourseTable) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardListCourseOrders() {
    return dashboardAPI.getDashboardListCourseOrders(queryParams)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetDashboardListCourseOrder>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER],
    queryFn: getDashboardListCourseOrders,

    ...configs,
  })

  useDeepCompareEffect(() => {
    refetchData()
  }, [queryParams])

  return {
    data: data?.data ?? ({} as IGetDashboardListCourseOrder),
    invalidateData,
    refetchData,
    ...restData,
  }
}
