import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import {
  DEFAULT_LIMIT_ITEMS,
  DEFAULT_REACT_QUERY_STALE_TIME,
  INITIAL_PAGE,
  QK_GET_LIST_NOTIFICATIONS,
} from '@configs'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetNotificationListRequestParam,
  INotificationListResponse,
} from '@interfaces'
import { notificationAPI } from 'src/api/notification'
import { useDebounceFunction } from 'src/hooks'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<INotificationListResponse>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
  params?: IGetNotificationListRequestParam
}

export const useGetListNotifications = (queryProps?: IQueryProps) => {
  const {
    configs,
    params = {
      page: INITIAL_PAGE,
      limit: DEFAULT_LIMIT_ITEMS,
    },
  } = queryProps || {}

  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_LIST_NOTIFICATIONS],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    if (!!restData?.isFetching) return

    queryClient.refetchQueries({
      queryKey: [QK_GET_LIST_NOTIFICATIONS],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getListNotifications() {
    return notificationAPI.getListNotifications(params)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<INotificationListResponse>,
    BaseResponseError
  >({
    queryKey: [QK_GET_LIST_NOTIFICATIONS],
    queryFn: getListNotifications,
    staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
    ...configs,
  })

  useDeepCompareEffect(() => {
    refetchData()
  }, [params])

  return {
    data: data?.data ?? ({} as INotificationListResponse),
    invalidateData,
    refetchData,
    ...restData,
  }
}
