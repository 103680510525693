import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { BaseResponseProps, IVerifyWithdrawResponse } from '@interfaces'
import {
  QK_GET_LIST_INVOICES,
  QK_VERIFY_WITHDRAW,
} from 'src/configs/queryClient/queryKeyConstants'
import { revenueAPI } from 'src/api/revenue'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<IVerifyWithdrawResponse>,
    any,
    any,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (value: IVerifyWithdrawResponse) => void
  handleError?: (err: any) => void
}

export const useVerifyWithdrawMutation = (configs?: IMutationParams) => {
  const { handleError, handleSuccess } = { ...configs }

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QK_VERIFY_WITHDRAW],
    mutationFn: (params: { hash: string; otpCode: string }) =>
      revenueAPI.verifyWithdraw(params),
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
      handleError?.(error)
    },
    onSuccess(res) {
      handleSuccess?.(res?.data)
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
    },

    ...configs,
  })
}
