import { lessonAPI } from '@api'
import { zodResolver } from '@hookform/resolvers/zod'
import { IAssignment } from '@interfaces'
import { message, Skeleton } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { AppModal } from 'src/components/AppModal'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { XCloseIcon } from 'src/components/Icon'
import { ELessonType } from 'src/interfaces/course'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import AssignmentUploadButton from 'src/pages/course-management/components/AssignmentUploadButton'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onSave: () => void
  assignmentId?: number
  selectSection?: number
  isActive?: boolean
  type?: ELessonType.AUDIO | ELessonType.VIDEO
}

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })

    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })

    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  content: z.string().trim().max(1000, {
    message: 'Content must be less than 1000 characters long',
  }),
  contentInDutch: z.string().trim().max(1000, {
    message: 'Content must be less than 1000 characters long',
  }),
  mediaId: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(1, {
      message: t('error:field_required') as string,
    }),

  attachmentIds: z.array(z.number()).optional(),
  restrictTimer: z.number().nullable(),
  thumbnailId: z
    .number({
      required_error: t('error:field_required') as string,
    })
    .min(1, {
      message: t('error:field_required') as string,
    }),
})
const ModalMedia = ({
  open,
  onClose,
  assignmentId,
  selectSection,
  onSave,
  type,
  isActive = true,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      title: '',
      content: '',
      titleInDutch: '',
      contentInDutch: '',
      mediaId: 0,
      thumbnailId: 0,
      restrictTimer: 0,
      attachmentIds: [],
    },
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const navigate = useNavigate()

  const [videoSrc, setVideoSrc] = useState('')

  const [imageSrc, setImageSrc] = useState('')
  const [showModalSelectVideoMedia, setShowModalSelectVideoMedia] =
    useState(false)
  const [showModalSelectImageMedia, setShowModalSelectImageMedia] =
    useState(false)
  const onCloseModalSelectImageMedia = () => {
    setShowModalSelectImageMedia(false)
  }
  const [aborted, setAborted] = useState(false)
  const onSelectImageMedia = (data: IMediaItem) => {
    setImageSrc(data?.baseUrl ?? '')
    setValue('thumbnailId', data?.id ?? 0)
    setShowModalSelectImageMedia(false)
  }
  const onCloseModalSelectVideoMedia = () => {
    setShowModalSelectVideoMedia(false)
  }
  const fetchLesson = async () => {
    if (!assignmentId) {
      return
    }
    setIsFetching(true)
    try {
      const response = await lessonAPI.getAssignment(assignmentId)
      if (response?.success) {
        const { data } = response
        setValue('id', data?.id || '')
        setValue('title', data?.title || '')
        setValue('content', data?.content || '')
        setValue('titleInDutch', data?.titleInDutch || '')
        setValue('thumbnailId', data?.thumbnailId || '')
        setImageSrc(data?.thumbnail?.url || '')
        setVideoSrc(data?.media?.url || '')
        setValue('mediaId', data?.mediaId || '')
        setValue('contentInDutch', data?.contentInDutch || '')
        setValue(
          'attachments',
          data?.lessonAttachment.map((data?) => data?.attachment) || []
        )
        setValue('restrictTimer', data?.restrictTimer)
      }
    } catch (error) {
      message.error(t('error:error_connect_server'))
    } finally {
      setIsFetching(false)
    }
  }
  const onSubmit = async (payload: IAssignment) => {
    setIsLoading(true)
    if (!selectSection) {
      setIsLoading(false)
      return
    }
    if (payload.type === ELessonType.ASSIGNMENT_EXERCISE) {
      delete payload.questions
      delete payload.restrictTimer
    }
    try {
      if (!assignmentId) {
        payload.sectionId = +selectSection
        const response = await lessonAPI.createMediaLesson({
          ...payload,
          type: type === ELessonType.AUDIO ? 'AUDIO' : 'VIDEO',
        })

        message.success(response.message)
      } else {
        const response = await lessonAPI.updateMediaLesson(assignmentId, {
          ...payload,
          type: type === ELessonType.AUDIO ? 'AUDIO' : 'VIDEO',
        })
        message.success(response.message)
      }
    } catch (error) {
      message.error(t('error:error_connect_server'))
    } finally {
      setIsLoading(false)
      onSave()
      reset()
      onClose()
    }
  }
  const onSelectVideoMedia = (data: IMediaItem) => {
    setVideoSrc(data?.baseUrl ?? '')
    setValue('mediaId', data?.id ?? 0)
    setShowModalSelectVideoMedia(false)
  }

  useEffect(() => {
    if (assignmentId) {
      return
    }
    setValue('attachmentIds', [])
    return () => {
      reset()
    }
  }, [open, setValue, reset, assignmentId])

  useEffect(() => {
    if (open) {
      fetchLesson()
    }
  }, [assignmentId, open])

  return (
    <>
      <TextAreaStyled>
        <AppModal
          open={open}
          haveCloseIcon={false}
          onClose={() => {
            if (isLoading) return
            onClose()
          }}
          title={
            <div className="flex items-center justify-between  w-full">
            <div>
              <h1 className="m-0 text-[20px]">
                {assignmentId && isActive
                  ? `Edit ${
                      type === ELessonType.AUDIO ? 'audio' : 'video'
                    } lesson`
                  : !isActive
                  ? `Detail ${
                      type === ELessonType.AUDIO ? 'audio' : 'video'
                    } lesson`
                  : `${
                      type === ELessonType.AUDIO
                        ? 'Add new audio'
                        : 'Add new video'
                    }`}
              </h1>
            </div>
            <div className="hover:opacity-75 cursor-pointer">
              {!isLoading && (
                <XCloseIcon width={16} height={16} onClick={onClose} />
              )}
            </div>
          </div>
          }
        >
          
          {isFetching ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <div className="mt-6 w-[1200px]">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row  gap-5">
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { value, onChange, ...rest },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          alignment="col"
                          label={t('common:title')}
                          name="title"
                          required
                          className="input"
                          value={value}
                          type="text"
                          onChange={onChange}
                          errors={error?.message}
                          disabled={!isActive}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="titleInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          alignment="col"
                          label={t('common:title_in_nl')}
                          name="titleInDutch"
                          required
                          className="input"
                          value={value}
                          type="text"
                          onChange={onChange}
                          errors={error?.message}
                          disabled={!isActive}
                        />
                      )
                    }}
                  />
                </div>

                <div className="flex flex-row  gap-5">
                  <Controller
                    name="content"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:content')}
                          onChange={onChange}
                          value={value}
                          name="content"
                          style={{
                            marginTop: 10,
                            width: '100%',
                            minHeight: '200px',
                          }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          autoSize={{ minRows: 8, maxRows: 8 }}
                          disabled={!isActive}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="contentInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextArea
                          label={t('common:content_in_nl')}
                          onChange={onChange}
                          value={value}
                          name="contentInDutch"
                          style={{ marginTop: 10, width: '100%' }}
                          errors={error?.message}
                          containerClassName="mt-[24px]"
                          labelClassName="mb-[16px] inline-block"
                          disabled={!isActive}
                          autoSize={{ minRows: 8, maxRows: 8 }}
                        />
                      )
                    }}
                  />
                </div>

                <div className="form-section mt-5">
                  <span className="text-[16px]  mr-6">Attachments:</span>
                  <AssignmentUploadButton
                    control={control}
                    setValue={setValue}
                    disabled={!isActive}
                  />
                </div>

                <div className=" mt-5 flex flex-row  gap-5">
                  <div className="w-full">
                    <label className="input__label  mb-[16px] inline-block">
                      Thumbnail
                      <span className="required text-[#B91C1C] font-bold">
                        *
                      </span>
                    </label>
                    <Controller
                      name={'thumbnailId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <CustomDragger
                              // label="Image"
                              containerClassName="mt-[10px]"
                              onLoadEnd={(data) => {
                                setValue('thumbnailId', data?.id ?? 0)
                                onChange(data?.id ?? 0)
                                // setIsChange(true)
                              }}
                              name="thumbnailId"
                              id="thumbnailId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={imageSrc}
                              changeLoading={(loading) => setIsLoading(loading)}
                              allowFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/jpg',
                                'image/*',
                              ]}
                              disabled={!isActive}
                              limitFileSize={500}
                              uploadType={EMediaType.IMAGE as any}
                              uploadCategory={EMediaCategory.COURSE}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <label className="input__label  mb-[16px] inline-block">
                      {type === ELessonType.AUDIO ? 'Audio' : 'Video'}
                      <span className="required text-[#B91C1C] font-bold">
                        *
                      </span>
                    </label>
                    <Controller
                      name={'mediaId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CustomDragger
                            containerClassName="mt-[10px]"
                            onLoadEnd={(data) => {
                              setValue('mediaId', data?.dataValues.id)
                              onChange(data?.dataValues.id)
                              // setIsChange(true)
                            }}
                            aborted={aborted}
                            onSetDuration={(duration) => {
                              setValue('restrictTimer', duration)
                            }}
                            disabled={!isActive}
                            id="mediaId"
                            name="mediaId"
                            required
                            errors={error?.message}
                            multiple={false}
                            initResource={videoSrc}
                            changeLoading={(loading) => setIsLoading(loading)}
                            limitFileSize={0}
                            allowFileTypes={
                              type === ELessonType.AUDIO
                                ? [
                                    'audio/mp3,audio/ogg,audio/WAV,audio/wav',
                                    'audio/mpeg3',
                                  ]
                                : [
                                    'video/mp4',
                                    'video/webm',
                                    'video/m4v',
                                    'video/mov',
                                    'video/qt',
                                  ]
                            }
                            uploadType={
                              type === ELessonType.AUDIO
                                ? EMediaType.AUDIO
                                : (EMediaType.VIDEO as any)
                            }
                            // uploadCategory={EMediaCategory.BLOG}
                          />
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="mt-10">
                  <div className="flex items-center mt-5 justify-center">
                    <Button
                      type="ghost"
                      size="middle"
                      className="submit__btn login-btn mr-10"
                      onClick={() => {
                        if (isLoading) {
                          // navigate(0)
                          setAborted(true)
                        }
                        onClose()
                        reset()
                      }}
                    >
                      {'Cancel'}
                    </Button>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="middle"
                      className="submit__btn login-btn"
                      loading={isLoading}
                      disabled={!isActive}
                    >
                      {'Save'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </AppModal>
      </TextAreaStyled>
    </>
  )
}

const TextAreaStyled = styled.div`
  .display-block {
    display: block !important;
  }
  .upload-video {
    display: none !important;
  }
`
export default ModalMedia
