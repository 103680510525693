import {
  IGetCreateWithdrawGenericInfor,
  IGetInvoiceListParams,
  IGetInvoiceListResponse,
  IHistoryLogItem,
  IInvoiceItem,
  IRegenerateInvoiceResponse,
  IVerifyWithdrawResponse,
} from '@interfaces'
import { ApiClient } from 'src/api/axiosClient'
import {
  ENDPOINT_CREATE_ACCOUNT_ONBOARD,
  ENDPOINT_GENERATE_REVENUE,
  ENDPOINT_GET_HISTORY_LOG,
  ENDPOINT_GET_INVOICES_DETAILS,
  ENDPOINT_GET_LIST_TEACHER_INVOICES,
  ENDPOINT_GET_WITHDRAW_INFORMATION,
  ENDPOINT_REDO_TRANSACTION,
  ENDPOINT_REGENERATE_INVOICE_BY_MONTH,
  ENDPOINT_VERIFY_WITHDRAW,
  ENDPOINT_WITHDRAW,
} from 'src/api/react-query'

export const revenueAPI = {
  getListInvoice: async (params?: IGetInvoiceListParams) => {
    return await ApiClient.get<IGetInvoiceListResponse>(
      ENDPOINT_GET_LIST_TEACHER_INVOICES,
      {
        params,
      }
    )
  },

  getInvoiceDetail: async (invoiceId: string | number) => {
    return await ApiClient.get<IInvoiceItem>(
      ENDPOINT_GET_INVOICES_DETAILS.replace('$id', `${invoiceId}`)
    )
  },

  getHistoryLogByInvoiceId: async (invoiceId: string | number) => {
    return await ApiClient.get<IHistoryLogItem[]>(
      ENDPOINT_GET_HISTORY_LOG.replace('$id', `${invoiceId}`)
    )
  },

  connectAccountOnboard: async () => {
    return await ApiClient.post<string>(ENDPOINT_CREATE_ACCOUNT_ONBOARD)
  },

  generateRevenue: async () => {
    return await ApiClient.post<any>(ENDPOINT_GENERATE_REVENUE)
  },

  regenerateInvoiceByMonth: async (time: string) => {
    return await ApiClient.post<IRegenerateInvoiceResponse>(
      ENDPOINT_REGENERATE_INVOICE_BY_MONTH,
      {
        time,
      }
    )
  },

  getWithdrawInfor: async () => {
    return await ApiClient.get<IGetCreateWithdrawGenericInfor>(
      ENDPOINT_GET_WITHDRAW_INFORMATION
    )
  },

  withdraw: async (data: { amount: number }) => {
    return await ApiClient.post<{ data: string; message: string }>(
      ENDPOINT_WITHDRAW,
      data
    )
  },

  verifyWithdraw: async (data: { hash: string; otpCode: string }) => {
    return await ApiClient.post<IVerifyWithdrawResponse>(
      ENDPOINT_VERIFY_WITHDRAW,
      data
    )
  },

  redoTransaction: async (id: string | number) => {
    return await ApiClient.post<{ message: string }>(
      ENDPOINT_REDO_TRANSACTION.replace('$id', `${id}`)
    )
  },
}
