import { ISubscription } from '@interfaces'
import React from 'react'
import { SubscriptionStyled } from '../styled'
import { PackageManagementForm } from './package-management'
import { PackagesSelection } from './PackagesSelection'

type TSubscriptionCheckoutProps = {
  selectedPackage?: ISubscription
  onSelectPackage?: (packageData?: ISubscription) => void
  packages?: Array<ISubscription>
  onConfirmPayment?: (card: any) => Promise<void>
  onChangeCard?: (card: any) => void
  cardInformation?: any
  isLoading?: boolean
}

export const SubscriptionCheckout: React.FC<TSubscriptionCheckoutProps> = ({
  packages,
  onSelectPackage,
  selectedPackage,
  onConfirmPayment,
  onChangeCard,
  cardInformation,
  isLoading,
}) => {
  const handleConfirmPayment = async (card: any) => {
    await onConfirmPayment?.(card)
  }

  const handleSelectPackage = (value: ISubscription) => {
    onSelectPackage?.(value)
  }

  if (!packages) {
    return null
  }

  return (
    <SubscriptionStyled className="!justify-start !pt-40 !px-[10dvw]">
      <div className="w-full flex flex-row">
        <PackagesSelection
          data={packages}
          selectedPackage={selectedPackage}
          onChangeValue={handleSelectPackage}
        />

        <PackageManagementForm
          packagesData={packages}
          selectedPackage={selectedPackage}
          onChangeSelectedPackage={handleSelectPackage}
          onConfirmPayment={handleConfirmPayment}
          cardInformation={cardInformation}
          onChangeCard={onChangeCard}
          isUpgrading={isLoading}
        />
      </div>
    </SubscriptionStyled>
  )
}
