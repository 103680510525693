import { IGetCreateWithdrawGenericInfor, IInvoiceItem } from '@interfaces'
import { createContext, useContext, useState, SetStateAction } from 'react'

interface IWithdrawalInfor extends IGetCreateWithdrawGenericInfor {
  amount?: number
  fees?: number
  actualAmount?: number
  hash?: string
}
interface IWithdrawalContextValue {
  genericWithdrawalInfo: IWithdrawalInfor
  withdrawInvoice?: Partial<IInvoiceItem>
  setGenericWithdrawalInfo: (value: SetStateAction<IWithdrawalInfor>) => void
  setWithdrawInvoice?: (value: SetStateAction<Partial<IInvoiceItem>>) => void
}

const initialState = {
  genericWithdrawalInfo: {
    amount: 0,
    fees: 0,
    actualAmount: 0,
    bankName: '',
    lastFourBankNumber: '',
    totalBalance: 0,
  },
  withdrawInvoice: {},
  setGenericWithdrawalInfo: (value: SetStateAction<IWithdrawalInfor>) => {},
  setWithdrawInvoice: (value: SetStateAction<Partial<IInvoiceItem>>) => {},
}

const WithdrawalContext = createContext<IWithdrawalContextValue>(initialState)

export const WithdrawalProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [genericWithdrawalInfo, setGenericWithdrawalInfo] =
    useState<IWithdrawalInfor>(initialState.genericWithdrawalInfo)

  const [withdrawInvoice, setWithdrawInvoice] = useState<Partial<IInvoiceItem>>(
    initialState.withdrawInvoice
  )

  return (
    <WithdrawalContext.Provider
      value={{
        genericWithdrawalInfo,
        withdrawInvoice,
        setGenericWithdrawalInfo,
        setWithdrawInvoice,
      }}
    >
      {children}
    </WithdrawalContext.Provider>
  )
}

export const useWithdrawContext = () => {
  const context = useContext(WithdrawalContext)

  if (!context)
    throw new Error('WithdrawContext must be use inside WithdrawalProvider')

  return context
}
