import { Card } from 'antd'
import styled from 'styled-components'

export const FillInTeacherInformationPageStyled = styled(Card)`
  min-width: 70dvw;
  max-width: 70dvw;

  .ant-card-body {
    width: 100%;
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`

export const CheckBoxStyled = styled.div`
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${({ theme }) => {
          return theme.colors?.primary
        }};
      }
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => {
      return theme.colors?.primary
    }};
  }

  .ant-checkbox-checked {
    :after {
      border-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }

    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors?.primary};
      background-color: ${({ theme }) => {
        return theme.colors?.primary
      }};
    }
  }
`
