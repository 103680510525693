import { memo } from 'react'
import { Skeleton, Typography } from 'antd'
import styled from 'styled-components'
import { Bar } from 'react-chartjs-2'
import 'chartjs-adapter-moment'
import {
  Chart as ChartJS,
  CategoryScale,
  ChartData,
  ChartOptions,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

interface IProps {
  label: string
  value: string | number
  bgColor?: string
  barColor?: string
  isLoading?: boolean
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const MiniDashboard = memo(
  ({ label, value, bgColor, barColor, isLoading }: IProps) => {
    const chartData: ChartData<'bar'> = {
      labels: ['', '', '', '', '', '', ''],
      datasets: [
        {
          label: '',
          data: [17, 20, 15, 14, 19, 13, 16],
          backgroundColor: barColor,
          maxBarThickness: 20,
        },
      ],
    }

    const chartOptions: ChartOptions<'bar'> = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    }

    return (
      <MiniDashboardStyled $bgColor={bgColor}>
        <span className="!m-0 flex flex-col gap-1">
          <Typography.Paragraph className="!m-0 !text-[14px] font-semibold">
            {label}
          </Typography.Paragraph>
          {!isLoading ? (
            <Typography.Paragraph className="!m-0 !text-[20px] font-semibold">
              {value}
            </Typography.Paragraph>
          ) : (
            <Skeleton
              paragraph={{ rows: 0, className: '!hidden' }}
              style={{
                marginBottom: 8,
              }}
              active
            />
          )}
        </span>
        <Bar
          data={chartData}
          options={chartOptions}
          className="!max-w-[120px] !max-h-[50px] mt-4"
        />
      </MiniDashboardStyled>
    )
  }
)

const MiniDashboardStyled = styled.div<{
  $bgColor?: string
}>`
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  border-radius: 8px;
  padding: 16px;
  background: ${({ $bgColor }) => $bgColor ?? ''};
`
