import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import {
  QK_GET_LIST_INVOICES,
  QK_GET_USER_PROFILE,
  QK_REDO_TRANSACTION,
} from 'src/configs/queryClient/queryKeyConstants'
import { revenueAPI } from 'src/api/revenue'

type IMutationOptions = Omit<
  UseMutationOptions<BaseResponseProps<{ message: string }>, any, any, unknown>,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (value: { message: string }) => void
  handleError?: (err: any) => void
}

export const useRedoTransactionMutation = (configs?: IMutationParams) => {
  const { handleError, handleSuccess } = { ...configs }

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QK_REDO_TRANSACTION],
    mutationFn: (id: number | string) => revenueAPI.redoTransaction(id),
    onError: (error) => {
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
      handleError?.(error)
    },
    onSuccess(res) {
      handleSuccess?.(res?.data)
      queryClient.invalidateQueries({
        queryKey: [QK_GET_LIST_INVOICES],
        type: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QK_GET_USER_PROFILE],
        type: 'all',
      })
    },

    ...configs,
  })
}
