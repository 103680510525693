import { ESocketMessage } from '@configs'
import {
  addNewMessageToChat,
  addNewSendingMessageId,
  removeSendingFailedMessageById,
  removeSendingMessageId,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { useRef, useState } from 'react'
import { message } from 'antd'
import { uploadMessageAttachment } from 'src/redux/actions/course'
import { useChatContext } from '../ChatContext'
import { ACCEPT_FILE_TYPES, EMessageType } from '../config'
import SendFileModal from 'src/components/chatChannel/SendFileModal'
import { IUploadAttachmentData } from 'src/interfaces/course'
import { useParams } from 'react-router-dom'

interface IFileGroupProps {
  chatId: string | number
  disabled?: boolean
}

const CHAT_FILE_LIMIT_SIZE_MB = 10
const MAX_FILE_SIZE = CHAT_FILE_LIMIT_SIZE_MB * 1024 * 1024 // 10MB

const UploadFileButton = ({ chatId, disabled }: IFileGroupProps) => {
  const [showModal, setShowModal] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [imgData, setImgData] = useState<string | ArrayBuffer | null>(null)
  const { id: courseId } = useParams()
  const { selectedConversation, socket } = useChatContext()

  const dispatch = useAppDispatch()
  const accountInfo = useAppSelector((state) => state.auth.accountInfo)

  const onClose = () => {
    setShowModal(false)
    setSelectedFile(null)
    setImgData(null)
  }

  const handlePickFile = (e: any) => {
    const file = e?.target?.files[0]
    if (file) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'text/plain',
        'audio/mpeg',
        'video/mp4',
        'image/gif',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ]

      if (!allowedTypes.includes(file.type)) {
        message.error({
          content: `Unsupported file type!`,
        })
      } else if (file.size > MAX_FILE_SIZE) {
        message.error({
          content: `File size must be less than ${CHAT_FILE_LIMIT_SIZE_MB}MB`,
        })
      } else {
        setSelectedFile(e.target.files[0])
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          setImgData(reader.result)
        })
        reader.readAsDataURL(e.target.files[0])
        setShowModal(true)
        e.target.value = null // reset input value for upload the same file the second time
      }
    }
  }

  const handleSendFile = async () => {
    const timeStamp = Date.now()
    const sendingMessageId = String(timeStamp)
    try {
      dispatch(
        addNewMessageToChat({
          message: {
            tempId: sendingMessageId,
            isSendingMessage: true,
            type: selectedFile?.type.includes('image')
              ? EMessageType.IMAGE
              : EMessageType.FILE,
            createdAt: new Date(timeStamp).toISOString(),
            user: {
              name: accountInfo?.name,
              id: accountInfo?.id,
              profilePhoto: accountInfo?.profilePhoto,
            },
            media: {
              url: selectedFile?.type.includes('image') ? imgData : '',
              name: selectedFile?.name,
            },
          },
        })
      )
      dispatch(addNewSendingMessageId({ sendingMessageId }))
      onClose()

      const responseUploadFile = (await dispatch(
        uploadMessageAttachment(selectedFile as File)
      ).unwrap()) as IUploadAttachmentData

      socket?.volatile.emit(ESocketMessage.SEND_MESSAGE, {
        tempId: sendingMessageId,
        userId: selectedConversation?.userId,
        courseId: Number(courseId),
        type: responseUploadFile?.dataValues?.type.includes('image')
          ? EMessageType.IMAGE
          : EMessageType.FILE,
        content: '',
        mediaId: responseUploadFile?.dataValues?.id || null,
      })
    } catch (error: any) {
      message.error(error?.data?.message || error?.message)
      dispatch(removeSendingFailedMessageById({ sendingMessageId }))
    }
  }

  return (
    <>
      <div
        className={`relative mr-3 h-4 w-4 text-slate-500 sm:mr-5 sm:h-5 sm:w-5 !cursor-pointer ${
          disabled ? '!opacity-50 !pointer-events-none !cursor-not-allowed' : ''
        }`}
        onClick={(e) => {
          inputRef?.current?.click()
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          icon-name="paperclip"
          data-lucide="paperclip"
          className="lucide lucide-paperclip stroke-1.5 h-full w-full !cursor-pointer"
        >
          <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
        </svg>

        <input
          ref={inputRef}
          type="file"
          accept={ACCEPT_FILE_TYPES}
          size={MAX_FILE_SIZE}
          onChange={(e) => {
            if (disabled) return
            handlePickFile(e)
          }}
          className="invisible"
        />
      </div>
      <SendFileModal
        open={showModal}
        onClose={onClose}
        onDelete={() => {}}
        onSave={handleSendFile}
        file={selectedFile as any}
        imgData={imgData as any}
      />
    </>
  )
}

export default UploadFileButton
