import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import { QK_CREATE_ACCOUNT_ONBOARD_LINK } from 'src/configs/queryClient/queryKeyConstants'
import { revenueAPI } from 'src/api/revenue'

type IMutationOptions = Omit<
  UseMutationOptions<BaseResponseProps<string>, any, any, unknown>,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (connectLink: string) => void
  onError?: (err: any) => void
}

export const useCreateAccountOnboardLinkMutation = (
  configs?: IMutationParams
) => {
  const { onError, handleSuccess } = { ...configs }

  return useMutation({
    mutationKey: [QK_CREATE_ACCOUNT_ONBOARD_LINK],
    mutationFn: () => revenueAPI.connectAccountOnboard(),
    onError: (error) => {
      onError?.(error)
    },
    onSuccess(res) {
      handleSuccess?.(res?.data)
    },

    ...configs,
  })
}
