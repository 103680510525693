import { useState } from 'react'
import { CHUNK_SIZE } from '@configs'
import { galleryAPI } from 'src/api/gallery'
import { IMultipartPresignUrlResponse } from 'src/interfaces/media'

const useMultipartUploader = () => {
  const [finalResult, setFinalResult] = useState(null)
  const [parts, setParts] = useState<IMultipartPresignUrlResponse[]>([])
  const [uploadedParts, setUploadedParts] = useState<
    {
      PartNumber: number
      ETag: string
    }[]
  >([])
  const [uploadId, setUploadedId] = useState('')
  const [key, setKey] = useState('')

  const chunkSize = CHUNK_SIZE
  const threadsQuantity = Math.min(5, 15)
  let uploadedSize = 0
  let activeConnections: any = {}

  const initialize = async (file: File) => {
    const fileName = file.name
    const fileType = file.type

    try {
      // adding the file extension (if present) to fileName
      let fileNameWithExtension = fileName

      // initializing the multipart request
      const videoInitializationUploadInput = {
        fileName: fileNameWithExtension,
        fileType,
      }
      const initializeResponse = await galleryAPI.initMultipartUpload(
        videoInitializationUploadInput
      )

      if (initializeResponse.data) {
        setUploadedId(initializeResponse.data.uploadId)
        setKey(initializeResponse.data.key)

        // retrieving the pre-signed URLs
        const numberOfParts = Math.ceil(file.size / chunkSize)

        const AWSMultipartFileDataInput = {
          uploadId: initializeResponse.data.uploadId,
          key: initializeResponse.data.key,
          parts: numberOfParts,
        }

        try {
          const urlsResponse = await galleryAPI.getMulitpartPresignedUrl(
            AWSMultipartFileDataInput
          )

          if (urlsResponse.data) {
            const newParts = urlsResponse.data
            setParts([...newParts])

            urlsResponse.data.forEach(async (part) => {
              try {
                await upload(
                  file,
                  part,
                  initializeResponse.data.uploadId,
                  initializeResponse.data.key
                )
              } catch (error) {}
            })
          }
        } catch (error) {
          console.error('Error presign uploader:', error)
        }
      }
    } catch (error) {
      console.error('Error initializing uploader:', error)
    }
  }

  //   const sendNext = () => {
  //     // Implement sendNext logic
  //     const activeConnections = Object.keys(activeConnections).length

  //     if (activeConnections >= threadsQuantity) {
  //       return
  //     }

  //     if (!parts.length) {
  //       if (!activeConnections) {
  //         complete()
  //       }

  //       return
  //     }

  //     const part = parts.pop()
  //     if (file && part) {
  //       const sentSize = (part.partNumber - 1) * chunkSize
  //       const chunk = file.slice(sentSize, sentSize + chunkSize)

  //       const sendChunkStarted = () => {
  //         sendNext()
  //       }

  //       sendChunk(chunk, part, sendChunkStarted)
  //         .then(() => {
  //           sendNext()
  //         })
  //         .catch((error) => {
  //           parts.push(part)

  //           complete(error)
  //         })
  //     }
  //   }

  //   const complete = async (error) => {
  //     // Implement complete method
  //     if (error && !aborted) {
  //       //   onErrorFn(error)
  //       return
  //     }

  //     if (error) {
  //       //   onErrorFn(error)
  //       return
  //     }

  //     try {
  //       await sendCompleteRequest()
  //     } catch (error) {
  //       //   onErrorFn(error)
  //     }
  //   }

  const sendCompleteRequest = async () => {
    // Implement sendCompleteRequest method
    return new Promise(async (resolve, reject) => {
      if (uploadId && key) {
        const videoFinalizationMultiPartInput = {
          uploadId,
          key,
          partsInfo: uploadedParts,
        }

        const finalResult = await galleryAPI.completeUploadMultipart(
          videoFinalizationMultiPartInput
        )
        if (finalResult.data) {
          setFinalResult(finalResult.data)
          resolve(finalResult.data)
        } else {
          reject(new Error('No data in final result'))
        }
      }
    })
  }

  //   const sendChunk = (chunk, part, sendChunkStarted) => {
  //     // Implement sendChunk method
  //     return new Promise((resolve, reject) => {
  //       upload(chunk, part, sendChunkStarted)
  //         .then((status) => {
  //           if (status !== 200) {
  //             reject(new Error('Failed chunk upload'))
  //             return
  //           }
  //           resolve('success')
  //         })
  //         .catch((error) => {
  //           reject(error)
  //         })
  //     })
  //   }

  const upload = (
    file: File,
    part: IMultipartPresignUrlResponse,
    uploadId: string,
    key: string
    // sendChunkStarted: () => {}
  ) => {
    // Implement upload method
    return new Promise((resolve, reject) => {
      console.log(uploadId, key, 'Whyyyyy')
      if (uploadId && key) {
        const xhr = new XMLHttpRequest()

        // sendChunkStarted()

        // const progressListener = handleProgress.bind(this, part.partNumber - 1)

        // xhr.upload.addEventListener('progress', progressListener)

        // xhr.addEventListener('error', progressListener)
        // xhr.addEventListener('abort', progressListener)
        // xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl)
        console.log('what')

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag')

            console.log(ETag)

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              }

              setUploadedParts((prev) => [...prev, uploadedPart])

              resolve(xhr.status)
              //   delete activeConnections[part.partNumber - 1]
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error)
          //   delete activeConnections[part.partNumber - 1]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          //   delete activeConnections[part.partNumber - 1]
        }

        xhr.send(file)
      }
    })
  }

  //   const handleProgress = (part, event) => {
  //     // Implement handleProgress method
  //     if (file) {
  //       if (
  //         event.type === 'progress' ||
  //         event.type === 'error' ||
  //         event.type === 'abort'
  //       ) {
  //         progressCache[part] = event.loaded
  //       }

  //       if (event.type === 'uploaded') {
  //         uploadedSize += progressCache[part] || 0
  //         delete progressCache[part]
  //       }

  //       const inProgress = Object.keys(progressCache)
  //         .map(Number)
  //         .reduce((memo, id) => (memo += progressCache[id]), 0)

  //       const sent = Math.min(uploadedSize + inProgress, file.size)

  //       const total = file.size

  //       const percentage = Math.round((sent / total) * 100)

  //       onProgressFn({
  //         sent: sent,
  //         total: total,
  //         percentage: percentage,
  //       })
  //     }
  //   }

  return { initialize, finalResult }
}

export default useMultipartUploader
