import { memo } from 'react'
import styled from 'styled-components'
import { Card, Typography } from 'antd'
import { DeleteTwoTone, EditOutlined, IdcardOutlined } from '@ant-design/icons'

import { themes } from '@theme'
import { TDegreeView } from 'src/pages/auth/sign-up/fill-in-teacher-information/types'
import { ICONS } from '@assets'

export const DegreeView = memo(
  ({ data, index, onRemove, onEdit }: TDegreeView) => {
    if (!data?.file?.name && !data?.file?.fileId) return null

    return (
      <div>
        <div className="flex flex-row justify-between mb-2">
          <div className="flex gap-2">
            <IdcardOutlined className="self-end mr-3 text-base text-gray-500 mb-1" />

            <Typography.Text className="text-base font-semibold capitalize max-w-[780px]">
              {data?.file?.name}
            </Typography.Text>
          </div>
          <div className="ml-5 min-w-14 items-center">
            {!!onEdit && (
              <EditOutlined
                className="self-end mr-3 text-base text-gray-500"
                onClick={() => onEdit?.(index)}
              />
            )}
            {!!onRemove && (
              <DeleteTwoTone
                onClick={() => onRemove?.(index)}
                className="self-end text-base"
                twoToneColor={themes.theme.light.colors.icon_gray_500}
              />
            )}
          </div>
        </div>
        <div className="flex items-center gap-7">
          <img src={ICONS.IconFileCommon} className="w-[50px]" alt="" />
          <Typography.Text className="text-base max-w-[600px]">
            {data?.mediaFile?.name}
          </Typography.Text>
        </div>
      </div>
    )
  }
)
