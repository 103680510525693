import * as yup from 'yup'
import { t } from 'i18next'
import {
  PASSWORD_LENGTH_MAX,
  PASSWORD_LENGTH_MIN,
  PASSWORD_REGEX,
} from '@configs'

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(t('error:email_required'))
    .email(t('error:email_format_error')),
  password: yup
    .string()
    .required(t('error:pass_required'))
    .min(PASSWORD_LENGTH_MIN, t('error:password_min_error'))
    .max(PASSWORD_LENGTH_MAX, t('error:password_max_error'))
    .matches(PASSWORD_REGEX, t('error:password_format_error')),
})
