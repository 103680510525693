import { useMemo } from 'react'

import { SharedStyledTableWrapper, SharedTable } from 'src/common'
import { REVENUE_RECEIVED_TABLE_COLUMNS } from './config'
import { IInvoiceItem } from '@interfaces'

export const RevenueReceived = ({
  invoiceItem,
  isLoading,
}: {
  invoiceItem: IInvoiceItem
  isLoading?: boolean
}) => {
  const tableData = useMemo(() => {
    if (!invoiceItem?.invoiceDetail?.courses) return []

    return invoiceItem?.invoiceDetail?.courses?.map((course, index) => ({
      ...course,
      courseName: course?.courseTitle,
    }))
  }, [invoiceItem?.invoiceDetail?.courses])

  return (
    <SharedStyledTableWrapper>
      <SharedTable
        columns={REVENUE_RECEIVED_TABLE_COLUMNS}
        dataSource={tableData}
        paginationProps={{
          total: 0,
          pageSize: 0,
          current: 0,
          showSizeChanger: false,
          onChange(page) {},
        }}
        loading={{
          spinning: isLoading || !invoiceItem,
          size: 'large',
        }}
        sticky
      />
    </SharedStyledTableWrapper>
  )
}
