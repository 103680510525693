import { zodResolver } from '@hookform/resolvers/zod'
import { message, Skeleton, Checkbox, Typography } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useLayoutEffect, useState } from 'react'
import { z } from 'zod'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { personalInformationSchema, specificInformationSchema } from './schema'
import { Button } from 'src/common'
import { TEducation, TFillInTeacherInformation } from '@interfaces'
import { WorkExperiences } from './work-experiences'
import { CoursesReference } from './courses-reference'
import { Educations } from './educations'
import { PersonalInformation } from './personal-information'
import { ModalConfirmBack } from './modals'
import { authActions, RootState, useAppDispatch } from '@redux'
import {
  UPLOAD_AVATAR_LOADING_KEY,
  UPLOAD_DEGREE_LOADING_KEY,
} from 'src/pages/auth/sign-up/configs'
import {
  ButtonWrapper,
  FillInTeacherInformationPageStyled,
  CheckBoxStyled,
} from './style'
import {
  useMutationSignUpDetailsQuery,
  useMutationUpdateDetailsQuery,
} from '@api'
import { useGetTeacherApplicationQuery } from '@api'
import {
  PATH_LOGIN,
  PATH_UPDATE_APPLICATION,
  PATH_WAITING_SCREEN,
  QK_GET_USER_APPLICATION,
} from '@configs'
import { deleteObjectFields } from '@utils'
import { SignatureSection } from 'src/pages/auth/sign-up/fill-in-teacher-information/signature/SignatureSection'

export const schema = z.object({
  personalInformation: personalInformationSchema,
  specificInformation: specificInformationSchema,
})

const defaultFormValues: TFillInTeacherInformation = {
  personalInformation: {
    firstname: '',
    lastname: '',
    gender: '',
    avatarId: 0,
    dateOfBirth: '',
    phone: '',
    description: '',
    email: '',
    password: '',
  },
  specificInformation: {
    education: [
      {
        institution: '',
        educationLevel: '',
        qualification: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
        degree: {
          file: {
            name: '',
            fileId: 0,
          },
        },
      },
    ],
    workExperience: [
      {
        companyName: '',
        jobTitle: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
      },
    ],
    courseReference: [
      {
        name: '',
        url: '',
        editable: true,
      },
    ],
    teacherSignature: {
      fileId: 0,
      url: '',
      name: '',
    },
  },
}

export const FillInTeacherInformationPage = ({
  onBack,
}: {
  onBack?: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()

  const isUpdatingApplication = [PATH_UPDATE_APPLICATION].includes(pathname)

  const { signUpAccountInfor, loadings, accessToken } = useSelector(
    (state: RootState) => state.auth
  )

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [isAgreePolicy, setAgreePolicy] = useState(false)

  const isUploadAvatarLoading = !!loadings[UPLOAD_AVATAR_LOADING_KEY]
  const isUploadDegreeLoading = !!loadings[UPLOAD_DEGREE_LOADING_KEY]

  const methods = useForm<TFillInTeacherInformation>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  })

  const { resetField, handleSubmit, reset } = methods

  const { mutate: signUpDetailsMutation, isPending: isSignUpDetailsLoading } =
    useMutationSignUpDetailsQuery({
      handleSuccess(data) {
        navigate(PATH_WAITING_SCREEN)
      },
    })

  const { mutate: updateDetailsMutation, isPending: isUpdateDetailsLoading } =
    useMutationUpdateDetailsQuery({})

  const getTeacherApplicationQuery = useGetTeacherApplicationQuery({
    options: {
      enabled: !!accessToken,
    },
  })

  const handleBackButton = () => {
    setOpenModalConfirm(true)
  }

  const onConfirmBack = () => {
    reset()
    if (isUpdatingApplication) {
      navigate(PATH_WAITING_SCREEN)
      return
    }
    dispatch(authActions.logout())
    onBack?.()
  }

  const handleSubmitForm = handleSubmit(async (value) => {
    const payload: TFillInTeacherInformation = {
      ...value,
      specificInformation: {
        ...value?.specificInformation,
        education: value?.specificInformation?.education?.map((item) => {
          if (!item?.degree?.file?.fileId && !item?.degree?.file?.name) {
            const newItem = { ...item }
            delete newItem.degree

            return newItem
          }

          return {
            ...item,
            degree: { ...item?.degree?.file },
          }
        }) as TEducation[],
      },
    }

    if (isUpdatingApplication) {
      deleteObjectFields(payload.personalInformation, ['email', 'password'])
      await updateDetailsMutation(payload, {
        onSuccess: () => {
          message.success(t('signUp:update_sign_up_request_successfully'))
          navigate(PATH_WAITING_SCREEN)
        },
      })
      return
    }
    await signUpDetailsMutation({
      ...payload,
      personalInformation: {
        ...payload.personalInformation,
        email: signUpAccountInfor?.email,
        password: signUpAccountInfor?.password,
      },
    })
  })

  useEffect(() => {
    if (!signUpAccountInfor || !Object.keys(signUpAccountInfor)?.length) return

    resetField('personalInformation', {
      defaultValue: {
        ...defaultFormValues.personalInformation,
        ...signUpAccountInfor,
      },
    })
  }, [signUpAccountInfor, resetField, reset])

  useEffect(() => {
    if (
      getTeacherApplicationQuery?.isError &&
      Number(getTeacherApplicationQuery?.error?.code) === 404
    ) {
      message.error(t('common:account_not_exist'))
      dispatch(authActions.logout())
      reset()
      navigate(PATH_LOGIN)
      return
    }

    if (
      getTeacherApplicationQuery.isSuccess &&
      getTeacherApplicationQuery.data?.data
    ) {
      const { specificInformation, personalInformation } =
        getTeacherApplicationQuery.data?.data

      const transformedSpecificInformation = Object.assign(
        specificInformation || {},
        {
          workExperience: specificInformation?.workExperience?.map(
            (experience) => ({
              ...experience,
              isPresent: experience?.isPresent || false,
              editable: false,
            })
          ),
          courseReference: specificInformation?.courseReference?.map(
            (reference) => ({
              ...reference,
              editable: false,
            })
          ),
          education: specificInformation?.education?.map((education) => ({
            ...education,
            degree: {
              file: {
                fileId: education?.degree?.fileId || 0,
                name: education?.degree?.name || '',
              },
              mediaFile: { ...education?.degree?.file },
            },
            isPresent: education?.isPresent || false,
            editable: false,
          })),
          teacherSignature: {
            ...specificInformation?.teacherSignature,
            editable: !specificInformation?.teacherSignature?.fileId,
            signaturePhoto: {
              ...specificInformation?.teacherSignature?.signaturePhoto,
            },
          },
        }
      )

      const transformedPersonalInformation = Object.assign(
        personalInformation || {},
        {
          avatarId: personalInformation?.avatar?.id,
          editable: false,
        }
      )

      reset({
        ...defaultFormValues,
        personalInformation: {
          ...defaultFormValues.personalInformation,
          ...transformedPersonalInformation,
        },
        specificInformation: {
          ...defaultFormValues.specificInformation,
          ...transformedSpecificInformation,
        },
      })
    }
  }, [
    dispatch,
    getTeacherApplicationQuery.data?.data,
    getTeacherApplicationQuery?.error?.code,
    getTeacherApplicationQuery?.isError,
    getTeacherApplicationQuery.isSuccess,
    navigate,
    queryClient,
    reset,
  ])

  // remove cached data when unmount
  useLayoutEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: [QK_GET_USER_APPLICATION],
        type: 'all',
      })
      reset()
    }
  }, [])

  const isViewOnly =
    getTeacherApplicationQuery?.isSuccess && !isUpdatingApplication

  const isLoadingSubmit =
    isUploadAvatarLoading ||
    isUploadDegreeLoading ||
    isSignUpDetailsLoading ||
    isUpdateDetailsLoading

  if (getTeacherApplicationQuery?.isFetching) {
    return <Skeleton paragraph={{ rows: 4 }} className="p-5" />
  }

  // TODO: refactor this code block
  return (
    <>
      <ModalConfirmBack
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        onConfirmBack={onConfirmBack}
      />
      <FormProvider {...methods}>
        <FillInTeacherInformationPageStyled className="!border-none flex items-center justify-center w-full">
          <PersonalInformation isViewMode={isViewOnly} />
          <Educations isViewMode={isViewOnly} />
          <WorkExperiences isViewMode={isViewOnly} />
          <CoursesReference isViewMode={isViewOnly} />
          <SignatureSection
            isViewMode={isViewOnly}
            isRegister={!isUpdatingApplication}
          />

          {!getTeacherApplicationQuery?.isSuccess && (
            <CheckBoxStyled className="mt-6">
              <Checkbox
                checked={isAgreePolicy}
                onChange={(e) => {
                  setAgreePolicy(e.target.checked)
                }}
              />
              <Typography.Text className="ml-4">
                {t('signUp:bySignUpFirst')}
                <Typography.Text className="!text-main">
                  {` ${t('signUp:termAndPrivacy')}`}
                </Typography.Text>
              </Typography.Text>
            </CheckBoxStyled>
          )}

          {!isViewOnly && (
            <ButtonWrapper>
              <Button
                htmlType="button"
                type="default"
                size="small"
                id="blog-form"
                onClick={handleBackButton}
              >
                {t('signUp:back_to_home')}
              </Button>
              <Button
                htmlType="button"
                type="primary"
                size="small"
                id="blog-form"
                onClick={handleSubmitForm}
                loading={isLoadingSubmit}
                disabled={!isAgreePolicy && !isUpdatingApplication}
              >
                {isUpdatingApplication
                  ? t('common:update')
                  : t('common:submit')}
              </Button>
            </ButtonWrapper>
          )}
        </FillInTeacherInformationPageStyled>
      </FormProvider>
    </>
  )
}
