import { memo } from 'react'
import { Card } from 'antd'
import styled from 'styled-components'

import { ChannelsOverviewSection } from 'src/pages/dashboard/channels/overview-section'
import { ChannelsStatisticSection } from 'src/pages/dashboard/channels/statistics-section'
import { IGetDashboardChannels } from '@interfaces'
import { StyledSpin } from '@components'

interface IProps {
  dashboardChannels?: IGetDashboardChannels
  onChangeFilter?: (year: number | string) => void
}

export const DashboardChannels = memo(
  ({ dashboardChannels, onChangeFilter }: IProps) => {
    return (
      <DashboardChannelsStyled>
        <Card className="!rounded-[8px]">
          {!!Object.keys(dashboardChannels ?? {})?.length ? (
            <>
              <ChannelsOverviewSection
                channelsOverview={dashboardChannels?.channelOverview}
              />
              <ChannelsStatisticSection
                statisticByMonths={dashboardChannels?.channelStatisticByMonths}
                onChangeFilter={onChangeFilter}
              />
            </>
          ) : (
            <StyledSpin size="large" className="min-h-[475px]" />
          )}
        </Card>
      </DashboardChannelsStyled>
    )
  }
)

const DashboardChannelsStyled = styled.div`
  width: 100%;

  .ant-card-body {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 12px !important;
  }
`
