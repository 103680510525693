import { Spin, Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'
import { LoadingOutlined, WalletOutlined } from '@ant-design/icons'

import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

interface IProps {
  onStripeConnect: () => void
  balance?: number | string
  isLoading?: boolean
}

export const BalanceOverview = ({
  onStripeConnect,
  balance,
  isLoading,
}: IProps) => {
  return (
    <BalanceOverviewStyled>
      <TitleWrapper>
        {isLoading ? (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        ) : null}
        <Typography.Text
          className={`!m-0 !text-[14px] underline cursor-pointer ${
            isLoading ? '!text-blue-200' : '!text-blue-500'
          }`}
          onClick={() => {
            if (isLoading) return
            onStripeConnect()
          }}
        >
          {t('revenue:stripe_connect')}
        </Typography.Text>
      </TitleWrapper>

      <OverviewWrapper>
        <WalletOutlined
          style={{
            fontSize: 60,
          }}
        />
        <Typography.Text className="!m-0 !font-light !text-[18px]">
          {t('revenue:your_balance')}
        </Typography.Text>
        <Typography.Title level={4} className="!m-0 !text-[30px]">
          €{formatCurrencyByType(Number(balance ?? 0), 'EUR').replace('€', '')}
        </Typography.Title>
        <Typography.Text className="!m-0 !font-light !text-[20px]">
          {t('revenue:eur')}
        </Typography.Text>
      </OverviewWrapper>
    </BalanceOverviewStyled>
  )
}

const BalanceOverviewStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  margin-bottom: 16px;
`
const OverviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-color: ${themes.theme.light.colors.balanceOverviewBg};
  padding: 42px 0;
  border-radius: 2px;
`
const TitleWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`
