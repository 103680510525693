import { z } from 'zod'
import { t } from 'i18next'

import { OPT_LENGTH, REGEX_OTP } from '@configs'

export const schemaVerifyOTP = z.object({
  otpCode: z
    .string()
    .trim()
    .min(1, {
      message: t('error:otp_required'),
    })
    .min(OPT_LENGTH, t('error:otp_character'))
    .max(OPT_LENGTH, t('error:otp_character'))
    .refine((value) => REGEX_OTP.test(value ?? ''), {
      message: t('error:otp_invalid'),
    }),
  hash: z.string(),
})
