import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { useAppDispatch } from '@redux'
import { QK_GET_TEACHER_COURSE_MINI_DASHBOARD } from '@configs'
import {
  BaseResponseError,
  BaseResponseProps,
  ITeacherCourseMiniDashboard,
} from '@interfaces'
import { packageManagementAPI } from 'src/api/package-management'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<ITeacherCourseMiniDashboard>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
}

export const useGetTeacherCourseMiniDashboardQuery = (
  queryProps?: IQueryProps
) => {
  const { configs } = queryProps || {}

  const queryClient = useQueryClient()

  function invalidateGetTeacherCourseMiniDashboard() {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_TEACHER_COURSE_MINI_DASHBOARD],
      type: 'all',
    })
  }

  function refetchGetTeacherCourseMiniDashboard() {
    queryClient.refetchQueries({
      queryKey: [QK_GET_TEACHER_COURSE_MINI_DASHBOARD],
      type: 'all',
    })
  }

  async function GetTeacherCourseMiniDashboard() {
    return packageManagementAPI.getTeacherCourseMiniDashboard()
  }

  const { data: miniDashboardData, ...restData } = useQuery<
    BaseResponseProps<ITeacherCourseMiniDashboard>,
    BaseResponseError
  >({
    queryKey: [QK_GET_TEACHER_COURSE_MINI_DASHBOARD],
    queryFn: GetTeacherCourseMiniDashboard,
    ...configs,
  })

  return {
    data: miniDashboardData,
    invalidateGetTeacherCourseMiniDashboard,
    refetchGetTeacherCourseMiniDashboard,
    ...restData,
  }
}
