import { ApiClient } from 'src/api/axiosClient'
import {
  ICreateEditPackageInput,
  ICreatePackageSuccessData,
  IGetPackageSubscriptionSuccessData,
  IGetPackageSuccessData,
  ITeacherCourseMiniDashboard,
  IPackage,
  IPackageBenefitsModule,
  TSubscribePackageRequest,
  TConfirmPaymentIntentRequest,
  TVerifySubscribePackageRequest,
  TSubscribePackageResponse,
  TVerifySubscribePackageResponse,
  TConfirmPaymentIntentResponse,
  TCheckoutPackageRequest,
  TPaymentInfo,
} from 'src/interfaces/package-management'
import { BaseResponseProps, ICommonGetParams } from 'src/interfaces/app'
import { objectToQueryString } from 'src/utils'
import {
  ENDPOINT_CHECKOUT_PACKAGE,
  ENDPOINT_CONFIRM_PAYMENT_INTENT,
  ENDPOINT_GET_PACKAGE_SUBSCRIPTIONS,
  ENDPOINT_GET_TEACHER_COURSE_MINI_DASHBOARD,
  ENDPOINT_SUBSCRIBE_PACKAGE,
  ENDPOINT_VERIFY_SUBSCRIBE_PACKAGE,
} from 'src/api/react-query'

export const packageManagementAPI = {
  getPackageBenefits: async () => {
    return await ApiClient.get<IPackageBenefitsModule[]>('/package-benefits')
  },
  getListPackage: async (params: ICommonGetParams) => {
    return await ApiClient.get<IGetPackageSuccessData>('/packages', {
      params: {
        ...params,
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },

  getListPublicPackageForUser: async () => {
    return await ApiClient.get<IPackage[]>('/packages/public')
  },
  createPackage: async (payload: any) => {
    return await ApiClient.post<ICreatePackageSuccessData>('/packages', payload)
  },

  getPackageById: async (id: string | number) => {
    return await ApiClient.get<IPackage>(`/packages/${id}`)
  },
  updatePackage: async (
    id: number | string,
    payload: ICreateEditPackageInput
  ) => {
    return await ApiClient.put<ICreatePackageSuccessData>(
      `/packages/${id}`,
      payload
    )
  },
  deletePackage: async (id: string | number, selectedPackageId: number) => {
    return await ApiClient.delete<any>(`/packages/${id}`, {
      packageId: selectedPackageId,
    })
  },

  getListPackageSubscription: async () => {
    return await ApiClient.get<IGetPackageSubscriptionSuccessData>(
      ENDPOINT_GET_PACKAGE_SUBSCRIPTIONS
    )
  },
  getTeacherCourseMiniDashboard: async () => {
    return await ApiClient.get<ITeacherCourseMiniDashboard>(
      ENDPOINT_GET_TEACHER_COURSE_MINI_DASHBOARD
    )
  },
  subscribePackage: async (payload: TSubscribePackageRequest) => {
    return await ApiClient.post<
      TSubscribePackageResponse,
      TSubscribePackageRequest
    >(ENDPOINT_SUBSCRIBE_PACKAGE.replace('$id', `${payload?.id ?? ''}`), {
      type: payload?.type,
    })
  },
  verifySubscribePackage: async (payload: TVerifySubscribePackageRequest) => {
    return await ApiClient.post<
      TVerifySubscribePackageRequest,
      TVerifySubscribePackageResponse
    >(ENDPOINT_VERIFY_SUBSCRIBE_PACKAGE, payload)
  },
  confirmPaymentIntent: async (payload: TConfirmPaymentIntentRequest) => {
    return await ApiClient.post<
      TConfirmPaymentIntentRequest,
      TConfirmPaymentIntentResponse
    >(ENDPOINT_CONFIRM_PAYMENT_INTENT, payload)
  },
  checkoutPackage: async (payload: TCheckoutPackageRequest) => {
    const url = !!payload?.isMigrate
      ? `${ENDPOINT_CHECKOUT_PACKAGE}?isMigrate=${payload.isMigrate}`
      : ENDPOINT_CHECKOUT_PACKAGE

    return await ApiClient.post<BaseResponseProps>(url, {
      packageId: payload?.packageId,
      paymentMethodId: payload?.paymentMethodId,
    })
  },
  getTeacherPaymentCards: async () => {
    return await ApiClient.get<TPaymentInfo[]>(`users/profile/payment-cards`)
  },
  updatePackageRenewStatus: async ({
    packageId,
  }: {
    packageId: string | number
  }) => {
    return await ApiClient.post(`packages/subscribe/change-renew/${packageId}`)
  },
  savePaymentCardInfo: async ({
    paymentMethodId,
    isDefault,
  }: {
    paymentMethodId: string
    isDefault: boolean
  }) => {
    return await ApiClient.post('users/profile/update-payment-card', {
      paymentMethodId,
      isDefault,
    })
  },
  deletePaymentCard: async ({ cardId }: { cardId: string }) => {
    return await ApiClient.delete(
      `/users/profile/delete-payment-card/${cardId}`
    )
  },
}
