import { Typography } from 'antd'
import GradeForm from './grade-form/GradeForm'
import StudentAnswer from './student-answer'
import { useTranslation } from 'react-i18next'
import { ContainerOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Loading } from 'src/common'
import {
  checkNotPassedValidationYet,
  validateAllGradedTestData,
} from 'src/pages/test-management/config'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  RootState,
  getGradeTestDetail,
  resetListGradedTestData,
  getGradingResult,
  submitGradingTest,
  updateCurrentQuestion,
  useAppDispatch,
  resetGradeTestError,
} from '@redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import {
  FieldValues,
  FormProvider,
  UseFormTrigger,
  useForm,
} from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import {
  EStudentAnswerResult,
  EGradeTestViewMode,
  IGradeTestDetailData,
  IGradingResultData,
  FinalTestSectionResults,
  IGradedTestDataItem,
  EGradingStatus,
} from 'src/pages/test-management/type'
import { PATH_404, PATH_TEST_MANAGEMENT } from '@configs'
import GradingResultForm from './grade-form/GradingResultForm'
import SubmitConfirmModal from './SubmitConfirmModal'
import useNavigateToErrorPage from 'src/utils/hooks/useNavigateToErrorPage.'

interface IFeedback {
  photoImage: string
  photoCaption: string
  mediaId: number
}

interface IGradeTestData {
  data: IGradeTestDetailData | IGradingResultData
}

interface IProps {
  mode: EGradeTestViewMode | string
}

export default function GradeTestPage({ mode = '' }: IProps) {
  const { t } = useTranslation(['testManagement'])
  const navigate = useNavigate()
  const { gradeTestId: finalTestId } = useParams()
  const [searchParams] = useSearchParams()
  const studentId = searchParams.get('studentId')

  const dispatch = useAppDispatch()
  const { currentQuestion, gradedTestData } = useSelector(
    (state: RootState) => state.testManagement
  )

  const isViewMode = mode === EGradeTestViewMode.VIEW
  const { gradeTestData } =
    useGetGradeTestData(mode as EGradeTestViewMode) || {}

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isShowConfirmSubmitModal, setIsShowConfirmSubmitModal] =
    useState(false)
  const { finalTestSectionResults = [] } = gradeTestData?.data || {}

  const FeedbackFormSchema = z.object({
    photoImage: z.string().optional(),
    photoCaption: z
      .string()
      .max(500, {
        message: t('testManagement:photoLengthError') as string,
      })
      .optional(),
  })

  const GradingFormSchema = z.object({
    comment: z
      .string()
      .max(500, {
        message: t('testManagement:commentLengthError') as string,
      })
      .optional(),
    feedback: z.array(FeedbackFormSchema).max(3, {
      message: t('testManagement:onlyAdd3comments'),
    }),
    status: z.string(),
  })

  const methods = useForm<{
    comment: string
    feedback: IFeedback[]
    status: string
  }>({
    resolver: zodResolver(GradingFormSchema),
    defaultValues: {
      comment: '',
      feedback: [],
      status: EStudentAnswerResult.NOT_YET,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { control, watch, setValue, reset, handleSubmit, trigger } = methods

  const isPassGradedTestValidation = validateAllGradedTestData(
    gradedTestData,
    gradeTestData?.data?.finalTestSectionResults as FinalTestSectionResults[]
  )

  const onNextQuestion = () => {
    dispatch(
      updateCurrentQuestion({
        currentQuestion:
          currentQuestion >= finalTestSectionResults?.length
            ? finalTestSectionResults?.length
            : currentQuestion + 1,
      })
    )
  }

  const practiceTestResult = useMemo(() => {
    const { totalCorrect = 0, totalQuestions = 0 } = gradeTestData?.data || {}

    if (!totalCorrect || !totalQuestions)
      return {
        correctPercentage: 0,
        status: t('testManagement:failed'),
        totalCorrect: Number(totalCorrect),
        totalQuestions: Number(totalQuestions),
      }

    const correctPercentage = Number(
      (totalCorrect * 100) / totalQuestions
    ).toFixed(0)

    return {
      correctPercentage,
      status:
        Number(correctPercentage) >= 80
          ? t('testManagement:passed')
          : t('testManagement:failed'),
      totalCorrect: Number(totalCorrect),
      totalQuestions: Number(totalQuestions),
    }
  }, [gradeTestData?.data, t])

  const onSubmitGradeTest = async () => {
    try {
      await trigger()
      const teacherComment = gradedTestData?.map((it, idx) => {
        const { feedback, status, comment } = it || {}

        return {
          finalTestSectionResultId: finalTestSectionResults?.[idx]?.id || '',
          mediaAndCaptions: feedback?.map((feedbackItem) => {
            const { mediaId, photoCaption } = feedbackItem || {}

            return {
              mediaId,
              caption: String(photoCaption)?.trim(),
            }
          }),
          comment: String(comment)?.trim(),
          isCorrected: status === EStudentAnswerResult.PASS,
        }
      })
      const reqData = {
        finalTestSectionHistoryId: Number(finalTestId),
        userId: Number(studentId),
        teacherComment,
      }

      setIsSubmitting(true)
      await dispatch(submitGradingTest(reqData as any)).unwrap()
      setIsSubmitting(false)
      toast.success(t('testManagement:submitGradedTestSuccessMessage'))
      navigate(PATH_TEST_MANAGEMENT)
    } catch (error: any) {
      setIsSubmitting(false)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    if (mode === EGradeTestViewMode.GRADE) {
      dispatch(
        getGradeTestDetail({
          finalTestId: finalTestId || '',
          studentId: studentId || '',
        })
      )
      return
    }

    if (mode === EGradeTestViewMode.VIEW) {
      dispatch(
        getGradingResult({
          finalTestId: finalTestId || '',
          studentId: studentId || '',
        })
      )
      return
    }
  }, [dispatch, finalTestId, mode, studentId])

  useEffect(() => {
    // if teacher try to grade the graded test we will navigate to 404 page
    if (
      !!Object.keys(gradeTestData?.data || {})?.length &&
      mode === EGradeTestViewMode.GRADE &&
      gradeTestData?.data?.gradingStatus === EGradingStatus.GRADED
    ) {
      navigate(PATH_404)

      return
    }
  }, [gradeTestData, mode, navigate])

  useNavigateToErrorPage({ statusCode: gradeTestData?.error?.code })

  useEffect(() => {
    return () => {
      dispatch(resetListGradedTestData())
      dispatch(resetGradeTestError())
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <div className="bg-white rounded-md p-4">
          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
              marginBottom: 8,
              textAlign: 'center',
            }}
          >
            {t('testManagement:gradingAssignments')}
          </Typography>

          <TestInfo
            gradeTestData={gradeTestData as IGradeTestData}
            isViewMode={isViewMode}
          />

          <Typography
            style={{
              fontSize: 32,
              fontWeight: 700,
              marginTop: 8,
              marginBottom: 8,
              textAlign: 'center',
            }}
          >
            {t('testManagement:studentSubmittedTest')}
          </Typography>

          <div>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 700,
                marginTop: 8,
                marginBottom: 8,
              }}
            >
              {`${t('testManagement:section')} 2: ${
                gradeTestData?.data?.finalTestSection?.title
              }`}
            </Typography>

            {isViewMode ? (
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  marginBottom: 8,
                }}
              >
                <span
                  className={`text-[20px] ${
                    practiceTestResult?.status === 'Passed'
                      ? 'text-[#19ba8c]'
                      : 'text-[#ff0000]'
                  } mr-2 italic`}
                >
                  {practiceTestResult?.status}
                </span>

                <span className="text-[16px]">
                  {`${practiceTestResult?.totalCorrect}/${
                    practiceTestResult?.totalQuestions
                  } ${t('testManagement:points')} (${
                    practiceTestResult?.correctPercentage
                  }%)`}
                </span>
              </Typography>
            ) : null}
          </div>

          <form className="px-0 mt-4" onSubmit={handleSubmit(onNextQuestion)}>
            <div className="flex gap-4">
              <ListStudentAnswer
                trigger={trigger as any}
                onOpenSubmitConfirm={() => setIsShowConfirmSubmitModal(true)}
                isSubmitting={isSubmitting}
                gradeTestData={gradeTestData as IGradeTestData}
                isPassGradedTestValidation={isPassGradedTestValidation}
                isLoadingData={gradeTestData?.isLoading || false}
                setValue={setValue}
                mode={mode}
              />

              <div className="border border-[#e2e2e2] rounded-md p-4 w-full relative">
                {(() => {
                  if (gradeTestData?.isLoading)
                    return (
                      <Loading
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      />
                    )

                  return (
                    <>
                      <StudentAnswer
                        gradeTestData={gradeTestData as IGradeTestData}
                      />

                      {isViewMode ? (
                        <GradingResultForm
                          gradedQuestionData={
                            (gradeTestData?.data?.finalTestSectionResults?.[
                              currentQuestion - 1
                            ] || {}) as IGradingResultData
                          }
                          onNextQuestion={onNextQuestion}
                        />
                      ) : (
                        <GradeForm
                          control={control as any}
                          watch={watch as any}
                          reset={reset as any}
                          setValue={setValue as any}
                          onOpenSubmitConfirm={() =>
                            setIsShowConfirmSubmitModal(true)
                          }
                          isSubmitting={isSubmitting}
                        />
                      )}
                    </>
                  )
                })()}
              </div>
            </div>
          </form>
        </div>
      </FormProvider>

      {isShowConfirmSubmitModal ? (
        <SubmitConfirmModal
          open={isShowConfirmSubmitModal}
          onClose={() => setIsShowConfirmSubmitModal(false)}
          onSubmit={onSubmitGradeTest}
          isSubmitting={isSubmitting}
        />
      ) : null}
    </>
  )
}

function TestInfo({
  gradeTestData,
  isViewMode,
}: {
  gradeTestData: IGradeTestData
  isViewMode: boolean
}) {
  const { t } = useTranslation(['testManagement'])

  return (
    <div className="border border-[#e2e2e2] rounded-md p-4 mb-4 flex items-center justify-between gap-4">
      <div className="flex items-start gap-2">
        <ContainerOutlined
          style={{ fontSize: 18, marginTop: 6, color: '#f3c514' }}
        />

        <div className="flex flex-col gap-1">
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {t('testManagement:testName')}
          </Typography>

          <Typography
            style={{
              fontSize: 16,
            }}
          >
            {gradeTestData?.data?.finalTestSection?.title || ''}
          </Typography>
        </div>
      </div>

      <div className="flex items-start gap-2">
        <UserOutlined
          style={{ fontSize: 18, marginTop: 6, color: '#ff0000' }}
        />

        <div className="flex flex-col gap-1">
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {t('testManagement:studentName')}
          </Typography>

          <Typography
            style={{
              fontSize: 16,
            }}
          >
            {gradeTestData?.data?.user?.name || ''}
          </Typography>
        </div>
      </div>

      {isViewMode ? (
        <>
          <div className="flex flex-col items-start gap-2">
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#000000',
              }}
            >
              {t('testManagement:gradedDate')}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#000000',
              }}
            >
              {gradeTestData?.data?.gradedDate
                ? format(
                    new Date(gradeTestData?.data?.gradedDate),
                    'MMMM do yyyy'
                  )
                : null}
            </Typography>
          </div>

          <div className="flex flex-col items-start gap-2">
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: '#000000',
              }}
            >
              {t('testManagement:gradedBy')}
            </Typography>

            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#000000',
              }}
            >
              {gradeTestData?.data?.gradedBy?.name || ''}
            </Typography>
          </div>
        </>
      ) : (
        <div className="flex items-start gap-2">
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: '#ff0000',
            }}
          >
            {gradeTestData?.data?.deadline
              ? format(new Date(gradeTestData?.data?.deadline), 'MMMM do yyyy')
              : null}
          </Typography>
        </div>
      )}
    </div>
  )
}

function ListStudentAnswer({
  trigger,
  setValue,
  onOpenSubmitConfirm,
  isSubmitting,
  gradeTestData,
  isPassGradedTestValidation,
  isLoadingData,
  mode,
}: {
  trigger: UseFormTrigger<FieldValues>
  setValue: any
  onOpenSubmitConfirm: () => void
  isSubmitting: boolean
  isPassGradedTestValidation: boolean
  isLoadingData: boolean
  gradeTestData: IGradeTestData
  mode: EGradeTestViewMode | string
}) {
  const { t } = useTranslation(['testManagement'])

  const dispatch = useAppDispatch()
  const { currentQuestion, gradedTestData } = useSelector(
    (state: RootState) => state.testManagement
  )
  const isViewMode = mode === EGradeTestViewMode.VIEW

  const renderStudentAnswerButtonStyle = useCallback(
    (item, index) => {
      if (index + 1 === currentQuestion) {
        return {
          backgroundColor: '#ffffff',
          color: '#8e8e8e',
        }
      }

      if (isViewMode) {
        if (item?.isCorrected)
          return {
            backgroundColor: '#19ba8c',
          }

        return {
          backgroundColor: '#ff0000',
        }
      }

      if (
        !checkNotPassedValidationYet(
          gradedTestData?.find(
            (it) => String(it?.id) === String(index + 1)
          ) as IGradedTestDataItem
        )
      ) {
        return {
          backgroundColor: '#19ba8c',
        }
      }

      return {
        backgroundColor: '#ff0000',
      }
    },
    [currentQuestion, gradedTestData, isViewMode]
  )

  return (
    <div className="flex flex-col items-center max-w-[268px] min-w-[268px] h-fit">
      <div className="border border-[#e2e2e2] rounded-md p-4 flex flex-col gap-2 w-full">
        <Typography
          style={{
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1,
          }}
        >
          {t('testManagement:questions')}
        </Typography>

        {(() => {
          if (isLoadingData) return <Loading />

          return (
            <div className="flex flex-wrap gap-2">
              {gradeTestData?.data?.finalTestSectionResults?.map(
                (it, idx: number) => {
                  return (
                    <Button
                      key={idx}
                      style={{
                        minWidth: 40,
                        maxWidth: 40,
                        border: 'none',
                        color: '#ffffff',
                        ...renderStudentAnswerButtonStyle(it, idx),
                      }}
                      onClick={async () => {
                        if (isViewMode) {
                          dispatch(
                            updateCurrentQuestion({ currentQuestion: idx + 1 })
                          )
                          return
                        }

                        const isValidationPassed = await trigger()

                        if (!isValidationPassed) return
                        dispatch(
                          updateCurrentQuestion({ currentQuestion: idx + 1 })
                        )

                        /* need to reset feedback to default values by manual because 
                        useFieldArray data doesn't sync when reset automatically */
                        setValue('feedback', [])
                      }}
                    >
                      {idx + 1}
                    </Button>
                  )
                }
              )}
            </div>
          )
        })()}
      </div>

      {!isViewMode ? (
        <Button
          size="small"
          type="primary"
          style={{ marginTop: 8 }}
          onClick={onOpenSubmitConfirm}
          disabled={!isPassGradedTestValidation}
          loading={isSubmitting}
        >
          {t('testManagement:submit')}
        </Button>
      ) : null}
    </div>
  )
}

function useGetGradeTestData(type: EGradeTestViewMode | '') {
  const { gradeTestDetail, gradingResult } = useSelector(
    (state: RootState) => state.testManagement
  )

  if (type === EGradeTestViewMode.GRADE)
    return {
      gradeTestData: gradeTestDetail,
    }

  if (type === EGradeTestViewMode.VIEW)
    return {
      gradeTestData: gradingResult,
    }

  return null
}
