import {
  IGetNotificationListRequestParam,
  INotificationListResponse,
  IMarkAsReadNotificationResponse,
} from '@interfaces'
import { ApiClient } from './axiosClient'
import {
  ENDPOINT_GET_LIST_NOTIFICATIONS,
  ENDPOINT_GET_NOTIFICATION_UNREAD_NUMBER,
  ENDPOINT_MARK_NOTIFICATION_AS_READ_BY_ID,
  ENDPOINT_MARK_ALL_NOTIFICATION_AS_REAd,
} from './react-query'

export const notificationAPI = {
  getListNotifications: async (params?: IGetNotificationListRequestParam) => {
    return await ApiClient.get<INotificationListResponse>(
      ENDPOINT_GET_LIST_NOTIFICATIONS,
      {
        params,
      }
    )
  },

  getNotificationUnreadAmount: async () => {
    return await ApiClient.get<{ unreadNumber: number }>(
      ENDPOINT_GET_NOTIFICATION_UNREAD_NUMBER
    )
  },

  markNotificationAsReadById: async (notificationId: number) => {
    return await ApiClient.put<IMarkAsReadNotificationResponse>(
      ENDPOINT_MARK_NOTIFICATION_AS_READ_BY_ID.replace(
        '$notificationId',
        `${notificationId}`
      )
    )
  },

  markAllNotificationsAsRead: async () => {
    return await ApiClient.put<{ message: string }>(
      ENDPOINT_MARK_ALL_NOTIFICATION_AS_REAd
    )
  },
}
