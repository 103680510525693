import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { Card, message, Typography } from 'antd'

import { Button, Input } from 'src/common'
import {
  CHANGE_PASSWORD_DEFAULT_VALUES,
  IChangePassword,
  changePasswordSchema,
} from './configs'
import { useMutationChangePasswordQuery } from '@api'
import { authActions, useAppDispatch } from '@redux'

export const ChangePassword = () => {
  const dispatch = useAppDispatch()

  const { control, handleSubmit } = useForm<IChangePassword>({
    defaultValues: CHANGE_PASSWORD_DEFAULT_VALUES,
    resolver: zodResolver(changePasswordSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { mutate: changePassword, isPending: isChangingPassword } =
    useMutationChangePasswordQuery({
      handleSuccess: () => {
        message.success(t('profile:change_password_successfully'))
        dispatch(authActions.logout())
      },
    })

  const handleChangePassword = handleSubmit((value) => {
    changePassword({
      oldPassword: value.password,
      password: value.newPassword,
      confirmPassword: value.confirmNewPassword,
    })
  })

  return (
    <ChangePasswordContainer>
      <Typography.Title
        level={3}
        className="!text-[24px] flex flex-col gap-1 text-center"
      >
        {t('profile:change_password')}
        <Typography.Text className="!m-0 !text-[14px] !text-neutral-500">
          {t('profile:change_password_subtitle')}
        </Typography.Text>
      </Typography.Title>
      <FormStyled onSubmit={handleChangePassword}>
        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="col"
                label={t('profile:currentPassword')}
                size="middle"
                type="password"
                value={value}
                onChange={onChange}
                errors={error?.message}
                haveShowPassIcon
                required
              />
            )
          }}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="col"
                label="New Password"
                size="middle"
                type="password"
                value={value}
                onChange={onChange}
                errors={error?.message}
                haveShowPassIcon
                required
              />
            )
          }}
        />
        <Controller
          name="confirmNewPassword"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="col"
                label="Re-enter new password"
                size="middle"
                type="password"
                value={value}
                onChange={onChange}
                errors={error?.message}
                haveShowPassIcon
                required
              />
            )
          }}
        />
        <div className="w-full flex justify-center">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="!w-max !px-8 !rounded-full"
            loading={isChangingPassword}
          >
            {t('profile:save_changes')}
          </Button>
        </div>
      </FormStyled>
    </ChangePasswordContainer>
  )
}

const FormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`

const ChangePasswordContainer = styled(Card)`
  .ant-card-body {
    width: 100%;
    min-height: 530px;
    padding: 24px 30% !important;
  }

  .Input__field-container {
    border-radius: 999px;

    .Input .ant-input {
      border-radius: 999px !important;
    }
  }
`
