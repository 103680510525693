import styled, { css } from 'styled-components'
import { t } from 'i18next'
import {
  BankOutlined,
  CalendarOutlined,
  ContainerOutlined,
  EuroOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'

import { useWithdrawContext } from 'src/pages/withdrawal/provider'
import { formatDate } from '@utils'
import { FORMAT_DATE_TIME } from '@configs'
import { themes } from '@theme'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { Button } from 'src/common'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export const WithdrawResultDetails = ({
  onBackToHome,
}: {
  onBackToHome: () => void
}) => {
  const { withdrawInvoice } = useWithdrawContext()

  const ListGenericInfors = [
    {
      title: (
        <GenericTitleWrapper>
          <CalendarOutlined className="!text-primaryPurple !text-[24px]" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:date_time')}
          </Typography.Text>
        </GenericTitleWrapper>
      ),
      content: (
        <Typography.Text className="!w-max !text-[14px]">
          {formatDate(withdrawInvoice?.createdAt, FORMAT_DATE_TIME)}
        </Typography.Text>
      ),
    },
    {
      title: (
        <GenericTitleWrapper>
          <UserOutlined className="!text-primaryPurple !text-[24px]" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:teacher')}
          </Typography.Text>
        </GenericTitleWrapper>
      ),
      content: (
        <Typography.Text className="!w-max !text-[14px] !font-bold">
          {withdrawInvoice?.invoiceDetail?.withdrawalDetail?.teacherName ?? ''}
        </Typography.Text>
      ),
    },
    {
      title: (
        <GenericTitleWrapper>
          <BankOutlined className="!text-primaryPurple !text-[24px]" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:bank')}
          </Typography.Text>
        </GenericTitleWrapper>
      ),
      content: (
        <Typography.Text className="!w-max !text-[14px]">
          {`${
            withdrawInvoice?.invoiceDetail?.withdrawalDetail?.bankName ?? ''
          } ******${
            withdrawInvoice?.invoiceDetail?.withdrawalDetail
              ?.lastFourBankNumber ?? ''
          }`}
        </Typography.Text>
      ),
    },
    {
      title: (
        <GenericTitleWrapper>
          <ContainerOutlined className="!text-primaryPurple !text-[24px]" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:order_id')}
          </Typography.Text>
        </GenericTitleWrapper>
      ),
      content: (
        <Typography.Text className="!w-max !text-[14px]">
          {withdrawInvoice?.orderId ?? ''}
        </Typography.Text>
      ),
    },
    {
      title: (
        <GenericTitleWrapper>
          <EuroOutlined className="!text-primaryPurple !text-[24px]" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:amount')}
          </Typography.Text>
        </GenericTitleWrapper>
      ),
      content: (
        <Typography.Text className="!w-max !text-[14px]">
          €{formatCurrencyByType(withdrawInvoice?.amount ?? 0, "EUR").replace('€', '')}
        </Typography.Text>
      ),
    },
  ]

  const listContents = [
    {
      title: (
        <Typography.Text className="!text-[14px] !text-neutral-400">
          {t('revenue:bank')}
        </Typography.Text>
      ),
      content: (
        <ContentWrapper $textColor={themes.theme.light.colors.lightBrown}>
          {`******${
            withdrawInvoice?.invoiceDetail?.withdrawalDetail
              ?.lastFourBankNumber ?? ''
          } ${
            withdrawInvoice?.invoiceDetail?.withdrawalDetail?.bankName ?? ''
          }`}
        </ContentWrapper>
      ),
    },
    {
      title: (
        <Typography.Text className="!text-[14px] !text-neutral-400">
          {t('revenue:amount')}
        </Typography.Text>
      ),
      content: (
        <ContentWrapper $textColor={themes.theme.light.colors.darkWarning}>
          {formatCurrencyByType(withdrawInvoice?.amount ?? 0, 'EUR')}
        </ContentWrapper>
      ),
    },
    {
      title: (
        <Typography.Text className="!text-[14px] !text-neutral-400">
          {t('revenue:fees')}
        </Typography.Text>
      ),

      content: (
        <ContentWrapper $textColor={themes.theme.light.colors.darkWarning}>
          {formatCurrencyByType(
            withdrawInvoice?.invoiceDetail?.withdrawalDetail?.fees ?? 0,
            'EUR'
          )}
        </ContentWrapper>
      ),
    },
    {
      title: (
        <Typography.Text className="!text-[14px] !text-neutral-400">
          {t('revenue:actual_amount_received')}
        </Typography.Text>
      ),
      content: (
        <ContentWrapper $textColor={themes.theme.light.colors.darkWarning}>
          {formatCurrencyByType(
            withdrawInvoice?.invoiceDetail?.withdrawalDetail
              ?.actualAmountReceived ?? 0,
            'EUR'
          )}
        </ContentWrapper>
      ),
    },
  ]

  return (
    <WithdrawResultDetailsStyled>
      {ListGenericInfors.map((item, index) => (
        <ItemWrapper
          key={index}
          className={`${
            (index === 2 || index === 4) &&
            '!border-b !border-neutral-300 mb-[14px]'
          }`}
        >
          {item.title}
          {item.content}
        </ItemWrapper>
      ))}
      <Typography.Text className=" !text-[16px]">
        {t('revenue:order_detail')}
      </Typography.Text>
      <OrderDetailWrapper>
        {listContents.map((item) => (
          <ItemWrapper className="mb-3">
            {item?.title}
            {item?.content}
          </ItemWrapper>
        ))}
      </OrderDetailWrapper>
      <ButtonWrapper className="!mt-[80px]">
        <Button
          htmlType="submit"
          type="primary"
          className="!bg-black !text-[12px] !rounded-full !text-white !border-none"
          onClick={onBackToHome}
        >
          {t('revenue:back_to_home')}
        </Button>
      </ButtonWrapper>
    </WithdrawResultDetailsStyled>
  )
}

const WithdrawResultDetailsStyled = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${themes.theme.light.colors.lightGray};
  padding: 24px;
`
const GenericTitleWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 14px;
`

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OrderDetailWrapper = styled.div`
  width: 100%;
  background-color: ${themes.theme.light.colors.lightGray};
  border-radius: 4px;
  padding: 16px 18px;
  margin-top: 14px;
`

const ContentWrapper = styled.span<{ $textColor?: string }>`
  font-size: 14px !important;
  ${({ $textColor }) =>
    !!$textColor &&
    css`
      color: ${$textColor} !important;
    `}
`
