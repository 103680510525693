import { useEffect } from 'react'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { authActions, RootState, useAppDispatch } from '@redux'
import { QK_GET_USER_PROFILE } from '@configs'
import { profileAPI } from 'src/api/profile'
import { BaseResponseError, BaseResponseProps, IProfile } from '@interfaces'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<IProfile>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
}

export const useGetProfileQuery = (queryProps?: IQueryProps) => {
  const { configs } = queryProps || {}

  const dispatch = useAppDispatch()
  const { accessToken } = useSelector((state: RootState) => state.auth)

  const queryClient = useQueryClient()

  function invalidateGetProfile() {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_USER_PROFILE],
      type: 'all',
    })
  }

  function refetchGetProfile() {
    queryClient.refetchQueries({
      queryKey: [QK_GET_USER_PROFILE],
      type: 'all',
    })
  }

  async function getUserApplication() {
    return profileAPI.getAdminProfile()
  }

  const { data: userProfile, ...restData } = useQuery<
    BaseResponseProps<IProfile>,
    BaseResponseError
  >({
    queryKey: [QK_GET_USER_PROFILE],
    queryFn: getUserApplication,
    enabled: !!accessToken,

    ...configs,
  })

  useEffect(() => {
    if (userProfile?.data) {
      dispatch(authActions.setAccountInfo(userProfile?.data))
    }
  }, [dispatch, userProfile])

  return {
    data: userProfile,
    invalidateGetProfile,
    refetchGetProfile,
    ...restData,
  }
}
