import { useState } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'

import { ENotificationTab } from '../configs'
import { NotificationTag } from '../notification-tag/NotificationTag'
import { themes } from '@theme'
import { StyledEmpty, StyledPagination, StyledSpin } from '@components'
import {
  useGetListNotifications,
  useGetNotificationUnreadNumber,
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadByIdMutation,
} from '@api'
import { DEFAULT_LIMIT_ITEMS, DEFAULT_TOTAL_ITEMS } from '@configs'
import { handleAPIError } from '@utils'

export const NotificationList = ({
  activeTab,
}: {
  activeTab: ENotificationTab
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const {
    data: listNotifications,
    isFetching: isGetListNotificationFetching,
    refetchData,
  } = useGetListNotifications({
    params: {
      page: currentPage,
      limit: DEFAULT_LIMIT_ITEMS,
      ...(activeTab === ENotificationTab.NOTIFICATION_UNREAD && {
        isRead: false,
      }),
    },
  })

  const {
    data: unreadNotifications,
    refetchData: refetchGetUnreadNotification,
  } = useGetNotificationUnreadNumber({})

  const refetchDataAfterReadNotification = async () => {
    await refetchData()
    await refetchGetUnreadNotification()
  }

  const { mutate: markAsReadById } = useMarkNotificationAsReadByIdMutation()
  const handleMarkAsReadNotification = async (
    notificationId: number,
    isRead: boolean
  ) => {
    if (isRead) return

    try {
      await markAsReadById(notificationId)
      refetchDataAfterReadNotification()
    } catch (err: any) {
      handleAPIError(err)
    }
  }

  const { mutate: markAllAsRead } = useMarkAllNotificationAsReadMutation()
  const handleMarkAllAsReadNotification = async () => {
    if (!Number(unreadNotifications)) return

    try {
      await markAllAsRead()
      refetchDataAfterReadNotification()
    } catch (err: any) {
      handleAPIError(err)
    }
  }

  return (
    <NotificationListStyled>
      <MarkAllReadStyled onClick={handleMarkAllAsReadNotification}>
        {t('notification:mark_all_as_read')}
      </MarkAllReadStyled>
      <ListNotificationWrapper>
        {isGetListNotificationFetching ? (
          <StyledSpin
            className="!absolute z-20 top-0 left-0 w-full h-full bg-blue-200 bg-opacity-20"
            size="large"
          />
        ) : null}
        {!!listNotifications?.items?.length ? (
          listNotifications?.items.map((item) => (
            <NotificationTag
              notificationItem={item}
              onClickNotification={(notificationId, isRead) =>
                handleMarkAsReadNotification(notificationId, isRead)
              }
            />
          ))
        ) : (
          <StyledEmpty className="!m-auto" />
        )}
      </ListNotificationWrapper>
      <PaginationWrapper>
        <StyledPagination
          current={currentPage}
          pageSize={Number(listNotifications?.limit ?? DEFAULT_LIMIT_ITEMS)}
          total={listNotifications?.total ?? DEFAULT_TOTAL_ITEMS}
          onChange={(page) => {
            setCurrentPage(page)
          }}
          showSizeChanger={false}
        />
      </PaginationWrapper>
    </NotificationListStyled>
  )
}

const NotificationListStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75dvh;
`

const ListNotificationWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 66dvh;
  max-height: 66dvh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid ${themes.theme.dark.colors.primaryPurple};
  border-radius: 8px;
  padding: 8px 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 999px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${themes.theme.dark.colors.primaryPurple};
    border-radius: 999px;
  }
`

const MarkAllReadStyled = styled(Typography.Paragraph)`
  color: ${themes.theme.light.colors.primary};
  font-size: 14px;
  text-decoration: underline;
  text-align: end;
  margin-bottom: 4px !important;
  cursor: pointer;
`
const PaginationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
