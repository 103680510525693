import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { SharedCarousel } from '@components'
import { ISubscription } from '@interfaces'
import { Typography } from 'antd'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { SwiperSlide } from 'swiper/react'
import { Swiper } from 'swiper/types'
import { PackageTag } from './PackageTag'
import { SubscriptionPackagesStyled, TransitionButtonStyled } from './styled'
type TSubscriptionPackagesProps = {
  onSelectPackage?: (packageData?: ISubscription) => void
  packages?: Array<ISubscription>
  title?: string
  subTitle?: string
  initialSlideIndex?: number
  // required a swiperRef passed through
  swiperRef: any
}

export const SubscriptionPackages: React.FC<TSubscriptionPackagesProps> = ({
  onSelectPackage,
  packages,
  title,
  subTitle,
  initialSlideIndex,
  swiperRef,
}) => {
  const canNavigate = useMemo(() => {
    if (!packages) return false
    return packages?.length > 3
  }, [packages])

  const handlePrev = useCallback(() => {
    if (!swiperRef?.current) return
    swiperRef?.current.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!swiperRef?.current) return
    swiperRef?.current.slideNext()
  }, [])

  const handleSelectPackage = (packageData?: ISubscription) => {
    if (!packageData) {
      return
    }
    onSelectPackage?.(packageData)
  }

  useEffect(() => {
    if (swiperRef?.current && Number.isInteger(initialSlideIndex)) {
      swiperRef?.current.slideToLoop(initialSlideIndex, 500, true)
    }
  }, [initialSlideIndex, swiperRef])

  if (!packages) {
    return null
  }
  return (
    <SubscriptionPackagesStyled>
      <Typography.Title level={3} className="!text-[48px] !mb-2">
        {title || t('common:pricing')}
      </Typography.Title>
      <Typography.Text className="!text-[18px] !mt-0">
        {subTitle || t('subscription:please_choose_package_plan')}
      </Typography.Text>
      <div className="w-full flex justify-center">
        <div className="swiper-container w-[75%] flex items-center justify-center gap-4 relative">
          {canNavigate ? (
            <TransitionButtonStyled
              className="prev_button"
              onClick={handlePrev}
            >
              <LeftOutlined className="text-[18px]" />
            </TransitionButtonStyled>
          ) : null}
          <SharedCarousel
            ref={swiperRef}
            onSwiper={(swiper: Swiper) => {
              swiperRef.current = swiper
            }}
            noSwiping={!canNavigate}
            allowTouchMove={canNavigate}
            onSlideChange={(swiper: Swiper) => {}}
          >
            {packages?.map((item) => (
              <SwiperSlide key={item?.id} className="!h-auto mx-auto">
                {({ isActive, isNext, isPrev }) => (
                  <PackageTag
                    key={item?.id}
                    packageData={item}
                    onUpgrade={() => {
                      handleSelectPackage(item)
                      if (!swiperRef?.current) return
                      if (canNavigate && isNext) swiperRef.current.slideNext()
                      if (canNavigate && isPrev) swiperRef.current.slidePrev()
                    }}
                    isActive={isActive}
                  />
                )}
              </SwiperSlide>
            ))}
          </SharedCarousel>
          {canNavigate ? (
            <TransitionButtonStyled
              className="next_button"
              onClick={handleNext}
            >
              <RightOutlined className="text-[18px]" />
            </TransitionButtonStyled>
          ) : null}
        </div>
      </div>
    </SubscriptionPackagesStyled>
  )
}
