import { memo, useMemo } from 'react'
import { t } from 'i18next'
import { Card, Typography } from 'antd'
import { capitalize } from 'lodash'

import { IGetDashboardListCourseOrder } from '@interfaces'
import { CourseAccordion } from 'src/pages/dashboard/list-course/course-acordion'

import { ShareSelectInput, StyledEmpty, StyledSpin } from '@components'
import { CustomFilterDashboard } from 'src/pages/dashboard/custom-filter'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'
import { StyledPagination } from 'src/components/pagination/StyledPagination'
import {
  FilterWrapper,
  FooterWrapper,
  HeaderWrapper,
  ListCourseOrdersStyled,
  TitleWrapper,
} from './themes'
import { useDashboardContext } from 'src/pages/dashboard/provider'
import { useGetFilterDashboardCourse } from '@api'

interface IProps {
  courseOrders?: IGetDashboardListCourseOrder
  isDisabled?: boolean
  isLoading?: boolean
}

const ListCourseOrderIncomeHeader = ({
  courseOrders,
  isDisabled,
}: Partial<IProps>) => {
  const { filter, setFilter } = useDashboardContext()
  const { data: filterCourseData } = useGetFilterDashboardCourse({})

  const courseFilterData = useMemo(() => {
    if (!filterCourseData) return []

    return filterCourseData?.map((item) => ({
      label: item?.title,
      value: item?.id,
    }))
  }, [filterCourseData])

  const handleFilter = ({
    filterBy,
    startDate,
    endDate,
  }: {
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }) => {
    if (
      [EOverviewDateFilter.CUSTOM].includes(filterBy) &&
      (!startDate || !endDate)
    ) {
      return
    }
    setFilter((prev) => ({
      ...prev,
      currentPage: 1,
      filterBy,
      ...(startDate && {
        startDate,
      }),
      ...(endDate && {
        endDate,
      }),
    }))
  }

  return (
    <HeaderWrapper>
      <TitleWrapper className="!items-center">
        <Typography.Title level={2} className="!m-0 !text-[24px]">
          {t('dashboard:course')}
        </Typography.Title>
        <Typography.Paragraph className="!m-0 p-2 !bg-primaryPurple !text-white !font-medium !text-[14px] !rounded-[14px]">
          {`${courseOrders?.totalOrders ?? 0} ${capitalize(
            t('dashboard:orders')
          )}`}
        </Typography.Paragraph>
      </TitleWrapper>
      <FilterWrapper>
        <ShareSelectInput
          data={courseFilterData}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              currentPage: 1,
              courses: value as unknown as number[],
            }))
          }}
          value={filter.courses}
          containerClassName="min-w-[280px] max-w-[400px]"
          placeholder={t('dashboard:course')}
          mode="multiple"
          disabled={isDisabled}
          showArrow
        />
        <CustomFilterDashboard
          containerClassName="!min-w-[280px]"
          onChangeFilterOverview={handleFilter}
          isDisable={isDisabled}
        />
      </FilterWrapper>
    </HeaderWrapper>
  )
}

const ListCourseOrderIncomeFooter = ({ courseOrders }: Partial<IProps>) => {
  const { filter, setFilter } = useDashboardContext()

  if (!courseOrders || !courseOrders.total) return null

  return (
    <FooterWrapper>
      <StyledPagination
        total={courseOrders?.total}
        pageSize={courseOrders?.limit}
        current={filter.currentPage}
        onChange={(page) =>
          setFilter((prev) => ({
            ...prev,
            currentPage: page,
          }))
        }
      />
    </FooterWrapper>
  )
}

export const ListCourseOrderIncome = memo(
  ({ courseOrders, isDisabled, isLoading }: IProps) => {
    return (
      <Card className="relative !rounded-[8px] min-h-[594px]">
        <ListCourseOrderIncomeHeader
          courseOrders={courseOrders}
          isDisabled={isDisabled}
        />
        {isLoading ? (
          <StyledSpin size="large" className="min-h-[420px]" />
        ) : (
          <>
            {!!courseOrders?.items?.length ? (
              <ListCourseOrdersStyled>
                {courseOrders?.items?.map((item) => (
                  <CourseAccordion key={item?.id} courseOrder={item} />
                ))}
              </ListCourseOrdersStyled>
            ) : (
              <StyledEmpty className="!mt-44" />
            )}
            <ListCourseOrderIncomeFooter courseOrders={courseOrders} />
          </>
        )}
      </Card>
    )
  }
)
