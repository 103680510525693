import selectDownIcon from './select-down.png'
import boldIcon from './b-icon.png'
import italicIcon from './italic-icon.png'
import h1Icon from './h1-icon.png'
import h2Icon from './h2-icon.png'
import linkIcon from './link-icon.png'
import blockquoteIcon from './quote.png'
import imageIcon from './image-icon.png'
import listBulletIcon from './list-bullet-icon.png'
import listNumberIcon from './list-numbers-icon.png'
import whiteBoldIcon from './white-b.png'
import whiteItalicIcon from './white-italic.png'
import whiteH1Icon from './white-h1.png'
import whiteH2Icon from './white-h2.png'
import whiteLinkIcon from './white-link.png'
import whiteBlockquoteIcon from './white-quote.png'
import whiteImageIcon from './white-image.png'
import whiteListBulletIcon from './white-list-bullet.png'
import whiteListNumberIcon from './white-list-numbers.png'
import IconFileCommon from './ic-file-common.svg'

export const ICONS = {
  selectDownIcon,
  boldIcon,
  italicIcon,
  h1Icon,
  h2Icon,
  linkIcon,
  blockquoteIcon,
  imageIcon,
  listBulletIcon,
  listNumberIcon,
  whiteBoldIcon,
  whiteItalicIcon,
  whiteH1Icon,
  whiteH2Icon,
  whiteLinkIcon,
  whiteBlockquoteIcon,
  whiteImageIcon,
  whiteListBulletIcon,
  whiteListNumberIcon,
  IconFileCommon,
}
