import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps, TCheckoutPackageRequest } from '@interfaces'
import { handleAPIError } from '@utils'
import { packageManagementAPI } from 'src/api/package-management'
import { QK_CHECKOUT_PACKAGE } from 'src/configs/queryClient/queryKeyConstants'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<any>,
    any,
    TCheckoutPackageRequest,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<any>) => void
  onError?: (err: any) => void
}

export const useCheckoutPackageMutation = (configs?: IMutationParams) => {
  const { onError } = { ...configs }

  return useMutation({
    mutationKey: [QK_CHECKOUT_PACKAGE],
    mutationFn: (payload: TCheckoutPackageRequest) =>
      packageManagementAPI.checkoutPackage(payload),
    onError: (error: any) => {
      onError?.(error)
    },

    ...configs,
  })
}
