import { ReactNode, Ref } from 'react'
import { Swiper as ReactSwiper, SwiperProps, SwiperRef } from 'swiper/react'
import { Autoplay, Grid, Pagination, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/grid'

import { SharedCarouselStyled } from './styled'

interface IProps extends SwiperProps {
  children: ReactNode
  className?: string
  ref?: Ref<SwiperRef>
}

export const SharedCarousel = ({
  children,
  className,
  ref,
  ...restProps
}: IProps) => {
  return (
    <SharedCarouselStyled>
      <ReactSwiper
        ref={ref}
        slidesPerView={3}
        pagination={{
          clickable: true,
          el: '.swiper-pagination',
        }}
        modules={[Pagination, Autoplay, Grid]}
        className={`h-full ${className}`}
        noSwiping={false}
        centeredSlidesBounds
        centeredSlides
        loop
        {...restProps}
      >
        {children}
      </ReactSwiper>
    </SharedCarouselStyled>
  )
}
