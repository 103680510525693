import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import { AppModal } from '@components'
import { Typography } from 'antd'
import { Button } from 'src/common'
import { handleAPIError } from '@utils'

interface IEditCardModalProps {
  cardInformation?: any
  visible?: boolean
  onClose?: () => void
  onSubmit?: (cardInformation: any) => Promise<void>
}

export const ModalAddCard = (props: IEditCardModalProps) => {
  const { visible, cardInformation, onClose, onSubmit } = props

  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [isLoading, setLoading] = React.useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmitCard = async () => {
    setLoading(true)
    try {
      if (stripe && elements) {
        const res = await stripe.createPaymentMethod({
          type: 'card',
          card: elements?.getElement('cardNumber') as any,
          billing_details: { name: 'check' },
        })
        await onSubmit?.(res.paymentMethod)
        onClose?.()
      }
    } catch (error: any) {
      handleAPIError(error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    return () => {
      setErrorMessage('')
    }
  }, [])

  return (
    <AppModal open={visible} onClose={onClose}>
      <div className="w-[600px]">
        <Typography.Text className="text-2xl font-bold capitalize text-black">
          {cardInformation
            ? t('subscription:changeCard')
            : t('subscription:addPaymentInfo')}
        </Typography.Text>
        <CardContainer className="gap-6 mt-10">
          <div className="pb-3 border-b border-solid border-brand-neutral-100 ">
            <CardNumberElement
              onChange={(e) => {
                setErrorMessage(e?.error?.message || '')
              }}
              options={{
                style: {
                  base: {
                    padding: '0 0 12px 0',
                    fontSize: '18px',
                    fontWeight: '500',
                    fontSmoothing: 'antialiased',
                  },
                },
              }}
            />
          </div>
          <div className="pb-3 border-b border-solid border-brand-neutral-100 ">
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    padding: '0 0 12px 0',
                    fontSize: '18px',
                    fontWeight: '500',
                    fontSmoothing: 'antialiased',
                  },
                },
              }}
            />
          </div>
          <div className="pb-3 border-b border-solid border-brand-neutral-100 ">
            <CardCvcElement
              options={{
                style: {
                  base: {
                    padding: '0 0 12px 0',
                    fontSize: '18px',
                    fontWeight: '500',
                    fontSmoothing: 'antialiased',
                  },
                },
              }}
            />
          </div>
        </CardContainer>

        {errorMessage && (
          <Typography.Text className="mt-6" type="danger">
            {errorMessage}
          </Typography.Text>
        )}

        <div className="flex items-center w-full justify-center mt-10">
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            className="!w-40"
            onClick={handleSubmitCard}
            loading={isLoading}
            disabled={!!errorMessage}
          >
            {t('common:upgrade')}
          </Button>
        </div>
      </div>
    </AppModal>
  )
}

const CardContainer = styled.div`
  display: flex;

  & > div:nth-child(1) {
    flex: 1;

    & .InputElement {
      font-size: 16px;
    }
  }

  & > div:nth-child(2) {
    width: 76px;

    & .InputElement {
      font-size: 16px;
    }
  }

  & > div:nth-child(3) {
    width: 76px;

    & .InputElement {
      font-size: 16px;
    }
  }
`
