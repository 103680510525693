import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Checkbox, message, Skeleton } from 'antd'
import { t } from 'i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'

import {
  ECourseDifficulty,
  ECourseType,
  ENationalLanguage,
  EPackagePrivacy,
  ERequest,
  ETeacherRequestStatus,
  PATH_COURSE_MANAGEMENT,
} from '@configs'
import { AppModal, ShareSelectInput, XCloseIcon } from '@components'
import { ICategory, IRequest } from '@interfaces'
import {
  getAllCategoriesAction,
  RootState,
  selectCourseLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Button, Input, SwitchButton, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import DeleteBlogModal from 'src/components/blog/DeleteBlogModal'
import PreviewPhotoModal from 'src/components/chatChannel/PreviewPhotoModal'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { IAddNewCourse, IResponseGetCourseDetail } from 'src/interfaces/course'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import {
  addNewCourseAction,
  deleteCourseByIdAction,
  getCourseDetailAction,
  updateCourseByIdAction,
} from 'src/redux/actions/course'
import { customSlugify } from 'src/utils/customSlugify'
import { getListPackageAction } from 'src/redux/actions/package-management'
import {
  courseSchema,
  ECourseOVState,
  ECourseOVStatus,
  ECourseTypeFilterOptions,
  optionsFilterType,
} from './config'
import { deleteObjectFields, handleAPIError, LogApp } from '@utils'
import { CourseOverviewMinidashboard } from 'src/pages/course-management/components/course-overview/CourseOverviewMinidashboard'
import { useMutationPublishCourse } from '@api'
import InactiveCourseModal from 'src/pages/course-management/components/course-overview/InactiveCourseModal'
import PublishCourseModal from 'src/pages/course-management/components/course-overview/PublisCourseModal'
import { useMutationInactiveCourse } from 'src/api/react-query/hooks/teacher-course/useMutationInactiveCourse'
import useDeepCompareEffect from 'use-deep-compare-effect'
import CourseDescriptionEditor from 'src/pages/course-management/CourseDescriptionEditor'

const CourseCreateScreen = () => {
  const { id } = useParams()
  const courseId = id

  let selectedCourse = useSelector(
    (state: RootState) => state.course.selectedCourse
  )

  selectedCourse = Boolean(courseId)
    ? selectedCourse
    : ({} as IResponseGetCourseDetail)

  const [dataSubmit, setDataSubmit] = useState<IAddNewCourse>(
    {} as IAddNewCourse
  )
  const [previewOpen, setPreviewOpen] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [detailImg, setDetailImg] = useState('')
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    ICategory[]
  >([])
  const [isUploading, setIsUploading] = useState(false)

  const [showModalInactiveCourse, setShowModalInactiveCourse] = useState<{
    open: boolean
    isActive: boolean
  }>({ open: false, isActive: false })

  const onChangeDesktopRef = useRef<any>(null)

  const [reset, setReset] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(selectedCourse?.thumbnail?.url ?? '')
  const [videoSrc, setVideoSrc] = useState(selectedCourse?.demoVideo?.url ?? '')
  const [type, setType] = useState(ECourseType.MEMBER_COURSE)
  const [slug, setSlug] = useState(selectedCourse?.seoConfig?.slug || '')
  //confirm publish
  const [confirm, setConfirm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [defaultFormStatus, setDefaultFormStatus] = useState<{
    targetUser: boolean
    packageIds?: number[]
    userCreationFrom?: string
    userCreationTo?: string
    isDrafted?: boolean
    requests?: IRequest[]
    courseStatus?: IRequest
    isActive?: boolean
  }>({
    targetUser: false,
    isDrafted: true,
    requests: [],
    isActive: false,
  })

  const [openConfirmRemoveTargetUser, setOpenConfirmRemoveTargetUser] =
    useState(false)

  const handleTypeChange = (selectedType: ECourseType) => {
    setType(selectedType)

    const packages = getValues('packageIds')
    if (selectedType !== ECourseType.FREE && packages && packages.includes(1)) {
      setValue(
        'packageIds',
        packages.filter((item: number) => item !== 1)
      )
    }
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { categories } = useAppSelector((state: RootState) => state.categories)
  const { courseRequestDetail } = useAppSelector(
    (state: RootState) => state.course
  )

  const packages = useSelector((state: RootState) => state.package)?.packages

  const uploadThumbnailActionLoading = useSelector((state: RootState) =>
    selectCourseLoading(state, 'uploadThumbnailAction')
  )

  const getCourseDetailActionLoading = useSelector((state: RootState) =>
    selectCourseLoading(state, 'getCourseDetailActionLoading')
  )
  const onCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const onCloseConfirmRemoveTargetUser = () => {
    setOpenConfirmRemoveTargetUser(false)
  }

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    reset: formReset,
    watch,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<IAddNewCourse>({
    defaultValues: {
      title: selectedCourse?.title || '',
      titleInDutch: selectedCourse?.titleInDutch || '',
      description: selectedCourse?.description || '',
      descriptionInDutch: selectedCourse?.descriptionInDutch || '',
      slug: selectedCourse?.seoConfig?.slug || '',
      userId: selectedCourse?.userId || undefined,
      type: selectedCourse?.type || ECourseType.MEMBER_COURSE,
      eurPrice: selectedCourse?.eurPrice ?? 0,
      usdPrice: selectedCourse?.usdPrice ?? 0,
      isActived: selectedCourse?.isActived || false,
      isDrafted: selectedCourse?.isDrafted || true,
      difficulty: selectedCourse?.difficulty || ECourseDifficulty.BEGINNER,
      categoriesIds:
        selectedCourse?.categories?.map((item: any) => item.id) || [],
      subCategoriesIds:
        selectedCourse?.subCategories?.map((item: any) => item.id) || [],
      thumbnailId: selectedCourse?.thumbnailId,
      demoVideoId: selectedCourse?.demoVideoId,
      nationalLanguage:
        selectedCourse?.nationalLanguage || ENationalLanguage.ENGLISH,
      subTitle: null,
      hasFinalTest: false,
      targetUser: false,
      isBestSeller: false,
      packageIds: [],
      userCreationFrom: '',
      userCreationTo: '',
    },
    resolver: zodResolver(courseSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const watchTargetUser = watch('targetUser')
  const watchSubCategoriesIds = watch('subCategoriesIds')

  useEffect(() => {
    if (!!defaultFormStatus.targetUser) {
      return
    }

    if (!watchTargetUser) {
      setValue('packageIds', [])
      setValue('userCreationFrom', '')
      setValue('userCreationTo', '')
    }
  }, [watchTargetUser])

  const reviewState = defaultFormStatus.requests?.find(
    (it) => it?.type === ERequest.PUBLISH
  )?.status as unknown as ECourseOVState

  const courseActiveStatus = defaultFormStatus.courseStatus

  const onClose = () => {
    setPreviewOpen(false)
  }
  const onCloseConfirmModal = () => {
    setOpenConfirmModal(false)
  }
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const filterSubCategoriesIds = (
    categories: ICategory[],
    subCategoriesIds: number[]
  ): number[] => {
    // Create a Set of existing subCategory ids for quick lookup
    const existingSubCategoryIds = new Set<number>()

    for (const category of categories) {
      for (const subCategory of category.subCategories) {
        existingSubCategoryIds.add(subCategory.id)
      }
    }

    // Filter out ids that do not exist in any subCategories
    return subCategoriesIds.filter((id) => existingSubCategoryIds.has(id))
  }

  const handleChangeCategories = (value: any) => {
    if (value.length !== 0) {
      clearErrors(['categoriesIds'])
    }

    const selectedSubCategories = categories.filter((category) =>
      value.includes(category.id)
    )
    setSelectedSubCategories(selectedSubCategories)

    const filterSubCatIds = filterSubCategoriesIds(
      selectedSubCategories,
      watchSubCategoriesIds
    )

    setValue('subCategoriesIds', filterSubCatIds)
  }

  const handleChangePackage = (value: any) => {
    if (!!value.length) {
      clearErrors(['packageIds'])
    }

    setValue('packageIds', value)
  }

  const packageOptions = useMemo(() => {
    if (!packages) return []
    return packages.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }, [packages])

  const handleConfirmSubmit = async (data: IAddNewCourse) => {
    setIsModalLoading(true)
    if (!data) return
    if (courseId) {
      try {
        const response = await dispatch(
          updateCourseByIdAction({
            courseId: courseId,
            params: data,
          })
        ).unwrap()
        if (response?.success) {
          message.success(response?.message)
        }
      } catch (error) {
        LogApp(error)
      } finally {
        setIsModalLoading(false)
        onCloseConfirm()
        formReset(undefined, {
          keepValues: true,
          keepDirtyValues: false,
        })
      }
    } else {
      try {
        const response = await dispatch(addNewCourseAction(data)).unwrap()
        if (response?.success) {
          message.success(response.message)
          navigate(
            `${PATH_COURSE_MANAGEMENT}/${response?.data?.newCourseForTeacher?.id}`,
            {
              state: {
                isCreateNew: true,
              },
            }
          )
        }
      } catch (error) {
        LogApp(error)
      } finally {
        setIsModalLoading(false)
        onCloseConfirm()
      }
    }
  }

  const { mutate: publishTeacherCourseMutation } = useMutationPublishCourse({
    handleSuccess(res) {
      message.success({
        content: res?.message,
      })
      setIsModalLoading(false)
      onCloseConfirm()
      getCourseDetail(String(courseId))
    },
    handleError(error) {
      handleAPIError(error)
      setIsModalLoading(false)
      onCloseConfirm()
    },
  })

  const handlePublishTeacherCourse = async () => {
    if (!courseId) return

    setIsModalLoading(true)
    await publishTeacherCourseMutation(courseId)
  }

  const { mutate: inactiveTeacherCourseMutation } = useMutationInactiveCourse({
    handleSuccess(res) {
      message.success({
        content: t('common:request_success'),
      })
      setIsModalLoading(false)
      setShowModalInactiveCourse({
        open: false,
        isActive: false,
      })
      getCourseDetail(String(courseId))
    },
    handleError(error) {
      handleAPIError(error)
      setIsModalLoading(false)
      setShowModalInactiveCourse({
        open: false,
        isActive: false,
      })
    },
  })

  const handleInactiveTeacherCourse = async (reason: string) => {
    if (!courseId) return

    setIsModalLoading(true)
    await inactiveTeacherCourseMutation({ id: courseId, reason })
  }

  const handleConfirmDelete = async () => {
    try {
      const response = await dispatch(
        deleteCourseByIdAction(courseId as string)
      ).unwrap()

      if (response?.success) {
        message.success(response?.message)
        navigate(PATH_COURSE_MANAGEMENT)
      }
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      setOpenDeleteModal(false)
    }
  }

  // useEffect(() => {
  //   const getDataRightSide = async () => {
  //     try {
  //       // await dispatch(getUserChat({ page: '1', limit: '1000', search: '' }))
  //     } catch (error: any) {}
  //   }
  //   getDataRightSide()
  // }, [dispatch])

  const getCourseDetail = useCallback(
    async (courseId: string) => {
      if (!courseId) return
      setIsLoading(true)
      try {
        const response = await dispatch(
          getCourseDetailAction({
            courseId,
          })
        ).unwrap()

        if (response?.success) {
          const courseDetail = response?.data
          const {
            title,
            description,
            titleInDutch,
            descriptionInDutch,
            type,
            eurPrice,
            usdPrice,
            difficulty,
            thumbnailId,
            demoVideoId,
            categories: categoriesAPI,
            nationalLanguage,
            seoConfig: { slug },
            targetUser,
            hasFinalTest,
            packages: packagesAPI,
            userCreationFrom,
            userCreationTo,
            subTitle,
            isDrafted,
            subCategories,
            isActived,
            requests,
          } = courseDetail || {}
          const selectedCategoriesIds = categoriesAPI.map(
            (category) => category.id
          )
          const selectedPackages = packagesAPI.map((item) => item.id)

          const courseStatus = requests?.find((it) =>
            [ERequest.ACTIVE, ERequest.INACTIVE].includes(it?.type)
          )
          setValue('title', title || '')
          setValue('titleInDutch', titleInDutch || '')
          setValue('slug', slug || '')
          setValue('description', description || '')
          setValue('descriptionInDutch', descriptionInDutch || '')
          setValue('type', type)
          setValue('eurPrice', eurPrice ?? 0)
          setValue('usdPrice', usdPrice ?? 0)
          setValue('difficulty', difficulty)
          setValue('thumbnailId', thumbnailId)
          setValue('demoVideoId', demoVideoId)
          setValue('categoriesIds', selectedCategoriesIds)
          setValue('subCategoriesIds', subCategories.map((it) => it?.id) ?? [])
          setValue('nationalLanguage', nationalLanguage)
          setValue('hasFinalTest', hasFinalTest)
          setValue('targetUser', targetUser)
          setValue('packageIds', selectedPackages)
          setValue('userCreationFrom', userCreationFrom)
          setValue('userCreationTo', userCreationTo)
          setValue('subTitle', subTitle)
          setType(type as ECourseType)
          setImageSrc(courseDetail?.thumbnail?.url)
          setVideoSrc(courseDetail?.demoVideo?.url)
          setDefaultFormStatus({
            targetUser: targetUser,
            packageIds: selectedPackages,
            userCreationFrom: userCreationFrom,
            userCreationTo: userCreationTo,
            isDrafted: isDrafted,
            isActive: isActived,
            courseStatus,
            requests,
          })
        }
      } catch (error: any) {
        message.error(error.message)
        navigate('/course-management')
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, navigate, setValue]
  )

  const disabledInput = useMemo(() => {
    if ([ECourseOVState.PENDING, ECourseOVState.REJECTED].includes(reviewState))
      return true

    if (courseActiveStatus?.status === ETeacherRequestStatus.PENDING)
      return true

    return false
  }, [courseActiveStatus, reviewState])

  const renderButtonBaseOnStatus = useMemo(() => {
    const saveBtn =
      ![ECourseOVState.PENDING, ECourseOVState.REJECTED].includes(
        reviewState
      ) && !courseActiveStatus ? (
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          id="blog-form"
          loading={isModalLoading}
          disabled={isModalLoading}
          onClick={handleSubmit(handleConfirmSubmit)}
        >
          {t('common:save')}
        </Button>
      ) : null

    const publishBtn =
      !!courseId && !reviewState ? (
        <Button
          type="primary"
          size="small"
          id="blog-form"
          loading={isModalLoading}
          disabled={isModalLoading}
          className="!bg-green-500 !text-neutral-700 !border-green-500"
          onClick={() => {
            if (!!Object.entries(dirtyFields).length) {
              message.warning(
                t('courseManagement:save_before_publish') as string
              )
              return
            }
            setOpenConfirm(true)
          }}
        >
          {t('common:publish_now')}
        </Button>
      ) : null

    const activeBtn =
      !!courseId &&
      [ECourseOVState.ACCEPTED].includes(reviewState) &&
      !defaultFormStatus.isActive ? (
        <Button
          type="primary"
          size="small"
          id="blog-form"
          loading={isModalLoading}
          disabled={isModalLoading}
          className="!bg-green-500 !text-neutral-700 !border-green-500"
          onClick={() =>
            setShowModalInactiveCourse({
              open: true,
              isActive: false,
            })
          }
        >
          {t('courseManagement:active_course')}
        </Button>
      ) : null

    const inactiveBtn =
      !!courseId &&
      [ECourseOVState.ACCEPTED].includes(reviewState) &&
      defaultFormStatus.isActive ? (
        <Button
          type="primary"
          size="small"
          id="blog-form"
          loading={isModalLoading}
          disabled={isModalLoading}
          className="!bg-yellow-500 !text-neutral-700 !border-yellow-500"
          onClick={() =>
            setShowModalInactiveCourse({
              open: true,
              isActive: true,
            })
          }
        >
          {t('courseManagement:inactive_course')}
        </Button>
      ) : null

    return (
      <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
        <Button
          type="default"
          size="small"
          id="blog-form"
          onClick={() => navigate(PATH_COURSE_MANAGEMENT)}
        >
          {t('common:cancel')}
        </Button>
        {!disabledInput ? (
          <>
            {saveBtn}
            {publishBtn}
            {activeBtn}
            {inactiveBtn}
          </>
        ) : null}
      </div>
    )
  }, [
    courseActiveStatus,
    courseId,
    defaultFormStatus.isActive,
    dirtyFields,
    disabledInput,
    handleSubmit,
    isModalLoading,
    navigate,
    reviewState,
  ])

  useEffect(() => {
    dispatch(getAllCategoriesAction())
    dispatch(
      getListPackageAction({
        page: 1,
        limit: 99999,
        isActive: true,
        type: EPackagePrivacy.PUBLIC,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (!courseId) return
    setIsLoading(true)

    getCourseDetail(String(courseId))
  }, [courseId, dispatch, getCourseDetail])

  useDeepCompareEffect(() => {
    const { categories: categoriesAPI } = courseRequestDetail || {}

    const categoriesIds = categoriesAPI?.map((category: any) => category.id)

    const selectedSubCategories = categories?.filter((category) =>
      categoriesIds?.includes(category.id)
    )
    setSelectedSubCategories(selectedSubCategories)
  }, [categories, courseRequestDetail, setValue])

  return (
    <CreateCourseStyled>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} className="p-5" />
      ) : (
        <>
          {!!courseId && !!defaultFormStatus?.requests?.length ? (
            <CourseOverviewMinidashboard
              courseActiveStatus={defaultFormStatus.courseStatus}
              reviewState={defaultFormStatus.requests?.find((it) =>
                [ERequest.PUBLISH].includes(it?.type)
              )}
              isActive={defaultFormStatus.isActive}
            />
          ) : null}

          <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
            <div className="intro-y box">
              <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
                {!disabledInput ? (
                  <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
                    {Boolean(courseId)
                      ? t('courseManagement:edit_course')
                      : t('courseManagement:create_new_course')}
                  </h2>
                ) : null}
              </div>

              <div className="blog-content-main intro-y mt-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="title"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Title"
                              value={value}
                              onChange={(event) => {
                                setSlug((pre) => event?.target?.value?.trim())
                                onChange(event?.target?.value)
                                setValue(
                                  'slug',
                                  customSlugify(event?.target?.value?.trim())
                                )
                              }}
                              required
                              disabled={disabledInput}
                              label="Title"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="titleInDutch"
                        defaultValue=""
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Title in Netherlands"
                              value={value}
                              onChange={onChange}
                              required
                              disabled={disabledInput}
                              label="Title in Netherlands"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="blog-content-m-left intro-y col-span-12">
                    <Controller
                      name="slug"
                      defaultValue=""
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            placeholder="Slug"
                            value={value}
                            onChange={onChange}
                            label="Slug"
                            errors={error?.message}
                            required
                            disabled={disabledInput}
                            alignment="col"
                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                          />
                        )
                      }}
                    />
                  </div>
                </div>

                <div className="blog-content-main intro-y mt-5">
                  <label>
                    Description{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8 mb-4">
                    <Controller
                      name="description"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CourseDescriptionEditor
                            name="description"
                            value={value}
                            clearErrors={clearErrors}
                            setError={setError}
                            setValue={setValue}
                            error={error}
                            disabled={disabledInput}
                          />
                        )
                      }}
                    />
                  </div>

                  <label>
                    Description in Netherlands{' '}
                    <span className="required text-[#B91C1C] font-bold">*</span>
                  </label>
                  <div className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
                    <Controller
                      name="descriptionInDutch"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <CourseDescriptionEditor
                            name="descriptionInDutch"
                            value={value}
                            clearErrors={clearErrors}
                            setError={setError}
                            setValue={setValue}
                            error={error}
                            disabled={disabledInput}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-5">
                  <div className="blog-categories">
                    <Controller
                      name="categoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            data={
                              !getCourseDetailActionLoading
                                ? categories &&
                                  categories?.map((item, index) => {
                                    return {
                                      label: item.name,
                                      value: item.id,
                                    }
                                  })
                                : []
                            }
                            label="Categories"
                            mode="multiple"
                            placeholder="Select categories"
                            value={value}
                            onChange={(value) => {
                              onChange(value)
                              handleChangeCategories(value)
                            }}
                            errors={error?.message}
                            showArrow
                            disabled={disabledInput}
                            required
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-tags">
                    <Controller
                      name="subCategoriesIds"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            options={
                              getCourseDetailActionLoading ||
                              !selectedSubCategories?.length
                                ? []
                                : (selectedSubCategories?.map((item) => {
                                    return {
                                      label: item.name,
                                      options: item.subCategories?.map(
                                        (it) => ({
                                          label: it.name,
                                          value: it.id,
                                        })
                                      ),
                                    }
                                  }) as any)
                            }
                            label="Sub Category"
                            mode="multiple"
                            placeholder="Select Sub categories"
                            onChange={(e) => {
                              onChange(e)
                            }}
                            value={value}
                            errors={error?.message}
                            showArrow
                            disabled={disabledInput}
                            onFocus={() => {
                              if (selectedSubCategories.length === 0) {
                                message.warning(
                                  'Please select categories first!'
                                )
                              }
                            }}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="blog-type">
                    <Controller
                      name="type"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={optionsFilterType.filter(
                              (it) => it?.value !== ECourseTypeFilterOptions.ALL
                            )}
                            label="Type"
                            onChange={(selectedType) => {
                              onChange(selectedType)
                              handleTypeChange(selectedType as ECourseType)
                            }}
                            value={value}
                            disabled={
                              !defaultFormStatus.isDrafted || disabledInput
                            }
                            required
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type">
                    <Controller
                      name="difficulty"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'Blog value'}
                            data={[
                              {
                                value: ECourseDifficulty.BEGINNER,
                                label: ECourseDifficulty.BEGINNER,
                              },
                              {
                                value: ECourseDifficulty.MODERATE,
                                label: ECourseDifficulty.MODERATE,
                              },
                              {
                                value: ECourseDifficulty.INTERMEDIATE,
                                label: ECourseDifficulty.INTERMEDIATE,
                              },
                              {
                                value: ECourseDifficulty.ADVANCED,
                                label: ECourseDifficulty.ADVANCED,
                              },
                            ]}
                            label="Difficulty"
                            onChange={onChange}
                            value={value}
                            required
                            disabled={disabledInput}
                          />
                        )
                      }}
                    />
                  </div>

                  {/* {type !== ECourseType.FREE && ( */}
                  <div className="blog-published">
                    <label className="blog-published-label mb-2">
                      Price in EUR
                    </label>
                    <span className="required text-[#B91C1C] font-bold">
                      {' '}
                      *
                    </span>
                    <Controller
                      name="eurPrice"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            name="eurPrice"
                            type="number"
                            onChange={(e) => {
                              if (
                                !e.target.value ||
                                parseFloat(e.target.value) < 0
                              ) {
                                onChange(0)
                                return
                              }
                              onChange(parseFloat(e.target.value))
                            }}
                            value={value}
                            errors={error?.message}
                            disabled={
                              !defaultFormStatus.isDrafted || disabledInput
                            }
                            required
                          />
                        )
                      }}
                    />
                  </div>
                  {/* )} */}
                  {/* {type !== ECourseType.FREE && ( */}
                  <div className="blog-published">
                    <label className="blog-published-label mb-2">
                      Price in USD
                    </label>
                    <span className="required text-[#B91C1C] font-bold">
                      {' '}
                      *
                    </span>
                    <Controller
                      name="usdPrice"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            name="usdPrice"
                            type="number"
                            onChange={(e) => {
                              if (
                                !e.target.value ||
                                parseFloat(e.target.value) < 0
                              ) {
                                onChange(0)
                                return
                              }
                              onChange(parseFloat(e.target.value))
                            }}
                            value={value}
                            errors={error?.message}
                            disabled={
                              !defaultFormStatus.isDrafted || disabledInput
                            }
                            required
                          />
                        )
                      }}
                    />
                  </div>
                  {/* )} */}

                  <div className="blog-type">
                    <Controller
                      name="nationalLanguage"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            defaultValue={'English'}
                            data={[
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            label="National language"
                            onChange={onChange}
                            value={value}
                            required
                            disabled={disabledInput}
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-type">
                    <Controller
                      name="subTitle"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <ShareSelectInput
                            name="subTitle"
                            defaultValue={''}
                            data={[
                              {
                                value: '',
                                label: '',
                              },
                              {
                                value: ENationalLanguage.ENGLISH,
                                label: t('common:english'),
                              },
                              {
                                value: ENationalLanguage.NETHERLAND,
                                label: t('common:netherland'),
                              },
                            ]}
                            showArrow
                            label="Subtitle"
                            onChange={(value) =>
                              !!value ? onChange(value) : onChange(null)
                            }
                            value={value ?? ''}
                            disabled={disabledInput}
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="blog-published !h-fit">
                    <span className="flex items-center gap-4">
                      <Controller
                        name="hasFinalTest"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Checkbox
                              name="hasFinalTest"
                              onChange={(e) => {
                                onChange(e.target.checked)
                              }}
                              checked={value}
                              className="!w-fit !h-fit !font-normal"
                              disabled={
                                disabledInput ||
                                [ECourseOVState.ACCEPTED].includes(reviewState)
                              }
                            >
                              {t('section:have_final_test')}
                            </Checkbox>
                          )
                        }}
                      />
                    </span>
                    <div>
                      <span className="flex items-center gap-4">
                        <Controller
                          name="targetUser"
                          control={control}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Checkbox
                                name="targetUser"
                                onChange={(e) => {
                                  if (
                                    defaultFormStatus.targetUser &&
                                    !defaultFormStatus.isDrafted &&
                                    !!courseId &&
                                    !e.target.checked
                                  ) {
                                    setOpenConfirmRemoveTargetUser(true)
                                    return
                                  }
                                  onChange(e.target.checked)
                                }}
                                checked={value}
                                className="!w-fit !h-fit !font-normal"
                                disabled={
                                  (!defaultFormStatus.targetUser &&
                                    !defaultFormStatus.isDrafted &&
                                    !!courseId) ||
                                  disabledInput
                                }
                              >
                                Target user
                              </Checkbox>
                            )
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  {watchTargetUser && (
                    <div className="blog-published col-span-2 grid grid-cols-2 gap-4 mb-4">
                      <div className="blog-type">
                        <Controller
                          name="packageIds"
                          control={control}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => {
                            return (
                              <ShareSelectInput
                                data={packageOptions}
                                label="Packages"
                                mode="multiple"
                                placeholder="Select packages"
                                value={value}
                                onChange={handleChangePackage}
                                errors={error?.message}
                                showArrow
                                disabled={
                                  (!!defaultFormStatus.targetUser &&
                                    !defaultFormStatus.isDrafted) ||
                                  disabledInput
                                }
                                required={watchTargetUser}
                              />
                            )
                          }}
                        />
                      </div>
                      <div className="flex items-start justify-between gap-4">
                        <div className="w-full">
                          <Controller
                            name="userCreationFrom"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation from"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  disabled={
                                    (!!defaultFormStatus.targetUser &&
                                      !defaultFormStatus.isDrafted) ||
                                    disabledInput
                                  }
                                  value={value}
                                />
                              )
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <Controller
                            name="userCreationTo"
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => {
                              return (
                                <Input
                                  containerClassName="w-full !flex !flex-col items-start !gap-[0px]"
                                  type="date"
                                  label="User creation to"
                                  alignment="col"
                                  errors={error?.message}
                                  onChangeDate={onChange}
                                  disabled={
                                    (!!defaultFormStatus.targetUser &&
                                      !defaultFormStatus.isDrafted) ||
                                    disabledInput
                                  }
                                  value={value}
                                />
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="blog-type" hidden={watchTargetUser}></div>

                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Thumbnail
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      name={'thumbnailId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        onChangeDesktopRef.current = onChange
                        return (
                          <>
                            <CustomDragger
                              // label="Image"
                              containerClassName="mt-[10px]"
                              onLoadEnd={(data) => {
                                setValue('thumbnailId', data?.id ?? 0)
                                onChange(data?.id ?? 0)
                                // setIsChange(true)
                              }}
                              name="thumbnailId"
                              id="thumbnailId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={imageSrc}
                              reset={reset}
                              allowFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/jpg',
                              ]}
                              limitFileSize={5}
                              changeLoading={(loading) =>
                                setIsModalLoading(loading)
                              }
                              uploadType={EMediaType.IMAGE as any}
                              uploadCategory={EMediaCategory.COURSE}
                              disabled={disabledInput}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                  <div className="blog-published">
                    <div className="wrapper-thumbnail-section mb-2 flex justify-between items-center">
                      <label className="blog-add-thumbnail-label">
                        Demo Video
                        <span className="required text-[#B91C1C] font-bold">
                          {' '}
                          *
                        </span>
                      </label>
                    </div>
                    <Controller
                      name={'demoVideoId'}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        onChangeDesktopRef.current = onChange
                        return (
                          <>
                            <CustomDragger
                              // label="Image"
                              containerClassName="mt-[10px]"
                              onLoadEnd={(data) => {
                                setValue(
                                  'demoVideoId',
                                  data?.dataValues.id ?? 0
                                )
                                onChange(data?.dataValues.id ?? 0)
                                // setIsChange(true)
                              }}
                              name="demoVideoId"
                              id="demoVideoId"
                              required
                              errors={error?.message}
                              multiple={false}
                              initResource={videoSrc}
                              reset={reset}
                              allowFileTypes={[
                                'video/mp4',
                                'video/webm',
                                'video/m4v',
                                'video/mov',
                                'video/qt',
                              ]}
                              limitFileSize={0}
                              changeLoading={(loading) =>
                                setIsModalLoading(loading)
                              }
                              uploadType={EMediaType.VIDEO as any}
                              uploadCategory={EMediaCategory.COURSE}
                              disabled={disabledInput}
                            />
                          </>
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {renderButtonBaseOnStatus}
          </div>
          <PreviewPhotoModal
            open={previewOpen}
            onDelete={() => {}}
            onSave={() => {}}
            onClose={onClose}
            url={previewImage || detailImg}
          />

          <DeleteBlogModal
            open={openDeleteModal}
            content={'Are you sure to delete this course?'}
            onSave={handleConfirmDelete}
            onClose={onCloseDeleteModal}
          />

          {showModalInactiveCourse.open ? (
            <InactiveCourseModal
              open
              isActive={showModalInactiveCourse.isActive}
              onClose={() =>
                setShowModalInactiveCourse({
                  open: false,
                  isActive: false,
                })
              }
              onSave={(data) => handleInactiveTeacherCourse(data.reason)}
            />
          ) : null}

          <PublishCourseModal
            open={openConfirm}
            onClose={onCloseConfirm}
            onSave={handlePublishTeacherCourse}
          />

          <AppModal open={openConfirmRemoveTargetUser} haveCloseIcon={false}>
            <div className="flex items-center justify-between gap-4">
              <div>
                <h1 className="m-0 text-[20px]">
                  Are you sure to remove target user for this course ?
                </h1>
              </div>
              <div className="hover:opacity-75 cursor-pointer">
                <XCloseIcon
                  width={16}
                  height={16}
                  onClick={onCloseConfirmRemoveTargetUser}
                />
              </div>
            </div>
            <div className="mt-6">
              <span className="text-[16px] text-danger">
                You CAN NOT edit this after you Save it !.
              </span>
            </div>
            <div className="mt-6">
              <div className="flex items-center mt-5 justify-end">
                <Button
                  type="ghost"
                  size="middle"
                  className="submit__btn login-btn mr-10"
                  onClick={onCloseConfirmRemoveTargetUser}
                >
                  {'No'}
                </Button>
                <Button
                  htmlType="button"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  onClick={() => {
                    setValue('targetUser', false)
                    setOpenConfirmRemoveTargetUser(false)
                  }}
                >
                  {'Yes'}
                </Button>
              </div>
            </div>
          </AppModal>
        </>
      )}
    </CreateCourseStyled>
  )
}

export default CourseCreateScreen

const CreateCourseStyled = styled('div')(() => {
  return {
    background: 'white',
    padding: '20px',
    borderRadius: '0.5rem',
    minHeight: '100dvh',

    label: {
      fontWeight: 'bold',
    },
    '.label': {
      fontWeight: 'bold',
    },
    '.ant-picker-disabled': {
      background: '#fafafa !important',
    },
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },

    '.ant-upload.ant-upload-select-picture-card': {
      width: '100%',
      height: 'auto',
    },
  }
})
