import { QK_PAYMENT_CARDS } from '@configs'
import { BaseResponseError, BaseResponseProps, TPaymentInfo } from '@interfaces'
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { packageManagementAPI } from 'src/api/package-management'

interface IQueryProps {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<TPaymentInfo[]>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
}

export const useGetTeacherPaymentCardsQuery = (queryProps?: IQueryProps) => {
  const { configs } = queryProps || {}

  const queryClient = useQueryClient()

  function invalidateGetTeacherPaymentCard() {
    queryClient.invalidateQueries({
      queryKey: [QK_PAYMENT_CARDS],
      type: 'all',
    })
  }

  function refetchGetTeacherPaymentCard() {
    queryClient.refetchQueries({
      queryKey: [QK_PAYMENT_CARDS],
      type: 'all',
    })
  }

  async function getGetTeacherPaymentCard() {
    return packageManagementAPI.getTeacherPaymentCards()
  }

  const { data: paymentCardData, ...restData } = useQuery<
    BaseResponseProps<TPaymentInfo[]>,
    BaseResponseError
  >({
    queryKey: [QK_PAYMENT_CARDS],
    queryFn: getGetTeacherPaymentCard,
    ...configs,
  })

  return {
    data: paymentCardData,
    invalidateGetTeacherPaymentCard,
    refetchGetTeacherPaymentCard,
    ...restData,
  }
}
