import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'

import { profileAPI } from 'src/api/profile'
import { QK_GET_USER_APPLICATION } from 'src/configs/queryClient/queryKeyConstants'
import { BaseResponseError, TGetTeacherApplicationResponse } from '@interfaces'

interface IGetTeacherApplication {
  options: Omit<
    UseQueryOptions<TGetTeacherApplicationResponse, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
}

export const useGetTeacherApplicationQuery = ({
  options,
}: IGetTeacherApplication): UseQueryResult<
  TGetTeacherApplicationResponse,
  BaseResponseError
> => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_USER_APPLICATION],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_USER_APPLICATION],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getUserApplication(): Promise<TGetTeacherApplicationResponse> {
    return profileAPI.getUserApplication()
  }

  return useQuery<TGetTeacherApplicationResponse, BaseResponseError>({
    ...options,
    queryKey: [QK_GET_USER_APPLICATION],
    queryFn: getUserApplication,
  })
}
