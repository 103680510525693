import { createContext, useContext, useState, SetStateAction } from 'react'

import { deleteObjectFields } from '@utils'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'

interface IDashboardFilter {
  courses?: number[]
  filterBy?: EOverviewDateFilter
  startDate?: string
  endDate?: string
  currentPage?: number
}

export interface ITempCourseInfor {
  totalOrder?: number
  totalAmount?: number
}

interface IDashboardContextValue {
  filter: IDashboardFilter
  temporaryCoursesInfor: Record<string, ITempCourseInfor>
  setFilter: (value: SetStateAction<IDashboardFilter>) => void
  setTemporaryCoursesInfor: (
    value: SetStateAction<Record<string, ITempCourseInfor>>
  ) => void
  removeTemporaryCourseByKey: (key: string) => void
}

const initialState = {
  filter: {
    courses: [],
    filterBy: EOverviewDateFilter.LAST_SIX_MONTHS,
    currentPage: 1,
  },
  temporaryCoursesInfor: {},
  setFilter: (value: SetStateAction<IDashboardFilter>) => {},
  setTemporaryCoursesInfor: (
    value: SetStateAction<Record<string, ITempCourseInfor>>
  ) => {},
  removeTemporaryCourseByKey: (key: string) => {},
}

const DashboardContext = createContext<IDashboardContextValue>(initialState)

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [filter, setFilter] = useState<IDashboardFilter>(initialState.filter)
  const [temporaryCoursesInfor, setTemporaryCoursesInfor] = useState<
    Record<string, ITempCourseInfor>
  >(initialState.temporaryCoursesInfor)

  const removeTemporaryCourseByKey = (key: string) => {
    const newTemporaryCoursesInfor = { ...temporaryCoursesInfor }
    deleteObjectFields(newTemporaryCoursesInfor, [key])
    setTemporaryCoursesInfor(newTemporaryCoursesInfor)
  }

  return (
    <DashboardContext.Provider
      value={{
        filter,
        temporaryCoursesInfor,
        setFilter,
        setTemporaryCoursesInfor,
        removeTemporaryCourseByKey,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)

  if (!context)
    throw new Error('DashboardContext must be use inside DashboardProvider')

  return context
}
