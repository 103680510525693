import { AppModal, XCloseIcon } from '@components'
import { handleAPIError } from '@utils'
import { message, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDeletePaymentCardMutation } from 'src/api/react-query/hooks/profile/useDeletePaymentCardMutation'
import { Button } from 'src/common'

interface IProps {
  open: boolean
  onClose: () => void
  cardId: string
  onDeleteSuccess: () => void
}

export default function DeleteCardModal({
  open,
  onClose,
  cardId,
  onDeleteSuccess,
}: IProps) {
  const { t } = useTranslation(['profile', 'common'])
  const { mutateAsync: deleteCardInfo, isPending: isSubmitting } =
    useDeletePaymentCardMutation()

  const handleDeleteCard = async () => {
    try {
      await deleteCardInfo({
        cardId,
      })
      message.success(t('profile:deleteCardSuccess'))
      onDeleteSuccess()
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon={false}
      contentClassName="!px-8 !py-6"
    >
      <div className="h-auto">
        <div className="flex items-center justify-between gap-4">
          <div>
            <Typography.Title
              style={{ marginBottom: 0, fontSize: 24, fontWeight: 'bold' }}
            >
              {t('profile:deleteCard')}
            </Typography.Title>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[450px] mt-4 mb-8 mx-auto">
        <Typography style={{ color: '#424955FF', fontSize: 16 }}>
          {t('profile:deleteCardMessage')}
        </Typography>
      </div>

      <div className="flex flex-col justify-center items-center my-2 gap-2">
        <Button
          type="primary"
          style={{
            width: '100%',
            borderRadius: '100rem',
            maxWidth: 200,
            background: '#535CE8FF',
          }}
          loading={isSubmitting}
          onClick={handleDeleteCard}
        >
          {t('common:confirm')}
        </Button>

        <Button
          danger
          type="text"
          onClick={onClose}
          style={{
            width: '100%',
            borderRadius: '100rem',
            maxWidth: 200,
          }}
        >
          <Typography.Text
            style={{ color: '#FF765AFF', textDecoration: 'underline' }}
          >
            {t('common:cancel')}
          </Typography.Text>
        </Button>
      </div>
    </AppModal>
  )
}
