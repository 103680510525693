export const stripeFeesCalculate = (amount: number) => {
  const stripeFixedFee = Number(process.env.REACT_APP_PUBLIC_STRIPE_FIXED_FEE)
  const stripePercentageFee = Number(
    process.env.REACT_APP_PUBLIC_STRIPE_PERCENTAGE_FEE
  )

  console.log()

  return Number(
    (stripeFixedFee + (stripePercentageFee * amount) / 100).toFixed(2)
  )
}
