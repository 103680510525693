import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  ProjectOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { useAppDispatch } from '@redux'
import { List, message, Row } from 'antd'
import { useEffect, useState } from 'react'
import { Button, SwitchButton } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

import {
  ELessonType,
  ICourseSections,
  ILesson,
  ITrailLesson,
} from 'src/interfaces/course'
import { deleteSectionAction } from 'src/redux/actions/course'
import { deleteLessonAction } from 'src/redux/actions/lesson-management'
import { useSectionContext } from '../../provider/section/SectionProvider'
import styled from 'styled-components'
import lesson from 'src/redux/Slices/lesson'
import { ECourseType } from '@configs'
import { t } from 'i18next'

function Section({
  item,
  index,
  courseType,
  onCloseEditSection,
  onOpenEditSection,
  onReset,
  onEditLesson,
  isActive,
  onSwitchTrial,
}: {
  item: ICourseSections
  index: number
  courseType?: ECourseType
  onOpenEditSection: (item: ICourseSections) => void
  onEditLesson: (item: ILesson, sectionId: number) => void
  onCloseEditSection: () => void
  onReset: () => void
  isActive?: boolean
  onSwitchTrial?: (trialLessons: ITrailLesson[]) => void
}) {
  const { openModalSelect, sectionId, openModal, setOpenModal } =
    useSectionContext()
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [trialLessonList, setTrialLessonList] = useState<ITrailLesson[]>([])

  const dispatch = useAppDispatch()
  const [selectLesson, setSelectLesson] = useState<number | undefined>(
    undefined
  )
  const [selectSection, setSelectSection] = useState<number | undefined>(
    undefined
  )

  const haveFinalTestLesson = !!item.lessons.find(
    (lesson) => lesson.type === ELessonType.FINAL_TEST
  )

  const [openConfirmDeleteLessonModal, setOpenConfirmDeleteLessonModal] =
    useState(false)
  const onDeleteLesson = async () => {
    if (!selectLesson) {
      return
    }
    try {
      setIsLoading(true)
      const response = await dispatch(deleteLessonAction(selectLesson)).unwrap()
      message.success({
        content: response.message,
      })
      setSelectLesson(undefined)
      setOpenConfirmDeleteLessonModal(false)
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    } finally {
      setIsLoading(false)
      onReset()
    }
  }
  const onCloseConfirmLessonModal = () => {
    setOpenConfirmDeleteLessonModal(false)
  }
  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }

  const canAddTrial = (id: number) => {
    if (item.isFinal) return false
    if (courseType === ECourseType.FREE) return false
    if (index !== 0) return false
    const canTrialLessons = item.lessons.slice(0, 2)
    for (let lessonItem of canTrialLessons) {
      if (lessonItem && lessonItem.id === id) {
        return true
      }
    }
    return false
  }

  const handleSwitchTrialLesson = (id: number, checked: boolean) => {
    setTrialLessonList((prevList) => {
      return prevList.map((lesson, index) => {
        if (lesson.id === id) {
          lesson.isTrial = checked

          if (!lesson.isTrial && index < prevList.length - 1) {
            prevList[index + 1].isTrial = false
          }
        }
        return lesson
      })
    })
  }

  const checkIsTrial = (index: number) => {
    if (trialLessonList.length <= 0) return false
    return trialLessonList[index].isTrial
  }

  useEffect(() => {
    if (item.lessons.length <= 0) return
    if (item.isFinal) return
    const defaultTrialLessonList = item.lessons.slice(0, 2).map((lesson) => {
      return {
        id: lesson.id,
        isTrial: lesson.isTrial ?? false,
      }
    })
    setTrialLessonList(defaultTrialLessonList)
  }, [item])

  const onDelete = async () => {
    if (!selectSection) {
      return
    }
    try {
      const response = await dispatch(
        deleteSectionAction(selectSection)
      ).unwrap()
      message.success({
        content: response.message,
      })
      setSelectSection(undefined)
      setOpenConfirmDeleteModal(false)
      onReset()
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }

  const handleOpenModalAddLesson = (isFinal: boolean) => {
    if (isFinal) {
      setOpenModal && setOpenModal(ELessonType.FINAL_TEST)
    } else {
      setOpenModal && setOpenModal(ELessonType.VIDEO)
    }
  }

  useEffect(() => {
    onSwitchTrial && onSwitchTrial(trialLessonList)
  }, [trialLessonList])

  return (
    <>
      <List
        size="large"
        style={{
          marginBottom: '5px',
          borderRadius: '4px',
          borderColor: '#ccc',
          margin: '20px',
        }}
        header={
          <Row className="items-center justify-between ">
            <h1 className="!text-[24px] font-bold text-black">
              {`${index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}. ${
                item.title
              }`}
            </h1>
            {!item.isFinal && (
              <Row className="gap-4">
                {isActive ? (
                  <EditOutlined
                    className="text-[18px]"
                    onClick={() => {
                      onOpenEditSection(item)
                    }}
                  />
                ) : (
                  <EyeOutlined
                    className="text-[18px]"
                    onClick={() => {
                      onOpenEditSection(item)
                    }}
                  />
                )}
                {isActive && (
                  <DeleteOutlined
                    className="text-[18px] !text-red-500"
                    onClick={() => {
                      setSelectSection(item.id)
                      setOpenConfirmDeleteModal(true)
                    }}
                  />
                )}
              </Row>
            )}
          </Row>
        }
        bordered
        dataSource={item.lessons}
        footer={
          !(haveFinalTestLesson && item.isFinal) &&
          isActive && (
            <Button
              htmlType="button"
              type="primary"
              size="middle"
              className="submit__btn login-btn ml-5 "
              // loading={isLoading}
              onClick={() => handleOpenModalAddLesson(item.isFinal)}
            >
              {item.isFinal
                ? t('section:add_finalTest')
                : t('section:add_lesson')}
            </Button>
          )
        }
        renderItem={(itemLesson, index) => {
          // temporary hide the lesson Assignment and audio
          if (
            itemLesson.type === ELessonType.AUDIO ||
            itemLesson.type === ELessonType.ASSIGNMENT_EXERCISE ||
            itemLesson.type === ELessonType.ASSIGNMENT_TEST
          )
            return
          return (
            <List.Item className="ml-5 cursor-pointer">
              <Row className="items-center gap-5">
                {itemLesson.type === ELessonType.VIDEO && (
                  <VideoCameraOutlined size={20} />
                )}
                {/* {itemLesson.type === ELessonType.AUDIO && <AudioOutlined size={20} />}
              {(itemLesson.type === ELessonType.ASSIGNMENT_EXERCISE ||
                itemLesson.type === ELessonType.ASSIGNMENT_TEST) && (
                <FileDoneOutlined size={20} />
              )} */}
                {itemLesson.type === ELessonType.FINAL_TEST && (
                  <ProjectOutlined size={20} />
                )}
                {itemLesson.title}
              </Row>
              <Row className="gap-4">
                {canAddTrial(itemLesson.id) && (
                  <TrialStyleWrapper>
                    <label className="blog-show-author-label">
                      Available for trial
                    </label>

                    <SwitchButton
                      size="default"
                      onChange={(checked) =>
                        handleSwitchTrialLesson(itemLesson.id, checked)
                      }
                      defaultChecked={itemLesson.isTrial ?? false}
                      checked={checkIsTrial(index)}
                      disabled={
                        index === 1 &&
                        (!trialLessonList[0] || !trialLessonList[0].isTrial)
                      }
                    />
                  </TrialStyleWrapper>
                )}

                {isActive ? (
                  <EditOutlined
                    className="text-[18px]"
                    onClick={() => {
                      if (!sectionId) return
                      onEditLesson(itemLesson, sectionId)
                    }}
                  />
                ) : (
                  <EyeOutlined
                    className="text-[18px]"
                    onClick={() => {
                      if (!sectionId) return
                      onEditLesson(itemLesson, sectionId)
                    }}
                  />
                )}
                {isActive && itemLesson.type !== ELessonType.FINAL_TEST && (
                  <DeleteOutlined
                    className="text-[18px] !text-red-500"
                    onClick={() => {
                      setOpenConfirmDeleteLessonModal(true)
                      setSelectLesson(itemLesson.id)
                    }}
                  />
                )}
              </Row>
            </List.Item>
          )
        }}
      />
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this course's section "
          content={
            isActive
              ? 'Are you sure to delete this section?'
              : 'This section was published , this deletion will affect to others participating in this!'
          }
          open={openConfirmDeleteModal}
          isLoading={false}
          onDelete={onDelete}
          highlight={!isActive}
          onClose={onCloseConfirmModal}
        />
      )}
      {openConfirmDeleteLessonModal && (
        <ConfirmDeleteModal
          title="Delete this course's lesson "
          content={
            isActive
              ? 'Are you sure to delete this lesson?'
              : 'This lesson was published , this deletion will affect to others participating in this!'
          }
          open={openConfirmDeleteLessonModal}
          isLoading={isLoading}
          onDelete={onDeleteLesson}
          onClose={onCloseConfirmLessonModal}
        />
      )}
    </>
  )
}

const TrialStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export default Section
