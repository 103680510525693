import { Card, Typography } from 'antd'
import { memo } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import PersonalInformationForm from './PersonalInformationForm'
import { TPersonalInformationForm } from '../types'

export const PersonalInformation = memo((props: TPersonalInformationForm) => {
  return (
    <PersonalInformationStyled>
      <Typography.Title level={4} className="text-left">
        {t('signUp:personal_information')}
      </Typography.Title>
      <PersonalInformationForm {...props} />
    </PersonalInformationStyled>
  )
})

const PersonalInformationStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
