import React from 'react'
import { useForm } from 'react-hook-form'
import { t } from 'i18next'
import { Typography } from 'antd'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button, HookFormTextArea } from 'src/common'
import { AppModal } from '@components'

interface IInactiveModalProps {
  open: boolean
  isActive: boolean
  onSave: (reason: { reason: string }) => void
  onClose: () => void
}

const schema = z.object({
  reason: z
    .string()
    .min(1, {
      message: t('error:thisFieldRequired') as string,
    })
    .max(500, {
      message: t('error:max_length_500'),
    }),
})

const InactiveCourseModal = ({
  open,
  onClose,
  onSave,
  isActive,
}: IInactiveModalProps) => {
  const { control, handleSubmit } = useForm<{ reason: string }>({
    defaultValues: { reason: '' },
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  return (
    <AppModal open={open} onClose={onClose}>
      <div className="wrapper-sendfile w-[700px] h-auto">
        <Typography.Title level={3} className="!m-0 !text-[20px]">
          {isActive
            ? t('courseManagement:inactive_course')
            : t('courseManagement:active_course')}
        </Typography.Title>

        <div className="mt-6">
          <Typography.Paragraph className="!m-0 !text-[14px] mb-5">
            {isActive
              ? t('courseManagement:confirm_inactive')
              : t('courseManagement:confirm_active')}
          </Typography.Paragraph>
          <HookFormTextArea
            control={control}
            controlName="reason"
            className="min-h-[100px]"
          />
          <div className="flex items-center mt-5 justify-end">
            <Button
              type="ghost"
              size="large"
              className="submit__btn login-btn mr-2"
              onClick={onClose}
            >
              {t('common:no')}
            </Button>
            <Button
              type="primary"
              size="large"
              className="submit__btn login-btn"
              onClick={handleSubmit(onSave)}
            >
              {t('common:yes')}
            </Button>
          </div>
        </div>
      </div>
    </AppModal>
  )
}

export default InactiveCourseModal
