import { useMemo, useState } from 'react'
import { message, Typography } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { AppModal } from '@components'
import { themes } from '@theme'
import { Button, Loading } from 'src/common'
import { withdrawInputSchema } from './config'
import { formatCurrencyByType } from 'src/utils/priceFormatter'
import { CreateWithdrawForm } from './CreateWithdrawForm'
import { WithdrawVerifyModal } from 'src/pages/withdrawal/create-withdraw-modal/withdraw-verification-modal/WithdrawVerifyModal'
import { stripeFeesCalculate } from 'src/utils/stripeFeesCalculate'
import { useWithdrawContext } from 'src/pages/withdrawal/provider'
import { useGetWithdrawInformationQuery, useWithdrawMutation } from '@api'
import { handleAPIError } from '@utils'
import { WITHDRAWAL_LIMIT } from '@configs'

interface IProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  onFailed?: () => void
}

const WithdrawalBottom = ({
  onWithdraw,
  isLoading,
  disabled,
  actualAmount,
  fees,
}: {
  onWithdraw: () => {}
  isLoading?: boolean
  actualAmount?: number
  fees?: number
  disabled?: boolean
}) => {
  return (
    <WithdrawalBottomStyled>
      <BottomContentWrapper>
        <Typography.Title
          level={4}
          className="!m-0 !text-[20px] !text-neutral-700"
        >
          {t('revenue:transaction_fees')}
        </Typography.Title>
        <Typography.Title
          level={4}
          className="!m-0 !text-[20px] !text-neutral-700"
        >
          {formatCurrencyByType(fees ?? 0, 'EUR')}
        </Typography.Title>
      </BottomContentWrapper>
      <BottomContentWrapper>
        <Typography.Title
          level={4}
          className="!m-0 !text-[20px] !text-neutral-700"
        >
          {t('revenue:actual_amount_received')}
        </Typography.Title>
        <Typography.Title
          level={4}
          className="!m-0 !text-[20px] !text-neutral-700"
        >
          {!!actualAmount ? formatCurrencyByType(actualAmount, 'EUR') : '--- €'}
        </Typography.Title>
      </BottomContentWrapper>

      <Button
        className="!w-full !rounded-full !border-none !bg-black !text-white mt-4"
        htmlType="submit"
        type="primary"
        onClick={onWithdraw}
        loading={isLoading}
        disabled={disabled}
      >
        {t('revenue:withdraw')}
      </Button>
    </WithdrawalBottomStyled>
  )
}

export const CreateWithDrawModal = (props: IProps) => {
  const { onClose, open, onSuccess, onFailed } = { ...props }
  const { setGenericWithdrawalInfo } = useWithdrawContext()
  const [openModalVerification, setOpenModalVerification] = useState(false)

  const { data: withdrawData, isFetching: isGettingWithdrawData } =
    useGetWithdrawInformationQuery({
      configs: {},
    })

  const maxBalance = withdrawData?.totalBalance ?? 0

  const schemaWithMax = withdrawInputSchema.extend({
    amount: withdrawInputSchema.shape.amount.refine(
      (val) => {
        const maxLimit =
          maxBalance > WITHDRAWAL_LIMIT ? WITHDRAWAL_LIMIT : maxBalance
        return val <= maxLimit
      },
      {
        message: t('revenue:over_budget'),
      }
    ),
  })

  const { watch, control, handleSubmit } = useForm<{
    amount: number
  }>({
    mode: 'onChange',
    defaultValues: {
      amount: undefined,
    },
    resolver: zodResolver(schemaWithMax),
  })

  const watchAmount = watch('amount')

  const fees = useMemo(() => {
    if (!watchAmount || watchAmount < 10) return 0

    return stripeFeesCalculate(watchAmount)
  }, [watchAmount])

  const actualAmount = useMemo(() => {
    if (!watchAmount || watchAmount < 10) return 0

    return watchAmount - fees
  }, [fees, watchAmount])

  const { mutate: withdraw, isPending: isSendingOtp } = useWithdrawMutation({
    handleSuccess(value) {
      message.success(value?.message ?? '')
      setGenericWithdrawalInfo((prev) => ({
        ...prev,
        bankName: withdrawData?.bankName,
        lastFourBankNumber: withdrawData?.lastFourBankNumber,
        totalBalance: withdrawData?.totalBalance,
        fees: fees,
        actualAmount: actualAmount,
        hash: value?.data,
      }))
      setOpenModalVerification(true)
    },
    onError(err) {
      handleAPIError(err)
    },
  })

  const handleWithdraw = handleSubmit(async (value) => {
    setGenericWithdrawalInfo((prev) => ({
      ...prev,
      amount: value.amount,
    }))
    await withdraw(value.amount)
  })

  return (
    <>
      {openModalVerification ? (
        <WithdrawVerifyModal
          open={true}
          onClose={() => setOpenModalVerification(false)}
          isSendingOtp={isSendingOtp}
          resendOtp={handleWithdraw}
          onSuccess={() => {
            setOpenModalVerification(false)
            onSuccess?.()
          }}
          onFailed={() => {
            setOpenModalVerification(false)
            onFailed?.()
          }}
        />
      ) : null}
      <AppModal
        open={open}
        onClose={onClose}
        title={
          <Typography.Title level={4} className="!text-[24px] !text-center">
            {t('revenue:new_withdraw')}
          </Typography.Title>
        }
        contentClassName="!min-w-[900px] !px-8 !max-h-[95dvh]"
        childrenClassName="!mt-0"
      >
        <CreateWithdrawContainer>
          {isGettingWithdrawData ? (
            <div className="w-full flex items-center justify-center">
              <Loading className="!text-[32px]" />
            </div>
          ) : (
            <>
              <CreateWithdrawForm
                control={control}
                available={withdrawData?.totalBalance}
                bankAccount={withdrawData?.bankName}
                withdrawLimit={WITHDRAWAL_LIMIT}
              />
              <WithdrawalBottom
                onWithdraw={handleWithdraw}
                actualAmount={actualAmount}
                fees={fees}
                disabled={!actualAmount || actualAmount < 0}
                isLoading={isSendingOtp}
              />
            </>
          )}
        </CreateWithdrawContainer>
      </AppModal>
    </>
  )
}

const CreateWithdrawContainer = styled.div`
  padding: 32px;
  border: 1px solid ${themes.theme.light.colors.lightGray};
  border-radius: 4px;
`

const WithdrawalBottomStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BottomContentWrapper = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
