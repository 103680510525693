import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Socket } from 'socket.io-client'

import ChatProvider, { useChatContext } from './ChatContext'
import ChatContent from './content'
import ChatLeftSideBar from './left-side-bar'
import useSocket from 'src/utils/hooks/useSocket'
import { getCourseDetailAction } from 'src/redux/actions/course'
import { useAppDispatch } from '@redux'
import { handleAPIError } from '@utils'
import { PATH_COURSE_MANAGEMENT } from '@configs'

function ChatTab() {
  const { setSocket } = useChatContext()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id: courseId } = useParams()

  const socketInstance = useSocket({
    url: process.env.REACT_APP_PUBLIC_API_URL
      ? process.env.REACT_APP_PUBLIC_API_URL.replace('/api', '/course-chat')
      : '',
    queryParams: {
      courseId: String(courseId) || '',
    },
  })

  const getCourseDetails = async () => {
    if (!courseId) return

    try {
      await dispatch(
        getCourseDetailAction({
          courseId: courseId,
          params: {
            checkDeleted: true,
          },
        })
      ).unwrap()
    } catch (error: any) {
      handleAPIError(error)
      navigate(PATH_COURSE_MANAGEMENT)
    }
  }

  useEffect(() => {
    if (!socketInstance) return

    setSocket(socketInstance as Socket)

    return () => {
      socketInstance.disconnect()
    }
  }, [setSocket, socketInstance])

  useEffect(() => {
    getCourseDetails()
  }, [])

  return (
    <div className="flex items-start justify-center gap-1 bg-white w-full h-[75dvh] 2xl:h-[80dvh]">
      <ChatLeftSideBar />
      <ChatContent />
    </div>
  )
}

export default function ChatTabWithContext() {
  return (
    <ChatProvider>
      <ChatTab />
    </ChatProvider>
  )
}
