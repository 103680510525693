import { useMemo, useState } from 'react'
import { Typography } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'

import { DashboardOverview } from 'src/pages/dashboard/overview'
import {
  useGetDashboardChannelQuery,
  useGetDashboardListOrderQuery,
  useGetDashboardOverviewQuery,
} from '@api'
import { EOverviewDateFilter } from 'src/pages/dashboard/overview/config'
import { DashboardChannels } from 'src/pages/dashboard/channels'
import { CURRENT_YEAR } from '@configs'
import { ListCourseOrderIncome } from 'src/pages/dashboard/list-course'
import {
  DashboardProvider,
  useDashboardContext,
} from 'src/pages/dashboard/provider'

const Dashboard = () => {
  const { filter } = useDashboardContext()

  const [filterOverview, setFilterOverview] = useState<{
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }>({ filterBy: EOverviewDateFilter.LAST_SIX_MONTHS })
  const [filterChannel, setFilterChannel] = useState<string | number>(
    CURRENT_YEAR
  )

  const courseOrdersQueryParams = useMemo(() => {
    const { currentPage, courses, ...rest } = filter

    return {
      ...rest,
      ...(!!courses?.length && {
        courseIds: courses?.join(','),
      }),
      page: currentPage ?? 1,
      limit: 5,
    }
  }, [filter])

  const { data: overviewData, isFetching: isFetchingOverviewData } =
    useGetDashboardOverviewQuery({
      queryParams: {
        ...filterOverview,
        limit: 10,
        page: 1,
      },
    })

  const { data: channelsData } = useGetDashboardChannelQuery({
    year: filterChannel,
  })

  const { data: courseOrders, isFetching: dashboardListOrderLoading } =
    useGetDashboardListOrderQuery({
      queryParams: courseOrdersQueryParams,
    })

  const handleFilterOverview = ({
    filterBy,
    startDate,
    endDate,
  }: {
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }) => {
    if (
      [EOverviewDateFilter.CUSTOM].includes(filterBy) &&
      (!startDate || !endDate)
    ) {
      return
    }
    setFilterOverview({
      filterBy,
      ...(startDate && {
        startDate,
      }),
      ...(endDate && {
        endDate,
      }),
    })
  }

  return (
    <>
      <Typography.Title level={2}>{t('dashboard:dashboard')}</Typography.Title>
      <DashboardStyled>
        <DashboardOverview
          onChangeFilterOverview={handleFilterOverview}
          overviewData={overviewData}
          isLoading={isFetchingOverviewData}
        />
        <DashboardChannels
          dashboardChannels={channelsData}
          onChangeFilter={(year) => setFilterChannel(year)}
        />
        <ListCourseOrderIncome
          courseOrders={courseOrders}
          isLoading={dashboardListOrderLoading}
          // isDisabled={!courseOrders?.items?.length}
        />
      </DashboardStyled>
    </>
  )
}

export const DashboardWithContext = () => (
  <DashboardProvider>
    <Dashboard />
  </DashboardProvider>
)

const DashboardStyled = styled.div`
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
