import styled, { css } from 'styled-components'

import { themes } from '@theme'
import { SubscriptionStyled } from 'src/pages/subscription/styled'
import { EScreenSize } from '@configs'

export const SubscriptionPackagesStyled = styled(SubscriptionStyled)`
  .swiper-wrapper {
    align-items: center;
    height: 480px;
  }
`
export const PackageTagStyled = styled.div<{ $isCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 30%;
  border-radius: 8px;
  padding: 16px 24px;
  margin: 12px 0;
  ${(props) =>
    props.$isCenter
      ? css`
          height: 440px;
          background: white;
          border: 1px solid ${themes.theme.light.colors.active};
          transition: all 0.25s ease-out;
        `
      : css`
          height: 400px;
          background: ${themes.theme.light.colors.bg_neutral_200};
          color: black;
        `}
`

export const TransitionButtonStyled = styled.div`
  cursor: pointer;
  min-width: 32px !important;
  max-width: 32px !important;
  min-height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border: 2px solid #000;
`
