import { t } from 'i18next'
import { Typography } from 'antd'
import styled from 'styled-components'
import { capitalize } from 'lodash'

import { themes } from '@theme'
import { ECourseOVState } from 'src/pages/course-management/config'
import { IRequest } from '@interfaces'
import { ERequest, ETeacherRequestStatus } from '@configs'

interface IProps {
  reviewState?: IRequest
  courseActiveStatus?: IRequest
  isActive?: boolean
}

const colorRendering: Record<ECourseOVState, string> = {
  [ECourseOVState.PENDING]: themes.theme.light.colors.darkWarning,
  [ECourseOVState.REJECTED]: themes.theme.light.colors.delete,
  [ECourseOVState.ACCEPTED]: themes.theme.light.colors.highlight,
}

export const CourseOverviewMinidashboard = ({
  courseActiveStatus,
  reviewState,
  isActive,
}: IProps) => {
  const renderStatus = (courseActiveStatus?: IRequest) => (
    <>
      {!courseActiveStatus && isActive ? (
        <Typography.Paragraph className="!text-green-500 !text-[14px] !m-0">
          {t('courseManagement:course_status', {
            string: t('common:active'),
          })}
        </Typography.Paragraph>
      ) : null}
      {!courseActiveStatus && !isActive ? (
        <Typography.Paragraph className="!text-red-500 !text-[14px] !m-0">
          {t('courseManagement:course_status', {
            string: t('common:inactive'),
          })}
        </Typography.Paragraph>
      ) : null}
    </>
  )

  const renderPendingStatus = (
    state?: string,
    courseActiveStatus?: IRequest
  ) => (
    <>
      {courseActiveStatus?.type === ERequest.ACTIVE &&
      courseActiveStatus?.status === ETeacherRequestStatus.PENDING ? (
        <Typography.Title
          level={4}
          className="course_status_text !text-[14px] !m-0"
        >
          {t('courseManagement:course_status', {
            string: t('courseManagement:pending_request_active'),
          })}
        </Typography.Title>
      ) : null}
      {courseActiveStatus?.type === ERequest.INACTIVE &&
      courseActiveStatus?.status === ETeacherRequestStatus.PENDING ? (
        <Typography.Title
          level={4}
          className="course_status_text !text-[14px] !m-0"
        >
          {t('courseManagement:course_status', {
            string: t('courseManagement:pending_request_inactive'),
          })}
        </Typography.Title>
      ) : null}
    </>
  )

  const renderReviewState = (reviewState?: IRequest) => {
    if (!reviewState) return null

    return (
      <>
        <Typography.Title
          level={2}
          className="review_state_text !text-[24px] !m-0"
        >
          {t('courseManagement:review_state', {
            string: capitalize(reviewState?.status),
          })}
        </Typography.Title>
        {reviewState?.status === ETeacherRequestStatus.REJECTED ? (
          <Typography.Text className="review_state_text">
            {t('courseManagement:reason', {
              string: capitalize(reviewState?.responseData?.reason),
            })}
          </Typography.Text>
        ) : null}
      </>
    )
  }

  return (
    <MiniDashboardWrapperStyled $state={reviewState?.status}>
      <div className="mini-dashboard-content">
        {renderReviewState(reviewState)}
        {renderPendingStatus(reviewState?.status, courseActiveStatus)}
        {renderStatus(courseActiveStatus)}
      </div>
    </MiniDashboardWrapperStyled>
  )
}

const MiniDashboardWrapperStyled = styled.div<{ $state?: string }>`
  width: max-content;
  max-width: 100%;
  border-radius: 16px;
  padding: 4px;
  margin-bottom: 32px;
  box-shadow: ${(props) =>
        colorRendering[props.$state as ECourseOVState] ??
        `${themes.theme.light.colors.primary}`}
      1px 3px 20px -10px,
    ${(props) =>
        colorRendering[props.$state as ECourseOVState] ??
        `${themes.theme.light.colors.primary}`}
      0px 10px 20px -10px,
    ${(props) =>
        colorRendering[props.$state as ECourseOVState] ??
        `${themes.theme.light.colors.primary}`}
      0px -2px 6px 0px inset;

  .mini-dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    background: #fff;
    border-radius: 8px;
    padding: 8px 12px;
  }

  .review_state_text {
    color: ${(props) =>
      colorRendering[props.$state as ECourseOVState] ?? `#000`};
  }

  .course_status_text {
    color: ${themes.theme.light.colors.error} !important;
  }
`
