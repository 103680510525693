import {
  COURSE_DETAIL_HEADING_HEIGHT,
  ECourseType,
  ERequest,
  ETeacherRequestStatus,
  PATH_COURSE_MANAGEMENT,
} from '@configs'
import { RootState, useAppDispatch } from '@redux'
import { message, Skeleton } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ELessonType,
  ICourseSections,
  ILesson,
  ITrailLesson,
} from 'src/interfaces/course'
import { t } from 'i18next'
import {
  deleteSectionAction,
  getCourseDetailAction,
  getCourseSectionDetailAction,
  updateSectionTrialLessonsAction,
} from 'src/redux/actions/course'
import { EmptyLesson } from './EmptyLesson'
import ModalAddSection from './ModalAddSection'

import Section from '../../components/sections/Section'
import { SectionProvider } from '../../provider/section/SectionProvider'
import ModalAssignment from '../assignment/ModalAssignment'
import ModalAudio from './ModalAudio'
import ModalVideo from './ModalVideo'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import ModalFinalTest from 'src/pages/course-management/form/final-test/ModalFinalTest'
import ModalMedia from './media/MediaModal'
import { Button } from 'src/common'
import { useSelector } from 'react-redux'

interface ILessonManagementProps {
  activeTab?: string
}

export function LessonManagement({ activeTab }: ILessonManagementProps) {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [listSections, setListSections] = useState<ICourseSections[]>([])

  const [reset, setReset] = useState(false)
  const [sectionId, setSectionId] = useState<number | undefined>(undefined)
  const [isActive, setIsActive] = useState(true)
  const [section, setSection] = useState<ICourseSections | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [openEditSection, setOpenEditSectionModal] = useState<boolean>(false)
  const [courseType, setCourseType] = useState<ECourseType>(ECourseType.FREE)
  const [trialLessonList, setTrialLessonList] = useState<ITrailLesson[]>([])
  const [hasFinalTest, setHasFinalTest] = useState(false)

  const { selectedCourse } = useSelector((state: RootState) => state.course)

  const onCloseEditSection = () => {
    setOpenEditSectionModal(false)
    setSection(undefined)
    // onReset()
  }

  const [openEditLesson, setOpenEditLessonModal] = useState<boolean>(false)
  const [lesson, setLesson] = useState<any>(undefined)
  const onCloseEditLesson = () => {
    setOpenEditLessonModal(false)
    setSection(undefined)
    // onReset()
  }

  const isViewOnly = useMemo(() => {
    if (!selectedCourse || !selectedCourse?.requests?.length) return false

    const publisRequestStatus = selectedCourse.requests.find((it) =>
      [ERequest.PUBLISH].includes(it?.type)
    )?.status

    const activeOrInactiveRequestStatus = selectedCourse.requests.find((it) =>
      [ERequest.ACTIVE, ERequest.INACTIVE].includes(it?.type)
    )?.status

    if (activeOrInactiveRequestStatus) {
      return [ETeacherRequestStatus.PENDING].includes(
        activeOrInactiveRequestStatus as ETeacherRequestStatus
      )
    }

    if (publisRequestStatus) {
      return [
        ETeacherRequestStatus.PENDING,
        ETeacherRequestStatus.REJECTED,
        ETeacherRequestStatus.ACCEPTED,
      ].includes(publisRequestStatus as ETeacherRequestStatus)
    }
  }, [selectedCourse])

  const canShowBtnSaveTrial = useMemo(() => {
    const firstSection = listSections?.filter((it) => !it?.isFinal)?.[0]
    return firstSection?.lessons?.length > 0 && courseType !== ECourseType.FREE
  }, [courseType, listSections])

  const onReset = () => {
    setReset(!reset)
    setLesson(undefined)
  }
  const getSections = async (id: number) => {
    try {
      setIsLoading(true)
      const response = await dispatch(getCourseSectionDetailAction(id)).unwrap()
      if (!!response?.data) {
        setListSections(response.data)
      }
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }

  const listLessonRender = useMemo(() => {
    if (!hasFinalTest) {
      const renderList = listSections.filter((item) => !item.isFinal)
      return renderList
    }
    return listSections
  }, [listSections])

  const getCourseDetail = async () => {
    if (!id) return
    try {
      const response = await dispatch(
        getCourseDetailAction({
          courseId: Number(id),
        })
      ).unwrap()
      if (!!response?.data) {
        setIsActive(response.data?.isDrafted)
        setCourseType(response.data?.type as ECourseType)
        setHasFinalTest(response.data?.hasFinalTest)
      }
    } catch (error: any) {
      message.error(error.message)
      navigate('/course-management')
    }
  }

  const handleChangeTrial = async () => {
    if (!id) return
    const data = {
      body: trialLessonList,
      sectionId: listSections[0].id,
    }
    try {
      const response = await dispatch(
        updateSectionTrialLessonsAction(data)
      ).unwrap()
      if (response.data) {
        message.success(t('common:set_trail_success'))
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  useEffect(() => {
    if (id) {
      getSections(+id)
      getCourseDetail()
    }
  }, [id, reset, activeTab, hasFinalTest])

  return (
    <>
      <div
        className={`w-full min-h-[82vh] justify-start flex  border-1 flex-col  rounded-lg bg-white ${
          !listLessonRender.length && 'bg-white'
        }`}
      >
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4 }} className="p-5" />
        ) : (
          <>
            {!listLessonRender.length && (
              <div className="flex justify-center m-auto">
                <EmptyLesson onReset={onReset} />
              </div>
            )}
            {!!listLessonRender.length && (
              <div className="">
                {isActive && (
                  <div className="algin-baseline m-5">
                    <EmptyLesson
                      isNotEmpty
                      isActive={isActive}
                      onReset={onReset}
                    />
                  </div>
                )}
                {listLessonRender.map((item, index) => (
                  <SectionProvider
                    key={item.id}
                    listSectionsLength={listLessonRender.length}
                    sectionIndex={index}
                    sectionId={item.id}
                    onReset={onReset}
                  >
                    <Section
                      item={item}
                      index={index}
                      courseType={courseType}
                      onSwitchTrial={setTrialLessonList}
                      onCloseEditSection={onCloseEditSection}
                      onOpenEditSection={(item: ICourseSections) => {
                        setSection(item)
                        setOpenEditSectionModal(true)
                      }}
                      isActive={isActive && !isViewOnly}
                      onReset={onReset}
                      onEditLesson={(item: ILesson, sectionId: number) => {
                        setSectionId(sectionId)
                        setOpenEditLessonModal(true)
                        setLesson({
                          type: item.type,
                          id: item.id,
                        })
                      }}
                    />
                  </SectionProvider>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {canShowBtnSaveTrial && (
        <div className="w-full flex items-center mt-5 mb-5 justify-center gap-3">
          <Button
            type="default"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={() => navigate(PATH_COURSE_MANAGEMENT)}
          >
            {t('common:cancel')}
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            size="middle"
            className="submit__btn login-btn"
            loading={false}
            onClick={handleChangeTrial}
          >
            {t('common:save')}
          </Button>
        </div>
      )}

      <ModalAddSection
        open={openEditSection}
        onClose={onCloseEditSection}
        isLoading={true}
        section={section}
        onSave={onReset}
        isActive={isActive && !isViewOnly}
      />

      {lesson && (
        <>
          {lesson?.type === ELessonType.VIDEO && (
            <ModalMedia
              open={openEditLesson}
              onClose={onCloseEditLesson}
              assignmentId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive && !isViewOnly}
            />
          )}
          {lesson?.type === ELessonType.FINAL_TEST && (
            <ModalFinalTest
              open={openEditLesson}
              onClose={() => {
                onCloseEditLesson()
                onReset()
              }}
              finalTestId={lesson.id}
              selectSection={sectionId}
              onSave={onReset}
              isActive={isActive && !isViewOnly}
            />
          )}
        </>
      )}
    </>
  )
}
