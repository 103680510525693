import styled from 'styled-components'
import { t } from 'i18next'
import { SearchOutlined } from '@ant-design/icons'
import {
  Control,
  Controller,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form'

import { Input } from 'src/common'
import { ShareSelectInput } from '@components'
import { INCOME_TYPE_OPTIONS, TABLE_TOOLBAR_RHF_FIELDS } from './config'

interface IProps {
  control: Control<any, any>
  reset: UseFormReset<any>
  setValue: UseFormSetValue<any>
}

export const CourseOrderTableToolbar = ({
  control,
  reset,
  setValue,
}: IProps) => {
  return (
    <CourseOrderTableToolbarStyled>
      <Controller
        name={TABLE_TOOLBAR_RHF_FIELDS.SEARCH}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <Input
              inputStyledClassName="max-w-[350px]"
              prefix={<SearchOutlined className="pr-2 pl-0" />}
              placeholder={t('dashboard:search_placeholder')}
              onChange={onChange}
              value={value}
            />
          )
        }}
      />

      <Controller
        name={TABLE_TOOLBAR_RHF_FIELDS.TYPE}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <ShareSelectInput
              data={INCOME_TYPE_OPTIONS}
              onChange={(value: string) => {
                onChange(value)
              }}
              errors={error?.message}
              value={value}
              containerClassName="min-w-[160px] max-w-max"
              placeholder={t('dashboard:type')}
              showArrow
            />
          )
        }}
      />
    </CourseOrderTableToolbarStyled>
  )
}

const CourseOrderTableToolbarStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  .ant-input-prefix {
    padding-left: 8px;
  }
`
