import { t } from 'i18next'
import { z } from 'zod'
import { capitalize } from 'lodash'

import { ECourseType, ETeacherRequestStatus, MIN_PRICE } from '@configs'

export const enum ECourseStatusFilterOptions {
  ALL = 'All status',
  ACTIVE = '1',
  INACTIVE = '0',
}

export const enum EActionSort {
  ASC = 'asc',
  DESC = 'desc',
}

export const enum ECourseActiveStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const enum ECoursePublishStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export const enum ECourseOVState {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export const enum ECourseOVStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export const enum ECourseTypeFilterOptions {
  ALL = 'All type',
  PREMIUM = 'PREMIUM',
  MEMBER_COURSE = 'MEMBER_COURSE',
}

export const optionsFilterStatus: {
  value: ECourseStatusFilterOptions
  label: string
}[] = [
  {
    value: ECourseStatusFilterOptions.ALL,
    label: t('courseManagement:all_status'),
  },
  {
    value: ECourseStatusFilterOptions.ACTIVE,
    label: t('courseManagement:active'),
  },
  {
    value: ECourseStatusFilterOptions.INACTIVE,
    label: t('courseManagement:inactive'),
  },
]

export const optionsFilterType: {
  value: ECourseTypeFilterOptions
  label: string
}[] = [
  {
    value: ECourseTypeFilterOptions.ALL,
    label: t('courseManagement:all_type'),
  },
  {
    value: ECourseTypeFilterOptions.PREMIUM,
    label: t('courseManagement:premium'),
  },
  {
    value: ECourseTypeFilterOptions.MEMBER_COURSE,
    label: t('courseManagement:member_course'),
  },
]

export const optionsFilterReviewState: {
  value: ETeacherRequestStatus
  label: string
}[] = [
  {
    value: ETeacherRequestStatus.ALL,
    label: capitalize(ETeacherRequestStatus.ALL),
  },
  {
    value: ETeacherRequestStatus.PENDING,
    label: capitalize(ETeacherRequestStatus.PENDING),
  },
  {
    value: ETeacherRequestStatus.ACCEPTED,
    label: capitalize(ETeacherRequestStatus.ACCEPTED),
  },
  {
    value: ETeacherRequestStatus.REJECTED,
    label: capitalize(ETeacherRequestStatus.REJECTED),
  },
  {
    value: ETeacherRequestStatus.NO_DATA,
    label: ETeacherRequestStatus.NO_DATA,
  },
]

const validateHtmlContentLength = (value: string, minLength: number) => {
  if (!value) return false

  const parser = new DOMParser()
  const doc = parser.parseFromString(value, 'text/html')
  const parsedText = doc.body.textContent || ''

  return parsedText.length >= minLength
}

export const courseSchema = z
  .object({
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:blog_title_too_long') as string,
      }),
    titleInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:blog_title_too_long') as string,
      }),
    slug: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:slug_length_error') as string,
      }),
    description: z
      .string()
      .nonempty({ message: t('error:field_required') as string })
      .refine((value) => validateHtmlContentLength(value, 50), {
        message: t('error:min_length_50') as string,
      }),
    descriptionInDutch: z
      .string()
      .nonempty({ message: t('error:field_required') as string })
      .refine((value) => validateHtmlContentLength(value, 50), {
        message: t('error:min_length_50') as string,
      }),
    type: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    eurPrice: z
      .number({
        required_error: t('error:field_required') as string,
      })
      .min(MIN_PRICE, {
        message: t('error:min_price', {
          num: MIN_PRICE,
        }) as string,
      }),
    usdPrice: z
      .number({
        required_error: t('error:field_required') as string,
      })
      .min(MIN_PRICE, {
        message: t('error:min_price', {
          num: MIN_PRICE,
        }) as string,
      }),
    difficulty: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    thumbnailId: z.number({
      required_error: t('error:field_required') as string,
    }),
    demoVideoId: z.number({
      required_error: t('error:field_required') as string,
    }),
    categoriesIds: z.array(z.number()).nonempty({
      message: t('error:field_required') as string,
    }),
    subCategoriesIds: z.array(z.number()).optional(),
    nationalLanguage: z.string().nonempty({
      message: t('error:field_required') as string,
    }),
    subTitle: z.string().nullable().optional(),
    hasFinalTest: z.boolean().optional(),
    targetUser: z.boolean().optional(),
    packageIds: z.array(z.number()),
    userCreationFrom: z.string().optional().nullable(),
    userCreationTo: z.string().optional().nullable(),
  })
  .refine(
    (schema) => {
      return !(schema.targetUser && schema.packageIds.length <= 0)
    },
    {
      path: ['packageIds'],
      message: t('error:field_required') as string,
    }
  )
  .transform((data) => ({
    ...data,
    userCreationFrom: data.userCreationFrom
      ? new Date(data.userCreationFrom)
      : undefined,
    userCreationTo: data.userCreationTo
      ? new Date(data.userCreationTo)
      : undefined,
  }))
  .refine(
    (data) => {
      if (data.userCreationFrom && data.userCreationTo) {
        return data.userCreationFrom < data.userCreationTo
      }
      return true
    },
    {
      path: ['userCreationTo'],
      message: t('error:to_date_greater') as string,
    }
  )
// .refine(
//   (data) => {
//     if (
//       [ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
//         data.type as ECourseType
//       )
//     ) {
//       return data.eurPrice >= MIN_PRICE
//     }
//     return true
//   },
//   {
//     path: ['eurPrice'],
//     message: t('error:min_price', {
//       num: MIN_PRICE,
//     }) as string,
//   }
// )
// .refine(
//   (data) => {
//     if (
//       [ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
//         data.type as ECourseType
//       )
//     ) {
//       return data.usdPrice >= MIN_PRICE
//     }
//     return true
//   },
//   {
//     path: ['usdPrice'],
//     message: t('error:min_price', {
//       num: MIN_PRICE,
//     }) as string,
//   }
// )
