import { Typography } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import 'antd/dist/antd.css'

import { AppModal } from '@components'
import { Button, Input } from 'src/common'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { monthYearFormat } from '@configs'
import { convertStartEndDate, EConvertStartDateType } from '@utils'

interface IProps {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onRegenerateInvoice: (month: string) => void
}

export const RegenerateInvoiceModal = ({
  open,
  isLoading,
  onClose,
  onRegenerateInvoice,
}: IProps) => {
  const DEFAULT_SELECT_MONTH = moment()
    .subtract(1, 'months')
    .startOf('month')
    .startOf('day')
    .toISOString()

  const regenerateModalSchema = z.object({
    month: z.string().min(1, {
      message: t('error:field_required'),
    }),
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      month: DEFAULT_SELECT_MONTH,
    },
    resolver: zodResolver(regenerateModalSchema),
  })

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={
        <Typography.Title level={4} className="!mb-0 !text-[24px] !text-left">
          {t('revenue:regenerate_invoice')}
        </Typography.Title>
      }
      contentClassName="!min-w-[500px] !max-w-[500px] !px-8"
      childrenClassName="!mt-2"
    >
      <Typography.Text className="!text-[14px]">
        {t('revenue:re_generate.message')}
        <li className="ml-2">{t('revenue:re_generate.optionLastMonth')}</li>
        <li className="ml-2">{t('revenue:re_generate.optionSpecificDate')}</li>
      </Typography.Text>
      <form className="mt-4">
        <Controller
          control={control}
          name="month"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                alignment="col"
                type="date"
                picker="month"
                dateFormat={monthYearFormat}
                value={value}
                onChangeDate={(value) => {
                  const newDate = new Date(value)
                  onChange(newDate.toISOString())
                }}
                errors={error?.message}
                allowClear={false}
                disabledDate={(current) => {
                  // limit from the past to last month
                  return current && current >= moment().startOf('month')
                }}
              />
            )
          }}
        />
        <ButtonWrapper className="!mt-6 !justify-end">
          <Button
            className="w-full flex justify-center !bg-neutral-500 !text-white !border-neutral-500"
            type="primary"
            onClick={onClose}
          >
            {t('common:back')}
          </Button>
          <Button
            className="w-full flex justify-center"
            type="primary"
            onClick={handleSubmit((value) => {
              const selectMonth = convertStartEndDate(
                value.month,
                EConvertStartDateType.END
              )
              onRegenerateInvoice(selectMonth ?? '')
            })}
            loading={isLoading}
          >
            {isDirty ? t('common:confirm') : t('revenue:generate_last_month')}
          </Button>
        </ButtonWrapper>
      </form>
    </AppModal>
  )
}
