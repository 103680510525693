import { useMemo } from 'react'
import styled from 'styled-components'
import { Tooltip, Typography } from 'antd'
import { t } from 'i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { IGetOrderDetails } from '@interfaces'
import { SharedTable } from 'src/common'
import { ORDER_DETAILS_COLUMN, ORDER_DETAILS_COLUMN_KEYS } from './configs'
import { EIncomeType } from '@configs'
import { TitleWrapperStyled } from 'src/pages/dashboard/list-course/course-acordion/config'

interface IProps {
  orderDetails?: IGetOrderDetails[]
  isLoading?: boolean
}

export const OrderTable = ({ orderDetails, isLoading }: IProps) => {
  const isSoldOrder = orderDetails?.[0]?.incomeType === EIncomeType.SOLD

  const columns = useMemo(() => {
    return ORDER_DETAILS_COLUMN.filter((column) => {
      // check condition to hide/show columns when order type is SOLD/MEMBER_COURSE
      if (
        !isSoldOrder &&
        [
          ORDER_DETAILS_COLUMN_KEYS.COURSE_NORMAL_PRICE,
          ORDER_DETAILS_COLUMN_KEYS.COURSE_DISCOUNT,
          ORDER_DETAILS_COLUMN_KEYS.SHARE_RATIO,
        ].includes(`${column?.key}`)
      ) {
        return false
      }

      if (
        isSoldOrder &&
        [
          ORDER_DETAILS_COLUMN_KEYS.PACKAGE_PRICE,
          ORDER_DETAILS_COLUMN_KEYS.COMMISSION_RATE,
        ].includes(`${column?.key}`)
      ) {
        return false
      }
      return true
    }).map((column) => ({
      ...column,
      ...(column.key === ORDER_DETAILS_COLUMN_KEYS.ORDER_ID && {
        title: () => {
          return (
            <TitleWrapperStyled>
              <p className="m-0">
                {isSoldOrder
                  ? t('dashboard:order_id')
                  : t('dashboard:income_id')}
              </p>
            </TitleWrapperStyled>
          )
        },
      }),
      ...(column.key === ORDER_DETAILS_COLUMN_KEYS.REVENUE_PRICE && {
        title: () => {
          return (
            <TitleWrapperStyled className="flex-col">
              <p className="m-0">
                {isSoldOrder
                  ? t('dashboard:revenue_of_this_order')
                  : t('dashboard:revenue')}
              </p>
              <Tooltip
                title={
                  isSoldOrder
                    ? t('dashboard:tooltip_title_sold')
                    : t('dashboard:tooltip_title_enroll')
                }
              >
                <ExclamationCircleOutlined className="!text-[20px] !text-white" />
              </Tooltip>
            </TitleWrapperStyled>
          )
        },
      }),
    }))
  }, [isSoldOrder])

  return (
    <OrderIncomeTableStyled>
      <Typography.Title level={2} className="text-center !text-[24px] mb-6">
        {isSoldOrder
          ? t('dashboard:order_details')
          : t('dashboard:income_details')}
      </Typography.Title>
      <SharedTable
        columns={columns}
        dataSource={orderDetails}
        loading={{
          spinning: !orderDetails || isLoading,
          size: 'large',
        }}
        hasIndexColumn={false}
        scroll={{ x: isSoldOrder ? 1800 : 1600 }}
        sticky
      />
    </OrderIncomeTableStyled>
  )
}

const OrderIncomeTableStyled = styled.div`
  width: 100%;
  padding-top: 14px;
  animation-name: FadeIn;
  animation-duration: 0.5s;
  transition-timing-function: linear;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .ant-table-wrapper {
    margin: 0 !important;
    margin-top: 16px !important;
  }

  .ant-table-content {
    margin-right: 0 !important;
  }

  // sticky header
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
`
