import { useState } from 'react'
import { Card, Typography } from 'antd'
import { t } from 'i18next'

import { IMAGES } from '@assets'
import { StyledAuthenContainer } from 'src/components/auth/loginStyle'
import { SignUpGeneralForm } from 'src/pages/auth/sign-up/general-form'
import { VerifyOtpForm } from 'src/pages/auth/sign-up/verify-otp'
import { FillInTeacherInformationPage } from 'src/pages/auth/sign-up/fill-in-teacher-information'
import { SIGN_UP_STEP } from 'src/pages/auth/sign-up/configs'

export const SignUpPage = () => {
  const [step, setStep] = useState(SIGN_UP_STEP.GENERAL_INFORMATION) // default is general information

  const formatTitle = () => {
    switch (step) {
      case SIGN_UP_STEP.GENERAL_INFORMATION:
        return t('signUp:begin_your_journey')
      case SIGN_UP_STEP.VERIFY_EMAIL:
        return t('signUp:verify_your_email')
      case SIGN_UP_STEP.FILL_IN_TEACHER_INFORMATION:
        return t('signUp:few_more_question')
      default:
        return t('signUp:begin_your_journey')
    }
  }

  const renderForm = (currentStep: number) => {
    switch (currentStep) {
      case SIGN_UP_STEP.GENERAL_INFORMATION:
        return (
          <SignUpGeneralForm
            onSuccess={() => setStep(SIGN_UP_STEP.VERIFY_EMAIL)}
          />
        )
      case SIGN_UP_STEP.VERIFY_EMAIL:
        return (
          <VerifyOtpForm
            onBack={() => setStep(SIGN_UP_STEP.GENERAL_INFORMATION)}
            onSuccess={() => setStep(SIGN_UP_STEP.FILL_IN_TEACHER_INFORMATION)}
          />
        )
      case SIGN_UP_STEP.FILL_IN_TEACHER_INFORMATION:
        return (
          <FillInTeacherInformationPage
            onBack={() => setStep(SIGN_UP_STEP.GENERAL_INFORMATION)}
          />
        )
      default:
        return (
          <SignUpGeneralForm
            onSuccess={() => setStep(SIGN_UP_STEP.VERIFY_EMAIL)}
          />
        )
    }
  }

  return (
    <StyledAuthenContainer
      className={`!bg-authen_bg_secondary ${
        [SIGN_UP_STEP.FILL_IN_TEACHER_INFORMATION].includes(step) && 'py-8'
      }`}
    >
      <Card
        className={`relative min-w-[600px] ${
          ![SIGN_UP_STEP.FILL_IN_TEACHER_INFORMATION].includes(step)
            ? 'max-w-[600px]'
            : '!border-none'
        } !rounded-2xl`}
      >
        <img
          src={IMAGES.llcTeacherBlackLogo}
          alt=""
          className="logo max-w-[160px] mx-auto mb-6"
        />
        <Typography.Title
          level={3}
          className="capitalize text-center text-[28px]"
        >
          {formatTitle()}
        </Typography.Title>
        {renderForm(step)}
      </Card>
    </StyledAuthenContainer>
  )
}
