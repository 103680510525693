import { useState } from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'
import { t } from 'i18next'

import { useWithdrawContext, WithdrawalProvider } from './provider'
import { BalanceOverview } from 'src/pages/withdrawal/balance-overview/BalanceOverview'
import { EmptyStripe } from 'src/pages/withdrawal/empty-stripe/EmptyStripe'
import { BalanceTable } from 'src/pages/withdrawal/balance-table/BalanceTable'
import { RootState, useAppSelector } from '@redux'
import { InvoiceModal } from './invoice-modals'
import { useCreateAccountOnboardLinkMutation, useGetProfileQuery } from '@api'
import { handleAPIError } from '@utils'
import { CreateWithDrawModal } from 'src/pages/withdrawal/create-withdraw-modal/CreateWithdrawModal'
import { WithdrawResultModal } from 'src/pages/withdrawal/withdraw-result-modal/WithdrawResultModal'

const WithdrawalPage = () => {
  const { accountInfo } = useAppSelector((state: RootState) => state.auth)
  const { setGenericWithdrawalInfo, setWithdrawInvoice } = useWithdrawContext()

  const [openInvoiceModal, setOpenInvoiceModal] = useState<
    string | number | null
  >(null)
  const [openCreateWithdrawModal, setOpenCreateWithdrawModal] = useState(false)
  const [openWithdrawResultModal, setOpenWithdrawResultModal] = useState(false)

  const { refetchGetProfile } = useGetProfileQuery()
  const { mutate: connectStripe, isPending: isConnecting } =
    useCreateAccountOnboardLinkMutation({
      handleSuccess(connectLink) {
        window.open(connectLink, '_self')
      },
      onError(err) {
        handleAPIError(err)
      },
    })

  const handleSuccessWithdraw = async () => {
    setOpenCreateWithdrawModal(false)
    await refetchGetProfile()
    setOpenWithdrawResultModal(true)
  }

  const handleFailedWithdraw = async () => {
    setOpenCreateWithdrawModal(false)
    await refetchGetProfile()
  }

  const handleBackToHome = () => {
    setGenericWithdrawalInfo({
      amount: 0,
      fees: 0,
      actualAmount: 0,
      bankName: '',
      lastFourBankNumber: '',
      totalBalance: 0,
    })
    setWithdrawInvoice?.({})
    setOpenWithdrawResultModal(false)
  }

  return (
    <WithdrawalProvider>
      <>
        {!!openInvoiceModal ? (
          <InvoiceModal
            open={true}
            onClose={() => setOpenInvoiceModal(null)}
            invoiceId={openInvoiceModal}
          />
        ) : null}
        {openCreateWithdrawModal ? (
          <CreateWithDrawModal
            open={true}
            onClose={() => setOpenCreateWithdrawModal(false)}
            onSuccess={handleSuccessWithdraw}
            onFailed={handleFailedWithdraw}
          />
        ) : null}
        {openWithdrawResultModal ? (
          <WithdrawResultModal open={true} onBackToHome={handleBackToHome} />
        ) : null}
        <WithdrawalStyled>
          <Typography.Title level={2} className="!-mb-[28px] !text-[32px]">
            {t('revenue:balance')}
          </Typography.Title>
          <BalanceOverview
            balance={accountInfo?.totalBalance ?? 0}
            onStripeConnect={connectStripe}
            isLoading={isConnecting}
          />
          {!!accountInfo?.stripeAccountId ? (
            <BalanceTable
              onCreateWithdraw={() => setOpenCreateWithdrawModal(true)}
              onSelectInvoiceDetails={(invoiceId) =>
                setOpenInvoiceModal(invoiceId)
              }
            />
          ) : (
            <EmptyStripe
              onStripeConnect={connectStripe}
              isLoading={isConnecting}
            />
          )}
        </WithdrawalStyled>
      </>
    </WithdrawalProvider>
  )
}

export default WithdrawalPage

const WithdrawalStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
`
