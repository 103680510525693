import { themes } from '@theme'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'
import styled from 'styled-components'
import { PROFILE_TAB_KEYS } from './config'
import { TeacherInfoForm } from 'src/pages/profile/teacher-info/TeacherInfoForm'
import PackagamentManagementTab from './package-management/PackageManagementTab'
import { ChangePassword } from 'src/pages/profile/change-password'

export default function ProfileSection() {
  const [activeTab, setActiveTab] = useState<string>(PROFILE_TAB_KEYS.USER_INFO)

  const items = [
    {
      label: <Text>User info</Text>,
      key: PROFILE_TAB_KEYS.USER_INFO,
      children: (
        <div className="p-4 bg-white">
          <TeacherInfoForm />
        </div>
      ),
    },

    {
      label: <Text>Change password</Text>,
      key: PROFILE_TAB_KEYS.CHANGE_PASSWORD,
      children: <ChangePassword />,
    },
    {
      label: <Text>Package management</Text>,
      key: PROFILE_TAB_KEYS.PACKAGE_MANAGEMENT,
      children: <PackagamentManagementTab />,
    },
  ]

  return (
    <ProfileSectionContainerStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </ProfileSectionContainerStyled>
  )
}

const ProfileSectionContainerStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: `${themes.theme.light.colors.primary}`,
            fontWeight: '500',
          },
        },

        '&.ant-tabs-tab-disabled': {
          background: '#b8b8b8 !important',
          '& .ant-tabs-tab-btn': {
            '& .ant-typography': {
              color: `${themes.theme.light.colors.secondary}`,
            },
          },
        },
      },
    },
  }
})
