import { useMemo, ReactNode } from 'react'
import { Typography } from 'antd'
import styled, { css } from 'styled-components'
import { t } from 'i18next'

import { IInvoiceItem } from '@interfaces'
import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export const OrderDetailTag = ({
  invoiceItem,
}: {
  invoiceItem?: IInvoiceItem
}) => {
  const titleListObject: Record<string, ReactNode> = useMemo(
    () => ({
      bankInfo: (
        <Typography.Text className="!text-base !text-neutral-400">
          {t('revenue:bank_infor')}
        </Typography.Text>
      ),
      amount: (
        <Typography.Text className="!text-base !text-neutral-400">
          {t('revenue:amount')}
        </Typography.Text>
      ),
      fee: (
        <Typography.Text className="!text-base !text-neutral-400">
          {t('revenue:fees')}
        </Typography.Text>
      ),
      actualAmount: (
        <Typography.Text className="!text-base !text-neutral-400">
          {t('revenue:actual_amount_received')}
        </Typography.Text>
      ),
    }),
    []
  )

  const contentListObject: Record<string, ReactNode> = useMemo(
    () => ({
      bankInfo: (
        <ContentStyled $textColor={themes.theme.light.colors.lightBrown}>
          {t('revenue:method_content', {
            bankName:
              invoiceItem?.invoiceDetail?.withdrawalDetail?.bankName ?? '',
            lastNumber:
              invoiceItem?.invoiceDetail?.withdrawalDetail
                ?.lastFourBankNumber ?? '',
          })}
        </ContentStyled>
      ),
      amount: (
        <ContentStyled
          className="!font-bold"
          $textColor={themes.theme.light.colors.darkWarning}
        >
          {formatCurrencyByType(
            invoiceItem?.invoiceDetail?.withdrawalDetail?.amount ?? 0,
            'EUR'
          )}
        </ContentStyled>
      ),
      fee: (
        <ContentStyled $textColor={themes.theme.light.colors.darkWarning}>
          {formatCurrencyByType(
            invoiceItem?.invoiceDetail?.withdrawalDetail?.fees ?? 0,
            'EUR'
          )}
        </ContentStyled>
      ),
      actualAmount: (
        <ContentStyled $textColor={themes.theme.light.colors.darkWarning}>
          {formatCurrencyByType(
            invoiceItem?.invoiceDetail?.withdrawalDetail
              ?.actualAmountReceived ?? 0,
            'EUR'
          )}
        </ContentStyled>
      ),
    }),
    [
      invoiceItem?.invoiceDetail?.withdrawalDetail?.actualAmountReceived,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.amount,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.bankName,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.fees,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.lastFourBankNumber,
    ]
  )
  return (
    <>
      <Typography.Paragraph className="!text-[16px] !font-normal my-5">
        {t('revenue:order_detail')}
      </Typography.Paragraph>
      <OrderDetailTagContainer>
        {Object.entries(titleListObject).map(([key, value]) => (
          <DetailItemWrapper key={key} className="py-2">
            {value}
            {contentListObject[key]}
          </DetailItemWrapper>
        ))}
      </OrderDetailTagContainer>
    </>
  )
}

const OrderDetailTagContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid ${themes.theme.light.colors.border_gray_100};
  background: ${themes.theme.light.colors.lightGray};
`
const DetailItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const ContentStyled = styled.span<{ $textColor?: string }>`
  text-align: end;
  font-size: 14px;
  font-weight: 400;
  ${({ $textColor }) =>
    !!$textColor &&
    css`
      color: ${$textColor} !important;
    `}
`
