import { Layout } from 'antd'
import styled, { css } from 'styled-components'

import { ILayout } from '@interfaces'
import { HeaderNodule, InnerAppModule, SidebarModule } from '@modules'
import {
  ETeacherRequestStatus,
  HEADER_HEIGHT,
  HEADER_PADDING_TOP,
  PATH_SUBSCRIPTION,
  PATH_SUBSCRIPTION_CHECKOUT,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
} from '@configs'
import { selectAuth, selectLayout, useAppSelector } from '@redux'
import { useLocation } from 'react-router-dom'

export const AppLayout = (props: ILayout) => {
  const { children } = props

  const { sidebarCollapsed } = useAppSelector(selectLayout)
  const { accountInfo } = useAppSelector(selectAuth)
  const { pathname } = useLocation()

  const subscriptionPathnames = [PATH_SUBSCRIPTION, PATH_SUBSCRIPTION_CHECKOUT]

  const isShowSideBar =
    !(
      accountInfo?.requests?.[0]?.status &&
      [
        ETeacherRequestStatus.PENDING,
        ETeacherRequestStatus.UPDATED,
        ETeacherRequestStatus.REQUEST_UPDATE,
      ].includes(accountInfo?.requests?.[0]?.status)
    ) && !subscriptionPathnames.includes(pathname)

  return (
    <StyledAppLayout className="main-layout">
      <HeaderNodule />
      <Layout className="content-layout">
        {isShowSideBar ? <SidebarModule /> : null}
        <StyledPageInner
          isShowSidebar={isShowSideBar}
          sidebarCollapsed={sidebarCollapsed}
          className={`max-w-full overflow-x-hidden ${
            subscriptionPathnames.includes(pathname) && '!bg-white'
          }`}
        >
          <InnerAppModule {...props}>{children}</InnerAppModule>
        </StyledPageInner>
      </Layout>
    </StyledAppLayout>
  )
}

const StyledAppLayout = styled((props) => <Layout {...props} />)`
  min-height: 100vh;
  background: #f1f5f9;
`

const StyledPageInner = styled.div<{
  sidebarCollapsed?: boolean
  backgroundColor?: string
  backgroundColor2?: string
  isShowSidebar?: boolean
}>`
  background: #f1f5f9;
  transition: all 0.3s;
  padding: 0 1.375rem;
  padding-top: calc(${HEADER_HEIGHT} + ${HEADER_PADDING_TOP} + 2rem);
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props) =>
    props.isShowSidebar
      ? css`
          flex: 1;
        `
      : css`
          justify-items: center;
          align-items: center;
        `}
`
