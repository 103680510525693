import React from 'react'
import { message, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import {
  useCheckoutPackageMutation,
  useGetProfileQuery,
  useGetSubscriptionQuery,
} from '@api'
import { PATH_DASHBOARD } from '@configs'
import { ISubscription } from '@interfaces'
import { SubscriptionStyled } from './styled'
import {
  ModalPaymentStatus,
  ModalPaymentStatusRef,
  SubscriptionCheckout,
} from './subscription-checkout'
import { SubscriptionPackages } from './subscription-packages'
import { handleAPIError } from '@utils'

enum ESubscriptionSteps {
  SUBSCRIPTION_PACKAGES,
  SUBSCRIPTION_CHECKOUT,
}
export const SubscriptionPage = () => {
  const navigate = useNavigate()
  const modalPaymentStatusRef = React.useRef<ModalPaymentStatusRef>(null)
  const swiperRef = React.useRef<any | null>(null)
  const stripePromise = React.useMemo(() => {
    return loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY || '', {
      locale: 'en',
    })
  }, [])

  const [step, setStep] = React.useState<ESubscriptionSteps>(
    ESubscriptionSteps.SUBSCRIPTION_PACKAGES
  )
  const [selectedPackage, setSelectedPackage] = React.useState<
    ISubscription | undefined
  >()
  const [cardInformation, setCardInformation] = React.useState<any>()

  const {
    data: packageSubscriptionData,
    isPending: isGetPackageSubscriptionLoading,
  } = useGetSubscriptionQuery()

  const { mutate: checkoutPackage, isPending: checkoutPackageLoading } =
    useCheckoutPackageMutation()

  const { refetchGetProfile } = useGetProfileQuery()

  const handlePushToCheckout = (packageData?: ISubscription) => {
    if (!packageData) {
      return
    }
    setSelectedPackage(packageData)
    setStep(ESubscriptionSteps.SUBSCRIPTION_CHECKOUT)
  }

  const handleSelectPackage = (packageData?: ISubscription) => {
    setSelectedPackage(packageData)
  }

  const handleConfirmPayment = async (cardInformation: any) => {
    if (!selectedPackage) return

    try {
      await checkoutPackage(
        {
          isMigrate: false,
          packageId: selectedPackage?.id,
          paymentMethodId: cardInformation?.id,
        },
        {
          onError: (err) => {
            handleShowError(err)
          },
          onSuccess: (res) => {
            message.success(res?.message)
            modalPaymentStatusRef?.current?.present()
          },
        }
      )
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  const handleShowError = (error: any) => {
    setCardInformation(null)
    handleAPIError(error)
  }

  const handleChangeCard = (card: any) => {
    setCardInformation(card)
  }

  if (isGetPackageSubscriptionLoading) {
    return (
      <SubscriptionStyled className="!z-50">
        <Spin size="large" />
      </SubscriptionStyled>
    )
  }

  const renderMainContent = () => {
    switch (step) {
      case ESubscriptionSteps.SUBSCRIPTION_PACKAGES:
        return (
          <SubscriptionPackages
            swiperRef={swiperRef}
            onSelectPackage={handlePushToCheckout}
            packages={packageSubscriptionData?.data?.packagesForTeacher}
          />
        )
      case ESubscriptionSteps.SUBSCRIPTION_CHECKOUT:
        return (
          <Elements stripe={stripePromise}>
            <SubscriptionCheckout
              onSelectPackage={handleSelectPackage}
              packages={packageSubscriptionData?.data?.packagesForTeacher}
              selectedPackage={selectedPackage}
              onConfirmPayment={handleConfirmPayment}
              onChangeCard={handleChangeCard}
              cardInformation={cardInformation}
              isLoading={checkoutPackageLoading}
            />
          </Elements>
        )
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      {renderMainContent()}
      <ModalPaymentStatus
        ref={modalPaymentStatusRef}
        onSubmit={() => {
          refetchGetProfile()
          navigate(PATH_DASHBOARD)
        }}
      />
    </React.Fragment>
  )
}
