import { ISubscription, TPackage } from '@interfaces'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { handleAPIError } from '@utils'
import { Typography } from 'antd'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { Button } from 'src/common'
import styled from 'styled-components'
import { CardInput } from './CardInput'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

interface IPackageManagementFormProps {
  packagesData?: Array<ISubscription>
  selectedPackage?: ISubscription
  isPaymentSuccess?: boolean | null
  isUpgrading?: boolean
  isDisableUpgradePackage?: boolean
  onChangeSelectedPackage?: (selectPackage: ISubscription) => void
  onOpenEditCard?: () => void
  onConfirmPayment?: (cardInformation?: any) => void
  packageSubscribed?: TPackage
  onChangeCard?: (card: any) => void
  cardInformation?: any
}

const schema = z.object({
  cardNumber: z.boolean(),
  exp: z.boolean(),
  cvc: z.boolean(),
})

export const PackageManagementForm = ({
  packagesData,
  onConfirmPayment,
  cardInformation,
  onChangeCard,
  isUpgrading,
}: IPackageManagementFormProps) => {
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<string>('')

  const { control, watch } = useForm({
    defaultValues: {
      cardNumber: false,
      exp: false,
      cvc: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  })

  const watchCardNumber = watch('cardNumber')
  const watchExp = watch('exp')
  const watchCvc = watch('cvc')

  const stripe = useStripe()
  const elements = useElements()

  const disabledInput = useMemo(
    () => !!errorMsg || !(!!watchCardNumber && !!watchExp && !!watchCvc),
    [errorMsg, watchCardNumber, watchCvc, watchExp]
  )

  const handleSubmitCard = async () => {
    if (disabledInput) return
    setLoading(true)
    try {
      if (stripe && elements) {
        const res = await stripe.createPaymentMethod({
          type: 'card',
          card: elements?.getElement('cardNumber') as any,
          billing_details: { name: 'check' },
        })
        onChangeCard?.(res.paymentMethod)
        onConfirmPayment?.(res.paymentMethod)
      }
    } catch (error: any) {
      setErrorMsg(error?.data?.message || error?.message || 'error')
      handleAPIError(error)
    } finally {
      setLoading(false)
    }
  }

  const renderFormItem = () => {
    if (cardInformation) {
      return (
        <div className="pb-4 w-full border-b border-solid border-brand-neutral-100 flex items-start sm:flex-row flex-col sm:items-center justify-between">
          <Typography.Text className={`text-base text-black`}>
            {`${cardInformation?.card?.brand} **** **** **** ${cardInformation?.card?.last4}`}
          </Typography.Text>
        </div>
      )
    }
    return <CardInput control={control} />
  }

  if (!packagesData) {
    return null
  }

  return (
    <StyledPackageManagementForm
      className={`flex flex-col w-full p-5 bg-white  border border-main rounded-lg ml-10`}
    >
      <Typography.Text className="text-2xl font-bold capitalize text-black">
        {t('subscription:setUpPaymentDetails')}
      </Typography.Text>

      <div className="flex item-start sm:flex-row flex-col mb-4 mt-10">
        <div className={`flex-1`}>{renderFormItem()}</div>
      </div>

      <div className="flex justify-end">
        <Button
          type="primary"
          size="large"
          onClick={handleSubmitCard}
          disabled={disabledInput}
          loading={isLoading || isUpgrading}
        >
          {t('common:upgrade')}
        </Button>
      </div>
    </StyledPackageManagementForm>
  )
}

const StyledPackageManagementForm = styled.div`
  .ant-btn > span {
    font-size: 1rem;
  }
`
