import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import { packageManagementAPI } from 'src/api/package-management'
import {
  QK_PAYMENT_CARDS,
  QK_SAVE_PAYMENT_CARD_INFO,
} from 'src/configs/queryClient/queryKeyConstants'

type TPaymentCardInfoPayload = {
  paymentMethodId: string
  isDefault: boolean
}

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<any>,
    any,
    TPaymentCardInfoPayload,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<any>) => void
}

export const useSavePaymentCardInfoMutation = (configs?: IMutationParams) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [QK_SAVE_PAYMENT_CARD_INFO],
    mutationFn: (payload: TPaymentCardInfoPayload) =>
      packageManagementAPI.savePaymentCardInfo(payload),
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [QK_PAYMENT_CARDS] })
    },
    ...configs,
  })
}
