import { useCheckoutPackageMutation, useGetSubscriptionQuery } from '@api'
import { AppModal, XCloseIcon } from '@components'
import { ISubscription, TPaymentInfo } from '@interfaces'
import { themes } from '@theme'
import { handleAPIError } from '@utils'
import { message, Typography } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/common'

interface IProps {
  open: boolean
  onClose: () => void
  newPlan: ISubscription
  currentPlan: ISubscription
  cardInformation: TPaymentInfo
  onBackToListPackage: () => void
}

export default function UpgradePlanModal({
  open,
  onClose,
  onBackToListPackage,
  newPlan,
  currentPlan,
  cardInformation,
}: IProps) {
  const { t } = useTranslation(['profile', 'common'])
  const daysLeft = moment(currentPlan?.packageSubscribed?.dueDate).diff(
    moment(),
    'days'
  )
  const [isSubmittingSwitchPlan, setIsSubmittingSwitchPlan] = useState(false)
  const [isSubmittingKeepPlan, setIsSubmittingKeepPlan] = useState(false)

  const { mutate: checkoutPackage } = useCheckoutPackageMutation()
  const { refetchGetPackageSubscription } = useGetSubscriptionQuery()

  const handleConfirmPayment = async (isMigrate: boolean) => {
    if (!newPlan) return

    await checkoutPackage(
      {
        isMigrate,
        packageId: newPlan?.id,
        paymentMethodId: cardInformation?.paymentMethod?.id,
      },
      {
        onSuccess: (res) => {
          message.success(res?.message)
          refetchGetPackageSubscription()
          onClose()
          onBackToListPackage()
        },
        onError: (err) => {
          handleAPIError(err)
        },
        onSettled: () => {
          setIsSubmittingSwitchPlan(false)
          setIsSubmittingKeepPlan(false)
        },
      }
    )
  }

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon={false}
      contentClassName="!px-8 !py-6"
    >
      <div className="h-auto">
        <div className="flex items-center justify-between gap-4">
          <div>
            <Typography.Title
              style={{ marginBottom: 0, fontSize: 24, fontWeight: 'bold' }}
            >
              {t('profile:upgradePlan')}
            </Typography.Title>
          </div>
          <div className="hover:opacity-75 cursor-pointer">
            <XCloseIcon width={16} height={16} onClick={onClose} />
          </div>
        </div>
      </div>

      <div className="flex flex-col max-w-[700px] mt-4 mb-8 mx-auto">
        <Typography
          style={{
            color: '#424955FF',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          {t('profile:upgradePlanMessageTitle')}
        </Typography>

        <Typography
          style={{ color: '#424955FF', textAlign: 'center', fontSize: 16 }}
        >
          {t('profile:upgradePlanMessageSubtitle')}
        </Typography>

        <div className="bg-[#F3F4F6FF] w-full rounded mt-6 p-4 flex flex-col gap-4">
          <div className="flex">
            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                flex: 1,
              }}
            >
              {t('profile:currentPlan')}:
            </Typography>

            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                fontWeight: 'bold',
                flex: 1,
              }}
            >
              {currentPlan?.name}
            </Typography>
          </div>

          <div className="flex">
            <Typography
              style={{
                color: '#424955FF',
                fontSize: 18,
                flex: 1,
              }}
            >
              {t('profile:expirationDate')}:
            </Typography>

            <Typography
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                flex: 1,
              }}
            >
              {moment(currentPlan?.packageSubscribed?.dueDate).format(
                'MMM DD, YYYY'
              )}{' '}
              <span style={{ fontSize: 18, color: '#ff0000' }}>
                {`(${daysLeft} ${
                  daysLeft > 1 ? t('profile:daysLeft') : t('profile:dayLeft')
                })`}
              </span>
            </Typography>
          </div>
        </div>

        <div className="bg-[#535CE8FF] w-full rounded mt-6 p-4 flex flex-col gap-4">
          <div className="flex justify-between">
            <Typography
              style={{
                color: themes.theme.light.colors.body,
              }}
            >
              {t('profile:newPlan')}:
            </Typography>

            <Typography
              style={{
                color: themes.theme.light.colors.body,
                fontWeight: 'bold',
              }}
            >
              {newPlan?.name}
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center my-2 mt-4 gap-2">
        <Button
          type="primary"
          style={{
            width: '100%',
            borderRadius: '100rem',
            maxWidth: 300,
            background: '#535CE8FF',
          }}
          onClick={() => {
            setIsSubmittingSwitchPlan(true)
            handleConfirmPayment(false)
          }}
          loading={isSubmittingSwitchPlan}
        >
          {t('profile:switchPlanNow')}
        </Button>

        <Button
          danger
          type="text"
          onClick={() => {
            setIsSubmittingKeepPlan(true)
            handleConfirmPayment(true)
          }}
          loading={isSubmittingKeepPlan}
        >
          <Typography.Text
            style={{ color: '#FF765AFF', textDecoration: 'underline' }}
          >
            {t('profile:useCurrentPlan')}
          </Typography.Text>
        </Button>
      </div>
    </AppModal>
  )
}
