import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_HISTORY_LOG } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IHistoryLogItem,
} from '@interfaces'
import { revenueAPI } from 'src/api/revenue'

interface IGetHistoryLogProps {
  configs?: Omit<
    UseQueryOptions<BaseResponseProps<IHistoryLogItem[]>, BaseResponseError>,
    'queryKey' | 'queryFn'
  >
  invoiceId: string | number
}

export const useGetHistoryLogQuery = ({
  configs,
  invoiceId,
}: IGetHistoryLogProps) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_HISTORY_LOG, invoiceId],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_HISTORY_LOG, invoiceId],
      type: 'all',
      ...queryFilters,
    }) 
  }

  async function getHistoryLog() {
    return revenueAPI.getHistoryLogByInvoiceId(invoiceId)
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IHistoryLogItem[]>,
    BaseResponseError
  >({
    queryKey: [QK_GET_HISTORY_LOG, invoiceId],
    queryFn: getHistoryLog,

    ...configs,
  })

  return {
    data: data?.data ?? [],
    invalidateData,
    refetchData,
    ...restData,
  }
}
