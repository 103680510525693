import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { AuthRoute, PublicRoute, RestrictedRoute } from '@components'
import {
  EPermissions,
  ETeacherRequestStatus,
  LLC_ACCESS_TOKEN,
  PATH_403,
  PATH_CHAT_CHANNEL,
  PATH_HOME,
  PATH_SUBSCRIPTION,
  PATH_SUBSCRIPTION_CHECKOUT,
  PATH_UPDATE_APPLICATION,
  PATH_VIEW_TEACHER_APPLICATION,
  PATH_WAITING_SCREEN,
} from '@configs'
import { IRoute } from '@interfaces'
import { AppLayout, DefaultLayout } from '@layouts'
import {
  authActions,
  getProfileAdminAction,
  selectAuth,
  useAppSelector,
} from '@redux'
import ChatLayout from 'src/layouts/ChatLayout'
import { PageError404 } from 'src/pages'
import { publicRoutes, restrictedRoutes, routes } from './routes'
import ChatWelcome from 'src/components/chatChannel/ChatWelcome'
import usePermissions from 'src/hooks/usePermissions'
import { PageError403 } from 'src/pages/errors/403'

const Router = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const accessToken = Cookies.get(LLC_ACCESS_TOKEN)
  const { accountInfo } = useAppSelector(selectAuth)

  const { permissions, isAdmin } = usePermissions({ moduleName: '' })
  const canAccessChat = useMemo(() => {
    if (isAdmin || !Object.keys(permissions)?.length) return true

    return (permissions as Record<EPermissions, boolean>)?.[
      EPermissions.CHAT_CHANNEL
    ]
  }, [isAdmin, permissions])

  useEffect(() => {
    if (!accessToken) return

    dispatch(authActions.setAccessToken({ access_token: accessToken }))
    dispatch(getProfileAdminAction())
  }, [accessToken, dispatch])

  useEffect(() => {
    const requestingPathnames = [
      PATH_WAITING_SCREEN,
      PATH_UPDATE_APPLICATION,
      PATH_VIEW_TEACHER_APPLICATION,
    ]

    const subscriptionPathnames = [
      PATH_SUBSCRIPTION,
      PATH_SUBSCRIPTION_CHECKOUT,
    ]

    const requestStatuses = [
      ETeacherRequestStatus.PENDING,
      ETeacherRequestStatus.UPDATED,
      ETeacherRequestStatus.REQUEST_UPDATE,
    ]

    if (
      accessToken &&
      !requestingPathnames.includes(pathname) &&
      accountInfo?.requests?.[0]?.status &&
      requestStatuses.includes(accountInfo?.requests?.[0]?.status)
    ) {
      navigate(PATH_WAITING_SCREEN)
    }

    if (
      accessToken &&
      accountInfo?.requests?.[0]?.status === ETeacherRequestStatus.ACCEPTED &&
      !accountInfo?.packages?.length &&
      !subscriptionPathnames.includes(pathname)
    ) {
      navigate(PATH_SUBSCRIPTION)
    }

    if (
      accessToken &&
      !!accountInfo?.packages?.length &&
      accountInfo?.requests?.[0]?.status === ETeacherRequestStatus.ACCEPTED &&
      subscriptionPathnames.includes(pathname)
    ) {
      navigate(PATH_HOME)
    }
  }, [
    accessToken,
    accountInfo,
    accountInfo?.packages?.length,
    accountInfo?.requests,
    navigate,
    pathname,
  ])

  return (
    <Routes>
      <Route
        path={'/*'}
        element={
          <DefaultLayout>
            <PageError404 />
          </DefaultLayout>
        }
      />

      <Route
        path={PATH_403}
        element={
          <DefaultLayout>
            <PageError403 />
          </DefaultLayout>
        }
      />

      {/* Auth Route */}
      <Route element={<AuthRoute />}>
        {routes.map(
          (
            route: IRoute & {
              permissionViewKeys?: EPermissions[]
              isNotAdmin?: boolean
            },
            index: number
          ) => {
            const Element = route.element
            const { permissionViewKeys, isNotAdmin } = route || {}
            const canAccess =
              (!!isNotAdmin ? true : isAdmin) ||
              !Object.keys(permissions)?.length
                ? true
                : permissionViewKeys?.some(
                    (permissionKey) =>
                      !!(permissions as Record<EPermissions, boolean>)?.[
                        permissionKey
                      ]
                  )

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  canAccess ? (
                    <AppLayout>
                      <Element />
                    </AppLayout>
                  ) : (
                    <DefaultLayout>
                      <PageError403 />
                    </DefaultLayout>
                  )
                }
              />
            )
          }
        )}

        <Route
          path={PATH_CHAT_CHANNEL}
          element={
            canAccessChat ? (
              <AppLayout>
                <ChatLayout>
                  <ChatWelcome />
                </ChatLayout>
              </AppLayout>
            ) : (
              <DefaultLayout>
                <PageError403 />
              </DefaultLayout>
            )
          }
        />
        {/* <Route
          path={`${PATH_CHAT_CHANNEL}/:chatId`}
          element={
            canAccessChat ? (
              <AppLayout>
                <ChatLayout>
                  <ChatContent />
                </ChatLayout>
              </AppLayout>
            ) : (
              <DefaultLayout>
                <PageError403 />
              </DefaultLayout>
            )
          }
        /> */}
      </Route>
      {/* Restricted Route */}
      <Route element={<RestrictedRoute />}>
        {restrictedRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
      {/* public Route */}
      <Route element={<PublicRoute />}>
        {publicRoutes.map((route: IRoute, index: number) => {
          const Element = route.element
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <DefaultLayout>
                  <Element />
                </DefaultLayout>
              }
            />
          )
        })}
      </Route>
    </Routes>
  )
}

export default Router
