import { EPermissions } from './enum'

export interface IUserManagement {
  [EPermissions.VIEW_USER_LIST]: boolean
  [EPermissions.VIEW_USER_DETAIL]: boolean
  [EPermissions.UPDATE_USER]: boolean
  [EPermissions.VIEW_USER_LOG]: boolean
  [EPermissions.VIEW_TRANSACTION_HISTORIES]: boolean
  [EPermissions.EXPORT_TRANSACTION_HISTORIES]: boolean
}

export interface IPackageManagement {
  [EPermissions.VIEW_USER_PACKAGE_LIST]: boolean
  [EPermissions.UPDATE_USER_PACKAGE]: boolean
  [EPermissions.VIEW_TEACHER_PACKAGE_LIST]: boolean
  [EPermissions.UPDATE_TEACHER_PACKAGE]: boolean
}

export interface INewLetterManagement {
  [EPermissions.VIEW_EMAIL_LIST]: boolean
  [EPermissions.EXPORT_EMAIL]: boolean
}

export interface IEmailManagement {
  [EPermissions.VIEW_EMAIL_TEMPLATE_LIST]: boolean
  [EPermissions.UPDATE_EMAIL_TEMPLATE]: boolean
  [EPermissions.SEND_RESEND_EMAIL]: boolean
}

export interface IGroupManagement {
  [EPermissions.VIEW_GROUP_LIST]: boolean
  [EPermissions.UPDATE_GROUP]: boolean
}

export interface ICategoryManagement {
  [EPermissions.VIEW_CATEGORY_LIST]: boolean
  [EPermissions.UPDATE_CATEGORY]: boolean
}

export interface ISubCategoryManagement {
  [EPermissions.VIEW_SUB_CATEGORY_LIST]: boolean
  [EPermissions.UPDATE_SUB_CATEGORY]: boolean
}

export interface IContentManagement {
  [EPermissions.ABOUT_US]: boolean
  [EPermissions.HOMEPAGE]: boolean
  [EPermissions.MEMBERSHIP]: boolean
  [EPermissions.ACADEMY]: boolean
  [EPermissions.BLOG_PAGE]: boolean
  [EPermissions.CONTACT]: boolean
  [EPermissions.TERM_POLICY]: boolean
  [EPermissions.GALLERY]: boolean
}

export interface IBlogManagement {
  [EPermissions.VIEW_BLOG_LIST]: boolean
  [EPermissions.UPDATE_BLOG]: boolean
}

export interface ICommunityManagement {
  [EPermissions.VIEW_POST_LIST]: boolean
  [EPermissions.UPDATE_POST]: boolean
}

export interface IGoalManagement {
  [EPermissions.VIEW_GOAL_LIST]: boolean
  [EPermissions.UPDATE_GOAL]: boolean
}

export interface IQuestionManagement {
  [EPermissions.VIEW_QUESTION_LIST]: boolean
  [EPermissions.UPDATE_QUESTION]: boolean
}

export interface IChallengeManagement {
  [EPermissions.VIEW_CHALLENGE_LIST]: boolean
  [EPermissions.UPDATE_CHALLENGE]: boolean
}

export interface IDailyRoutineManagement {
  [EPermissions.VIEW_DAILY_ROUTINE_LIST]: boolean
  [EPermissions.UPDATE_DAILY_ROUTINE]: boolean
}

export interface ICourseManagement {
  [EPermissions.VIEW_COURSE_LIST]: boolean
  [EPermissions.VIEW_COURSE_DETAIL]: boolean
  [EPermissions.UPDATE_COURSE]: boolean
  [EPermissions.COURSE_CHAT]: boolean
}

export interface ITestManagement {
  [EPermissions.VIEW_TEST_LIST]: boolean
  [EPermissions.VIEW_TEST_DETAIL]: boolean
  [EPermissions.VIEW_GRADE]: boolean
  [EPermissions.ASSIGN_TEACHER]: boolean
  [EPermissions.GRADE]: boolean
}

export interface ITeacherManagement {
  [EPermissions.VIEW_TEACHER_LIST]: boolean
  [EPermissions.UPDATE_TEACHER]: boolean
}

export interface IStaffManagement {
  [EPermissions.VIEW_STAFF_LIST]: boolean
  [EPermissions.UPDATE_ADMIN]: boolean
}

export interface IRoleManagement {
  [EPermissions.VIEW_ROLE_LIST]: boolean
  [EPermissions.UPDATE_ROLE]: boolean
}

export interface IPermissions {
  [EPermissions.USER_MANAGEMENT]: boolean
  [EPermissions.PACKAGE_MANAGEMENT]: boolean
  [EPermissions.NEWLETTER_MANAGEMENT]: boolean
  [EPermissions.EMAIL_MANAGEMENT]: boolean
  [EPermissions.GROUP_MANAGEMENT]: boolean
  [EPermissions.CATEGORY_MANAGEMENT]: boolean
  [EPermissions.SUB_CATEGORY_MANAGEMENT]: boolean
  [EPermissions.CONTENT_MANAGEMENT]: boolean
  [EPermissions.BLOG_MANAGEMENT]: boolean
  [EPermissions.COMMUNITY_MANAGEMENT]: boolean
  [EPermissions.GOAL_MANAGEMENT]: boolean
  [EPermissions.QUESTION_MANAGEMENT]: boolean
  [EPermissions.CHALLENGE_MANAGEMENT]: boolean
  [EPermissions.DAILY_ROUTINE_MANAGEMENT]: boolean
  [EPermissions.COURSE_MANAGEMENT]: boolean
  [EPermissions.TEST_MANAGEMENT]: boolean
  [EPermissions.TEACHER_MANAGEMENT]: boolean
  [EPermissions.STAFF_MANAGEMENT]: boolean
  [EPermissions.ROLE_MANAGEMENT]: boolean
  [EPermissions.DASHBOARD]: boolean
  [EPermissions.STARTING_SCREEN]: boolean
  [EPermissions.CHAT_CHANNEL]: boolean
}

export enum EStatusCode {
  NOT_FOUND = 404,
  RESTRICTED_ACCESS = 403,
}
