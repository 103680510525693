import { AppModal, XCloseIcon } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { handleAPIError } from '@utils'
import { Checkbox, message, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSavePaymentCardInfoMutation } from 'src/api/react-query/hooks/subscription/useSavePaymentCardInfo'
import { Button } from 'src/common'
import { CardInput } from 'src/pages/subscription'
import styled from 'styled-components'
import * as z from 'zod'

interface IProps {
  open: boolean
  onClose: () => void
}

function AddCardModal({ open, onClose }: IProps) {
  const { t } = useTranslation(['profile', 'common', 'subscription'])

  const [errorMsg, setErrorMsg] = useState<string>('')

  const { mutateAsync: savePaymentCardInfo } = useSavePaymentCardInfoMutation()

  const schema = z.object({
    cardNumber: z.boolean(),
    exp: z.boolean(),
    cvc: z.boolean(),
    isDefault: z.boolean(),
  })

  const methods = useForm({
    defaultValues: {
      cardNumber: false,
      exp: false,
      cvc: false,
      isDefault: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
  })

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const watchCardNumber = watch('cardNumber')
  const watchExp = watch('exp')
  const watchCvc = watch('cvc')

  const stripe = useStripe()
  const elements = useElements()

  const disabledInput = useMemo(
    () => !!errorMsg || !(!!watchCardNumber && !!watchExp && !!watchCvc),
    [errorMsg, watchCardNumber, watchCvc, watchExp]
  )

  const handleSubmitCard = async (data: {
    cardNumber: boolean
    exp: boolean
    cvc: boolean
    isDefault: boolean
  }) => {
    if (disabledInput) return

    try {
      if (stripe && elements) {
        const res = await stripe?.createPaymentMethod({
          type: 'card',
          card: elements?.getElement('cardNumber') as any,
          billing_details: { name: 'check' },
        })

        await savePaymentCardInfo({
          paymentMethodId: res?.paymentMethod?.id || '',
          isDefault: data?.isDefault,
        })

        message.success(t('profile:addCardSuccess'))
        onClose()
      }
    } catch (error: any) {
      setErrorMsg(error?.data?.message || error?.message || 'error')
      handleAPIError(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCard)}>
      <AppModal
        open={open}
        onClose={onClose}
        haveCloseIcon={false}
        contentClassName="!px-8 !py-6"
      >
        <div className="h-auto">
          <div className="flex items-center justify-between gap-4">
            <div>
              <Typography.Title
                style={{ marginBottom: 0, fontSize: 24, fontWeight: 'bold' }}
              >
                {t('profile:addNewCard')}
              </Typography.Title>
            </div>
            <div className="hover:opacity-75 cursor-pointer">
              <XCloseIcon width={16} height={16} onClick={onClose} />
            </div>
          </div>
        </div>

        <div className="flex flex-col max-w-[700px] mt-4 mb-8 mx-auto">
          <StyledPackageManagementForm
            className={`flex flex-col w-full min-w-[500px] p-5 bg-white rounded-lg`}
          >
            <div className="flex item-start sm:flex-row flex-col my-4">
              <div className={`flex-1`}>
                <CardInput control={control} />
              </div>
            </div>

            <Controller
              name="isDefault"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox checked={value} onChange={onChange}>
                    <Typography style={{ textWrap: 'nowrap' }}>
                      {t('profile:setAsDefault')}
                    </Typography>
                  </Checkbox>
                )
              }}
            />
          </StyledPackageManagementForm>
        </div>

        <div className="flex flex-col justify-center items-center my-2 mt-4 gap-2">
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabledInput}
            loading={isSubmitting}
            style={{
              width: '100%',
              borderRadius: '100rem',
              maxWidth: 300,
              background: '#535CE8FF',
            }}
          >
            {t('common:add')}
          </Button>
        </div>
      </AppModal>
    </form>
  )
}

export default function AddCardModalContainer(props: IProps) {
  const stripePromise = useMemo(() => {
    return loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY || '', {
      locale: 'en',
    })
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <AddCardModal {...props} />
    </Elements>
  )
}

const StyledPackageManagementForm = styled.div`
  .ant-btn > span {
    font-size: 1rem;
  }
`
