import { useEffect, useRef, useState } from 'react'
import { Typography } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSelector } from 'react-redux'

import { IVerifyForgotPassword } from '@interfaces'
import { Button, Input } from 'src/common'
import { EVerifyOTPStatusType, OTP_TIMER } from '@configs'
import { LeftOutlined } from '@ant-design/icons'
import { StyledContainer } from 'src/pages/auth/sign-up/style'
import { RootState } from '@redux'
import { schemaVerifyOTP } from 'src/pages/auth/sign-up/verify-otp/config'
import { useMutationResendOtpQuery, useMutationVerifyOtpQuery } from '@api'

interface IProps {
  onSuccess?: () => void
  onBack?: () => void
}

export const VerifyOtpForm = (props: IProps) => {
  const { onSuccess, onBack } = props

  const { t } = useTranslation(['common', 'login', 'signUp'])
  const { hash } = useSelector((state: RootState) => state.auth)

  const [countdown, setCountdown] = useState(OTP_TIMER)
  const Ref = useRef<ReturnType<typeof setInterval> | null>(null)

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IVerifyForgotPassword>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      otpCode: '',
      hash: '',
    },
    resolver: zodResolver(schemaVerifyOTP),
  })

  const startTimer = () => {
    if (countdown > 0) {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }

    if (countdown <= 0) {
      Ref.current = null
    }
  }

  const resetCountDown = () => {
    setCountdown(OTP_TIMER)

    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer()
    }, 1000)
    Ref.current = id
  }

  const { mutate: verifyOtpMutation, isPending: isVerifyOtpLoading } =
    useMutationVerifyOtpQuery({
      handleSuccess() {
        reset()
        onSuccess && onSuccess()
      },
    })

  const { mutate: resendOtpMutation, isPending: isResendOtpLoading } =
    useMutationResendOtpQuery({
      handleSuccess() {
        resetCountDown()
      },
    })

  const handleVerifySignUp = handleSubmit(async (value) => {
    verifyOtpMutation(value)
  })

  const handleResendCode = async () => {
    if (countdown > 0 || isResendOtpLoading) return
    await resendOtpMutation({
      hash: hash,
      type: EVerifyOTPStatusType.SIGNUP,
    })
    resetCountDown()
  }

  const handleRedirectToSignUp = () => {
    reset()
    onBack && onBack()
  }

  useEffect(() => setValue('hash', hash), [hash, setValue])

  useEffect(() => resetCountDown(), [])

  return (
    <StyledContainer className="login__section">
      <Typography.Text className="!text-[12px] !text-neutral-400 text-center max-w-[250px] mx-auto mb-6">
        {t('signUp:verify_otp_subtitle')}
      </Typography.Text>
      {/* @ts-ignore */}
      <form onSubmit={handleVerifySignUp} className="signUp-form">
        <Controller
          name="otpCode"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={t('signUp:otp_code')}
                placeholder={t('signUp:otp_code')}
                name="otp_code"
                alignment="col"
                labelClassName="font-semibold"
                errors={error?.message}
                value={value}
                onChange={onChange}
              />
            )
          }}
        />

        <div className="button_wrapper_grid">
          <div className="mt-2 text-sm col-span-7 flex justify-center mb-4">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="!w-full !rounded-full"
              loading={isVerifyOtpLoading}
            >
              {t('common:next')}
            </Button>
          </div>
          <div className="mt-2 col-span-7 flex justify-center mb-4">
            <Button
              htmlType="button"
              type="primary"
              size="large"
              disabled={countdown > 0}
              onClick={handleResendCode}
              className="!w-max px-2 !text-[14px] !text-neutral-600 !border-none !rounded-full !bg-green-400"
              loading={isResendOtpLoading}
            >
              {countdown <= 0
                ? t('signUp:resend_otp_code')
                : `${t('signUp:resend_otp_code')}: ${countdown}s`}
            </Button>
          </div>
          <div className="mt-2 text-sm col-span-7 flex justify-center">
            <Typography.Text
              onClick={handleRedirectToSignUp}
              className="cursor-pointer"
            >
              <LeftOutlined className="mr-1 mt-1" />
              {t('signUp:back_to_sign_up')}
            </Typography.Text>
          </div>
        </div>
      </form>
    </StyledContainer>
  )
}
