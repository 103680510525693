import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import { packageManagementAPI } from 'src/api/package-management'
import { QK_PAYMENT_CARDS } from 'src/configs/queryClient/queryKeyConstants'

type TPaymentCardInfoPayload = {
  cardId: string
}

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<any>,
    any,
    TPaymentCardInfoPayload,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<any>) => void
}

export const useDeletePaymentCardMutation = (configs?: IMutationParams) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: TPaymentCardInfoPayload) =>
      packageManagementAPI.deletePaymentCard(payload),
    onSuccess: () => {
      queryClient?.invalidateQueries({ queryKey: [QK_PAYMENT_CARDS] })
    },
    ...configs,
  })
}
