import { ETeacherRequestStatus } from '@configs'
import { IMedia } from 'src/interfaces/course'
import { ESignUpGender } from 'src/pages/auth/sign-up/fill-in-teacher-information/personal-information/config'

export type TFillInTeacherInformation = Partial<{
  personalInformation: TPersonalInformation
  specificInformation: TSpecificInformation
}>

export type TSignUpDetailsResponse = Partial<{
  teacherRequest: {
    id: number
    personalInformation: TPersonalInformation
    specificInformation: TSpecificInformation
    userId: number
    status: ETeacherRequestStatus
    createdAt: null | string
    updatedAt: null | string
    deletedAt: null | string
    responseData: TResponseData
  }
  token: {
    accessToken: string
    refreshToken: string
  }
  message: string
}>

export type TResponseData = Partial<{
  reason: string
  updatedBy: { name: string; client: string }
}>

export type TPersonalInformation = Partial<{
  firstname: string
  lastname: string
  gender: ESignUpGender | string
  dateOfBirth: string
  phone: string
  description: string
  avatarId: number
  email: string
  password: string
  isEditable: boolean
}>

export type TSpecificInformation = Partial<{
  education: TEducation[]
  workExperience: TWorkExperience[]
  courseReference: TCourseReference[]
  teacherSignature: TTeacherSignature
}>

export enum EEducationLevel {
  BACHELOR_DEGREE = 'BACHELOR_DEGREE',
  DIPLOMA = 'DIPLOMA',
  MASTER_DEGREE = 'MASTER_DEGREE',
  OTHER = 'OTHER',
}

export type TEducation = Partial<{
  institution: string
  educationLevel: EEducationLevel | string
  qualification: string
  description: string
  startDate: string
  endDate: string
  editable: boolean
  isPresent: boolean
  degree?: TDegree
}>

export type TWorkExperience = Partial<{
  companyName: string
  jobTitle: string
  description: string
  startDate: string
  endDate: string
  editable: boolean
  isPresent: boolean
}>

export type TDegree = Partial<{
  file: {
    name: string
    fileId: number
  }
  name: string
  fileId: number
  editable: boolean
  mediaFile: IMedia
}>

export type TCourseReference = Partial<{
  name: string
  url: string
  editable: boolean
}>

export type TTeacherSignature = Partial<{
  name: string
  url: string
  fileId: number
  editable: boolean
  signaturePhoto?: IMedia
}>
