import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  BaseResponseProps,
  TConfirmPaymentIntentRequest,
  IMarkAsReadNotificationResponse,
} from '@interfaces'
import { handleAPIError } from '@utils'
import { QK_MARK_NOTIFICATION_AS_READ_BY_ID } from 'src/configs/queryClient/queryKeyConstants'
import { notificationAPI } from 'src/api/notification'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<IMarkAsReadNotificationResponse>,
    any,
    TConfirmPaymentIntentRequest,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<IMarkAsReadNotificationResponse>) => void
}

export const useMarkNotificationAsReadByIdMutation = (
  configs?: IMutationParams
) => {
  return useMutation({
    mutationKey: [QK_MARK_NOTIFICATION_AS_READ_BY_ID],
    mutationFn: (notificationId: number) =>
      notificationAPI.markNotificationAsReadById(notificationId),
    onError: (error: any) => {
      handleAPIError(error)
    },
    ...configs,
  })
}
