import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { EyeOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { Moment } from 'moment'

import { SharedStyledTableWrapper, SharedTable } from 'src/common'
import { BalanceToolbar } from './balance-toolbar/BalanceToolbar'
import { convertStartEndDate, EConvertStartDateType } from '@utils'
import { IInvoiceItem } from '@interfaces'
import { DEFAULT_GET_INVOICES_PARAMS } from '@configs'
import { RegenerateInvoiceModal } from './regenerate-invoice-modal/RegenerateInvoiceModal'
import {
  BALANCE_TABLE_COLUMNS,
  BALANCE_TOOLBAR_RHF_FIELDS,
  DEFAULT_TOOLBAR_VALUES,
  EBalanceType,
} from './configs'
import {
  useGetTeacherInvoiceListQuery,
  useRegenerateInvoiceMutation,
} from '@api'

export const BalanceTable = ({
  onCreateWithdraw,
  onSelectInvoiceDetails,
  isLoading,
}: {
  onCreateWithdraw: () => void
  onSelectInvoiceDetails: (invoiceId: string | number) => void
  isLoading?: boolean
}) => {
  const [params, setParams] = useState<{
    page: number
    limit: number
    startDate?: string
    endDate?: string
  }>({
    page: DEFAULT_GET_INVOICES_PARAMS.PAGE,
    limit: DEFAULT_GET_INVOICES_PARAMS.LIMIT,
  })
  const [openRegenerateModal, setOpenRegenerateModal] = useState(false)

  const {
    control,
    reset,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: DEFAULT_TOOLBAR_VALUES,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const watchDateRange = watch(BALANCE_TOOLBAR_RHF_FIELDS.DATE_RANGE)
  const watchType = watch(BALANCE_TOOLBAR_RHF_FIELDS.TYPE)

  const { data: invoices, isFetching: fetchingListInvoices } =
    useGetTeacherInvoiceListQuery({
      queryParams: {
        ...params,
        ...(watchType !== EBalanceType.ALL && {
          invoiceType: watchType as EBalanceType,
        }),
        ...(!!params?.startDate && {
          startDate: params.startDate,
        }),
        ...(!!params?.endDate && {
          endDate: params.endDate,
        }),
      },
    })

  const tableData = useMemo(() => {
    if (!invoices?.items) return []

    return invoices?.items?.map((item) => ({
      ...item,
      type: item?.invoiceType,
      dateTime: item?.updatedAt,
    }))
  }, [invoices?.items])

  const tableColumns = useMemo(() => {
    return BALANCE_TABLE_COLUMNS.map((column) => ({
      ...column,
      ...(column.key === 'action' && {
        render: (_: any, record: IInvoiceItem) => (
          <EyeOutlined
            onClick={() => onSelectInvoiceDetails(record?.id)}
            className="!text-[16px] cursor-pointer"
          />
        ),
      }),
    }))
  }, [onSelectInvoiceDetails])

  // re-generate invoice
  const { mutate: regenerateInvoice, isPending: regeneratingInvoice } =
    useRegenerateInvoiceMutation({
      handleSuccess(value) {
        setOpenRegenerateModal(false)
        onSelectInvoiceDetails(value?.id)
      },
    })
  const handleRegenerateInvoice = async (time: string) => {
    await regenerateInvoice(time)
  }

  useEffect(() => {
    const startDate = convertStartEndDate(
      (watchDateRange?.[0] as unknown as Moment)?.toISOString() ?? '',
      EConvertStartDateType.START
    )
    const endDate = convertStartEndDate(
      (watchDateRange?.[1] as unknown as Moment)?.toISOString() ?? '',
      EConvertStartDateType.END
    )

    if (!isDirty) return

    setParams((prev) => ({
      ...prev,
      startDate,
      endDate,
      page: 1,
    }))
  }, [watchDateRange, watchType, isDirty])

  return (
    <>
      {openRegenerateModal ? (
        <RegenerateInvoiceModal
          open
          onClose={() => setOpenRegenerateModal(false)}
          onRegenerateInvoice={handleRegenerateInvoice}
          isLoading={regeneratingInvoice}
        />
      ) : null}
      <SharedStyledTableWrapper className="mt-4 p-4 !bg-white !rounded-[4px]">
        <BalanceToolbar
          control={control}
          reset={reset}
          setValue={setValue}
          onCreateWithdraw={onCreateWithdraw}
          openRegenerateModal={() => setOpenRegenerateModal(true)}
        />
        <SharedTable
          columns={tableColumns}
          dataSource={tableData}
          paginationProps={{
            total: invoices?.total ?? 0,
            pageSize: invoices?.limit ?? 0,
            current: invoices?.page ?? 1,
            showSizeChanger: false,
            onChange(page) {
              setParams((prev) => ({
                ...prev,
                page,
              }))
            },
          }}
          loading={{
            spinning: fetchingListInvoices || !invoices,
            size: 'large',
          }}
          sticky
        />
      </SharedStyledTableWrapper>
    </>
  )
}
