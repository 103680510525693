import {  Typography } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'

import WithdrawOngoing from '../assets/WithdrawOngoing.png'
import { AppModal } from '@components'
import { WithdrawResultDetails } from './WithdrawResultDetails'

interface IProps {
  open: boolean
  onBackToHome: () => void
}

export const WithdrawResultModal = (props: IProps) => {
  const { onBackToHome, open } = { ...props }

  return (
    <AppModal
      open={open}
      onClose={onBackToHome}
      contentClassName="!min-w-[50dvw] !px-8 !max-h-[95dvh]"
      childrenClassName="!mt-0"
    >
      <ModalTitleWrapper>
        <img src={WithdrawOngoing} alt="empty" className="w-[130px]" />
        <Typography.Title level={4} className="!text-[24px] !text-center">
          {t('revenue:withdrawal_ongoing')}
        </Typography.Title>
      </ModalTitleWrapper>
      <WithdrawResultDetails onBackToHome={onBackToHome} />
    </AppModal>
  )
}

const ModalTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
`
