import { ChartOptions } from 'chart.js'

import { themes } from '@theme'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

const rootColor = themes.theme.dark.colors.miniDashboard

export const channelsStatisticChartOptions: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      align: 'center',
      fullSize: true,
      maxHeight: 150,
      labels: {
        boxWidth: 13,
        boxHeight: 13,
        borderRadius: 999,
        usePointStyle: true,
        pointStyle: 'circle',
      },
      onHover: function (e: any) {
        e.native.target.style.cursor = 'pointer'
      },
    },
    tooltip: {
      backgroundColor: themes.theme.light.colors.body,
      borderColor: themes.theme.light.colors.primary,
      borderWidth: 1,
      callbacks: {
        title: function () {
          return ''
        },
        label: function (context) {
          const label = context.label
          return `${label} - €${formatCurrencyByType(
            (context.dataset.data[context.dataIndex] as number) ?? 0,
            'EUR'
          ).replace('€', '')}`
        },
        labelTextColor: function (context) {
          const bgColor =
            context.dataIndex === 0 ? rootColor.darkPurple : rootColor.darkBlue
          return bgColor
        },
      },
    },
  },
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawTicks: false,
      },
      ticks: {
        padding: 24,
      },
    },
    y: {
      display: false,
    },
  },
}
