import { useLocation } from 'react-router-dom'
import { Card, Typography } from 'antd'
import { t } from 'i18next'
import { isNil } from 'lodash'
import React, { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { TCourseReference } from '@interfaces'
import styled from 'styled-components'

import { Button } from 'src/common'
import { ReferenceSection } from './ReferenceSection'
import { TCoursesReference } from '../types'
import { PATH_UPDATE_APPLICATION } from '@configs'
import { TEACHER_INFO_SPECIFIC_INFO_VALIDATION } from 'src/pages/auth/sign-up/configs'

export const CoursesReference = ({
  isViewMode,
  canEdit = false,
}: TCoursesReference) => {
  const { pathname } = useLocation()
  const isUpdatingApplication = [PATH_UPDATE_APPLICATION].includes(pathname)

  const { control, getValues, setValue, watch } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'specificInformation.courseReference',
    rules: {
      minLength: 0,
      maxLength: TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH,
    },
  })
  const removableField = fields?.length > 1 && !isViewMode
  const [isAddableField, setAddableField] = React.useState<boolean>(false)

  const watchCourseReferenceValue = watch(`specificInformation.courseReference`)
  const isEditting = watchCourseReferenceValue?.some(
    (it: any) => !!it?.editable
  )

  const verifyAddableField = () => {
    return (
      watchCourseReferenceValue?.length <
      TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH
    )
  }

  const handleRemoveField = (id?: number) => {
    remove(id)
    const isAddableValue =
      watchCourseReferenceValue?.length >=
      TEACHER_INFO_SPECIFIC_INFO_VALIDATION.MAX_LENGTH
        ? true
        : verifyAddableField()
    setAddableField(isAddableValue)
  }

  const handleSaveField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    update(id, getValues(`specificInformation.courseReference[${id}]`))
    setAddableField(verifyAddableField())
  }

  const handleAddField = () => {
    const defaultFieldValue: TCourseReference = {
      name: '',
      url: '',
      editable: true,
    }
    append(defaultFieldValue)
    setAddableField(false)
  }

  const handleEitField = (id?: number) => {
    if (isNil(id)) {
      return
    }
    setValue(`specificInformation.courseReference[${id}].editable`, true)
    update(id, getValues(`specificInformation.courseReference[${id}]`))
  }

  useEffect(() => {
    if (isUpdatingApplication || canEdit) {
      setAddableField(true)
    }
  }, [canEdit, isUpdatingApplication])

  return (
    <CoursesReferenceStyled className="flex items-center justify-center w-full">
      <Typography.Title level={4} className="text-left">
        Courses Reference
      </Typography.Title>
      {fields?.map((field: any, index) => {
        return (
          <ReferenceSection
            key={field.id}
            data={field}
            index={index}
            onRemove={removableField ? handleRemoveField : undefined}
            onSave={handleSaveField}
            onEdit={isViewMode ? undefined : handleEitField}
          />
        )
      })}
      {isAddableField && !isEditting ? (
        <div className="flex items-center max-[800px] justify-center my-5">
          <Button
            htmlType="submit"
            type="primary"
            size="small"
            className="min-[600px] max-[600px]:!w-full"
            onClick={handleAddField}
          >
            {t('common:addCourseReference')}
          </Button>
        </div>
      ) : null}
    </CoursesReferenceStyled>
  )
}

const CoursesReferenceStyled = styled(Card)`
  .ant-card-body {
  }

  .reset-form {
    > div:last-child {
      justify-content: center;
    }
  }
`
