import { IRoute } from '@interfaces'
import {
  ChangePasswordPage,
  CourseDetail,
  DashboardWithContext,
  ForgotPasswordPage,
  LoginPage,
  NotificationScreen,
  PackageManagementPage,
  ResetPasswordPage,
  StaffAndRoleManagementPage,
  SubscriptionCheckout,
  SubscriptionPackages,
  SubscriptionPage,
  UserDetailPage,
  UserManagementPage,
} from '@pages'
import { ContactPage } from 'src/components/declaration/contact/ContactPage'
import AboutUsPageManagement from 'src/pages/about-us-page-management/AboutUsPageManagement'
import { InitPasswordAdminPage } from 'src/pages/auth/login/InitPasswordAdminPage'
import BlogContent from 'src/pages/blog/BlogContent'
import BlogNewsPage from 'src/pages/blog/BlogNewsPage'
import CoursePage from 'src/pages/course-management/CoursePage'
import SingleBlog from 'src/pages/blog/SingleBlog'
import { Category, SubCategory } from 'src/pages/category'
import DeclarationPage from 'src/pages/declaration/DeclarationPage'
import EditorPage from 'src/pages/editor/EditorPage'
import GalleryScreen from 'src/pages/gallery/GalleryScreen'
import ChallengeHabitPage from 'src/pages/habit-management/challenge/ChallengeHabitPage'
import DetailChallenge from 'src/pages/habit-management/challenge/DetailChallenge'
import DetailChallengeParticipant from 'src/pages/habit-management/challenge/DetailChallengeParticipant'
import DetailChallenge_V2 from 'src/pages/habit-management/challenge/DetailChallenge_V2'
import DetailMission from 'src/pages/habit-management/challenge/DetailMission'
import MissionPage from 'src/pages/habit-management/challenge/MissionPage'
import TagHabitPage from 'src/pages/habit-management/goal/GoalHabitPage'
import QuestionDetailPage from 'src/pages/habit-management/question/QuestionDetailPage'
import QuestionHabitPage from 'src/pages/habit-management/question/QuestionHabitPage'
import MembershipPage from 'src/pages/membership-page-management/MembershipPage'
import NewLetterPage from 'src/pages/new-letter/NewLetter'
import PostDetailScreen from 'src/pages/post-detail/PostDetailScreen'
import PostManagementScreen from 'src/pages/post-management/PostManagement'
import TermAndPolicyContent from 'src/pages/term-and-policy/TermAndPolicyContent'
import CreateEditEmailContentPage from 'src/pages/email-content-management/CreateEditEmailContentPage'
import EmailContentPage from 'src/pages/email-content-management/EmailContentPage'
import CreateEditGroupPage from 'src/pages/group-user-management/CreateEditGroupPage'
import GroupUserPage from 'src/pages/group-user-management/GroupUserPage'
import RoutineManagement from 'src/pages/habit-management/routine/RoutineManagement'
import RoutinePage from 'src/pages/habit-management/routine/RoutinePage'
import { HomepageContentManagement } from 'src/pages/home-page-management'
import { ProfilePage } from 'src/pages/auth/login/ProfilePage'
import PostCreateScreen from 'src/pages/post-create/PostCreateScreen'
import JobDetail from '../pages/habit-management/jobs/JobDetail'
import JobPage from '../pages/habit-management/jobs/JobPage'
import SetupStartingScreen from '../pages/habit-management/starting/SetupStartingScreen'
import CourseCreateScreen from 'src/pages/course-management/CourseCreateScreen'
import AcademyManagement from 'src/pages/academy-management/AcademyManagement'
import TestManagementPage from 'src/pages/test-management'
import TeacherPageWithContenxt from 'src/pages/teacher-management/TeacherPage'
import GradeTestPage from 'src/pages/test-management/grade-test'

import {
  PATH_CHALLENGE_DETAIL_PARTICIPANTS,
  PATH_CHALLENGE_DETAIL_V2,
  PATH_COURSE_MANAGEMENT_NEW,
  PATH_DAILY_ROUTINE_DETAIL,
  PATH_DAILY_ROUTINE_DETAIL_CREATE,
  PATH_DAILY_ROUTINE_JOBS,
  PATH_DAILY_ROUTINE_JOBS_DETAIL,
  PATH_EDIT_POST,
  PATH_EMAIL_CREATE,
  PATH_EMAIL_DETAIL,
  PATH_EMAIL_MANAGEMENT,
  PATH_GROUP_CREATE,
  PATH_GROUP_DETAIL,
  PATH_GROUP_MANAGEMENT,
  PATH_HOME_PAGE_MANAGEMENT,
  PATH_MISSION_DETAIL_CREATE,
  PATH_SUB_DAILY_ROUTINE,
  PATH_SUB_HABIT_QUESTION_DETAIL,
  PATH_SUB_MEMBERSHIP_PAGE,
  PATH_SUB_SETUP_DAILY_ROUTINE,
  PATH_COURSE_MANAGEMENT_DETAIL,
  PATH_ACADEMY_PAGE,
  PATH_TEST_MANAGEMENT,
  PATH_TEACHER_MANAGEMENT_DETAIL,
  PATH_FILL_IN_TEACHER_INFORMATION,
  PATH_SIGN_UP,
  PATH_VIEW_TEACHER_APPLICATION,
  PATH_WAITING_SCREEN,
  PATH_UPDATE_APPLICATION,
  PATH_SUBSCRIPTION,
  PATH_SUBSCRIPTION_CHECKOUT,
  PATH_WITHDRAWAL,
  PATH_NOTIFICATION,
} from './navigation'
import {
  PATH_ADD_BLOG_NEWS,
  PATH_ARTICLE_MANAGEMENT,
  PATH_BLOG_NEWS,
  PATH_CATEGORY,
  PATH_CHALLENGE_DETAIL,
  PATH_CHALLENGE_DETAIL_CREATE,
  PATH_CHALLENGE_DETAIL_MISSION,
  PATH_CHALLENGE_DETAIL_NEW_MISSION,
  PATH_CHALLENGE_DETAIL_NEW_MISSION_PAGE,
  PATH_CHANGE_PASSWORD,
  PATH_CONTACT,
  PATH_DASHBOARD,
  PATH_DETAIL_BLOG_NEWS,
  PATH_EDITOR,
  // PATH_EDIT_USER,
  PATH_FORGOT_PASSWORD,
  PATH_INIT_PASSWORD_ADMIN,
  PATH_LOGIN,
  PATH_NEW_LETTER,
  PATH_PACKAGE_MANAGEMENT,
  PATH_POST_CREATE,
  PATH_POST_MANAGEMENT,
  PATH_RESET_PASSWORD,
  PATH_STAFF_AND_ROLE_MANAGEMENT,
  PATH_SUB_CATEGORY,
  PATH_SUB_GALLERY_PAGE,
  PATH_SUB_HABIT_CHALLENGE,
  PATH_SUB_HABIT_GOAL,
  PATH_SUB_HABIT_QUESTION,
  PATH_SUB_MENU_ABOUT_US_PAGE,
  PATH_SUB_MENU_BLOG_PAGE,
  PATH_SUB_MENU_HOME_PAGE,
  PATH_SUB_MENU_TERM_POLICIES_PAGE,
  PATH_SUB_PACKAGE_PAGE,
  PATH_USER_DETAIL,
  PATH_USER_MANAGEMENT,
  PATH_USER_PROFILE,
  PATH_COURSE_MANAGEMENT,
  PATH_TEACHER_MANAGEMENT,
} from 'src/routes/navigation'
import TeacherOverviewWithContext from 'src/pages/teacher-management/TeacherOverview'
import { EPermissions } from '@configs'
import { FillInTeacherInformationPage } from 'src/pages/auth/sign-up/fill-in-teacher-information/FillInTeacherInformationPage'
import { SignUpPage } from 'src/pages/auth/sign-up'
import { WaittingScreen } from 'src/pages/auth/waitting-screen'
import { EGradeTestViewMode } from 'src/pages/test-management/type'
import WithdrawalPage from 'src/pages/withdrawal/WithdrawalPage'

export const routes: Array<
  IRoute & { permissionViewKeys?: EPermissions[]; isNoAdmin?: boolean }
> = [
  { path: PATH_RESET_PASSWORD, element: ResetPasswordPage },
  { path: PATH_CHANGE_PASSWORD, element: ChangePasswordPage },
  { path: PATH_USER_PROFILE, element: ProfilePage },

  {
    path: PATH_DASHBOARD,
    element: DashboardWithContext,
    permissionViewKeys: [EPermissions.DASHBOARD],
  },
  {
    path: PATH_VIEW_TEACHER_APPLICATION,
    element: FillInTeacherInformationPage,
  },
  {
    path: PATH_UPDATE_APPLICATION,
    element: FillInTeacherInformationPage,
  },
  {
    path: PATH_SUBSCRIPTION,
    element: SubscriptionPage,
  },
  {
    path: PATH_COURSE_MANAGEMENT_DETAIL,
    element: CourseDetail,
  },
  {
    path: PATH_COURSE_MANAGEMENT_NEW,
    element: CourseCreateScreen,
  },
  {
    path: PATH_COURSE_MANAGEMENT,
    element: CoursePage,
  },

  {
    path: PATH_WITHDRAWAL,
    element: WithdrawalPage,
  },

  // {
  //   path: PATH_USER_DETAIL,
  //   element: UserDetailPage,
  //   permissionViewKeys: [EPermissions.USER_MANAGEMENT],
  // },

  // {
  //   path: PATH_USER_MANAGEMENT,
  //   element: UserManagementPage,
  //   permissionViewKeys: [EPermissions.USER_MANAGEMENT],
  // },
  // {
  //   path: PATH_ARTICLE_MANAGEMENT,
  //   element: DeclarationPage,
  //   permissionViewKeys: [EPermissions.HOMEPAGE],
  // },
  // { path: PATH_EDITOR, element: EditorPage },
  // {
  //   path: PATH_STAFF_AND_ROLE_MANAGEMENT,
  //   element: StaffAndRoleManagementPage,
  //   permissionViewKeys: [
  //     EPermissions.STAFF_MANAGEMENT,
  //     EPermissions.ROLE_MANAGEMENT,
  //   ],
  // },
  // {
  //   path: PATH_PACKAGE_MANAGEMENT,
  //   element: PackageManagementPage,
  //   permissionViewKeys: [EPermissions.PACKAGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_NEW_LETTER,
  //   element: NewLetterPage,
  //   permissionViewKeys: [EPermissions.NEWLETTER_MANAGEMENT],
  // },
  // {
  //   path: PATH_CONTACT,
  //   element: ContactPage,
  //   permissionViewKeys: [EPermissions.CONTACT],
  // },
  // {
  //   path: PATH_BLOG_NEWS,
  //   element: BlogNewsPage,
  //   permissionViewKeys: [EPermissions.BLOG_MANAGEMENT],
  // },
  // {
  //   path: PATH_TEACHER_MANAGEMENT,
  //   element: TeacherPageWithContenxt,
  //   permissionViewKeys: [EPermissions.TEACHER_MANAGEMENT],
  // },
  // {
  //   path: PATH_ADD_BLOG_NEWS,
  //   element: SingleBlog,
  //   permissionViewKeys: [EPermissions.BLOG_MANAGEMENT],
  // },
  // {
  //   path: PATH_DETAIL_BLOG_NEWS,
  //   element: SingleBlog,
  //   permissionViewKeys: [EPermissions.BLOG_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_MENU_ABOUT_US_PAGE,
  //   element: AboutUsPageManagement,
  //   permissionViewKeys: [EPermissions.ABOUT_US],
  // },
  // {
  //   path: PATH_CATEGORY,
  //   element: Category,
  //   permissionViewKeys: [EPermissions.CATEGORY_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_CATEGORY,
  //   element: SubCategory,
  //   permissionViewKeys: [EPermissions.SUB_CATEGORY_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_MENU_HOME_PAGE,
  //   element: DeclarationPage,
  //   permissionViewKeys: [EPermissions.HOMEPAGE],
  // },
  // {
  //   path: PATH_SUB_MENU_ABOUT_US_PAGE,
  //   element: SubCategory,
  //   permissionViewKeys: [EPermissions.ABOUT_US],
  // },
  // {
  //   path: PATH_SUB_MENU_TERM_POLICIES_PAGE,
  //   element: TermAndPolicyContent,
  //   permissionViewKeys: [EPermissions.TERM_POLICY],
  // },
  // {
  //   path: PATH_SUB_MENU_BLOG_PAGE,
  //   element: BlogContent,
  //   permissionViewKeys: [EPermissions.BLOG_PAGE],
  // },
  // {
  //   path: PATH_ACADEMY_PAGE,
  //   element: AcademyManagement,
  //   permissionViewKeys: [EPermissions.ACADEMY],
  // },
  // {
  //   path: PATH_SUB_GALLERY_PAGE,
  //   element: GalleryScreen,
  //   permissionViewKeys: [EPermissions.GALLERY],
  // },
  // { path: PATH_SUB_PACKAGE_PAGE, element: MembershipPage },
  // { path: PATH_HOME_PAGE_MANAGEMENT, element: HomepageContentManagement },

  // {
  //   path: PATH_SUB_HABIT_GOAL,
  //   element: TagHabitPage,
  //   permissionViewKeys: [EPermissions.GOAL_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_HABIT_QUESTION,
  //   element: QuestionHabitPage,
  //   permissionViewKeys: [EPermissions.QUESTION_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_HABIT_QUESTION_DETAIL,
  //   element: QuestionDetailPage,
  //   permissionViewKeys: [EPermissions.QUESTION_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_HABIT_CHALLENGE,
  //   element: ChallengeHabitPage,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_DAILY_ROUTINE,
  //   element: RoutineManagement,
  //   permissionViewKeys: [EPermissions.DAILY_ROUTINE_MANAGEMENT],
  // },
  // {
  //   path: PATH_DAILY_ROUTINE_DETAIL,
  //   element: RoutinePage,
  //   permissionViewKeys: [EPermissions.DAILY_ROUTINE_MANAGEMENT],
  // },
  // {
  //   path: PATH_DAILY_ROUTINE_DETAIL_CREATE,
  //   element: RoutinePage,
  //   permissionViewKeys: [EPermissions.DAILY_ROUTINE_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL,
  //   element: DetailChallenge,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL_MISSION,
  //   element: MissionPage,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL_NEW_MISSION,
  //   element: DetailMission,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL_CREATE,
  //   element: DetailChallenge,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_MISSION_DETAIL_CREATE,
  //   element: DetailMission,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_DAILY_ROUTINE_JOBS,
  //   element: JobPage,
  //   permissionViewKeys: [EPermissions.DAILY_ROUTINE_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_SETUP_DAILY_ROUTINE,
  //   element: SetupStartingScreen,
  //   permissionViewKeys: [EPermissions.STARTING_SCREEN],
  // },

  // {
  //   path: PATH_CHALLENGE_DETAIL_NEW_MISSION_PAGE,
  //   element: MissionPage,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_POST_MANAGEMENT,
  //   element: PostManagementScreen,
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // {
  //   path: PATH_TEACHER_MANAGEMENT_DETAIL,
  //   element: TeacherOverviewWithContext,
  //   permissionViewKeys: [EPermissions.TEACHER_MANAGEMENT],
  // },
  // {
  //   path: `${PATH_POST_MANAGEMENT}/post-detail/:postId`,
  //   element: PostDetailScreen,
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // {
  //   path: `${PATH_POST_CREATE}`,
  //   element: PostCreateScreen,
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // {
  //   path: `${PATH_POST_CREATE}/:postId`,
  //   element: PostCreateScreen,
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // {
  //   path: `${PATH_EDIT_POST}`,
  //   element: PostCreateScreen,
  //   permissionViewKeys: [EPermissions.COMMUNITY_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL_V2,
  //   element: DetailChallenge_V2,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },
  // {
  //   path: PATH_CHALLENGE_DETAIL_PARTICIPANTS,
  //   element: DetailChallengeParticipant,
  //   permissionViewKeys: [EPermissions.CHALLENGE_MANAGEMENT],
  // },

  // {
  //   path: PATH_GROUP_MANAGEMENT,
  //   element: GroupUserPage,
  //   permissionViewKeys: [EPermissions.GROUP_MANAGEMENT],
  // },
  // {
  //   path: PATH_GROUP_CREATE,
  //   element: CreateEditGroupPage,
  //   permissionViewKeys: [EPermissions.GROUP_MANAGEMENT],
  // },
  // {
  //   path: PATH_GROUP_DETAIL,
  //   element: CreateEditGroupPage,
  //   permissionViewKeys: [EPermissions.GROUP_MANAGEMENT],
  // },

  // {
  //   path: PATH_EMAIL_MANAGEMENT,
  //   element: EmailContentPage,
  //   permissionViewKeys: [EPermissions.EMAIL_MANAGEMENT],
  // },
  // {
  //   path: PATH_EMAIL_CREATE,
  //   element: CreateEditEmailContentPage,
  //   permissionViewKeys: [EPermissions.EMAIL_MANAGEMENT],
  // },
  // {
  //   path: PATH_EMAIL_DETAIL,
  //   element: CreateEditEmailContentPage,
  //   permissionViewKeys: [EPermissions.EMAIL_MANAGEMENT],
  // },
  // {
  //   path: PATH_SUB_MEMBERSHIP_PAGE,
  //   element: MembershipPage,
  //   permissionViewKeys: [EPermissions.MEMBERSHIP],
  // },
  // { path: PATH_DAILY_ROUTINE_JOBS_DETAIL, element: JobDetail },
  {
    path: PATH_TEST_MANAGEMENT,
    element: TestManagementPage,
    permissionViewKeys: [EPermissions.TEST_MANAGEMENT],
  },
  {
    path: `${PATH_TEST_MANAGEMENT}/grade-test/:gradeTestId`,
    element: () => GradeTestPage({ mode: EGradeTestViewMode.GRADE }),
    permissionViewKeys: [EPermissions.TEST_MANAGEMENT],
  },
  {
    path: `${PATH_TEST_MANAGEMENT}/view-graded-test/:gradeTestId`,
    element: () => GradeTestPage({ mode: EGradeTestViewMode.VIEW }),
    permissionViewKeys: [EPermissions.TEST_MANAGEMENT],
  },
  {
    path: PATH_NOTIFICATION,
    element: NotificationScreen,
    isNoAdmin: true,
  },
]

export const publicRoutes: Array<IRoute> = [
  //auth
  { path: PATH_LOGIN, element: LoginPage },
  { path: PATH_SIGN_UP, element: SignUpPage },
  { path: PATH_FORGOT_PASSWORD, element: ForgotPasswordPage },
]

export const restrictedRoutes: Array<IRoute> = [
  //auth
  { path: PATH_WAITING_SCREEN, element: WaittingScreen },
  { path: PATH_INIT_PASSWORD_ADMIN, element: InitPasswordAdminPage },
]
