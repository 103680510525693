import { ColumnsType } from 'antd/lib/table'
import { upperCase } from 'lodash'
import { t } from 'i18next'

import { TitleWrapperStyled } from 'src/pages/dashboard/list-course/course-acordion/config'
import { Typography } from 'antd'

export const REVENUE_RECEIVED_TABLE_COLUMNS: ColumnsType<any> = [
  {
    width: '5%',
    title: upperCase(t('dashboard:no')),
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '40%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('revenue:course')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'courseName',
    key: 'courseName',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '15%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('revenue:amount')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'amount',
    key: 'amount',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-center">
          {e} EUR
        </Typography.Text>
      )
    },
  },
]
