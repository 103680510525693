import styled from 'styled-components'
import { t } from 'i18next'
import { DatePicker } from 'antd'
import {
  Control,
  Controller,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form'
import { PlusOutlined } from '@ant-design/icons'

import { ShareSelectInput } from '@components'
import {
  BALANCE_TOOLBAR_RHF_FIELDS,
  BALANCE_TYPE_OPTIONS,
} from 'src/pages/withdrawal/balance-table/configs'
import { FORMAT_DATE_TABLE } from '@configs'
import { themes } from '@theme'
import { Button } from 'src/common'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'

export declare type EventValue<DateType> = DateType | null
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export const { RangePicker } = DatePicker

interface IProps {
  control: Control<any, any>
  reset: UseFormReset<any>
  setValue: UseFormSetValue<any>
  onCreateWithdraw: () => void
  openRegenerateModal: () => void
}

export const BalanceToolbar = ({
  control,
  reset,
  setValue,
  onCreateWithdraw,
  openRegenerateModal,
}: IProps) => {
  return (
    <BalanceToolbarStyled>
      <div className="flex flex-1 gap-2">
        <Controller
          name={BALANCE_TOOLBAR_RHF_FIELDS.DATE_RANGE}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <RangePicker
                value={value}
                format={FORMAT_DATE_TABLE}
                allowEmpty={[true, true]}
                onChange={onChange}
                style={{
                  borderRadius: 6,
                  borderColor: `${themes.theme.dark.colors.input.border}`,
                }}
              />
            )
          }}
        />

        <Controller
          name={BALANCE_TOOLBAR_RHF_FIELDS.TYPE}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                data={BALANCE_TYPE_OPTIONS}
                onChange={(value: string) => {
                  onChange(value)
                }}
                errors={error?.message}
                value={value}
                containerClassName="min-w-[160px] max-w-max"
                placeholder={t('dashboard:type')}
                showArrow
              />
            )
          }}
        />
      </div>

      <ButtonWrapper className="!mt-0 flex-1 !items-start !justify-end">
        <Button
          className="!flex !items-center !gap-2 !bg-orange-500 !text-white !border-none !h-full !max-h-[38px]"
          onClick={openRegenerateModal}
        >
          <PlusOutlined />
          {t('revenue:regenerate_invoice')}
        </Button>
        <Button
          className="!flex !items-center !gap-2 !bg-green-500 !text-white !border-none !h-full !max-h-[38px]"
          onClick={onCreateWithdraw}
        >
          <PlusOutlined />
          {t('revenue:new_withdraw')}
        </Button>
      </ButtonWrapper>
    </BalanceToolbarStyled>
  )
}

const BalanceToolbarStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  .ant-input-prefix {
    padding-left: 8px;
  }
`
