import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import { QK_WITHDRAW } from 'src/configs/queryClient/queryKeyConstants'
import { revenueAPI } from 'src/api/revenue'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<{ data: string; message: string }>,
    any,
    any,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  handleSuccess?: (value: { data: string; message: string }) => void
  onError?: (err: any) => void
}

export const useWithdrawMutation = (configs?: IMutationParams) => {
  const { onError, handleSuccess } = { ...configs }

  return useMutation({
    mutationKey: [QK_WITHDRAW],
    mutationFn: (amount: number) =>
      revenueAPI.withdraw({
        amount,
      }),
    onError: (error) => {
      onError?.(error)
    },
    onSuccess(res) {
      handleSuccess?.(res?.data)
    },

    ...configs,
  })
}
