import { t } from 'i18next'
import { ColumnsType } from 'antd/lib/table'
import { Tooltip, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { capitalize } from 'lodash'

import {
  TitleWrapperStyled,
  IncomeTypeStyled,
  TypeWrapper,
} from '../course-acordion/config'
import { ECourseType, EIncomeType } from '@configs'
import { themes } from '@theme'
import {
  ICourseItem,
  ITeacherPackageInformation,
  ProfilePhoto,
  TPackage,
} from '@interfaces'
import { StyledAvatar } from '@components'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export const ORDER_DETAILS_COLUMN_KEYS = {
  ORDER_ID: 'revenueId',
  INCOME_TYPE: 'income_type',
  TEACHER_PACKAGE: 'teacher_package',
  STUDENT: 'student',
  STUDENT_PACKAGE: 'student_package',
  COURSE_NAME: 'course_name',
  COURSE_NORMAL_PRICE: 'course_normal_price',
  COURSE_DISCOUNT: 'course_discount',
  COURSE_TYPE: 'course_type',
  REVENUE_PRICE: 'revenue_price',
  PACKAGE_PRICE: 'package_price',
  COMMISSION_RATE: 'commission_rate',
  SHARE_RATIO: 'share_ratio',
}

export const ORDER_DETAILS_COLUMN: ColumnsType<any> = [
  {
    width: '80%',
    title: '',
    dataIndex: 'revenueId',
    key: ORDER_DETAILS_COLUMN_KEYS.ORDER_ID,
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 font-medium !text-primaryPurple !text-[14px] !whitespace-normal">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '60%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:income_type')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'incomeType',
    key: ORDER_DETAILS_COLUMN_KEYS.INCOME_TYPE,
    render: (e: string) => {
      if (e === EIncomeType.SOLD) {
        return (
          <TypeWrapper>
            <IncomeTypeStyled
              $bgColor={themes.theme.dark.colors.miniDashboard.lightBlue}
              $textColor={themes.theme.dark.colors.miniDashboard.darkBlue}
            >
              {capitalize(e?.replace('_', ' '))}
            </IncomeTypeStyled>
          </TypeWrapper>
        )
      }
      return (
        <TypeWrapper>
          <IncomeTypeStyled
            $bgColor={themes.theme.dark.colors.miniDashboard.lightGreen}
            $textColor={themes.theme.dark.colors.miniDashboard.darkGreen}
          >
            {capitalize(e?.replace('_', ' '))}
          </IncomeTypeStyled>
        </TypeWrapper>
      )
    },
    ellipsis: true,
  },
  {
    width: '70%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:teacher_package')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'teacherPackageInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.TEACHER_PACKAGE,
    render: (e: ITeacherPackageInformation) => (
      <TypeWrapper>
        <IncomeTypeStyled
          $bgColor={themes.theme.dark.colors.miniDashboard.lightBlue}
          $textColor={themes.theme.dark.colors.miniDashboard.darkBlue}
        >
          {e?.name}
        </IncomeTypeStyled>
      </TypeWrapper>
    ),
    ellipsis: true,
  },
  {
    width: '60%',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:student')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'userInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.STUDENT,
    render: (e: {
      name: string
      avatar: ProfilePhoto
      package: Partial<TPackage>
    }) => {
      return (
        <div className="flex items-center gap-2">
          <StyledAvatar src={e?.avatar?.url ?? ''} className="min-w-[32px]" />
          <Typography.Text className="!m-0 font-medium !text-[14px] !text-wrap">
            {e?.name}
          </Typography.Text>
        </div>
      )
    },
  },
  {
    width: '45%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:student_package')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'userInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.STUDENT_PACKAGE,
    render: (e: {
      name: string
      avatar: ProfilePhoto
      package: Partial<TPackage>
    }) => (
      <TypeWrapper>
        <IncomeTypeStyled
          $bgColor={themes.theme.dark.colors.miniDashboard.lightBlue}
          $textColor={themes.theme.dark.colors.miniDashboard.darkBlue}
        >
          {e?.package?.name}
        </IncomeTypeStyled>
      </TypeWrapper>
    ),
    ellipsis: true,
  },

  {
    width: '40%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled className="flex-col">
          <p className="m-0">{t('dashboard:package_price')}</p>
          <Tooltip title={t('dashboard:package_price_tooltip')}>
            <ExclamationCircleOutlined className="!text-[20px] !text-white" />
          </Tooltip>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'userInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.PACKAGE_PRICE,
    render: (e: {
      name: string
      avatar: ProfilePhoto
      package: Partial<TPackage>
    }) => (
      <Typography.Text className="!m-0 !text-[14px]">
        {formatCurrencyByType(e?.package?.eurPrice ?? 0, 'EUR')}
      </Typography.Text>
    ),
    ellipsis: true,
  },
  {
    width: '45%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:commission_rate')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'teacherPackageInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.COMMISSION_RATE,
    render: (e: ITeacherPackageInformation) => (
      <Typography.Text className="!m-0 !text-[14px]">
        {e?.commissionRate ?? 0}%
      </Typography.Text>
    ),
    ellipsis: true,
  },
  {
    width: '80%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:course_name')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'courseInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.COURSE_NAME,
    render: (
      e: Partial<ICourseItem> & {
        name: string
      }
    ) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e?.name}
        </Typography.Text>
      )
    },
  },
  {
    width: '50%',
    align: 'center',
    ellipsis: true,
    title: () => {
      return (
        <TitleWrapperStyled className="flex-col">
          <p className="m-0">{t('dashboard:course_normal_price')}</p>
          <Tooltip title={t('dashboard:tooltip_course_normal')}>
            <ExclamationCircleOutlined className="!text-[20px] !text-white" />
          </Tooltip>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'courseInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.COURSE_NORMAL_PRICE,
    render: (
      e: Partial<ICourseItem> & {
        name: string
      }
    ) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]">
          {formatCurrencyByType(e?.eurPrice ?? 0, 'EUR')}
        </Typography.Text>
      )
    },
  },
  {
    width: '40%',
    align: 'center',
    ellipsis: true,
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:course_discount')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'userInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.COURSE_DISCOUNT,
    render: (e: {
      name: string
      avatar: ProfilePhoto
      package: Partial<TPackage>
    }) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]">
          {e?.package?.discount ?? 0}%
        </Typography.Text>
      )
    },
  },
  {
    width: '40%',
    align: 'center',
    ellipsis: true,
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:share_ratio')} %</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'teacherPackageInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.SHARE_RATIO,
    render: (e: ITeacherPackageInformation) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]">
          {e?.shareRatio ?? 0}%
        </Typography.Text>
      )
    },
  },
  {
    width: '60%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('dashboard:course_type')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'courseInformation',
    key: ORDER_DETAILS_COLUMN_KEYS.COURSE_TYPE,
    render: (
      e: Partial<ICourseItem> & {
        name: string
      }
    ) => {
      if (e?.type === ECourseType.MEMBER_COURSE) {
        return (
          <TypeWrapper>
            <IncomeTypeStyled
              $bgColor={themes.theme.dark.colors.miniDashboard.lightBlue}
              $textColor={themes.theme.dark.colors.miniDashboard.darkBlue}
            >
              {capitalize(e?.type)?.replace('_', ' ')}
            </IncomeTypeStyled>
          </TypeWrapper>
        )
      }

      if (e?.type === ECourseType.PREMIUM) {
        return (
          <TypeWrapper>
            <IncomeTypeStyled
              $bgColor={themes.theme.dark.colors.miniDashboard.lightOrange}
              $textColor={themes.theme.dark.colors.miniDashboard.darkOrange}
            >
              {capitalize(e?.type)}
            </IncomeTypeStyled>
          </TypeWrapper>
        )
      }

      return (
        <TypeWrapper>
          <IncomeTypeStyled
            $bgColor={themes.theme.dark.colors.miniDashboard.lightGreen}
            $textColor={themes.theme.dark.colors.miniDashboard.darkGreen}
          >
            {capitalize(e?.type)}
          </IncomeTypeStyled>
        </TypeWrapper>
      )
    },
    ellipsis: true,
  },
  {
    width: '40%',
    align: 'center',
    ellipsis: true,
    title: '',
    dataIndex: 'amount',
    key: ORDER_DETAILS_COLUMN_KEYS.REVENUE_PRICE,
    render: (e: any) => {
      return (
        <Typography.Text className="!m-0 !text-[14px]" ellipsis={true}>
          €{formatCurrencyByType(Number(e ?? 0), 'EUR').replace('€', '')}
        </Typography.Text>
      )
    },
  },
]
