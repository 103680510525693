import { Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { capitalize } from 'lodash'

import { FORMAT_DATE_TIME } from '@configs'
import { formatDate } from '@utils'
import { TitleWrapperStyled } from 'src/pages/dashboard/list-course/course-acordion/config'
import { EBalanceStatus } from 'src/pages/withdrawal/balance-table/configs'
import { themes } from '@theme'

export const HISTORY_LOG_COLUMNS: ColumnsType<any> = [
  {
    width: '15%',
    title: t('revenue:log'),
    dataIndex: 'index',
    key: 'index',
    ellipsis: true,
    align: 'center',
  },
  {
    width: '25%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:date_time')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'dateTime',
    key: 'dateTime',
    render: (e: string) => {
      return (
        <Typography.Text
          className="!m-0 !text-[14px] !text-wrap"
          ellipsis={true}
        >
          {formatDate(e, FORMAT_DATE_TIME)}
        </Typography.Text>
      )
    },
    ellipsis: true,
  },
  {
    width: '20%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:status')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'status',
    key: 'status',
    render: (e: EBalanceStatus) => {
      const textColors = {
        [EBalanceStatus.SUCCESS]: `${themes.theme.light.colors.note}`,
        [EBalanceStatus.PENDING]: `${themes.theme.light.colors.darkWarning}`,
        [EBalanceStatus.FAIL]: `${themes.theme.light.colors.delete}`,
        [EBalanceStatus.PAYOUT_FAIL]: `${themes.theme.light.colors.delete}`,
      }
      return (
        <Typography.Text
          className="!m-0 !text-[14px]"
          ellipsis={true}
          style={{
            color: textColors[e],
          }}
        >
          {capitalize(e)}
        </Typography.Text>
      )
    },
    ellipsis: true,
  },
  {
    width: '50%',
    align: 'center',
    title: () => {
      return (
        <TitleWrapperStyled>
          <p className="m-0">{t('revenue:description')}</p>
        </TitleWrapperStyled>
      )
    },
    dataIndex: 'description',
    key: 'description',
    render: (e: string) => {
      return (
        <Typography.Text
          className="!m-0 !text-[14px] !text-wrap"
          ellipsis={true}
        >
          {e}
        </Typography.Text>
      )
    },
    ellipsis: true,
  },
]
