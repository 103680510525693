import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { useEffect } from 'react'
import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { TUpdateUserData } from 'src/interfaces'
import { useAppDispatch } from 'src/redux'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { addSectionAction, editSectionAction } from 'src/redux/actions/course'
import { useParams } from 'react-router-dom'
import { ICourseSections } from 'src/interfaces/course'
import { message } from 'antd'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  section?: ICourseSections
  isActive?: boolean
  onSave: () => void
}

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  description: z
    .string()
    .trim()
    .max(1000, {
      message: 'Description must be less than 1000 characters long',
    })
    .optional(),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(150, {
      message: 'Title must be less than 150 characters long',
    })
    .min(2, {
      message: 'Title must be more than 2 characters long',
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .max(1000, {
      message: 'Description must be less than 1000 characters long',
    })
    .optional(),
})

const ModalAddSection = ({
  open,
  onClose,
  section,
  onSave,
  isActive = true,
}: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,

    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      title: '',
      description: '',
      titleInDutch: '',
      descriptionInDutch: '',
    },
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const dispatch = useAppDispatch()
  const { id } = useParams()

  const addSection = async (data: any) => {
    if (!id) {
      return
    }
    if (section) {
      try {
        const response = await dispatch(
          editSectionAction({
            ...data,
            sectionId: section.id,
          })
        ).unwrap()
        message.success(response.message)

        onClose()
      } catch (error: any) {
        message.error(error.message)
      } finally {
        onSave()
      }
      return
    }
    try {
      const response = await dispatch(
        addSectionAction({
          ...data,
          courseId: +id,
        })
      ).unwrap()
      message.success(response.message)

      onClose()
    } catch (error: any) {
      message.error(error.message)
    } finally {
      onSave()
    }
  }

  useEffect(() => {
    if (open && section) {
      setValue('title', section.title)
      setValue('description', section.description)
      setValue('titleInDutch', section.titleInDutch)
      setValue('descriptionInDutch', section.descriptionInDutch)
    }
    return () => {
      reset()
    }
  }, [open, setValue, reset])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={section ? 'Edit section' : 'Add new section'}
    >
      <div className="mt-6 w-[500px]">
        <form>
          <div>
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={'Section title'}
                    name="title"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    required
                    disabled={!isActive}
                  />
                )
              }}
            />
          </div>

          <div className="mt-5">
            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={'Section title in Netherlands'}
                    name="titleInDutch"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    required
                    disabled={!isActive}
                  />
                )
              }}
            />
          </div>

          <div className="mt-5">
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={'Description'}
                    name="description"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    disabled={!isActive}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>

          <div className="mt-5">
            <Controller
              name="descriptionInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label={'Description in Netherlands'}
                    name="descriptionInDutch"
                    className="input"
                    value={value}
                    type="text"
                    onChange={onChange}
                    errors={error?.message}
                    disabled={!isActive}
                  />
                )
              }}
            />
          </div>

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className=" login-btn mr-10"
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                htmlType="button"
                type="primary"
                size="middle"
                disabled={!isActive}
                className="submit__btn login-btn"
                onClick={handleSubmit(addSection, (e) => {
                  console.log('e', JSON.stringify(e, null, 2))
                })}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AppModal>
  )
}

export default ModalAddSection
