import { ApiClient } from './axiosClient'
import { IChangeProfileData, IProfile } from '@interfaces'
import { ENDPOINT_GET_USER_APPLICATION } from './react-query'

export const profileAPI = {
  getAdminProfile: () => {
    return ApiClient.get<IProfile>(`users/profile`)
  },

  updateAdminProfile: (data: IChangeProfileData) => {
    return ApiClient.put<{ message: string; user: IProfile }>(`users/profile`, {
      name: data.name,
      phone: data.phone,
      profilePhotoId: data.profilePhotoId,
      description: data.description,
    })
  },
  getUserApplication: () => {
    // TODO: declare specific type and remove any
    return ApiClient.get(ENDPOINT_GET_USER_APPLICATION) as any
  },
}
