import llcTeacherBlackLogo from './llc_teacher_black_logo.png'

export const IMAGES = {
  logo: require('./thumbs/logo.png'),
  defaultImage: require('./thumbs/default-image.jpg'),
  backgroundLogin: require('./bg_auth.svg'),
  user64: require('./user_64.png'),
  user512: require('./user_512.png'),
  noImage: require('./no-image.png'),
  icon_love: require('./icon_love.png'),
  icon_like: require('./icon_like.png'),
  icon_lol: require('./icon_lol.png'),
  icon_sad: require('./icon_sad.png'),
  icon_angry: require('./icon_angry.png'),
  icon_thumb_down: require('./icon_thumsp_down.png'),
  logo_defaultProfileImage: require('./logo_defaultProfileImage.png'),
  llcTeacherBlackLogo,
}
