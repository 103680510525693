import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseError, BaseResponseProps, IRequest } from '@interfaces'
import { QK_INACTIVE_TEACHER_COURSE } from 'src/configs/queryClient/queryKeyConstants'
import { courseManagementAPI } from 'src/api/course'

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<{
      request: IRequest
    }>,
    any,
    { id: number | string; reason: string },
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

interface IMutationParams {
  configs?: IMutationOptions
  handleSuccess?: (res: { request: IRequest }) => void
  handleError?: (error: BaseResponseError) => void
}

export const inactiveTeacherCourse = (params: {
  id: number | string
  reason: string
}) => courseManagementAPI.inactiveTeacherCourse(params.id, params.reason)

export const useMutationInactiveCourse = (params?: IMutationParams) => {
  const { configs, handleSuccess, handleError } = { ...params }

  return useMutation({
    mutationKey: [QK_INACTIVE_TEACHER_COURSE],
    mutationFn: inactiveTeacherCourse,
    onSuccess: (res) => {
      handleSuccess?.(res?.data)
    },
    onError: (error) => {
      handleError?.(error)
    },

    ...configs,
  })
}
