import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  ERequest,
  ETeacherRequestStatus,
  ETeacherRole,
  HOME_PAGE_CHAT,
  HOME_PAGE_CONTENT,
  HOME_PAGE_VIDEO,
  HOME_SEO,
  SEO_CONFIG,
} from '@configs'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Text } from 'src/common'
import CourseCreateScreen from './CourseCreateScreen'
import CourseSeoConfigScreen from './CourseSeoConfigScreen'
import { LessonManagement } from './form/lesson/SectionManagement'
import ChatTab from './chat'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { checkDeletedCourse } from 'src/redux/actions/course'
import { themes } from '@theme'

interface Props {}

// eslint-disable-next-line no-empty-pattern
export const CourseDetail = ({}: Props) => {
  const { id: courseId } = useParams()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') || HOME_PAGE_VIDEO
  )

  const dispatch = useAppDispatch()
  const isCourseDeleted = useAppSelector(
    (state) => state.course.isCourseDeleted
  )

  const { selectedCourse } = useSelector((state: RootState) => state.course)

  const canChat = useMemo(() => {
    if (isCourseDeleted) return true

    const courseRequestStatus = selectedCourse?.requests?.find(
      (request) => request?.type === ERequest.PUBLISH
    )?.status

    if (!courseRequestStatus) return false

    return ![
      ETeacherRequestStatus.REJECTED,
      ETeacherRequestStatus.PENDING,
    ].includes(courseRequestStatus)
  }, [isCourseDeleted, selectedCourse?.requests])

  const items = [
    {
      label: <Text>Overview</Text>,
      key: HOME_PAGE_VIDEO,
      children: <CourseCreateScreen />,
      disabled: isCourseDeleted,
    },

    {
      label: <Text>Content</Text>,
      key: HOME_PAGE_CONTENT,
      children: <LessonManagement activeTab={activeTab} />,
      disabled: isCourseDeleted,
    },
    {
      label: <Text>{SEO_CONFIG}</Text>,
      key: HOME_SEO,
      children: <CourseSeoConfigScreen activeTab={activeTab} />,
      disabled: isCourseDeleted,
    },
    {
      label: <Text>Chat</Text>,
      key: HOME_PAGE_CHAT,
      children: <ChatTab />,
      disabled: !canChat,
    },
  ]

  useEffect(() => {
    if (location.state && location.state.isCreateNew) {
      setActiveTab(HOME_PAGE_CONTENT)
    }
  }, [location.state])

  return (
    <HomePageStyled activeTab={activeTab}>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        size={size}
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </HomePageStyled>
  )
}

const HomePageStyled = styled('div')(
  ({ activeTab }: { activeTab?: string }) => {
    return {
      '.ant-tabs-nav': {
        marginBottom: 0,
        paddingLeft: '1.25rem',

        '& .ant-tabs-tab': {
          borderRadius: '0.75rem 0.75rem 0 0 !important',

          '&.ant-tabs-tab-active': {
            span: {
              color: `${themes.theme.light.colors.primary}`,
              fontWeight: '500',
            },
          },

          '&.ant-tabs-tab-disabled': {
            background: '#b8b8b8 !important',
            '& .ant-tabs-tab-btn': {
              '& .ant-typography': {
                color: `${themes.theme.light.colors.secondary}`,
              },
            },
          },
        },
      },

      '.ant-tabs-content': {
        paddingBottom: `${activeTab === HOME_PAGE_CHAT ? 0 : 24}`,
      },
    }
  }
)
