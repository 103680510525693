import { memo, useState } from 'react'
import { message, Typography } from 'antd'
import { t } from 'i18next'

import { AppModal } from '@components'
import { OverviewSection } from './overview-section/OverviewSection'
import { RevenueReceived } from './revenue-received/RevenueReceived'
import { RevenueWithdraw } from './revenue-withdraw/RevenueWithdraw'
import {
  EBalanceStatus,
  EBalanceType,
} from 'src/pages/withdrawal/balance-table/configs'
import { Button } from 'src/common'
import { ButtonWrapper } from 'src/pages/auth/sign-up/fill-in-teacher-information/style'
import { useGetInvoiceDetailsQuery, useRedoTransactionMutation } from '@api'
import { RedoTransactionModal } from './redo-transaction-modal/RedoTransactionModal'

interface IProps {
  invoiceId: string | number
  open: boolean
  onClose: () => void
}

export const InvoiceModal = memo(({ invoiceId, onClose, open }: IProps) => {
  const [redoTransactionModalVisible, setRedoTransactionModalVisible] =
    useState(false)

  const { data: invoiceDetails, isFetching: isFetchingInvoiceDetails } =
    useGetInvoiceDetailsQuery({
      invoiceId,
    })

  const { mutate: redoTransaction, isPending: redoingTransaction } =
    useRedoTransactionMutation({
      handleSuccess(value) {
        message.success(value?.message)
        onClose()
      },
      handleError(err) {
        setRedoTransactionModalVisible(false)
      },
    })
  const handleRedoTransaction = async () => {
    if (!invoiceId) return

    await redoTransaction(invoiceId)
  }

  return (
    <>
      {redoTransactionModalVisible ? (
        <RedoTransactionModal
          open
          onClose={() => setRedoTransactionModalVisible(false)}
          onRedoTransaction={handleRedoTransaction}
          isLoading={redoingTransaction}
        />
      ) : null}
      <AppModal
        open={open}
        onClose={onClose}
        title={
          <Typography.Title level={4} className="!text-[36px] !text-center">
            {t('revenue:receipt')}
          </Typography.Title>
        }
        bottomButton={
          <ButtonWrapper className="!mt-0">
            <Button
              className="w-full flex justify-center !bg-neutral-500 !text-white !border-neutral-500"
              type="primary"
              onClick={onClose}
            >
              {t('common:back')}
            </Button>
            {[EBalanceStatus.PAYOUT_FAIL].includes(invoiceDetails?.status) ? (
              <Button
                className="w-full flex justify-center"
                type="primary"
                onClick={() => setRedoTransactionModalVisible(true)}
              >
                {t('revenue:re_do_transaction')}
              </Button>
            ) : null}
          </ButtonWrapper>
        }
        contentClassName="!min-w-[900px] !max-w-[900px] !max-h-[95dvh]"
      >
        <OverviewSection invoiceItem={invoiceDetails} />
        <Typography.Title level={4} className="!text-[36px] my-4 px-[22px]">
          {t('revenue:detail')}
        </Typography.Title>

        {[EBalanceType.REVENUE_RECEIVED].includes(
          invoiceDetails.invoiceType
        ) ? (
          <RevenueReceived
            invoiceItem={invoiceDetails}
            isLoading={isFetchingInvoiceDetails}
          />
        ) : (
          <RevenueWithdraw
            invoiceItem={invoiceDetails}
            isLoading={isFetchingInvoiceDetails}
          />
        )}
      </AppModal>
    </>
  )
})
