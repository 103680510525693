export enum EMessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export const ACCEPT_FILE_TYPES =
  '.png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.ppt,.PPT,.pptx,.PPTX,.xls,.XLS,.xlsx,.XLSX,.csv,.CSV'

export const MESSAGE_LIMIT_CHARACTERS = 20000
