export const SIGN_UP_STEP = {
  GENERAL_INFORMATION: 1,
  VERIFY_EMAIL: 2,
  FILL_IN_TEACHER_INFORMATION: 3,
}

export const UPLOAD_DEGREE_LOADING_KEY = 'UPLOAD_DEGREE_LOADING_KEY'
export const UPLOAD_AVATAR_LOADING_KEY = 'UPLOAD_AVATAR_LOADING_KEY'

export const TEACHER_INFO_SPECIFIC_INFO_VALIDATION = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 5,
}
