import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { BaseResponseProps } from '@interfaces'
import { handleAPIError } from '@utils'
import { packageManagementAPI } from 'src/api/package-management'
import { QK_PACKAGE_RENEW_STATUS } from 'src/configs/queryClient/queryKeyConstants'

type TUpdatePackageRenewPayload = {
  packageId: string | number
}

type IMutationOptions = Omit<
  UseMutationOptions<
    BaseResponseProps<any>,
    any,
    TUpdatePackageRenewPayload,
    unknown
  >,
  'onSuccess' | 'mutationKey' | 'mutationFn' | 'onError'
>

type IMutationParams = {
  configs?: IMutationOptions
  onSuccess?: (res: BaseResponseProps<any>) => void
}

export const useUpdatePackageRenewStatusMutation = (
  configs?: IMutationParams
) => {
  return useMutation({
    mutationKey: [QK_PACKAGE_RENEW_STATUS],
    mutationFn: (payload: TUpdatePackageRenewPayload) =>
      packageManagementAPI.updatePackageRenewStatus(payload),
    onError: (error: any) => {
      handleAPIError(error)
    },
    ...configs,
  })
}
