import { DeleteTwoTone } from '@ant-design/icons'
import { themes } from '@theme'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, HookFormTextArea, Input } from 'src/common'
import styled from 'styled-components'
import { TEducationForm } from 'src/pages/auth/sign-up/fill-in-teacher-information/types'
import { enumBreakPoint } from '@configs'
import { ShareSelectInput } from '@components'
import { educationLevelOptions } from './config'
import { Checkbox, Typography } from 'antd'
import { DegreeForm } from 'src/pages/auth/sign-up/fill-in-teacher-information/degree/DegreeForm'

export const EducationForm = ({
  data,
  index,
  onRemove,
  onSave,
}: TEducationForm) => {
  const { control, trigger, setValue, getValues, watch } = useFormContext()

  const isCurrentEducation = watch(
    `specificInformation.education[${index}].isPresent`
  )

  const handleSaveForm = async () => {
    const isValid = await trigger([
      `specificInformation.education[${index}].institution`,
      `specificInformation.education[${index}].educationLevel`,
      `specificInformation.education[${index}].qualification`,
      `specificInformation.education[${index}].description`,
      `specificInformation.education[${index}].startDate`,
      `specificInformation.education[${index}].endDate`,
      `specificInformation.education[${index}].degree.file.fileId`,
      `specificInformation.education[${index}].degree.file.name`,
    ])

    if (isValid) {
      setValue(`specificInformation.education[${index}].editable`, false)
      onSave?.(index)
    }
  }

  return (
    <StyledExperienceForm className="reset-password__section">
      {!!onRemove && (
        <DeleteTwoTone
          onClick={() => onRemove?.(index)}
          className="self-end text-base"
          twoToneColor={themes.theme.light.colors.icon_gray_500}
        />
      )}

      <Controller
        name={`specificInformation.education[${index}].institution`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={i18next.t('common:institution')}
              alignment="col"
              placeholder={i18next.t('common:enterInstitution')}
              name={`specificInformation.education[${index}].institution`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              required
            />
          )
        }}
      />
      <div className="flex flex-row w-full mt-5 gap-10">
        <Controller
          name={`specificInformation.education[${index}].educationLevel`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <ShareSelectInput
                data={educationLevelOptions}
                label={i18next.t('common:educationLevel')}
                value={value || undefined}
                containerClassName="flex w-full flex-col"
                onChange={onChange}
                placeholder={i18next.t('common:enterEducationLevel')}
                errors={error?.message}
                showArrow
                required
              />
            )
          }}
        />
        <Controller
          name={`specificInformation.education[${index}].qualification`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={i18next.t('common:qualification')}
                alignment="col"
                placeholder={i18next.t('common:enterQualification')}
                name={`specificInformation.education[${index}].qualification`}
                errors={error?.message}
                value={value}
                onChange={onChange}
                allowClear={false}
                required
              />
            )
          }}
        />
      </div>

      <div className="col-span-8 mt-5">
        <HookFormTextArea
          control={control}
          controlName={`specificInformation.education[${index}].description`}
          label={i18next.t('common:description')}
          labelClassName="mb-2"
          rows={5}
          required
          placeholder={i18next.t('common:description')}
        />
      </div>

      <div className="flex flex-row w-full mt-5 gap-10 items-start">
        <Controller
          name={`specificInformation.education[${index}].startDate`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={i18next.t('common:startDate')}
                alignment="col"
                type="date"
                haveShowPassIcon
                name={`specificInformation.education[${index}].startDate`}
                value={value}
                onChangeDate={(value) => {
                  onChange(value)
                  const currentEndDate = getValues(
                    `specificInformation.education[${index}].endDate`
                  )
                  if (!!currentEndDate) {
                    trigger(`specificInformation.education[${index}].endDate`)
                  }
                }}
                errors={error?.message}
                inputReadOnly
                allowClear={false}
                required
              />
            )
          }}
        />
        <Controller
          name={`specificInformation.education[${index}].endDate`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label={i18next.t('common:endDate')}
                alignment="col"
                type="date"
                haveShowPassIcon
                name={`specificInformation.education[${index}].endDate`}
                value={value}
                onChangeDate={(value) => {
                  onChange(value)
                  const currentStartDate = getValues(
                    `specificInformation.education[${index}].startDate`
                  )
                  if (!!currentStartDate) {
                    trigger(`specificInformation.education[${index}].startDate`)
                  }
                }}
                errors={isCurrentEducation ? '' : error?.message}
                inputReadOnly
                allowClear={false}
                required={!isCurrentEducation}
                disabled={isCurrentEducation}
              />
            )
          }}
        />

        <Controller
          name={`specificInformation.education[${index}].isPresent`}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                checked={value}
                onChange={(e) => {
                  onChange(e.target.checked)
                  setValue(
                    `specificInformation.education[${index}].endDate`,
                    ''
                  )
                }}
                style={{
                  transform: 'translateY(50%)',
                  marginTop: 16,
                }}
              >
                <Typography style={{ textWrap: 'nowrap' }}>
                  Current education
                </Typography>
              </Checkbox>
            )
          }}
        />
      </div>

      <DegreeForm index={index} />

      <div className="flex items-center max-[800px] justify-center my-5">
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          className="min-[600px] max-[600px]:!w-full"
          onClick={handleSaveForm}
        >
          Save education
        </Button>
      </div>
    </StyledExperienceForm>
  )
}

export const StyledExperienceForm = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => themes.theme.light.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => themes.theme.light.colors.subText};
    }
  }

  .actions {
    @media (min-width: ${enumBreakPoint.DESKTOP}) {
      display: flex;
      align-items: center;
      .btn {
        width: 12.8rem;
      }
    }
  }

  .reset-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      @media (min-width: ${enumBreakPoint.DESKTOP}) {
        margin-right: 1.2rem;
        margin-bottom: 0;
      }
      margin-bottom: 1.2rem;
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.8rem;
    }
  }
`
