import { ReactNode, useMemo } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'

import { IInvoiceItem } from '@interfaces'
import { themes } from '@theme'
import {
  BankOutlined,
  CalendarOutlined,
  ContainerOutlined,
  EuroCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { formatDate } from '@utils'
import { FORMAT_DATE_TIME } from '@configs'
import { capitalize } from 'lodash'
import { OrderDetailTag } from 'src/pages/withdrawal/invoice-modals/revenue-withdraw/OrderDetailTag'
import { Loading } from 'src/common'
import { formatCurrencyByType } from 'src/utils/priceFormatter'

export const RevenueWithdraw = ({
  invoiceItem,
  isLoading,
}: {
  invoiceItem: IInvoiceItem
  isLoading?: boolean
}) => {
  const titleListObject: Record<string, ReactNode> = useMemo(
    () => ({
      dateTime: (
        <TitleWrapper>
          <CalendarOutlined className="!text-[24px] !text-primaryPurple" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:date_time')}
          </Typography.Text>
        </TitleWrapper>
      ),
      teacher: (
        <TitleWrapper>
          <UserOutlined className="!text-[24px] !text-primaryPurple" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:teacher')}
          </Typography.Text>
        </TitleWrapper>
      ),
      method: (
        <TitleWrapper>
          <BankOutlined className="!text-[24px] !text-primaryPurple" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:method')}
          </Typography.Text>
        </TitleWrapper>
      ),
      orderNumber: (
        <TitleWrapper>
          <ContainerOutlined className="!text-[24px] !text-primaryPurple" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:order_number')}
          </Typography.Text>
        </TitleWrapper>
      ),
      total: (
        <TitleWrapper>
          <EuroCircleOutlined className="!text-[24px] !text-primaryPurple" />
          <Typography.Text className="!text-base !text-neutral-400">
            {t('revenue:total')}
          </Typography.Text>
        </TitleWrapper>
      ),
    }),
    []
  )

  const contentListObject: Record<string, ReactNode> = useMemo(
    () => ({
      dateTime: (
        <ContentStyled>
          {!!invoiceItem?.updatedAt
            ? formatDate(invoiceItem?.updatedAt, FORMAT_DATE_TIME)
            : ''}
        </ContentStyled>
      ),
      teacher: (
        <ContentStyled className="!font-bold">
          {invoiceItem?.invoiceDetail?.withdrawalDetail?.teacherName ?? ''}
        </ContentStyled>
      ),
      method: (
        <ContentStyled>
          {capitalize(
            invoiceItem?.invoiceDetail?.withdrawalDetail?.method
          ).replace('_', ' ')}
        </ContentStyled>
      ),
      orderNumber: <ContentStyled>{invoiceItem?.orderId}</ContentStyled>,
      total: (
        <ContentStyled>
          €
          {formatCurrencyByType(
            invoiceItem?.invoiceDetail?.withdrawalDetail?.amount ?? 0,
            'EUR'
          ).replace('€', '')}
        </ContentStyled>
      ),
    }),
    [
      invoiceItem?.createdAt,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.amount,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.method,
      invoiceItem?.invoiceDetail?.withdrawalDetail?.teacherName,
      invoiceItem?.orderId,
    ]
  )

  return (
    <RevenueWithdrawStyled>
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Loading className="!text-[32px]" />
        </div>
      ) : (
        <>
          {Object.entries(titleListObject).map(([key, value], index) => (
            <DetailItemWrapper
              key={key}
              className={`py-4 ${
                (index === 2 || index === 4) &&
                '!border-b !border-b-neutral-300'
              }`}
            >
              {value}
              {contentListObject[key]}
            </DetailItemWrapper>
          ))}
          <OrderDetailTag invoiceItem={invoiceItem} />
        </>
      )}
    </RevenueWithdrawStyled>
  )
}

const RevenueWithdrawStyled = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 24px;
  border: 1px solid ${themes.theme.light.colors.border_gray_100};
`

const DetailItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ContentStyled = styled(Typography.Paragraph)`
  text-align: end;
  font-size: 14px;
  font-weight: 400;
  margin: 0 !important;
`
