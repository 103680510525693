import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { AppModal, SharedCheckbox, XCloseIcon } from '@components'
import { Divider, Empty, RadioChangeEvent, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Button, Loading } from 'src/common'
import { Radio } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { TPaymentInfo } from '@interfaces'
import AddCardModalContainer from './AddCardModal'
import DeleteCardModal from './DeleteCardModal'

interface IProps {
  open: boolean
  onClose: () => void
  isLoadingData: boolean
  listPaymentCards: TPaymentInfo[]
  onSelectCard: (cardInfo: TPaymentInfo | null) => void
  cardInfo: TPaymentInfo | null
}

export default function ChangeCardModal({
  open,
  onClose,
  listPaymentCards,
  isLoadingData,
  onSelectCard,
  cardInfo,
}: IProps) {
  const { t } = useTranslation(['profile', 'common'])
  const [isOpenAddCardModal, setIsOpenAddCardModal] = useState(false)
  const [isOpenDeleteCardModal, setIsOpenDeleteCardModal] = useState(false)

  const [selectedCard, setSelectedCard] = useState('')
  const [deleteCardId, setDeleteCardId] = useState('')

  const listCard = useMemo(() => {
    return listPaymentCards?.map((it) => {
      const { brand, last4 } = it?.paymentMethod?.card || {}

      return {
        value: it?.paymentMethod?.id,
        label: `${brand?.toLowerCase()} ${
          last4 ? `**** **** **** ${last4}` : ''
        }`,
        isDefault: it?.isDefault,
      }
    })
  }, [listPaymentCards])

  useEffect(() => {
    const hasSelectedCard = listCard?.some(
      (card) => card?.value === selectedCard
    )
    if (hasSelectedCard) return

    const defaultCard = listPaymentCards?.find((it) => it?.isDefault)
    if (!defaultCard) return

    setSelectedCard(
      cardInfo?.paymentMethod?.id || defaultCard?.paymentMethod?.id
    )
  }, [cardInfo?.paymentMethod?.id, listCard, listPaymentCards, selectedCard])

  return (
    <>
      <AppModal
        open={open}
        onClose={onClose}
        haveCloseIcon={false}
        contentClassName="!px-8 !py-6"
      >
        <div className="h-auto">
          <div className="flex items-center justify-between gap-4">
            <div>
              <Typography.Title
                style={{ marginBottom: 0, fontSize: 24, fontWeight: 'bold' }}
              >
                {t('profile:changeCard')}
              </Typography.Title>
            </div>
            <div className="hover:opacity-75 cursor-pointer">
              <XCloseIcon width={16} height={16} onClick={onClose} />
            </div>
          </div>
        </div>

        <div className="flex flex-col max-w-[700px] min-w-[500px] mt-4 mb-8 mx-auto">
          {(() => {
            if (isLoadingData)
              return (
                <Loading
                  style={{
                    height: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              )

            if (!Array.isArray(listCard) || !listCard?.length)
              return <Empty style={{ padding: 16 }} />

            return (
              <ListCard
                listCard={listCard}
                isLoadingData={isLoadingData}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setDeleteCardId={setDeleteCardId}
                setIsOpenDeleteCardModal={setIsOpenDeleteCardModal}
              />
            )
          })()}

          <div className="flex mt-6 items-center justify-between">
            <Typography.Title
              style={{ marginBottom: 0, fontSize: 18, fontWeight: 'bold' }}
            >
              {t('profile:addCard')}
            </Typography.Title>

            <PlusCircleOutlined
              style={{ fontSize: 20 }}
              onClick={() => setIsOpenAddCardModal(true)}
            />
          </div>
        </div>

        <div className="flex justify-center items-center my-2 mt-4 gap-2">
          <Button
            danger
            type="ghost"
            onClick={onClose}
            style={{
              width: '100%',
              borderRadius: '100rem',
            }}
          >
            {t('common:cancel')}
          </Button>

          <Button
            type="primary"
            style={{
              width: '100%',
              borderRadius: '100rem',
              background: '#535CE8FF',
            }}
            onClick={() => {
              const chosenCard = listPaymentCards?.find(
                (card) => card?.paymentMethod?.id === selectedCard
              )
              onSelectCard(chosenCard as TPaymentInfo)
              onClose()
            }}
          >
            {t('common:save')}
          </Button>
        </div>
      </AppModal>

      {isOpenDeleteCardModal ? (
        <DeleteCardModal
          open
          onClose={() => setIsOpenDeleteCardModal(false)}
          onDeleteSuccess={() => {
            onSelectCard(null)
            setIsOpenDeleteCardModal(false)
          }}
          cardId={deleteCardId}
        />
      ) : null}

      {isOpenAddCardModal ? (
        <AddCardModalContainer
          open
          onClose={() => setIsOpenAddCardModal(false)}
        />
      ) : null}
    </>
  )
}

interface IListCardProps {
  listCard: {
    value: string
    label: string
    isDefault: boolean
  }[]
  isLoadingData: boolean
  selectedCard: string
  setSelectedCard: (val: string) => void
  setDeleteCardId: (val: string) => void
  setIsOpenDeleteCardModal: (val: boolean) => void
}

function ListCard({
  listCard,
  isLoadingData,
  selectedCard,
  setSelectedCard,
  setDeleteCardId,
  setIsOpenDeleteCardModal,
}: IListCardProps) {
  const { t } = useTranslation(['profile'])
  return (
    <Radio.Group
      onChange={(e: RadioChangeEvent) => {
        setSelectedCard(e?.target?.value)
      }}
      value={selectedCard}
      style={{
        maxHeight: 300,
        overflowY: 'auto',
        padding: '4px 0',
      }}
    >
      <div className="flex flex-col gap-4">
        {isLoadingData ? (
          <Loading
            style={{
              width: '100%',
              height: 400,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        ) : (
          listCard?.map((it, idx) => {
            return (
              <>
                <div className="flex items-center justify-between gap-2 pl-2">
                  <Typography
                    style={{
                      color: '#383838FF',
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                  >
                    {`${it?.label} ${
                      it?.isDefault ? `(${t('profile:default')})` : ''
                    }`}
                  </Typography>

                  <div className="flex items-center gap-0">
                    <SharedCheckbox
                      value={it?.value}
                      type="radio"
                      size="1rem"
                    />

                    <DeleteOutlined
                      style={{ fontSize: 20 }}
                      onClick={() => {
                        setIsOpenDeleteCardModal(true)
                        setDeleteCardId(it?.value)
                      }}
                    />
                  </div>
                </div>

                {idx !== listCard?.length - 1 ? (
                  <Divider style={{ margin: 0 }} />
                ) : null}
              </>
            )
          })
        )}
      </div>
    </Radio.Group>
  )
}
