import { DeleteTwoTone } from '@ant-design/icons'
import { themes } from '@theme'
import i18next from 'i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Input } from 'src/common'
import styled from 'styled-components'
import { TExperienceForm } from '../types'
import { enumBreakPoint, MAT_SM_SCREEN_WIDTH_MIN } from '@configs'

export const ReferenceForm = ({
  data,
  index,
  onRemove,
  onSave,
}: TExperienceForm) => {
  const { control, trigger, setValue } = useFormContext()

  const handleSaveForm = async () => {
    const isValid = await trigger([
      `specificInformation.courseReference[${index}].name`,
      `specificInformation.courseReference[${index}].url`,
    ])

    if (isValid) {
      setValue(`specificInformation.courseReference[${index}].editable`, false)
      onSave?.(index)
    }
  }

  return (
    <StyledExperienceForm className="reset-password__section">
      {!!onRemove && (
        <DeleteTwoTone
          onClick={() => onRemove?.(index)}
          className="self-end text-base"
          twoToneColor={themes.theme.light.colors.icon_gray_500}
        />
      )}

      <Controller
        name={`specificInformation.courseReference[${index}].name`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={i18next.t('common:courseName')}
              alignment="col"
              placeholder={i18next.t('common:inputCourseName')}
              name={`specificInformation.courseReference[${index}].name`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              required
            />
          )
        }}
      />
      <Controller
        name={`specificInformation.courseReference[${index}].url`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Input
              label={i18next.t('common:courseUrl')}
              alignment="col"
              placeholder={i18next.t('common:inputLink')}
              name={`specificInformation.courseReference[${index}].url`}
              containerClassName={'mt-5'}
              errors={error?.message}
              value={value}
              onChange={onChange}
              allowClear={false}
              required
            />
          )
        }}
      />
      <div className="flex items-center max-[800px] justify-center my-5">
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          className="min-[600px] max-[600px]:!w-full"
          onClick={handleSaveForm}
        >
          {i18next.t('common:save')}
        </Button>
      </div>
    </StyledExperienceForm>
  )
}

export const StyledExperienceForm = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .heading {
    margin-bottom: 3.6rem;
    .heading__title {
      text-align: center;
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 0.8rem;
      color: ${(p) => themes.theme.light.colors.text};
    }
    .desc {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${(p) => themes.theme.light.colors.subText};
    }
  }

  .actions {
    @media (min-width: ${enumBreakPoint.DESKTOP}) {
      display: flex;
      align-items: center;
      .btn {
        width: 12.8rem;
      }
    }
  }

  .reset-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    .shared-input {
      margin-bottom: 2rem;
      .inner-input {
        height: 4.6rem;
      }
    }
    .submit__btn {
      @media (min-width: ${MAT_SM_SCREEN_WIDTH_MIN}) {
        margin-right: 1.2rem;
        margin-bottom: 0;
      }
      margin-bottom: 1.2rem;
    }

    .inner-input {
      width: 100%;
      height: 4.6rem;
    }

    .agree-policy__cb {
      margin-bottom: 1.8rem;
    }
  }
`
