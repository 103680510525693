import { z } from 'zod'
import { t } from 'i18next'

import {
  PASSWORD_LENGTH_MAX,
  PASSWORD_LENGTH_MIN,
  PASSWORD_REGEX,
  REGEX_EMAIL,
  REGEX_FULLNAME,
} from '@configs'

export const SignUpGeneralFormSchema = z.object({
  firstname: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required'),
    })
    .max(50, {
      message: t('error:name_staff_length_error'),
    })
    .min(2, {
      message: t('error:name_staff_length_error'),
    })
    .refine(
      (value) => REGEX_FULLNAME.test(value ?? ''),
      t('error:name_invalid')
    ),
  lastname: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required'),
    })
    .max(50, {
      message: t('error:name_staff_length_error'),
    })
    .min(2, {
      message: t('error:name_staff_length_error'),
    })
    .refine(
      (value) => REGEX_FULLNAME.test(value ?? ''),
      t('error:name_invalid')
    ),
  email: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .email({
      message: t('error:email_format_error') as string,
    })
    .regex(REGEX_EMAIL, {
      message: t('error:email_format_error') as string,
    })
    .max(50, {
      message: t('error:email_length_error') as string,
    }),
  password: z
    .string()
    .nonempty({
      message: t('error:field_required'),
    })
    .min(PASSWORD_LENGTH_MIN, t('error:password_min_error'))
    .max(PASSWORD_LENGTH_MAX, t('error:password_max_error'))
    .refine(
      (value) => PASSWORD_REGEX.test(value ?? ''),
      t('error:password_format_error')
    ),
})
