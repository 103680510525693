import React, { memo, ReactNode, useMemo, useState } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { t } from 'i18next'
import { CalendarOutlined } from '@ant-design/icons'

import { ShareSelectInput } from '@components'
import {
  EOverviewDateFilter,
  FILTER_OVERVIEW_DATA,
} from 'src/pages/dashboard/overview/config'
import { themes } from '@theme'
import { RangePicker, RangeValue } from 'src/pages/dashboard/config'
import { FORMAT_DATE_TABLE } from '@configs'
import { convertStartEndDate, EConvertStartDateType } from '@utils'

interface IProps {
  onChangeFilterOverview: ({
    filterBy,
    startDate,
    endDate,
  }: {
    filterBy: EOverviewDateFilter
    startDate?: string
    endDate?: string
  }) => void
  containerClassName?: string
  isDisable?: boolean
}

export const CustomFilterDashboard = memo(
  ({ onChangeFilterOverview, isDisable, containerClassName }: IProps) => {
    const [openFilter, setOpenFilter] = useState(false)
    const [filterBy, setFilterBy] = useState<EOverviewDateFilter | undefined>(
      EOverviewDateFilter.LAST_SIX_MONTHS
    )
    const [rangeDate, setRangeDate] = useState<RangeValue<moment.Moment>>()

    const clearRangPicker = () => {
      setRangeDate(undefined)
    }

    const handleChangeFilterOverview = (
      value: EOverviewDateFilter,
      startDate?: string,
      endDate?: string
    ) => {
      setFilterBy(value)
      if (value !== EOverviewDateFilter.CUSTOM) {
        clearRangPicker()
      }

      onChangeFilterOverview({
        filterBy: value,
        ...(startDate && {
          startDate: convertStartEndDate(
            startDate,
            EConvertStartDateType.START
          ),
        }),
        ...(endDate && {
          endDate: convertStartEndDate(endDate, EConvertStartDateType.END),
        }),
      })
    }

    const filterOverview = useMemo(() => {
      return (
        FILTER_OVERVIEW_DATA as Array<{
          value?: number | string
          label?: string | React.ReactElement
          subLabel?: string | React.ReactElement
          disabled?: boolean
          note?: string
          children?: ReactNode
        }>
      ).concat({
        label:
          filterBy !== EOverviewDateFilter.CUSTOM ? (
            <Typography.Text className="!m-0">
              {t('dashboard:custom')}
            </Typography.Text>
          ) : undefined,
        subLabel: (
          <Typography.Text className="!m-0">
            {`${
              rangeDate?.[0]?.format(FORMAT_DATE_TABLE) ??
              t('dashboard:start_date')
            } -> ${
              rangeDate?.[1]?.format(FORMAT_DATE_TABLE) ??
              t('dashboard:end_date')
            }`}
          </Typography.Text>
        ),
        children:
          filterBy === EOverviewDateFilter.CUSTOM ? (
            <RangePicker
              onFocus={() => {
                setOpenFilter(true)
              }}
              value={rangeDate}
              format={FORMAT_DATE_TABLE}
              allowEmpty={[true, true]}
              onChange={(values) => {
                setRangeDate(values)
                handleChangeFilterOverview(
                  EOverviewDateFilter.CUSTOM,
                  values?.[0]?.toISOString(),
                  values?.[1]?.toISOString()
                )
              }}
              style={{
                fontWeight: 'normal',
                minWidth: 230,
                borderRadius: 6,
                borderColor: `${themes.theme.dark.colors.input.border}`,
              }}
            />
          ) : undefined,
        value: EOverviewDateFilter.CUSTOM,
      })
    }, [filterBy, rangeDate])

    return (
      <CustomSelectStyled>
        <ShareSelectInput
          open={openFilter}
          data={filterOverview}
          value={filterBy}
          onFocus={() => {
            setOpenFilter(true)
          }}
          onBlur={() => {
            setOpenFilter(false)
            if (filterBy === EOverviewDateFilter.CUSTOM && !rangeDate) {
              setFilterBy(EOverviewDateFilter.LAST_SIX_MONTHS)
              handleChangeFilterOverview(EOverviewDateFilter.LAST_SIX_MONTHS)
            }
          }}
          onChange={(value) =>
            handleChangeFilterOverview(value as EOverviewDateFilter)
          }
          containerClassName={containerClassName}
          suffixIcon={<CalendarOutlined size={18} />}
          disabled={isDisable}
          placeholder={t('dashboard:select_time')}
          optionLabelProp="label"
          showArrow
        />
      </CustomSelectStyled>
    )
  }
)

const CustomSelectStyled = styled.div`
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: black !important;
  }
`
