import axios from 'axios'

import {
  EMediaCategory,
  EMediaType,
  IRequestGetMedia,
  IResponseGetMedia,
} from 'src/interfaces/gallery'
import {
  IMultipartInitResponse,
  IMultipartPresignUrlResponse,
  IUploadMediaResponse,
} from 'src/interfaces/media'
import { ApiClient } from './axiosClient'
import { MEDIA_API_PATHS, MEDIA_PATH } from './api.path'
import { BaseResponseProps } from '@interfaces'

export const galleryAPI = {
  getMedia: (params: IRequestGetMedia) => {
    return ApiClient.get<IResponseGetMedia>(`${MEDIA_PATH}?`, {
      params,
    })
  },

  getVideos: (params: {
    category: EMediaCategory
    page: number
    limit: number
  }) => {
    return ApiClient.get<IResponseGetMedia>(
      `${MEDIA_PATH}?type=${EMediaType.VIDEO}`,
      {
        params,
      }
    )
  },
  getImages: (params: {
    category: EMediaCategory
    page: number
    limit: number
  }) => {
    return ApiClient.get<IResponseGetMedia>(
      `${MEDIA_PATH}?type=${EMediaType.IMAGE}`,
      {
        params,
      }
    )
  },

  uploadImage: (file: File, category?: EMediaCategory) => {
    const formData = new FormData()
    formData.append('file', file)
    if (category) formData.append('category', category)

    return ApiClient.post<IUploadMediaResponse>(
      `/media/resize-resolution`,
      formData
    )
  },

  uploadVideo: (file: File, category?: EMediaCategory) => {
    const formData = new FormData()
    formData.append('file', file)
    if (category) formData.append('category', category)

    return ApiClient.post<IUploadMediaResponse>(`/media/video-media`, formData)
  },

  initMultipartUpload: (data: { fileName: string; fileType: string }) => {
    return ApiClient.post<IMultipartInitResponse>(
      `/upload/multipart-upload/init`,
      {
        fileName: data.fileName,
        fileType: data.fileType,
      }
    )
  },

  getMulitpartPresignedUrl: (data: {
    uploadId: string
    key: string
    parts: number
  }) => {
    return ApiClient.post<IMultipartPresignUrlResponse[]>(
      `/upload/multipart-upload/pre-signed-urls`,
      data
    )
  },

  completeUploadMultipart: (data: any) => {
    return ApiClient.post<any>(`/upload/multipart-upload/complete`, data)
  },

  createMedia: (data: any) => {
    return ApiClient.post<IUploadMediaResponse>(`/media/video-media`, data)
  },

  uploadSignUpMedia: (file: File, category?: EMediaCategory) => {
    const formData = new FormData()
    formData.append('file', file)
    if (category) formData.append('category', category)

    return axios.post<BaseResponseProps<IUploadMediaResponse>>(
      `${process.env.REACT_APP_PUBLIC_API_URL}${MEDIA_API_PATHS.TEACHER_UPLOAD_SIGNUP}`,
      formData
    )
  },
}
