import { useAppDispatch } from '@redux'
import { message } from 'antd'
import type React from 'react'
import {
  createContext,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { deleteSectionAction } from 'src/redux/actions/course'
import ModalSelectionLesson from '../../form/lesson/ModalSelectionLesson'
import ModalMedia from 'src/pages/course-management/form/lesson/media/MediaModal'
import { ELessonType } from 'src/interfaces/course'
import ModalFinalTest from 'src/pages/course-management/form/final-test/ModalFinalTest'

interface SectionContextProps {
  sectionId?: number
  sectionIndex?: number
  listSectionsLength?: number
  openModal?: ELessonType
  setOpenModal?: (value: SetStateAction<ELessonType | undefined>) => void
  openModalSelect: () => void
}
const SectionContext = createContext<SectionContextProps | null>(null)

interface SectionProviderProps extends React.PropsWithChildren<any> {
  sectionId: number
  sectionIndex?: number
  listSectionsLength?: number
  onReset: () => void
}
function SectionProvider(props: SectionProviderProps) {
  const { children, sectionId, onReset, sectionIndex, listSectionsLength } =
    props

  const dispatch = useAppDispatch()

  const [openModal, setOpenModal] = useState<ELessonType | undefined>(undefined)

  const closeModal = () => {
    setOpenModal(undefined)
  }
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [openSelectSection, setOpenAddSectionModal] = useState<boolean>(false)

  const closeModalSelect = () => {
    setOpenAddSectionModal(false)
  }
  const openModalSelect = () => {
    setOpenAddSectionModal(true)
  }
  const onDelete = async () => {
    if (!sectionId) {
      return
    }
    try {
      const response = await dispatch(deleteSectionAction(sectionId)).unwrap()
      message.success({
        content: response.message,
      })
      setShowDeleteModal(false)
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }
  const onCloseConfirmModal = () => {
    setShowDeleteModal(false)
  }

  const valueContext = useMemo(
    function () {
      return {
        sectionId,
        sectionIndex,
        listSectionsLength,
        openModal,
        openModalSelect,
        setOpenModal,
      }
    },
    [listSectionsLength, openModal, sectionId, sectionIndex]
  )
  return (
    <SectionContext.Provider value={valueContext}>
      {children}
      <ConfirmDeleteModal
        title="Delete this course's section "
        content="Are you sure to delete this section?"
        open={showDeleteModal}
        isLoading={false}
        onDelete={onDelete}
        onClose={onCloseConfirmModal}
      />
      {openModal === ELessonType.VIDEO ? (
        <ModalMedia
          open={openModal === ELessonType.VIDEO}
          onClose={closeModal}
          selectSection={sectionId}
          onSave={onReset}
        />
      ) : (
        <></>
      )}

      {openModal === ELessonType.FINAL_TEST ? (
        <ModalFinalTest
          open={openModal === ELessonType.FINAL_TEST}
          onClose={() => {
            closeModal()
            onReset()
          }}
          selectSection={sectionId}
          onSave={onReset}
        />
      ) : (
        <></>
      )}
      {/* <ModalSelectionLesson
        open={openSelectSection}
        onClose={closeModalSelect}
        onSave={onReset}
      /> */}
    </SectionContext.Provider>
  )
}

function useSectionContext() {
  const context = useContext(SectionContext)

  if (!context) {
    throw new Error('useSectionContext must be used within a SectionsProvider')
  }

  return context
}

export { SectionProvider, useSectionContext }
