import {
  CLIENT_HEADER_ADMIN,
  CLIENT_HEADER_TEACHER,
  EAuthType,
  EForgotPasswordPageType,
} from '@configs'
import {
  IChangePasswordFields,
  ICommonDataResponse,
  ILoginFields,
  ILoginRequest,
  ILoginResponse,
  IResendCode,
  IResetPassword,
  ISignUpFields,
  IUserResponseLogin,
  IVerifyForgotPassword,
  TFillInTeacherInformation,
} from '@interfaces'
import { ApiClient } from './axiosClient'

const baseEndPoint = '/auth'

export const authAPI = {
  signUp: (values: ISignUpFields) => {
    return ApiClient.post<any, ISignUpFields>(
      `${baseEndPoint}/${EAuthType.TEACHER_SIGNUP}`,
      values
    )
  },

  signUpDetails: (values: TFillInTeacherInformation) => {
    return ApiClient.post<any, TFillInTeacherInformation>(
      `${EAuthType.TEACHER_REQUEST}`,
      values
    )
  },

  updateSignUpDetails: (values: TFillInTeacherInformation) => {
    return ApiClient.put<any, TFillInTeacherInformation>(
      `${EAuthType.TEACHER_REQUEST}`,
      values
    )
  },

  verifyTeacherSignUp: (values: IVerifyForgotPassword) => {
    return ApiClient.post<ICommonDataResponse, IVerifyForgotPassword>(
      `${baseEndPoint}/${EAuthType.TEACHER_VERIFY_SIGNUP}`,
      values
    )
  },

  login: (values: ILoginRequest) => {
    return ApiClient.post<IUserResponseLogin, ILoginRequest>(
      `${baseEndPoint}/${EAuthType.LOGIN}`,
      values
    )
  },
  forgotPassword: (values: ILoginFields) => {
    return ApiClient.post<ICommonDataResponse, ILoginFields>(
      `${baseEndPoint}/${EForgotPasswordPageType.FORGOT_PASSWORD}`,
      values
    )
  },
  verifyPassword: (values: IVerifyForgotPassword) => {
    return ApiClient.post<ICommonDataResponse, IVerifyForgotPassword>(
      `${baseEndPoint}/${EForgotPasswordPageType.VERIFY_FORGOT_PASSWORD}`,
      values
    )
  },

  resendCode: (values: IResendCode) => {
    return ApiClient.post<ICommonDataResponse, IResendCode>(
      `${baseEndPoint}/${EForgotPasswordPageType.RESEND_CODE}`,
      values
    )
  },

  resetPassword: (values: IResetPassword) => {
    return ApiClient.post<{}, IResetPassword>(
      `${baseEndPoint}/${EForgotPasswordPageType.RESET_PASSWORD}`,
      values
    )
  },
  changePassword: (values: IChangePasswordFields) => {
    return ApiClient.post<{}, IChangePasswordFields>(
      `${baseEndPoint}/change-password`,
      values
    )
  },
  initPasswordAdmin: (values: IResetPassword) => {
    return ApiClient.post<{}, IResetPassword>(
      `${baseEndPoint}/init-password-admin`,
      values
    )
  },
}
