import {
  InvalidateQueryFilters,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER } from 'src/configs/queryClient/queryKeyConstants'
import {
  BaseResponseError,
  BaseResponseProps,
  IGetFilterDashboardCourse,
} from '@interfaces'
import { dashboardAPI } from 'src/api/dashboard'

interface IGetDashboardCourseFilter {
  configs?: Omit<
    UseQueryOptions<
      BaseResponseProps<IGetFilterDashboardCourse[]>,
      BaseResponseError
    >,
    'queryKey' | 'queryFn'
  >
}

export const useGetFilterDashboardCourse = ({
  configs,
}: IGetDashboardCourseFilter) => {
  const queryClient = useQueryClient()

  function invalidateData(queryFilters?: InvalidateQueryFilters) {
    queryClient.invalidateQueries({
      queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER],
      type: 'all',
      ...queryFilters,
    })
  }

  function refetchData(queryFilters?: RefetchQueryFilters) {
    queryClient.refetchQueries({
      queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER],
      type: 'all',
      ...queryFilters,
    })
  }

  async function getDashboardListCourseOrders() {
    return dashboardAPI.getDashboardListCourseFilter()
  }

  const { data, ...restData } = useQuery<
    BaseResponseProps<IGetFilterDashboardCourse[]>,
    BaseResponseError
  >({
    queryKey: [QK_GET_DASHBOARD_LIST_COURSE_ORDER_FOR_FILTER],
    queryFn: getDashboardListCourseOrders,

    ...configs,
  })

  return {
    data: data?.data ?? [],
    invalidateData,
    refetchData,
    ...restData,
  }
}
